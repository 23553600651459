import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule],
})
export class BreadcrumbComponent implements OnChanges {
  @Input()
  items: MenuItem[];
  @Output()
  public itemSelected: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items.length > 3) {
      this.items = this.getCollapsedItems(this.items);
    }
  }

  public onItemSelect(id?: string) {
    if (id) {
      this.itemSelected.emit(id);
    }
  }

  private getCollapsedItems(items: MenuItem[]) {
    return [
      items[0],
      { icon: 'pi pi-ellipsis-h' },
      items[items.length - 2],
      items[items.length - 1],
    ];
  }
}
