<div class="drawer-navigation-item">
  <div
    class="content-pane"
    [ngClass]="{ active: isActive, minimized: !maximizeView, 'sub-menu-item': depth > 0 }"
    [ngStyle]="{ height: itemHeight }"
    (click)="onNavItemClicked()"
  >
    <ng-container *ngIf="maximizeView; then maximizedView; else minimizedView"></ng-container>

    <ng-template #maximizedView>
      <app-icon *ngIf="navItem.icon" class="nav-item-icon" [shape]="navItem.icon"></app-icon>
      <span class="nav-item-label" [ngClass]="{ 'no-icon': !navItem.icon }">{{
        navItem.label | translate
      }}</span>
      <app-icon
        class="expand-option"
        *ngIf="hasChildren"
        shape="{{ expandChildren ? 'expand_less' : 'expand_more' }}"
      ></app-icon>
    </ng-template>

    <ng-template #minimizedView>
      <ng-container *ngIf="!hasChildren; else floatingMenu">
        <app-icon
          *ngIf="navItem.icon"
          class="nav-item-icon"
          [shape]="navItem.icon"
          (click)="navigate()"
        ></app-icon>
      </ng-container>

      <ng-template #floatingMenu>
        <app-menu
          #appMenuHost
          *ngIf="navItem.icon"
          class="nav-item-icon"
          direction="rd"
          [icon]="navItem.icon"
          iconSize="24px"
          [backgroundColor]="menuBackgroundColor"
          (click)="$event.stopPropagation(); $event.preventDefault()"
          anchor="component"
          [openOnHover]="true"
          offset="18"
        >
          <div class="floating-menu">
            <span class="floating-menu-header">{{ navItem.label | translate }}</span>
            <ng-content
              *ngTemplateOutlet="childMenus; context: { appMenuHost: appMenuHost }"
            ></ng-content>
          </div>
        </app-menu>
      </ng-template>
    </ng-template>
  </div>

  <div *ngIf="maximizeView && hasChildren && expandChildren">
    <ng-content *ngTemplateOutlet="childMenus"></ng-content>
  </div>
</div>

<ng-template #childMenus let-appMenuHost="appMenuHost">
  <app-drawer-navigation-item
    *ngFor="let child of navItem.children"
    [drawerExpanded]="drawerExpanded"
    [navItem]="child"
    [depth]="depth + 1"
    [currentMenuHost]="appMenuHost ?? currentMenuHost"
  ></app-drawer-navigation-item>
</ng-template>
