<div>
  <app-callout severity="warn">
    <div [innerHTML]="warningText"></div>
  </app-callout>
</div>
<div class="dialog-footer">
  <button
    pButton
    type="button"
    class="ui-button-outline"
    (click)="onCancel()"
    label="{{ 'assetPermissions.buttons.cancel' | translate }}"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    (click)="onGrantPermission()"
    label="{{ 'assetPermissions.buttons.grantPermission' | translate }}"
  ></button>
</div>
