import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-asset-error',
  templateUrl: './asset-error.component.html',
  styleUrls: ['./asset-error.component.scss'],
  standalone: true,
  imports: [IconComponent, ButtonModule, TranslateModule],
})
export class AssetErrorComponent {
  @Input({ required: true }) errorTitle: string;
  @Input({ required: true }) errorDetails: string;
  @Input() icon = 'sentiment_dissatisfied';
  @Input() showNavLink = true;

  reloadApp() {
    window.location.replace('/');
  }
}
