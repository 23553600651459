<p-dialog
  header="Images and Videos"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [blockScroll]="true"
  [focusOnShow]="false"
  [visible]="show()"
  [style]="{ width: '90%', height: '90%', 'max-width': '90%', 'max-height': '90%' }"
  appendTo="body"
>
  <div class="main">
    <div class="viewer">
      <button
        pButton
        type="button"
        class="ui-button-bare-neutral"
        (click)="onPrevious()"
        [disabled]="index() <= 0"
      >
        <app-icon shape="chevron_left" size="large"></app-icon>
      </button>
      @if (currentItem(); as item) {
        @switch (item.type) {
          @case ('image') {
            <app-media-image
              class="image"
              [previewUrl]="item.previewUrl"
              [url]="item.url"
              display="full"
              [selectable]="false"
            ></app-media-image>
          }
          @case ('custom-image') {
            <app-media-custom-image
              class="image"
              [imageUuid]="item.id"
              [filename]="item.filename"
              display="full"
              [asset]="asset()"
              [writable]="writable()"
              [selectable]="false"
            ></app-media-custom-image>
          }
          @case ('video') {
            <app-media-video class="image" [videoId]="item.id" display="full"></app-media-video>
          }
        }
      }
      <button
        pButton
        type="button"
        class="ui-button-bare-neutral"
        (click)="onNext()"
        [disabled]="index() >= maxIndex()"
      >
        <app-icon shape="chevron_right" size="large"></app-icon>
      </button>
    </div>
    <div class="dialog-footer">
      <ng-container [ngTemplateOutlet]="footer()"></ng-container>
      <button pButton type="button" (click)="onClose()">
        {{ 'buttons.close' | translate }}
      </button>
    </div>
  </div>
</p-dialog>
