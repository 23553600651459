import { asyncScheduler, expand, map, Observable, scheduled, SchedulerLike, timer } from 'rxjs';
import { exponentialBackoffDelay, getDelay } from '../utils';

export interface IntervalBackoffConfig {
  initialInterval: number;
  maxInterval?: number;
  backoffDelay?: (iteration: number, initialInterval: number) => number;
}
/**
 * Creates an Observable that emits sequential numbers with by default
 * exponentially increasing interval of time.
 */
export function intervalBackoff(
  config: IntervalBackoffConfig,
  scheduler: SchedulerLike = asyncScheduler,
): Observable<number> {
  const {
    initialInterval,
    maxInterval = Infinity,
    backoffDelay = exponentialBackoffDelay,
  } = config;

  return scheduled([0], scheduler).pipe(
    // Expend starts with number 1 and then recursively
    // projects each value to new Observable and puts it back in.
    expand((iteration: number) =>
      timer(getDelay(backoffDelay(iteration, Math.max(initialInterval, 0)), maxInterval))
        // Once timer is complete, iteration is increased
        .pipe(map(() => iteration + 1)),
    ),
  );
}
