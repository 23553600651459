import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AssetSearchResult, ENVIRONMENT, MainSearchItem, TreeSearchItem } from '../models';
import { AssetSearchCriteria } from '../models/asset-search-criteria';
import { PagingSortOptions } from '../models/paging-sort-options';

@Injectable({ providedIn: 'root' })
export class AssetSearchService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  searchAssets(
    filters?: AssetSearchCriteria,
    pagingOpts?: PagingSortOptions,
  ): Observable<AssetSearchResult<MainSearchItem>> {
    let sortOrder: 'ASC' | 'DESC' | undefined;

    if (pagingOpts?.sortField && pagingOpts?.sortOrder) {
      sortOrder = pagingOpts.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    const types = filters?.selectedTypes?.length ? filters.selectedTypes : undefined;
    const vendors = filters?.selectedVendors?.length ? filters.selectedVendors : undefined;
    const products = filters?.selectedProductNames?.length
      ? filters.selectedProductNames
      : undefined;
    const firmwareStates = filters?.selectedFirmwareStates?.length
      ? filters.selectedFirmwareStates
      : undefined;
    // Empty strings not allowed in backend
    const partNumber = filters?.partNumber || undefined;
    const serialNumber = filters?.serialNumber || undefined;

    let startingAssets: string[] | undefined;
    let depth: { min?: number; equal?: number } | undefined;
    if (filters) {
      // Buildings/locations and all other "belowAssets" form a union of sub-trees. They are
      // restricted by the current selection of trees. This means that if the customer's choice
      // of sub-trees and trees is disjunct the result is an empty list of starting-points. And
      // when that happens this function can immediately return an empty result.
      if (filters.belowAssets || filters.selectedBuildingsOrLocations) {
        startingAssets = (filters.belowAssets || []).concat(
          filters.selectedBuildingsOrLocations || [],
        );
        depth = { min: 1 };
      } else if (filters.selectedTrees) {
        startingAssets = filters.selectedTrees;
      }
    }
    if (startingAssets?.length === 0) {
      return of({ total: 0, offset: 0, pageSize: 0, items: [] });
    }
    return this.httpClient.post<AssetSearchResult<MainSearchItem>>(this.apiUrl + '/v2/search', {
      query: filters?.searchValue || '',
      startingAssets,
      depth,
      types,
      vendors,
      products,
      partNumber,
      serialNumber,
      firmwareStates,
      offset: pagingOpts?.first,
      pageSize: pagingOpts?.rows,
      sortBy: pagingOpts?.sortField,
      sortOrder,
      extraColumns: {
        parentBuildingOrLocation: true,
        parentMachineSystem: true,
        root: true,
      },
    });
  }

  searchAssetsInTree(
    searchString: string,
    rootUuid: string,
    pageSize = 10,
  ): Observable<AssetSearchResult<TreeSearchItem>> {
    return this.httpClient.post<AssetSearchResult<TreeSearchItem>>(this.apiUrl + '/v2/search', {
      query: searchString,
      startingAssets: [rootUuid],
      pageSize,
      extraColumns: {
        root: true,
        parent: true,
      },
    });
  }

  searchAssetByPartNumberSerial(
    partNumber?: string,
    serialNumber?: string,
    pageSize = 10,
  ): Observable<AssetSearchResult> {
    return this.httpClient.post<AssetSearchResult>(this.apiUrl + '/v2/search', {
      partNumber,
      serialNumber,
      pageSize,
    });
  }

  findOutdatedProducts(
    rootUuid: string,
    offset: number,
    pageSize: number,
  ): Observable<AssetSearchResult> {
    return this.httpClient.post<AssetSearchResult>(this.apiUrl + '/v2/search', {
      startingAssets: rootUuid,
      firmwareStates: ['UPDATE_AVAILABLE'],
      offset,
      pageSize,
    });
  }
}
