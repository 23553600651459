<div class="navbar-scroll" data-testid="drawer-navigation">
  <div class="drawer-navigation" [ngClass]="{ expanded }">
    <div class="nav-items">
      <app-drawer-navigation-item
        *ngFor="let navItem of navItems"
        [navItem]="navItem"
        [drawerExpanded]="expanded"
      ></app-drawer-navigation-item>
    </div>
    <div class="divider divider-margin-top"></div>
    <div class="action-bar">
      <app-icon
        class="drawer-navigation-button"
        *ngIf="expanded"
        shape="{{ !isExpandStateLocked ? 'custom_pin_outline' : 'custom_pin_off_outline' }}"
        (click)="persistExpandMode()"
      ></app-icon>
      <app-icon
        class="drawer-navigation-button"
        shape="pi {{ !expanded ? 'pi-double-arrow-right' : 'pi-double-arrow-left' }}"
        (click)="toggleExpandState()"
      ></app-icon>
    </div>
    <div class="divider"></div>
  </div>
</div>
