<div class="asset-error-container">
  <div class="header">
    <app-icon [shape]="icon"></app-icon>
    <h3>
      {{ errorTitle }}
    </h3>
  </div>
  <h6>
    {{ errorDetails }}
  </h6>
  @if (showNavLink) {
    <button
      pButton
      type="button"
      class="ui-button-outline"
      label="{{ 'error.nav-button' | translate }}"
      (click)="reloadApp()"
    ></button>
  }
</div>
