import { FilterMatchMode, FilterMetadata } from 'primeng/api';
import { Observable, Subscription, switchMap, timer } from 'rxjs';
import { isDateRange } from '../models/date-range';
import { isMultiSelectItemArray } from '../models/multiselect';
import { KVStorage } from '../services/browser-storage.service';
import { isNumberRange } from '../models/number-range';
import { TableLazyLoadEvent } from 'primeng/table';

export type LazyLoadResponse<T> = Observable<{ items: T[]; total: number }>;
export interface LazyLoadRequestParams {
  [key: string]: string;
  offset: string;
  size: string;
  order: string;
  dir: 'asc' | 'desc';
}

export class LazyLoadTable<T> {
  items: T[] = [];
  totalRecords = 0;
  loading = true;
  showLoadError = false;
  displayMode: 'content' | 'error' | 'no-data' = 'content';
  first: number;
  sortField: string;
  sortOrder: number;
  visibleRows: number;
  filters: { [k: string]: FilterMetadata };

  private subscription: Subscription;
  private state?: string;

  constructor(
    private requestFactory: (params: LazyLoadRequestParams) => LazyLoadResponse<T>,
    public defaultSortField: keyof T,
    public defaultSortOrder: 1 | -1,
    public defaultVisibleRows = 20,
    private persist?: { id: string; storage: KVStorage },
  ) {
    if (this.persist) {
      this.state = this.persist.storage.get(`table.${this.persist.id}`);
    }
    const setup = this.unpackState();
    this.first = setup.event.first ?? 0;
    this.filters =
      (Array.isArray(setup.event.filters) ? setup.event.filters[0] : setup.event.filters) ?? {};
    this.sortField = (setup.event.sortField ?? this.defaultSortField).toString();
    this.sortOrder = setup.event.sortOrder ?? this.defaultSortOrder;
    this.visibleRows = setup.event.rows ?? this.defaultVisibleRows;
  }

  onLazyLoad(event: TableLazyLoadEvent, customParams?: { [k: string]: string }): void {
    this.loading = true;
    this.displayMode = 'content';
    this.showLoadError = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.state = JSON.stringify({ event, customParams });
    if (this.persist) {
      this.persist.storage.set(`table.${this.persist.id}`, this.state);
    }
    const params: LazyLoadRequestParams = {
      offset: (event.first || 0).toString(),
      size: (event.rows || this.visibleRows).toString(),
      order: (event.sortField || this.sortField).toString(),
      dir: (event.sortOrder || this.sortOrder) === 1 ? 'asc' : 'desc',
      ...customParams,
    };
    if (event.filters) {
      for (const [field, filters] of Object.entries(event.filters)) {
        const filter = (Array.isArray(filters) ? filters[0] : filters) || {};
        switch (filter.matchMode) {
          case FilterMatchMode.IN:
            if (isMultiSelectItemArray(filter.value)) {
              params[field] = filter.value.map(x => x.value).join(',');
            }
            break;
          case FilterMatchMode.CONTAINS:
            if (typeof filter.value === 'string') {
              params[field] = filter.value;
            }
            break;
          case FilterMatchMode.BETWEEN:
            if (isDateRange(filter.value)) {
              const after = filter.value.after
                ? Math.floor(filter.value.after.getTime() / 1000).toString()
                : '';
              const before = filter.value.before
                ? Math.floor(filter.value.before.getTime() / 1000).toString()
                : '';
              if (after || before) {
                params[field] = `${after},${before}`;
              }
            } else if (isNumberRange(filter.value)) {
              const greaterThan = filter.value.greaterThan;
              const lessThan = filter.value.lessThan;
              if (greaterThan || lessThan) {
                params[field] = `${greaterThan},${lessThan}`;
              }
            }
            break;
        }
      }
    }
    this.subscription = timer(250)
      .pipe(switchMap(() => this.requestFactory(params)))
      .subscribe({
        next: result => {
          this.items = result.items;
          this.totalRecords = result.total;
          this.loading = false;
          // Fix-up for tables that persist current position: if server reports less total rows
          // than rows visible, pagination is turned off. This prevents user from going back to
          // the beginning of the table.
          // The fix-up built into the table only kicks in if event.first >= result.total and then
          // it causes one extra call to this function so we better handle this here as well.
          if (event.first) {
            if (result.total <= this.visibleRows || event.first >= result.total) {
              this.first = 0;
              event.first = 0;
              this.onLazyLoad(event, customParams);
            }
          }
          if (
            this.items.length === 0 &&
            (!event.filters || Object.keys(event.filters).length === 0)
          ) {
            this.displayMode = 'no-data';
          }
        },
        error: () => {
          this.items = [];
          this.loading = false;
          this.totalRecords = 0;
          this.showLoadError = true;
          this.displayMode = 'error';
        },
      });
  }

  reload(event: TableLazyLoadEvent = {}): void {
    const state = this.unpackState();
    if (event.first !== undefined) {
      this.first = event.first;
    }
    this.onLazyLoad({ ...state.event, ...event }, state.customParams);
  }

  resetFilters() {
    this.filters = {};
    const state = this.unpackState();
    if (state.event.filters) {
      state.event.filters = {};
      this.state = JSON.stringify(state);
    }
  }

  cancel(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private unpackState(): { event: TableLazyLoadEvent; customParams?: { [k: string]: string } } {
    if (!this.state) {
      return { event: {} };
    }
    return JSON.parse(this.state, (k, v) =>
      k === 'after' ? new Date(v) : k === 'before' ? new Date(v) : v,
    );
  }
}
