<div *ngIf="treeId" class="app-snapshot">
  <ng-container *ngIf="!snapshotId; then createSnapshotMode; else editSnapshotMode"> </ng-container>
  <ng-template #createSnapshotMode
    ><button
      pButton
      type="button"
      class="ui-button-primary"
      label="{{ 'snapshot.createSnapshot' | translate }}"
      icon="pi pi-star-fill"
      [disabled]="loading"
      (click)="createSnapshot()"
    ></button
  ></ng-template>
  <ng-template #editSnapshotMode
    ><button
      pButton
      type="button"
      class="ui-button-primary"
      label="{{ 'snapshot.updateSnapshot' | translate }}"
      icon="pi pi-sync"
      [disabled]="loading"
      (click)="updateSnapshot()"
    ></button>
    <button
      pButton
      type="button"
      [ngClass]="'ui-button-outline'"
      label="{{ 'snapshot.deleteSnapshot' | translate }}"
      icon="pi pi-trash"
      [disabled]="loading"
      (click)="deleteSnapshot()"
    ></button
  ></ng-template>
</div>
