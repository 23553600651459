export class UrlHelper extends Map<string, string> {
  buildUrl(url: string): string {
    return url.replace(/:([a-z][a-zA-Z0-9]*)/g, (...x) => {
      const key = x[1]; // match w/o colon
      const value = this.get(key);
      if (!value) {
        throw new Error(`URL path parameter for ${x[0]} missing`);
      }
      return value;
    });
  }
}
