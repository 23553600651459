import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpFileService {
  constructor(private restService: HttpClient) {}

  public uploadBinary(url: string, data: ArrayBuffer): Observable<object> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/octet-stream')
      .set('x-amz-server-side-encryption', 'AES256');

    return this.restService.put(url, data, {
      headers,
      withCredentials: false,
    });
  }

  public uploadFormData(url: string, formData: FormData): Observable<object> {
    return this.restService.post<object>(url, formData, {
      withCredentials: false,
    });
  }

  public downloadBinary(url: string): Observable<ArrayBuffer> {
    return this.restService.get(url, {
      responseType: 'arraybuffer',
      withCredentials: false,
    });
  }
}
