export function getIconNameForAsset(assetType: string) {
  switch (assetType.toLowerCase()) {
    case 'building':
      return 'custom_building';
    case 'company':
      return 'location_city';
    case 'device':
      return 'memory';
    case 'line':
      return 'device_hub';
    case 'location':
      return 'control_camera';
    case 'machine_system':
      return 'developer_board';
    case 'ship':
      return 'custom_ship';
    case 'software':
      return 'custom_software';
    case 'factory':
      return 'custom_factory';
    default:
      return 'control_camera';
  }
}
