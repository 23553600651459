import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type UserInteraction = WheelEvent | MouseEvent | TouchEvent;

@Injectable({ providedIn: 'root' })
export class MenuService {
  private _hideAll = new Subject<void>();
  private _userInteraction = new Subject<UserInteraction>();

  constructor() {
    window.addEventListener('wheel', event => this._userInteraction.next(event), {
      passive: true,
    });
    window.addEventListener('mousedown', event => this._userInteraction.next(event), {
      passive: true,
    });
    window.addEventListener('touchmove', event => this._userInteraction.next(event), {
      passive: true,
    });
    window.addEventListener('touchstart', event => this._userInteraction.next(event), {
      passive: true,
    });
    window.addEventListener('keyup', event => this.onKeyUp(event), {
      passive: true,
    });
  }

  get hideAll$(): Observable<void> {
    return this._hideAll.asObservable();
  }

  get userInteraction$(): Observable<UserInteraction> {
    return this._userInteraction.asObservable();
  }

  hideAll(): void {
    this._hideAll.next();
  }

  onKeyUp(event: KeyboardEvent): void {
    if (
      event.key === 'Escape' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey &&
      !event.isComposing
    ) {
      this.hideAll();
    }
  }
}
