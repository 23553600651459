import { Component, ViewChild, computed, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectItem } from '@assethub/shared/models/multiselect';
import { LazyLoadTable } from '@assethub/shared/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { TableColumn } from '../table-base/table-base.component';

export interface TableOptions<T> {
  rows: number;
  columns: T[];
}

const DEFAULT_VISIBLE_ROW_OPTIONS: MultiSelectItem[] = [
  { value: '5', displayLabel: '5' },
  { value: '10', displayLabel: '10' },
  { value: '20', displayLabel: '20' },
  { value: '50', displayLabel: '50' },
  { value: '100', displayLabel: '100' },
];

@Component({
  selector: 'app-table-options-menu',
  standalone: true,
  imports: [
    MenuComponent,
    MenuItemComponent,
    TranslateModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    ListboxModule,
  ],
  templateUrl: './table-options-menu.component.html',
  styleUrl: './table-options-menu.component.scss',
})
export class TableOptionsMenuComponent<T, U = keyof T> {
  readonly list = input.required<LazyLoadTable<T>>();

  readonly visibleRowsOptions = input<MultiSelectItem[]>(DEFAULT_VISIBLE_ROW_OPTIONS);

  readonly columns = input.required<TableColumn<U>[]>();
  readonly visibleColumns = input.required<U[]>();

  readonly availableColumnOptions = computed(() => this.computeAvailableColumnOptions());

  readonly selectedVisibleRows = model<string>('');
  readonly selectedColumnOptions = model<MultiSelectItem[]>([]);

  readonly applySettings = output<TableOptions<U>>();
  readonly resetSettings = output();

  @ViewChild('menu') menu: MenuComponent;

  constructor(private translateService: TranslateService) {}

  onShowSettings() {
    this.selectedVisibleRows.set(this.list().visibleRows.toString());

    const visibleColumns = this.visibleColumns();
    const columns = this.columns();
    const columnOptions = columns
      .filter(x => visibleColumns.indexOf(x.field) >= 0)
      .map(x => this.convertTableColumnToMultiSelectItem(x))
      .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
    this.selectedColumnOptions.set(columnOptions);
  }

  onApplySettings(): void {
    this.applySettings.emit({
      rows: parseInt(this.selectedVisibleRows(), 10),
      columns: this.selectedColumnOptions().map(x => x.value as U),
    });
    this.menu.hide();
  }

  private computeAvailableColumnOptions(): MultiSelectItem[] {
    return this.columns()
      .map(x => this.convertTableColumnToMultiSelectItem(x))
      .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
  }

  private convertTableColumnToMultiSelectItem(column: TableColumn<U>): MultiSelectItem {
    return {
      displayLabel: column.label
        ? this.translateService.instant(column.label)
        : String(column.field),
      value: String(column.field),
    };
  }
}
