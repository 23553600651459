import { Component, computed, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AssetIcon, AssetIconComponent } from '../asset-icon/asset-icon.component';
import { NgClass } from '@angular/common';
import { AssetTitlePipe } from '../../pipes';

export interface AssetChip extends AssetIcon {
  uuid?: string;
  customName?: string;
  productName?: string;
}

@Component({
  selector: 'app-asset-chip',
  templateUrl: './asset-chip.component.html',
  styleUrls: ['./asset-chip.component.scss'],
  standalone: true,
  imports: [AssetIconComponent, RouterLink, NgClass],
})
export class AssetChipComponent {
  readonly asset = input.required<AssetChip>();
  readonly navigationTemplate = input<string[]>(['/asset', ':uuid']);
  readonly navigated = output<void>();

  readonly navigation = computed(() => this.computeNavigation());
  readonly label = computed(() => this.computeLabel());

  constructor(private assetTitlePipe: AssetTitlePipe) {}

  private computeLabel(): string {
    const asset = this.asset();
    return this.assetTitlePipe.transform({
      type: asset.type,
      name: asset.customName,
      productName: asset.productName,
    });
  }

  onLinkClicked(event: Event) {
    event.stopPropagation();
    this.navigated.emit();
  }

  private computeNavigation(): string[] | undefined {
    const uuid = this.asset().uuid;
    if (!uuid) {
      return;
    }
    return this.navigationTemplate().map(x => (x === ':uuid' ? uuid : x));
  }
}
