export enum AssetIconMode {
  SYMBOL = 'symbol',
  PHOTO = 'photo',
}

export enum AssetTitleMode {
  OFFICIAL = 'official',
  CUSTOM = 'custom',
}

export interface UserConfiguration {
  showAsIcon: AssetIconMode;
  showAsTitle: AssetTitleMode;
  navDrawerExpanded: boolean;
  lastTreeOpenedId?: string;
  hideMapInfo: boolean;
  assetSearch: {
    sortField?: string;
    sortOrder?: number;
    hiddenColumns?: string[];
  };
  lastReadReleaseNote: string;
}
