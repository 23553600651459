import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { catchError, Observable, of } from 'rxjs';
import { ThingDescription } from 'wot-thing-description-types';

@Injectable({
  providedIn: 'root',
})
export class ThingDescriptionService {
  private icc2Url = inject(ENVIRONMENT).icc2Url;

  constructor(private httpClient: HttpClient) {}

  public getThingDescription(thingId: string): Observable<ThingDescription | undefined> {
    if (!this.icc2Url) {
      return of(undefined);
    }
    return this.httpClient
      .get<ThingDescription>(`${this.icc2Url}/wot/things/${thingId}`)
      .pipe(catchError((err: any) => of(undefined)));
  }
}
