import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FrontendInstanceService {
  private id: string;

  public constructor() {
    if (crypto && crypto.randomUUID) {
      this.id = `urn:sick:assethub:origin:${crypto.randomUUID()}`;
    } else {
      // for running on an IPad against backend instance without SSL within local network
      this.id = `urn:sick:assethub:origin:no-crypto`;
    }
  }

  public getId(): string {
    return this.id;
  }

  public isSameInstance(id: string | undefined): boolean {
    if (id === undefined) {
      return false;
    }

    return id === this.id;
  }
}
