import { Component, Input, OnChanges, OnInit, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { FIRMWARE_STATE_ICON, FirmwareState } from '@assethub/shared/models/firmware-state';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { IconComponent } from '../icon/icon.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-firmware-state-icon',
  templateUrl: './firmware-state-icon.component.html',
  styleUrls: ['./firmware-state-icon.component.scss'],
  standalone: true,
  imports: [IconComponent, TooltipComponent, TranslateModule, SafeHtmlPipe, CommonModule],
})
export class FirmwareStateIconComponent implements OnInit, OnChanges {
  @Input() firmwareState: FirmwareState = 'UNKNOWN';
  @Input() showLabel = true;

  color: string;
  size: string;
  icon: string;
  label: string;
  labelColor: string;
  description: string;
  envFeatures = inject(ENVIRONMENT).envSpecificFeatures;

  constructor() {
    this.update();
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  private update(): void {
    const icon = FIRMWARE_STATE_ICON[this.firmwareState];
    this.icon = icon.shape;
    this.color = icon.color;
    this.size = icon.size || '24px';
    this.label = icon.label;
    this.labelColor = icon.labelColor || icon.color;
    this.description = `asset.firmware.state.${this.firmwareState}.description`;
  }
}
