export interface NumberRange {
  greaterThan?: number;
  lessThan?: number;
}

export function isNumberRange(o: unknown): o is NumberRange {
  if (o === null || typeof o !== 'object') {
    return false;
  }
  if ('greaterThan' in o && typeof o.greaterThan === 'undefined') {
    return false;
  }
  if ('lessThan' in o && typeof o.lessThan === 'undefined') {
    return false;
  }
  return true;
}
