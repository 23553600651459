@if (sortOrder === -1) {
  <app-icon shape="custom_arrow_down"></app-icon>
} @else if (sortOrder === 1) {
  <app-icon shape="custom_arrow_up"></app-icon>
}
@if (filterActive) {
  <app-icon shape="filter_alt"></app-icon>
}
@if (type !== 'unsortable' || filterMode !== 'none') {
  <app-menu
    #menu
    icon="pi pi-chevron-down"
    class="ui-button-bare-neutral"
    (showMenu)="onShowMenu()"
  >
    @if (type !== 'unsortable') {
      <app-menu-item
        label="{{ labelSortAscending | translate }}"
        icon="custom_arrow_up"
        (selected)="onSortColumn(1)"
      ></app-menu-item>
      <app-menu-item
        label="{{ labelSortDescending | translate }}"
        icon="custom_arrow_down"
        (selected)="onSortColumn(-1)"
      ></app-menu-item>
    }
    @if (filterMode !== 'none') {
      @if (type !== 'unsortable') {
        <hr />
      }
      @if (filterActive) {
        <app-menu-item
          label="{{ 'sort-icon.clear-filter' | translate }}"
          icon="filter_alt_off"
          (selected)="onClearFilter()"
        ></app-menu-item>
      }
      @switch (filterMode) {
        @case ('listbox') {
          <p-listbox
            [options]="availableFilter"
            [(ngModel)]="currentFilterSelection"
            [multiple]="true"
            [checkbox]="true"
            optionLabel="displayLabel"
            [showToggleAll]="true"
            scrollHeight="250px"
            [style]="{ 'max-width': '300px' }"
            (onChange)="onFilterSelectionChanged($event)"
          >
            <ng-template pTemplate="filter">{{ 'sort-icon.select-all' | translate }}</ng-template>
            <ng-template pTemplate="item" let-filter>
              @if (filter.icon) {
                <app-icon [shape]="filter.icon" [color]="filter.iconColor"></app-icon>
              }
              <span>{{ filter.displayLabel }}</span>
            </ng-template>
          </p-listbox>
        }
        @case ('text') {
          <app-text-field
            label="{{ 'sort-icon.contains-text' | translate }}"
            [(ngModel)]="currentSearchText"
            (keyup.enter)="onEnterKey($event)"
          ></app-text-field>
        }
        @case ('daterange') {
          <app-date-field
            label="{{ 'sort-icon.after' | translate }}"
            [(ngModel)]="currentDateRange.after"
            (keyup.enter)="onEnterKey($event)"
          ></app-date-field>
          <app-date-field
            label="{{ 'sort-icon.before' | translate }}"
            [(ngModel)]="currentDateRange.before"
            (keyup.enter)="onEnterKey($event)"
          ></app-date-field>
        }
        @case ('numberrange') {
          <app-number-field
            label="{{ 'sort-icon.greater-than' | translate }}"
            [(ngModel)]="currentNumberRange.greaterThan"
            (keyup.enter)="onEnterKey($event)"
          ></app-number-field>
          <app-number-field
            label="{{ 'sort-icon.less-than' | translate }}"
            [(ngModel)]="currentNumberRange.lessThan"
            (keyup.enter)="onEnterKey($event)"
          ></app-number-field>
        }
      }
      <div class="filter-buttons">
        <button
          pButton
          type="button"
          label="{{ 'sort-icon.apply-filter' | translate }}"
          [disabled]="noFilterSelected"
          (click)="onApply()"
        ></button>
        <button
          pButton
          type="button"
          class="ui-button-outline"
          label="{{ 'buttons.cancel' | translate }}"
          (click)="onCancel()"
        ></button>
      </div>
    }
    <ng-content></ng-content>
  </app-menu>
}
