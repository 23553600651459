/* eslint-disable @typescript-eslint/naming-convention */
import { AssetTree, assetTypeMappingToString } from './asset-details';
import { CrmId } from './crm-id';

export interface UpdateInfos {
  uuid: string;
  productName?: string;
  type?: number;
  name?: string;
  profilePicture?: string;
  productPictureUrl?: string;
  imo?: string;
}

export type AssetItemMap = Map<string, AssetItem>;

export class AssetItem {
  public name?: string;
  public icon?: string;
  public expanded?: boolean;
  public hasChildren: boolean;
  public productName?: string;
  public productPictureUrl?: string;
  public profilePicture?: string;
  public type: string;
  public extern: boolean;

  protected writeable: boolean;
  protected _administrable: boolean;
  protected _permission?: string;
  protected _root?: AssetRootItem;
  private _children: AssetItem[] = [];
  private _parent?: AssetItem;
  private _map: AssetItemMap;

  protected constructor(public readonly uuid: string) {}

  get children(): readonly AssetItem[] {
    return this._children;
  }

  get parent(): AssetItem | undefined {
    return this._parent;
  }

  get root(): AssetRootItem {
    if (this._root === undefined) {
      throw new Error('Cannot access root in dangling node');
    }

    return this._root;
  }

  get readonly(): boolean {
    return !this.writeable;
  }

  get administrable(): boolean {
    return this._administrable;
  }

  get virtual(): boolean {
    return false;
  }

  get permission(): string | undefined {
    return this._permission;
  }

  public static build(map: AssetItemMap, entity: AssetTree) {
    const node = new this(entity.uuid);
    node.assign(map, entity);
    return node;
  }

  protected assign(map: AssetItemMap, entity: AssetTree) {
    this._map = map;

    this.name = entity.name;
    this.productName = entity.productName;
    this.productPictureUrl = entity.productPictureUrl;
    this.profilePicture = entity.profilePicture;
    this.hasChildren = entity.hasChildren || entity.children.length > 0;
    this.extern = entity.extern || false;

    this.type = entity.type.toLowerCase();
    this.icon = `${this.type}.svg`;
    this.expanded = entity.children && entity.children.length > 0;

    this._permission = entity.permission;
    this.updatePermissions();

    for (const child of entity.children) {
      this.appendChild(AssetItem.build(map, child));
    }

    if (this._children.length === 0 && this.hasChildren) {
      this.expanded = false;
    }

    return this;
  }

  public equals(other: AssetItem): boolean {
    return (
      this.name === other.name &&
      this.productName === other.productName &&
      this.productPictureUrl === other.productPictureUrl &&
      this.profilePicture === other.profilePicture &&
      this.type === other.type &&
      this._permission === other._permission &&
      this.expanded === other.expanded
    );
  }

  protected updatePermissions() {
    if (true === this._root?.managed) {
      this.writeable = false;
      this._administrable = false;
      return;
    }

    this._administrable =
      this._permission?.includes('a') === true || this._parent?._administrable === true;

    this.writeable =
      this._administrable ||
      this._permission?.includes('w') === true ||
      this._parent?._administrable === true;
  }

  public setParent(parent: AssetItem) {
    this._parent = parent;
    this.setRoot(parent._root);
  }

  private setRoot(root?: AssetRootItem) {
    this._root = root;
    this.register();
    this.updatePermissions();

    for (const child of this._children) {
      child.setRoot(root);
    }
  }

  public isRoot(): boolean {
    return false;
  }

  public proxy(): this {
    return new Proxy(this, {});
  }

  public clearChildren(): void {
    this._children = [];
    this.hasChildren = false;
  }

  public prependChild(...children: AssetItem[]) {
    for (const child of children) {
      child?.parent?.abandonChild(child);
      child.setParent(this);
    }
    this._children.unshift(...children);
    this.hasChildren = this._children.length > 0;
  }

  public appendChild(...children: AssetItem[]) {
    for (const child of children) {
      child?.parent?.abandonChild(child);
      child.setParent(this);
    }
    this._children.push(...children);
    this.hasChildren = this._children.length > 0;
  }

  public insertChildAfter(predecessor: AssetItem, child: AssetItem) {
    if (!this._children.some(x => x === predecessor)) {
      throw new Error('Unknown predecessor');
    }

    child.parent?.abandonChild(child);

    const pos = this._children.findIndex(x => x === predecessor);
    child.setParent(this);
    this._children.splice(pos + 1, 0, child);
  }

  public remove(): void {
    if (undefined !== this._parent) {
      this._parent.abandonChild(this);
    }

    this.release();
  }

  protected register(): void {
    this._map.set(this.uuid, this);
  }

  private release(): void {
    for (const child of this._children) {
      child.release();
    }

    this._children = [];
    this._map.delete(this.uuid);
  }

  public applyUpdate(updateInfos: UpdateInfos) {
    if (undefined !== updateInfos.name) {
      this.name = updateInfos.name;
    }
    if (updateInfos.type) {
      const type = assetTypeMappingToString[updateInfos.type];
      if (updateInfos.type) {
        this.type = type;
        this.icon = type + '.svg';
      }
    }
    if (undefined !== updateInfos.productName) {
      this.productName = updateInfos.productName;
    }
    if (undefined !== updateInfos.productPictureUrl) {
      this.productPictureUrl = updateInfos.productPictureUrl;
    }
    if (undefined !== updateInfos.profilePicture) {
      this.profilePicture = updateInfos.profilePicture;
    }
  }

  public childrenLoaded(): boolean {
    return this.hasChildren === false || this._children.length > 0;
  }

  private abandonChild(child: AssetItem): void {
    const index = this._children.findIndex(iterator => iterator === child);
    if (-1 === index) {
      return;
    }

    this._children.splice(index, 1);
    if (this._children.length === 0) {
      this.expanded = false;
      this.hasChildren = false;
    }
  }
}

export class AssetRootItem extends AssetItem {
  snapshotId?: string;
  selectedNode: string;
  managed: boolean;
  crm365: boolean;
  owner?: {
    company?: string;
    fullname?: string;
    uuid?: string;
  };
  customerNumber?: string;
  subsidiaryCode?: string;
  protected _virtual: boolean;

  protected constructor(uuid: string) {
    super(uuid);
    this._root = this;
  }

  override get virtual(): boolean {
    return this._virtual;
  }

  protected override updatePermissions(): void {
    super.updatePermissions();
    this._virtual = this._permission === '-';
  }

  public static override build(
    map: AssetItemMap,
    tree: AssetTree,
    customerIds?: CrmId[],
  ): AssetRootItem {
    const root = new this(tree.uuid);

    root.owner = tree.owner;
    root.snapshotId = tree.snapshotId;
    root.selectedNode = tree.rootUuid;
    root.managed = tree.extern ?? false;
    root.crm365 = false;
    root.customerNumber = tree.customerNumber;
    root.subsidiaryCode = tree.subsidiaryCode;

    if (
      customerIds !== undefined &&
      tree.customerNumber !== undefined &&
      tree.subsidiaryCode !== undefined
    ) {
      const foundCustomerId = customerIds.find(
        x => x.customerNumber === tree.customerNumber && x.subsidiaryCode === tree.subsidiaryCode,
      );
      if (foundCustomerId !== undefined) {
        root.crm365 = true;
      }
    }

    root.assign(map, tree);
    root.register();

    return root;
  }

  public override isRoot(): boolean {
    return true;
  }
}
