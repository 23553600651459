<div class="messagebox">
  <h1>
    {{ 'welcome.header' | translate }}
  </h1>

  @if (haveTrees() === false) {
    @if (allowCreateTree()) {
      <p>{{ 'welcome.create-tree.text' | translate }}</p>
      <div class="center-button">
        <button
          pButton
          type="button"
          class="ui-button-primary"
          label="{{ 'welcome.create-tree.button' | translate }}"
          icon="pi pi-plus"
          [disabled]="sendingRequest()"
          (click)="createTree()"
        ></button>
      </div>
    } @else if (treeRequestPending()) {
      <app-callout severity="success">
        <span>{{ 'welcome.request-successful.title' | translate }}</span>
      </app-callout>
      <div class="message">
        <span>{{ 'welcome.request-successful.message' | translate }}</span>
      </div>
    } @else {
      <p>{{ 'welcome.request-tree.text' | translate }}</p>
      <div class="center-button">
        <button
          pButton
          type="button"
          class="ui-button-primary"
          label="{{ 'welcome.request-tree.button' | translate }}"
          icon="pi pi-plus"
          [disabled]="sendingRequest()"
          (click)="requestTree()"
        ></button>
      </div>
    }
  }
</div>
