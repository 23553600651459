import {
  Component,
  computed,
  HostBinding,
  HostListener,
  input,
  Optional,
  output,
  SkipSelf,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasicMenuComponent } from '../basic-menu.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [IconComponent, TooltipComponent],
})
export class MenuItemComponent {
  readonly label = input('');
  readonly icon = input<string>();
  readonly iconSrc = input('');
  readonly iconName = input<string>();
  readonly reason = input('');
  readonly checked = input(false);
  readonly disabled = input(false);

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.disabled();
  }

  readonly selected = output();

  readonly shape = computed(() => this.computeShape());

  constructor(
    private sanitizer: DomSanitizer,
    @SkipSelf() @Optional() private parentMenu: BasicMenuComponent | null,
  ) {}

  @HostListener('click', [])
  onClick() {
    if (this.isDisabled) {
      return;
    }
    if (this.parentMenu) {
      this.parentMenu.hideAll();
    }
    this.selected.emit();
  }

  private computeShape(): string | SafeHtml | undefined {
    const iconSrc = this.iconSrc();
    if (iconSrc) {
      // [innerHtml] filters out SVGs. To make it work it has to be sanitized explicitly
      return this.sanitizer.bypassSecurityTrustHtml(iconSrc);
    }
    return this.icon() ?? this.iconName();
  }
}
