import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

export interface ResizedEvent {
  newRect: DOMRectReadOnly;
  oldRect?: DOMRectReadOnly;
  isFirst: boolean;
}

@Directive({
  selector: '[resized]',
  standalone: true,
})
export class ResizedDirective implements OnInit, OnDestroy {
  private observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  @Output()
  resized: Readonly<EventEmitter<ResizedEvent>>;

  constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone,
  ) {
    this.resized = new EventEmitter();
    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      this.zone.run(() => this.observe(entries)),
    );
  }

  ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent: ResizedEvent = {
      newRect: domSize.contentRect,
      oldRect: this.oldRect,
      isFirst: !this.oldRect,
    };
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}
