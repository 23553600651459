import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isUrl(url: string | undefined): boolean {
  const urlReg = new RegExp(
    /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
    'gi',
  );
  return url ? urlReg.test(url) : false;
}

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const url = control.value;
    return isUrl(url) ? null : { url: url };
  };
}
