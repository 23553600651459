import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { assetTypeMappingToString } from '@assethub/shared/models';
import { AssetTitlePipe } from '@assethub/shared/pipes';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { IconComponent } from '../icon/icon.component';
import { OutsideActionDirective } from '../../directives/action-outside.directive';
import { NgClass } from '@angular/common';

export interface PinMetadata {
  typeId: number;
  profilePicture?: string;
  productPictureUrl?: string;
  name?: string;
  productName?: string;
  assetUuid: string;
}

interface PinMetadataInternal {
  typeId: number;
  profilePicture?: string;
  productPictureUrl?: string;
  name?: string;
  productName?: string;
  assetUuid: string;
  type: string;
  icon: string;
}

@Component({
  selector: 'app-bouncing-pin',
  templateUrl: './bouncing-pin.component.html',
  styleUrls: ['./bouncing-pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, OutsideActionDirective, IconComponent, AssetIconComponent],
})
export class BouncingPinComponent implements OnInit {
  @Input()
  public asset: PinMetadata;

  public assetInfo: PinMetadataInternal;

  public active = false;

  private readonly pinTitleGraphic =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xhtml="http://www.w3.org/1999/xhtml" viewBox="0 0 190.81 40" style="enable-background:new 0 0 190.81 40" xml:space="preserve"><path d="M804 608H617.19a29.84 29.84 0 0 0 8.15-20.56c.01-3.6-.64-7.17-1.9-10.54a29.745 29.745 0 0 0-5.25-8.9H804c2.21 0 4 1.79 4 4v32c0 2.21-1.79 4-4 4z" transform="translate(-617.186 -568)"/><foreignObject width="90%" height="100%" x="1em" y="0"><xhtml:div style="width: 100%; height: 100%; display: flex; align-items: center;"><xhtml:span style="color: #fff; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: 700; margin-left: 0.5em; font-size: 1em;">{{label}}</xhtml:span></xhtml:div></foreignObject></svg>';

  constructor(
    private sanitizer: DomSanitizer,
    private assetTitlePipe: AssetTitlePipe,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    if (this.asset) {
      const type = assetTypeMappingToString[this.asset.typeId] || 'location';
      this.assetInfo = {
        ...this.asset,
        type: type,
        icon: `${type}.svg`,
      };
    }
  }

  getPinHeaderSvg(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.pinTitleGraphic.replace('{{label}}', this.assetTitlePipe.transform(this.assetInfo)),
    );
  }

  public onPinClicked() {
    this.active = !this.active;
    this.cd.detectChanges();
  }

  public unclickPin() {
    this.active = false;
    this.cd.detectChanges();
  }

  navigateToDetails() {
    return this.router.navigate(['/asset', this.asset.assetUuid]);
  }

  navigateToMap() {
    return this.router.navigate(['/asset', this.asset.assetUuid, 'map']);
  }
}
