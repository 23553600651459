<span class="p-float-label">
  <input
    [name]="name"
    [type]="type"
    pInputText
    [attr.placeholder]="placeholder ? placeholder : null"
    [(ngModel)]="contents"
    (blur)="onBlur()"
    [required]="required"
    [disabled]="disabled"
    [readonly]="readonly"
    [value]="value"
    [ngClass]="{ 'bad-input': error || !!errorMsg }"
    [tooltipDisabled]="!showTooltip"
    [pTooltip]="toolTipText"
    [escape]="!renderTooltipAsHtml"
    [hideDelay]="100"
    [tooltipStyleClass]="tooltipStyleClass"
    [tooltipPosition]="tooltipPosition"
    tooltipEvent="focus"
    appendTo="body"
  />
  <label>{{ label }}</label>
  <span
    class="pi pi-exclamation-circle error-icon"
    [ngClass]="{ 'show-pointer': handleErrorIconClick }"
    (click)="errorIconClick()"
  ></span>
</span>
<span *ngIf="!!errorMsg" class="errormsg">{{ errorMsg }}</span>
