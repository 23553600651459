import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { NotificationMessage, Permission, isPermissionNotification } from '../models';
import { filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TreeService } from './tree.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionEventService {
  constructor(
    private messageService: MessageService,
    private notificationService: NotificationService,
    private treeService: TreeService,
  ) {}

  public initialize(): void {
    this.notificationService.notification$
      .pipe(filter(msg => isPermissionNotification(msg)))
      .subscribe((msg: NotificationMessage) => this.handleWsPermissionEvent(msg.permission));
  }

  private handleWsPermissionEvent(permissionEvent: Permission | undefined) {
    if (undefined === permissionEvent) {
      return;
    }
    // Handle events affecting the current logged in user (account not provided)
    if (undefined !== permissionEvent.account) {
      return;
    }

    if (permissionEvent.action === 'granted' && !permissionEvent.supersededRootNodes) {
      this.messageService.add({
        severity: 'info',
        summary: 'assetPermissions.info.granted-on-asset',
        life: 10000,
      });
    } else if (permissionEvent.action === 'revoked') {
      const revokedNode = this.treeService.findNode(permissionEvent.uuid);
      if (revokedNode && this.treeService.isActiveTree(revokedNode.uuid)) {
        this.messageService.add({
          severity: 'info',
          summary: 'assetPermissions.info.revoked-current-tree',
          sticky: true,
          closable: true,
          life: 10000,
        });
      } else {
        this.messageService.add({
          severity: 'info',
          summary: 'assetPermissions.info.revoked',
          sticky: true,
          closable: true,
          life: 10000,
        });
      }
    }
  }
}
