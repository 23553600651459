import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import {
  ContactsResponse,
  GrantedPermission,
  PermissionsQuery,
  PermissionsResponse,
  UserPermissions,
} from '../models/permissions';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  getContacts(assetUuid: string, qp?: PermissionsQuery): Observable<ContactsResponse> {
    const query: { [param: string]: string } = {};
    if (qp) {
      if (qp.start && qp.start >= 0) {
        query.start = qp.start.toString();
      }
      if (qp.size) {
        query.size = qp.size.toString();
      }
    }

    return this.httpClient.get<ContactsResponse>(`${this.apiUrl}/asset/${assetUuid}/contacts`, {
      params: query,
    });
  }

  public getPermissions(assetUuid: string, qp?: PermissionsQuery): Observable<PermissionsResponse> {
    const query: { [param: string]: string } = {};
    if (qp) {
      if (qp.start && qp.start >= 0) {
        query.start = qp.start.toString();
      }
      if (qp.size) {
        query.size = qp.size.toString();
      }
    }

    return this.httpClient.get<PermissionsResponse>(
      this.apiUrl + '/asset/' + assetUuid + '/permissions',
      { params: query },
    );
  }

  public grantPermissionsToUser(assetUuid: string, permissions: UserPermissions): Observable<void> {
    return this.httpClient.put<void>(this.apiUrl + '/asset/' + assetUuid + '/permission', {
      ...permissions,
      sendMail: true,
    });
  }

  public revokePermissionsForUser(
    assetUuid: string,
    username: string,
  ): Observable<GrantedPermission | void> {
    return this.httpClient.delete<GrantedPermission>(
      this.apiUrl + '/asset/' + assetUuid + '/permission/' + username,
    );
  }
}
