import { AssetTree, AssetUpdateRequest } from './asset-details';

export interface Permission {
  action: 'granted' | 'revoked' | 'changed';
  uuid: string;
  account?: string;
  permission?: string;
  supersededRootNodes?: string[];
}

export interface AssetCreated {
  action: 'created';
  uuid: string;
  typeId: number;
  customName: string;
  description: string;
  rootUuid: string;
  parentUuid?: string;
  created: number;
  updated: number;
  productName?: string;
  productPictureUrl?: string;
  sourceType: string;
}

export interface AssetCloned extends AssetTree {
  action: 'cloned';
  parentUuid: string;
  insertAfterUuid?: string;
}

export interface AssetChanged extends AssetUpdateRequest {
  action: 'changed';
  uuid: string;

  // ... now all the fields from the request
}

export interface AssetMoved {
  action: 'moved';
  uuid: string;
  destination: string;
  toStartOfChildren: boolean;
}

export interface AssetDeleted {
  action: 'deleted';
  uuid: string;
  movedToTrash?: true;
}

export interface AssetAASGenerated {
  action: 'aasxFileCreated';
  aasxDownloadUrl: string;
  uuid: string;
  fileName: string;
}

export function isAssetNotification(
  notification: NotificationMessage,
): notification is Required<Pick<NotificationMessage, 'asset'>> {
  return notification.asset !== undefined;
}

export function isAssetAASNotification(
  notification: NotificationMessage,
): notification is Required<Pick<NotificationMessage, 'aas'>> {
  return !!notification.aas;
}

export function isPermissionNotification(
  notification: NotificationMessage,
): notification is Required<Pick<NotificationMessage, 'permission'>> {
  return !!notification.permission;
}

export interface NotificationMessage {
  originId?: string;
  permission?: Permission;
  asset?: AssetCreated | AssetCloned | AssetChanged | AssetMoved | AssetDeleted;
  aas?: AssetAASGenerated;
}
