<app-navigation-spinner></app-navigation-spinner>

<h3>{{ 'open-with.product-id.title' | translate }}</h3>

<app-callout *ngIf="showLoadingIndicator"
  ><div class="label">
    <p-progressSpinner strokeWidth="4"></p-progressSpinner>
    <div>{{ 'open-with.product-id.loading' | translate }}</div>
  </div>
</app-callout>

<app-callout *ngIf="showCreateHint"
  ><span>{{ 'open-with.product-id.next-steps.title' | translate }}</span>
  <ol>
    <li>
      <a [routerLink]="['/asset']">{{ 'open-with.product-id.next-steps.list.0' | translate }}</a>
    </li>
    <li>{{ 'open-with.product-id.next-steps.list.1' | translate }}</li>
    <li>{{ 'open-with.product-id.next-steps.list.2' | translate }}</li>
    <li>{{ 'open-with.product-id.next-steps.list.3' | translate }}</li>
    <li>{{ 'open-with.product-id.next-steps.list.4' | translate }}</li>
  </ol>
</app-callout>

<ng-container *ngIf="matchingAssets.length > 0">
  <app-callout severity="success">{{
    'open-with.product-id.found-assets' | translate
  }}</app-callout>

  <div>
    <ng-container *ngFor="let item of matchingAssets">
      <ng-container *ngTemplateOutlet="searchResult; context: { item: item }"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #searchResult let-searchResult="item">
  <div class="row" (click)="openAsset(searchResult.uuid)">
    <div *ngIf="searchResult.productPictureUrl" class="productPicture">
      <img
        class="searchResultPicture"
        [src]="searchResult | assetImageUrl | async"
        width="48"
        height="48"
      />
    </div>
    <div class="info">
      <div class="primary">
        <a [routerLink]="['/asset', searchResult.uuid]">
          <div>
            {{ searchResult.customName || searchResult.productName }}
          </div>
        </a>
      </div>
      <div class="secondary">
        <span *ngIf="searchResult.customName">
          {{ 'open-with.product-id.asset-fields.productName' | translate }}:
          {{ searchResult.productName }} |
        </span>
        <span>
          {{ 'open-with.product-id.asset-fields.partNumber' | translate }}:
          {{ searchResult.partNumber }} |
        </span>
        <span>
          {{ 'open-with.product-id.asset-fields.serialNumber' | translate }}:
          {{ searchResult.serialNumber }} |
        </span>
        <span class="tree">
          {{ 'open-with.product-id.asset-fields.tree' | translate }}:
          {{ searchResult.rootName }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
