import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerMenuItem } from '@assethub/shared/models';
import { SDSColors } from '@assethub/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuComponent } from '../menu/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgStyle, NgIf, NgTemplateOutlet, NgFor } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-drawer-navigation-item',
  templateUrl: './drawer-navigation-item.component.html',
  styleUrls: ['./drawer-navigation-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgIf,
    IconComponent,
    MenuComponent,
    NgTemplateOutlet,
    NgFor,
    TranslateModule,
  ],
})
export class DrawerNavigationItemComponent implements OnInit, OnChanges {
  @Input() navItem: DrawerMenuItem;
  @Input() drawerExpanded = false;
  @Input() depth = 0;
  @Input() currentMenuHost: MenuComponent | undefined;

  isActive = false;
  hasChildren = false;
  expandChildren: boolean | null =
    null; /* null means a user did not manually open/close the current items sub-menus */
  maximizeView = false;
  itemHeight = '48px';
  readonly menuBackgroundColor = SDSColors.secondary;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navItem']?.currentValue !== undefined) {
      this.hasChildren = this.navItem.children !== undefined && this.navItem.children.length > 0;
    }

    if (changes['drawerExpanded']?.currentValue !== undefined) {
      // Show full item content if drawer is expanded or current item is shown inside app menu
      this.maximizeView = this.drawerExpanded === true || this.currentMenuHost !== undefined;
      if (
        this.expandChildren === null &&
        this.hasChildren === true &&
        this.drawerExpanded === true
      ) {
        // If drawer navigation is expanded and user did not expanded / collapsed this item, we display sub-menus below
        this.expandChildren = true;
      }

      this.updateActivationState(this.router.url.toString());
    }
  }

  ngOnInit(): void {
    if (!this.navItem) {
      throw new Error('DrawerNavigationItemComponent needs nav item');
    }

    if (this.depth === 0 && !this.navItem.icon) {
      throw new Error('Root items must provide an icon');
    }

    if (this.hasChildren && (this.navItem.pattern || this.navItem.route)) {
      throw new Error('Cannot configure item to have sub-menus and navigation attributes');
    }

    if (this.depth === 1) {
      this.itemHeight = '40px';
    } else if (this.depth > 1) {
      this.itemHeight = '32px';
    }

    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActivationState(event.urlAfterRedirects);
      }
    });
  }

  navigate() {
    if (this.hasChildren === true) {
      return;
    }
    this.router.navigate([this.navItem.route]);
  }

  toggleChildrenVisibility() {
    if (this.hasChildren === false) {
      return;
    }

    this.expandChildren = !this.expandChildren;
    this.updateActivationState(this.router.url.toString());
  }

  onNavItemClicked() {
    if (this.hasChildren === false) {
      this.currentMenuHost?.hide();
      this.navigate();
    } else {
      this.toggleChildrenVisibility();
    }
  }

  private updateActivationState(url: string) {
    if (this.maximizeView && this.expandChildren) {
      this.isActive = false;
      return;
    }
    this.isActive =
      this.navItem.pattern?.test(url) ?? this.isAnyChildActive(url, this.navItem.children);
  }

  private isAnyChildActive(url: string, children?: DrawerMenuItem[]): boolean {
    if (children) {
      for (const child of children) {
        if (child.pattern?.test(url)) {
          return true;
        }
        if (this.isAnyChildActive(url, child.children)) {
          return true;
        }
      }
    }
    return false;
  }
}
