import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, signal, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { HighlightTextPipe } from '../../pipes/text-highlight-pipe';
import { LicensingService } from '../../services';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { ExpandableSearchFieldComponent } from '../expandable-search-field/expandable-search-field.component';
import { IconComponent } from '../icon/icon.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { ResultGroup, ResultItem, SearchStatus, StatusTracker } from './global-search.models';
import { GlobalSearchService } from './global-search.service';

@UntilDestroy()
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  standalone: true,
  imports: [
    MenuComponent,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    SkeletonModule,
    NgSwitchDefault,
    NgIf,
    AssetIconComponent,
    IconComponent,
    ExpandableSearchFieldComponent,
    TranslateModule,
    HighlightTextPipe,
  ],
})
export class GlobalSearchComponent {
  @ViewChild('overlay', { static: true }) private overlay: MenuComponent;

  selection?: ResultItem | string;
  expandState: boolean = false;

  results: ResultGroup[] = [];
  searchState = signal<StatusTracker>({});
  overallState: SearchStatus;

  private hasSM365 = false;

  constructor(
    private globalSearchService: GlobalSearchService,
    private router: Router,
    private licensingService: LicensingService,
  ) {
    this.clearResult();
    this.hasSM365 = this.licensingService.hasServiceModuleInSync;
  }

  private clearResult() {
    this.results = [];
    this.resetSearchState();
  }

  private onSearchChanged(event) {
    const searchText = event.trim();
    if (searchText.length < 3) {
      this.results = [];
      this.resetSearchState();
      this.overlay.hide();
      return;
    }

    this.results = [];
    this.resetSearchState();

    if (this.hasSM365) {
      this.searchWorkOrders(searchText);
      this.searchCases(searchText);
      this.searchDocuments(searchText);
    }

    this.searchAssets(searchText);

    if (!this.expandState) {
      this.overlay.hide();
    } else {
      this.overlay.show();
    }
  }

  searchChanged(event: string) {
    this.onSearchChanged(event);
  }

  expandChanged(event: boolean) {
    this.expandState = event;
  }

  onSelection(item: ResultItem | string) {
    if (typeof item === 'string') {
      return;
    }
    this.selection = undefined;
    switch (item.content) {
      case 'assets':
        this.router.navigate(['/asset', item.assetId, 'asset-management']);
        break;
      case 'cases':
        this.router.navigate(['/maintenance365', item.assetId, 'request', item.uuid, 'details']);
        break;
      case 'workOrders':
        this.router.navigate(['/maintenance365', item.assetId, 'workorder', item.uuid, 'details']);
        break;
      case 'documents':
        this.router.navigate(['/maintenance365', item.assetId, 'documents']);
        break;
    }
    this.overlay.hide();
  }

  private searchWorkOrders(searchText: string) {
    this.searchState.update(state => ({
      ...state,
      workOrderState: 'searching',
    }));
    this.globalSearchService.searchWorkOrders(searchText).subscribe({
      next: resultItems => {
        if (resultItems.length === 0) {
          this.searchState.update(state => ({
            ...state,
            workOrderState: 'empty',
          }));
        } else {
          this.results.push({ label: 'workOrders', items: resultItems });
          this.searchState.update(state => ({
            ...state,
            workOrderState: 'ok',
          }));
        }
      },
      error: error => {
        this.searchState.update(state => ({
          ...state,
          workOrderState: 'error',
        }));
        throw error;
      },
      complete: () => {
        this.updateOverallSearchState();
      },
    });
  }

  private searchDocuments(searchText: string) {
    this.searchState.update(state => ({
      ...state,
      documentState: 'searching',
    }));
    this.globalSearchService.searchDocuments(searchText).subscribe({
      next: resultItems => {
        if (resultItems.length === 0) {
          this.searchState.update(state => ({
            ...state,
            documentState: 'empty',
          }));
        } else {
          this.results.push({ label: 'documents', items: resultItems });
          this.searchState.update(state => ({
            ...state,
            documentState: 'ok',
          }));
        }
      },
      error: error => {
        this.searchState.update(state => ({
          ...state,
          documentState: 'error',
        }));
        throw error;
      },
      complete: () => {
        this.updateOverallSearchState();
      },
    });
  }

  private searchCases(searchText: string) {
    this.searchState.update(state => ({
      ...state,
      caseState: 'searching',
    }));
    this.globalSearchService.searchCases(searchText).subscribe({
      next: resultItems => {
        if (resultItems.length === 0) {
          this.searchState.update(state => ({
            ...state,
            caseState: 'empty',
          }));
        } else {
          this.results.push({ label: 'cases', items: resultItems });
          this.searchState.update(state => ({
            ...state,
            caseState: 'ok',
          }));
        }
      },
      error: error => {
        this.searchState.update(state => ({
          ...state,
          caseState: 'error',
        }));
        throw error;
      },
      complete: () => {
        this.updateOverallSearchState();
      },
    });
  }

  private searchAssets(searchText: string) {
    this.searchState.update(state => ({
      ...state,
      assetState: 'searching',
    }));
    this.globalSearchService.searchAssets(searchText).subscribe({
      next: resultItems => {
        if (resultItems.length === 0) {
          this.searchState.update(state => ({
            ...state,
            assetState: 'empty',
          }));
        } else {
          this.results.push({ label: 'assets', items: resultItems });
          this.searchState.update(state => ({
            ...state,
            assetState: 'ok',
          }));
        }
      },
      error: error => {
        this.searchState.update(state => ({
          ...state,
          assetState: 'error',
        }));
        throw error;
      },
      complete: () => {
        this.updateOverallSearchState();
      },
    });
  }

  resetSearchState() {
    this.searchState.update(state => ({}));
  }

  updateOverallSearchState() {
    const states = Object.values(this.searchState());
    if (states.includes('searching')) {
      this.overallState = 'searching';
    } else if (states.every(state => state === 'error')) {
      this.overallState = 'error';
    } else if (states.every(state => state === 'empty' || state === 'error')) {
      this.overallState = 'empty';
    } else {
      this.overallState = 'ok';
    }
  }
}
