import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import {
  AssetAdministrationShellService,
  LanguageService,
  LicensingService,
  TreeService,
} from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-menu-item-asset-export-aas',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetExportAasComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private assetAdminShellService: AssetAdministrationShellService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('download', 'assetDetails.menu.export-aasx-file');
  }

  static override isViableOption(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    return true;
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.disabled = true;
    this.assetAdminShellService
      .exportAssetAdminShell(this.asset.uuid)
      .pipe(
        finalize(() => {
          this.disabled = false;
        }),
      )
      .subscribe();
  }
}
