import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-save-cancel-options',
  templateUrl: './save-cancel-options.component.html',
  styleUrls: ['./save-cancel-options.component.scss'],
  standalone: true,
  imports: [ButtonModule, TranslateModule],
})
export class AcceptCancelOptionsComponent {
  @Input() public cancelDisabled = false;
  @Input() public saveDisabled = false;

  @Output() public cancelClick = new EventEmitter();
  @Output() public saveClick = new EventEmitter();

  public save() {
    this.saveClick.emit();
  }

  public cancel() {
    this.cancelClick.emit();
  }
}
