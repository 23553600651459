import { AssetTypeMapping } from './asset-details';

export const assetTypesSupportDeploymentDate = [
  AssetTypeMapping.DEVICE,
  AssetTypeMapping.MACHINE_SYSTEM,
  AssetTypeMapping.SHIP,
  AssetTypeMapping.LINE,
  AssetTypeMapping.FACTORY,
  AssetTypeMapping.LOCATION,
  AssetTypeMapping.BUILDING,
  AssetTypeMapping.SOFTWARE,
];
