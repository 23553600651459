/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ENVIRONMENT, QueryFilter } from '../models';

interface AppVersion {
  uuid: string;
  version: string;
}

export interface AppSearchResult {
  apps: SensorApp[];
  totalAppCount: number;
}

export interface SensorApp {
  uuid: string;
  name: string;
  summary: string;
  iconHref?: string;
  latestPublishedVersion?: AppVersion;
  installedVersion?: AppVersion;
}

export interface AppPaging {
  pagination_perPage?: string;
  pagination_offset?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppPoolService {
  private apppoolBackend = inject(ENVIRONMENT).apppoolBackend;

  constructor(private httpClient: HttpClient) {}

  findApps(
    deviceId: string,
    partNumber: string,
    pagingOpts: Required<Pick<QueryFilter, 'offset' | 'size'>>,
  ): Observable<AppSearchResult> {
    const params = {
      pagination_perPage: pagingOpts.size.toString(),
      pagination_offset: pagingOpts.offset.toString(),
      filter_device: partNumber,
    };
    return this.httpClient.get<AppSearchResult>(`${this.apppoolBackend}/apps/device/${deviceId}`, {
      params,
    });
  }

  hasAppsFor(partNumber: string): Observable<boolean> {
    if (!this.apppoolBackend) {
      return of(false);
    }
    return this.httpClient
      .get<number>(`${this.apppoolBackend}/apps/counter`, {
        params: { filter_device: partNumber },
      })
      .pipe(map(res => res > 0));
  }

  getAppDownloadUrl(appId: string, versionId: string): Observable<string> {
    return this.httpClient
      .get<{
        presignedUrl: string;
      }>(`${this.apppoolBackend}/app/${appId}/versionid/${versionId}/download`)
      .pipe(map(res => res.presignedUrl));
  }

  getAppNamesIncluded(versionId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.apppoolBackend}/app/version/${versionId}/included`,
    );
  }
}
