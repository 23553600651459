import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';

/**
 *
 * @example
 * ```html
 * <app-text-field
 *   label="Email"
 *   placeholder="Enter a valid email"
 *   error="{{ validateEmail }}"
 * </app-text-field>
 * ```
 */
@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextFieldComponent), multi: true },
  ],
  standalone: true,
  imports: [FormsModule, InputTextModule, NgClass, TooltipModule, NgIf],
})
// Implements the ControlValueAccessor interface to support "ngModel"
export class TextFieldComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() error = false;
  @Input() errorMsg?: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showTooltip = false;
  @Input() toolTipText = '';
  @Input() renderTooltipAsHtml = false;
  @Input() tooltipStyleClass?;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';
  @Input() handleErrorIconClick?: () => void;

  @Output() blurred: EventEmitter<boolean> = new EventEmitter();

  private internalValue: string;

  private onTouch?: () => void;
  private onChange?: (val: string) => void;

  get contents(): string {
    return this.internalValue;
  }

  set contents(val: string) {
    this.internalValue = val;
    if (this.onChange) {
      this.onChange(val);
    }
  }

  writeValue(val: any): void {
    if (val !== this.internalValue) {
      this.internalValue = val;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }

    this.blurred.emit(true);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  errorIconClick() {
    if (this.handleErrorIconClick) {
      this.handleErrorIconClick();
    }
  }
}
