import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { ENVIRONMENT, ProductId } from '../models';
import {
  ProductDetailsResultV2,
  ProductsQueryResult,
  ProductsSearchResult,
} from '../models/product-service';

@Injectable({ providedIn: 'root' })
export class ProductService {
  private productDetailCache: Map<string, ProductDetailsResultV2> = new Map();
  private pendingRequests: Map<string, Observable<ProductDetailsResultV2>> = new Map();
  private preselectedProduct?: ProductId;
  private logger = new Logger(this.constructor.name);

  private env = inject(ENVIRONMENT);
  private ilpUrl = this.env.integrationLayerProxyUrl;
  private apikey = this.env.integrationLayerApiKey || this.env.swsApiKey;

  constructor(
    private httpClient: HttpClient,
    private oauth: OAuthService,
    private translateService: TranslateService,
  ) {}

  public findProducts(query: string, start = 0, size = 5): Observable<ProductsSearchResult> {
    if (this.env.productFinderInterface === 'search') {
      return this.getProductsSearch(query, start, size);
    }
    return this.getProductsQuery(query, start, size);
  }

  public getProductsQuery(query: string, start = 0, size = 5): Observable<ProductsSearchResult> {
    let params = new HttpParams();
    params = params.append('query', query);

    // Add localization to query, e.g. "deAG-sick"
    const claims = this.oauth.getIdentityClaims();
    const localization = this.getLocalization(this.translateService.currentLang, claims['ssucode']);
    params = params.append('localization', localization);

    // Add customer number to query if available, e.g. "135875.AG"
    if (this.env.enableCustomerProductQuery) {
      if (claims['customernumber'] && claims['ssucode']) {
        params = params.append('b2bunits', claims['customernumber'] + '.' + claims['ssucode']);
      }
    }

    if (this.ilpUrl) {
      return this.httpClient
        .get<ProductsQueryResult>(this.ilpUrl + '/products', {
          headers: {
            Authorization: 'Bearer ' + this.oauth.getAccessToken(),
            'x-api-key': this.apikey,
          },
          withCredentials: false,
          params: params,
        })
        .pipe(
          // this interface returns a fixed maximum number of results -> act as if it did support paging
          map(x => ({
            results: {
              Product: x.Product.slice(start, start + size),
            },
            currentPage: Math.floor(start / size) + 1,
            resultsPerPage: x.Product.length,
            total: x.Product.length,
            pageCount: Math.floor(x.Product.length / size) + 1,
          })),
        );
    } else {
      this.logger.warn(
        'no integration layer proxy url configured for this stage - no product suggestion possible',
      );
      return of<ProductsSearchResult>({
        results: { Product: [] },
        currentPage: 1,
        resultsPerPage: 0,
        total: 0,
        pageCount: 1,
      });
    }
  }

  public getProductsSearch(query: string, start = 0, size = 5): Observable<ProductsSearchResult> {
    if (this.ilpUrl) {
      // Add localization to query, e.g. "deAG-sick"
      const claims = this.oauth.getIdentityClaims();
      const localization = this.getLocalization(
        this.translateService.currentLang,
        claims['ssucode'],
      );
      return this.httpClient.get<ProductsSearchResult>(`${this.ilpUrl}/product/search`, {
        headers: {
          Authorization: 'Bearer ' + this.oauth.getAccessToken(),
          'x-api-key': this.apikey,
        },
        withCredentials: false,
        params: {
          query,
          page: Math.floor(start / size + 1).toString(),
          size: size.toString(),
          localization,
        },
      });
    } else {
      this.logger.warn(
        'no integration layer proxy url configured for this stage - no product suggestion possible',
      );
      return of<ProductsSearchResult>({
        results: { Product: [] },
        currentPage: 1,
        resultsPerPage: size,
        total: 0,
        pageCount: 1,
      });
    }
  }

  public getProductDetails(partNumber: string): Observable<ProductDetailsResultV2> {
    if (!partNumber || !this.ilpUrl) {
      return of(this.emptyProduct(partNumber));
    }
    const cache = this.productDetailCache.get(partNumber);
    if (cache) {
      return of(cache);
    }
    const pendingRequest = this.pendingRequests.get(partNumber);
    if (pendingRequest) {
      return pendingRequest;
    }
    const request = this.httpClient
      .get<ProductDetailsResultV2>(this.ilpUrl + '/product/details', {
        headers: {
          Authorization: 'Bearer ' + this.oauth.getAccessToken(),
          'x-api-key': this.apikey,
        },
        withCredentials: false,
        params: { partNumber },
      })
      .pipe(
        map(details => this.sanitizeDetails(partNumber, details)),
        tap(details => {
          this.pendingRequests.delete(partNumber);
          this.productDetailCache.set(partNumber, details);
        }),
        catchError(() => {
          this.pendingRequests.delete(partNumber);
          return of(this.emptyProduct(partNumber));
        }),
        share(),
      );
    this.pendingRequests.set(partNumber, request);
    return request;
  }

  private sanitizeDetails(
    partNumber: string,
    details: ProductDetailsResultV2,
  ): ProductDetailsResultV2 {
    const retval = Object.assign({}, this.emptyProduct(partNumber), details);
    const product = retval.Product;
    const pathPattern = /^([^?]+)/;
    // sanitize product videos
    if (product.MultiMedia.Videos.MediaFile) {
      product.MultiMedia.Videos.MediaFile = product.MultiMedia.Videos.MediaFile.filter(
        x => x.URL,
      ).map(x => {
        const matches = x.URL.match(pathPattern);
        if (matches && matches[1]) {
          x.URL = matches[1];
        }
        return x;
      });
    }
    // sanitize conformity declaration
    if (product.MultiMedia.ConformityDeclaration.DeclarationOfConformity) {
      product.MultiMedia.ConformityDeclaration.DeclarationOfConformity =
        product.MultiMedia.ConformityDeclaration.DeclarationOfConformity.filter(
          x => x.URL && x.Preview && x.Preview.URL,
        );
    }
    // sanitize software download
    if (product.MultiMedia.Software.SoftwareDownload) {
      product.MultiMedia.Software.SoftwareDownload =
        product.MultiMedia.Software.SoftwareDownload.filter(x => x.URL);
    }
    // sanitize CAD download
    if (product.MultiMedia.CAD.EDB && product.MultiMedia.CAD.EDB.MediaFile) {
      product.MultiMedia.CAD.EDB.MediaFile = product.MultiMedia.CAD.EDB.MediaFile.filter(
        x => x.URL && x.FileName,
      );
    }
    // sanitize literature
    if (product.MultiMedia.Literature.Literature) {
      product.MultiMedia.Literature.Literature = product.MultiMedia.Literature.Literature.filter(
        x => x.URL && x.PublicationType,
      ).map(x => {
        x.ReleaseDate = x.ReleaseDate ? x.ReleaseDate.split('T')[0] : '&nbsp;';
        return x;
      });
    }
    return retval;
  }

  private emptyProduct(partNumber: string): ProductDetailsResultV2 {
    return {
      Product: {
        MetaData: {
          ProductType: 'PRODUCT',
          PartNumber: partNumber,
          Name: '',
          ID: '',
        },
        MultiMedia: {
          Videos: {},
          TechnicalDrawings: {},
          OtherDownloads: {},
          Software: {},
          ConformityDeclaration: {},
          CAD: {},
          FactSheet: {},
          Certificates: {},
          Literature: {},
          ProductImages: {},
        },
        Relations: {},
        ERPData: {},
      },
    };
  }

  /**
   * Returns localization for FACTFinder product suggestion based on locale and
   * SSU code. This is a string that can be used for a FACTFinder channel, e.g.
   * 'de' or 'enCN-sick'. Default language is 'en'. For details see Mosaic+:
   *
   * https://mosaicplus.sickcn.net/pages/viewpage.action?pageId=402761312
   * https://mosaicplus.sickcn.net/display/WSFINPUB/GAM+2.1.4.+Area+of+Application
   */

  private getLocalization(language: string, ssuCode?: string): string {
    const ssuCountries = {
      AG: 'AG', // special "country" in FACTFinder
      SAE: 'AE',
      GXA: 'AT',
      GAU: 'AU',
      GSB: 'BE',
      SBE: 'BE',
      GBR: 'BR',
      SCA: 'CA',
      GCH: 'CH',
      SCH: 'CH',
      FCC: 'CN',
      GCN: 'CN',
      SCL: 'CL',
      SMC: 'CN',
      GCZ: 'CZ',
      VSE: 'CZ',
      ATG: 'DE',
      GDD: 'DE',
      SAI: 'DE',
      SMA: 'DE',
      STD: 'DE',
      SVD: 'DE',
      GDK: 'DK',
      GXE: 'ES',
      GSF: 'FI',
      GXF: 'FR',
      GUK: 'GB',
      SGR: 'AT', // 'GR',
      GHK: 'HK',
      GHU: 'AT', // 'HU',
      IND: 'IN',
      SIL: 'IL',
      GIT: 'IT',
      GXJ: 'JP',
      GKR: 'KR',
      SMX: 'MX',
      SMY: 'MY',
      GNL: 'NL',
      GSN: 'NO',
      SNZ: 'NZ',
      GPL: 'PL',
      SRU: 'RU',
      GSS: 'SE',
      IVP: 'SE',
      GSG: 'SG',
      PCS: 'SG',
      SSK: 'SK',
      GTR: 'TR',
      GRC: 'TW',
      GUS: 'US',
      PCA: 'US',
      SZA: 'ZA',
    };
    // not every combination of channel and locale is supported
    // https://mosaicplus.sickcn.net/pages/viewpage.action?pageId=402761312
    const factFinderChannels: { [k: string]: string[] } = {
      AE: ['en'],
      AG: ['en', 'de'],
      AT: ['en', 'de'],
      AU: ['en'],
      BE: ['en', 'de', 'fr', 'nl'],
      BR: ['en', 'pt'],
      CA: ['en', 'fr'],
      CH: ['en', 'de', 'fr'],
      CL: ['en', 'es'],
      CN: ['en', 'zh'],
      CZ: ['en', 'cs', 'de'],
      DE: ['en', 'de'],
      DK: ['en', 'da'],
      ES: ['en', 'es'],
      FI: ['en', 'fi', 'sv'],
      FR: ['fr'],
      GB: ['en'],
      HK: ['zh'],
      HQ: ['en', 'de'],
      IL: ['en'],
      IN: ['en'],
      IT: ['en', 'it'],
      JP: ['en', 'ja'],
      KR: ['en', 'ko'],
      MX: ['en', 'es'],
      MY: ['en'],
      NL: ['en', 'nl'],
      NO: ['en'],
      NZ: ['en'],
      PL: ['en', 'pl', 'ru'],
      RU: ['en', 'ru'],
      SE: ['en', 'sy'],
      SG: ['en'],
      SK: ['en', 'cs'],
      TR: ['en', 'tr'],
      TW: ['en', 'zf'],
      US: ['en'],
      ZA: ['en'],
    };
    const ssuFallback = {
      cs: 'CZ',
      de: 'DE',
      en: 'US',
      es: 'ES',
      fr: 'FR',
      nl: 'NL',
      ru: 'RU',
      sv: 'FI',
    };

    let lang = language in ssuFallback ? language : 'en';
    const country = ssuCountries[ssuCode?.toUpperCase() || ''] || ssuFallback[lang];
    if (!country) {
      throw new Error('failed to resolve subsidiary code');
    }
    if (!factFinderChannels[country].includes(lang)) {
      lang = factFinderChannels[country][0];
    }
    return `${lang}${country}-sick`;
  }

  public storeProductId(productId: ProductId | undefined): void {
    this.preselectedProduct = productId;
  }

  public getPreselectedProduct(): ProductId | undefined {
    return this.preselectedProduct;
  }
}
