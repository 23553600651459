import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationTrackingService {
  public navigating$: Observable<boolean>;
  private navigating = new BehaviorSubject<boolean>(false);
  // Some components require navigation service to prevent tracking the navigation state, because
  // frontend dialogs can be overflown by navigation spinners activated by "navigating" oberservable
  private trackingDisabled = false;

  public set disableTracking(disable: boolean) {
    this.trackingDisabled = disable;
  }

  constructor(private router: Router) {
    this.navigating$ = this.navigating.asObservable();

    this.router.events.subscribe(event => {
      if (!this.trackingDisabled) {
        if (event instanceof NavigationStart) {
          this.navigating.next(true);
        } else if (event instanceof NavigationEnd) {
          this.navigating.next(false);
        } else if (event instanceof NavigationCancel) {
          this.navigating.next(false);
        } else if (event instanceof NavigationError) {
          this.navigating.next(false);
        }
      }
    });
  }
}
