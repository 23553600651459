export interface PermissionsQuery {
  start?: number;
  size?: number;
}

export interface UserPermissions {
  username: string;
  permissions: string;
}

export interface GrantedPermission {
  username: string;
  permissions: string;
  inherited: boolean;
  parentPermissions?: string;
}

export interface PermissionsResponse {
  permissions: GrantedPermission[];
  total: number;
}

export interface PermissionEntry {
  label: string;
  id: string;
  admin: boolean;
  write: boolean;
  read: boolean;
  pAdmin: boolean;
  pWrite: boolean;
  pRead: boolean;
  rightsInherited: boolean;
  pendingAction: boolean;
  deleteDisabled: boolean;
}

export interface AssetPermissions {
  admin: boolean;
  write: boolean;
  read: boolean;
}

export function permissionsFromString(permissions: string | undefined): AssetPermissions {
  return {
    admin: permissions?.includes('a') ?? false,
    write: permissions?.includes('w') ?? false,
    read: permissions?.includes('r') ?? false,
  };
}

interface AssetContacts {
  type: 'person' | 'group';
  name: string;
  email?: string;
  groupId?: string;
}

export interface ContactsResponse {
  contacts: AssetContacts[];
  total: number;
}
