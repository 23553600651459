<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  height="100%"
  viewBox="0 0 100 100"
>
  <text x="50%" y="50%" font-family="Material Icons" font-size="60px" fill="currentColor">
    <tspan dominant-baseline="central" text-anchor="middle">{{ shape() }}</tspan>
  </text>
</svg>
