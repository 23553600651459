<div class="sensor-apps" *ngIf="device">
  <ng-container *ngIf="isSupportedDevice; else deviceNotSupported">
    <div class="app-list" *ngIf="!errorTranslationKey; else errorView">
      <div class="remote-apps-issues" *ngIf="remoteServiceIssues">
        <app-callout>
          @if (remoteServiceIssues.deviceUnpaired) {
            <div [innerHTML]="'sensor-apps.capability-check.device-not-paired' | translate"></div>
          } @else if (remoteServiceIssues.deviceOffline) {
            <div [innerHTML]="'sensor-apps.capability-check.device-offline' | translate"></div>
          } @else if (remoteServiceIssues.remoteAppUnavailable) {
            <div [innerHTML]="'sensor-apps.capability-check.remote-app-missing' | translate"></div>
          } @else if (remoteServiceIssues.serviceVersionInsufficient) {
            <div
              [innerHTML]="
                'sensor-apps.capability-check.insufficient-app-version'
                  | translate
                    : {
                        MAJOR_VERSION: remoteAppMajorVersion,
                        MINOR_VERSION: remoteAppMinorVersion,
                        UP_TO_MAJOR_VERSION: remoteAppMajorVersion + 1,
                      }
              "
            ></div>
          } @else if (remoteServiceIssues.insufficientPermissions) {
            <div
              [innerHTML]="'sensor-apps.capability-check.insufficient-permissions' | translate"
            ></div>
          }
        </app-callout>
      </div>

      <div class="remote-app-action-status">
        <app-status-line
          [hideAfterCompleteTimeout]="remoteOperationDisplayTimeout"
          #progressStatusLine
        ></app-status-line>
      </div>

      <p-table
        #appTable
        [value]="appResults.apps"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        (onLazyLoad)="loadApps($event)"
        [totalRecords]="appResults.totalAppCount"
        [rows]="defaultRowCount"
        [paginator]="true"
        [alwaysShowPaginator]="false"
        [loading]="loading || progressStatusLine?.currentlyActive()"
        [resetPageOnSort]="false"
        paginatorPosition="bottom"
        [ngClass]="{ narrow: narrow }"
        breakpoint="0px"
      >
        <ng-template pTemplate="header">
          <tr>
            <!-- Icon -->
            <th style="width: 80px"></th>
            <!-- Name -->
            <th style="width: 33%">
              <div class="ellipsis">
                <label>{{ 'sensor-apps.table.cols.name' | translate }}</label>
              </div>
            </th>
            <!-- Summary -->
            <th>
              <div class="ellipsis">
                <label>{{ 'sensor-apps.table.cols.summary' | translate }}</label>
              </div>
            </th>
            <!-- Action buttons -->
            <th style="width: 80px"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-app let-index="rowIndex">
          <tr>
            <td>
              <span class="p-column-title"></span>
              <div class="image">
                <app-icon
                  *ngIf="app.installedVersion"
                  class="checkmark"
                  shape="pi pi-check-circle"
                  [ngClass]="{ narrow }"
                  [title]="
                    'sensor-apps.app-installed-title'
                      | translate: { installedVersion: app.installedVersion.version }
                  "
                  [color]="colorSuccess"
                ></app-icon>
                <app-icon
                  size="48px"
                  [shape]="app.iconHref || 'assets/images/default-app-icon.png'"
                ></app-icon>
              </div>
            </td>
            <td (click)="toggleRowEllipsis(index)">
              <span class="p-column-title">{{ 'sensor-apps.table.cols.name' | translate }}</span>
              <div [ngClass]="{ ellipsis: !disabledEllipsisIds[index] }">
                <a href="{{ appPoolFrontendUrl }}/publications/{{ app.uuid }}" target="_blank"
                  ><span>{{ app.name }}</span></a
                >
              </div>
            </td>
            <td (click)="toggleRowEllipsis(index)">
              <span class="p-column-title">{{ 'sensor-apps.table.cols.summary' | translate }}</span>
              <div [ngClass]="{ ellipsis: !disabledEllipsisIds[index] }">
                <label class="pointer">{{ app.summary }}</label>
              </div>
            </td>
            <td>
              <span class="p-column-title"> </span>
              <div class="actions-panel">
                <button
                  pButton
                  *ngIf="!app.installedVersion && app.latestPublishedVersion"
                  class="ui-button-bare-neutral"
                  title="{{
                    'sensor-apps.table.action-buttons.install-on-device'
                      | translate: { latestPublishedVersion: app.latestPublishedVersion.version }
                  }}"
                  icon="pi pi-download-for-offline"
                  [disabled]="!remoteAppServiceLocation"
                  (click)="installApp(app)"
                ></button>
                <button
                  pButton
                  *ngIf="app.installedVersion"
                  class="ui-button-bare-neutral"
                  title="{{
                    'sensor-apps.table.action-buttons.uninstall-from-device'
                      | translate: { installedVersion: app.installedVersion.version }
                  }}"
                  icon="pi pi-cancel"
                  [disabled]="!remoteAppServiceLocation || app.protected"
                  (click)="removeApp(app)"
                ></button>
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr *ngIf="!loading">
            <td colspan="3" class="empty-table-message">
              <app-callout
                ><div class="no-apps-found">
                  <span class="title">{{ 'sensor-apps.no-apps-found' | translate }}</span>
                  <button
                    class="ui-button-bare"
                    title="{{ 'sensor-apps.refresh-apps-title' | translate }}"
                    pButton
                    type="button"
                    icon="pi pi-refresh ui-button-bare"
                    (click)="loadApps()"
                  ></button>
                </div>
              </app-callout>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <ng-template #errorView>
      <div class="error-pane">
        <app-callout *ngIf="errorTranslationKey" severity="error">{{
          errorTranslationKey | translate
        }}</app-callout>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #deviceNotSupported>
    <app-callout>{{ 'sensor-apps.device-not-capable' | translate }}</app-callout>
  </ng-template>
</div>
