import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { AssetSearchService } from '@assethub/shared/services/asset-search.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { TreeService } from '../../services';
import {
  AssetSearchResultItem,
  CaseSearchResponse,
  DocumentWithAssetId,
  FoundCaseWithAssetId,
  FoundWorkOrderWithAssetId,
  GetDocumentsResponse,
  ResultItem,
  WithCustomerId,
  WithRelatedAssetId,
  WorkOrderSearchResponse,
} from './global-search.models';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  private sm365Url = inject(ENVIRONMENT).sm365Url;

  constructor(
    private httpClient: HttpClient,
    private assetSearchService: AssetSearchService,
    private translateService: TranslateService,
    private treeService: TreeService,
  ) {}

  searchAssets(searchText: string): Observable<ResultItem[]> {
    return this.assetSearchService
      .searchAssets(
        { searchValue: searchText },
        { first: 0, rows: 3, sortField: 'updated', sortOrder: -1 },
      )
      .pipe(
        map(response =>
          response.items.length > 0
            ? response.items.map(item => this.convertAsset(item, searchText))
            : [],
        ),
      );
  }

  searchWorkOrders(searchText: string): Observable<ResultItem[]> {
    return this.httpClient
      .post<WorkOrderSearchResponse>(`${this.sm365Url}/workorders/search`, { search: searchText })
      .pipe(
        switchMap(workOrders =>
          workOrders.length > 0
            ? forkJoin(workOrders.map(workOrder => this.appendAssetIdForCustomerId(workOrder)))
            : of([]),
        ),
        map(workOrders => workOrders.map(item => this.convertWorkOrder(item, searchText))),
      );
  }

  searchCases(searchText: string): Observable<ResultItem[]> {
    return this.httpClient
      .post<CaseSearchResponse>(`${this.sm365Url}/cases/search`, { search: searchText })
      .pipe(
        switchMap(cases =>
          cases.length > 0
            ? forkJoin(cases.map(caseItem => this.appendAssetIdForCustomerId(caseItem)))
            : of([]),
        ),
        map(cases => cases.map(item => this.convertCase(item, searchText))),
      );
  }

  searchDocuments(searchText: string): Observable<ResultItem[]> {
    return this.httpClient
      .get<GetDocumentsResponse>(`${this.sm365Url}/documents/search`, {
        params: { offset: '0', size: '3', order: 'lastModified', dir: 'desc', title: searchText },
      })
      .pipe(
        switchMap(response =>
          response.documents.length > 0
            ? forkJoin(response.documents.map(doc => this.appendAssetIdForCustomerId(doc)))
            : of([]),
        ),
        map(documents => documents.map(doc => this.convertDocument(doc, searchText))),
      );
  }

  private appendAssetIdForCustomerId<T extends WithCustomerId>(
    item: T,
  ): Observable<T & WithRelatedAssetId> {
    const cleanedSubsidiaryCode =
      item.subsidiaryCode.match(/^[A-Z]+/i)?.[0].toUpperCase() ?? item.subsidiaryCode;

    return this.treeService.findRootByCustomerId(item.customerNumber, cleanedSubsidiaryCode).pipe(
      map(asset => ({
        ...item,
        assetId: asset?.uuid ?? '',
      })),
    );
  }

  private convertAsset(item: AssetSearchResultItem, keyword: string): ResultItem {
    const keys = new Set<keyof AssetSearchResultItem>([
      'customName',
      'productName',
      'partNumber',
      'serialNumber',
    ]);
    let primaryLabel = '';
    if (item.customName) {
      primaryLabel = item.customName;
      keys.delete('customName');
    } else if (item.productName) {
      primaryLabel = item.productName;
      keys.delete('productName');
    } else if (item.partNumber) {
      primaryLabel = item.partNumber;
      if (item.serialNumber) {
        primaryLabel += ' / ' + item.serialNumber;
      }
      keys.delete('partNumber');
      keys.delete('serialNumber');
    } else {
      primaryLabel = item.uuid;
    }
    const secondary: string[] = [];
    for (const key of keys.keys()) {
      const value = item[key];
      if (value) {
        secondary.push(
          `${this.translateService.instant('global-search.groups.assets.fields.' + key)}: ${item[key]}`,
        );
      }
    }
    return {
      uuid: item.uuid,
      assetId: item.uuid,
      content: 'assets',
      primaryLabel,
      secondaryLabel: secondary.join(' | '),
      keyword,
      assetImage: {
        type: item.type,
        productPictureUrl: item.productPictureUrl,
        profilePictureUuid: item.profilePictureUuid,
      },
    };
  }

  private convertWorkOrder(item: FoundWorkOrderWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'workOrders',
      primaryLabel: item.name,
      keyword,
      icon: 'checklist',
      assetId: item.assetId,
    };
    if (item.number) {
      retval.secondaryLabel = `${this.translateService.instant('global-search.groups.workOrders.fields.number')}: ${
        item.number
      }`;
    }
    return retval;
  }

  private convertDocument(item: DocumentWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'documents',
      primaryLabel: item.title,
      keyword,
      icon: item.type === 'pdf' ? 'pi-file-pdf' : 'pi-file',
      assetId: item.assetId,
    };
    if (item.description) {
      retval.secondaryLabel = `${this.translateService.instant('global-search.groups.documents.fields.description')}: ${
        item.description
      }`;
    }
    return retval;
  }

  private convertCase(item: FoundCaseWithAssetId, keyword: string): ResultItem {
    const retval: ResultItem = {
      uuid: item.uuid,
      content: 'cases',
      primaryLabel: item.title,
      keyword,
      icon: 'work',
      assetId: item.assetId,
    };
    if (item.ticketNumber) {
      retval.secondaryLabel = `${this.translateService.instant('global-search.groups.cases.fields.ticket-number')}: ${
        item.ticketNumber
      }`;
    }
    return retval;
  }
}
