<ng-container *ngIf="file.downloading; else download">
  <p-progressSpinner strokeWidth="4"></p-progressSpinner>
</ng-container>
<ng-template #download>
  <button
    pButton
    type="button"
    class="ui-button-bare-neutral"
    icon="pi pi-file-download"
    (click)="onDownload()"
    title="{{
      'service-module-365.list-of-service-documents.tooltip.download-document' | translate
    }}"
  ></button>
</ng-template>
