interface DeviceInfo {
  uuid: string;
  name?: string;
  productName?: string;
}

interface DuplicatedDeviceInfo {
  srcDevice: DeviceInfo;
  dstDevice: DeviceInfo;
}

interface OptionalIssue<T> {
  optional: true;
  value: T;
}

export type IccDevice = DeviceInfo & {
  isGatewayDevice: boolean;
};

export interface IccDeviceClusterIncomplete {
  devicesMoved: IccDevice[];
  devicesMissing: IccDevice[];
}

export function isOptionalIssue<T>(obj): obj is OptionalIssue<T> {
  return obj?.optional === true && obj.value !== undefined;
}

export interface MoveAssetPreflightInfo {
  cannotMoveNextToRoot?: boolean;
  isManagedNode?: boolean;
  insufficientPermissions?: boolean;
  canMovePermissions?: OptionalIssue<boolean>;
  duplicateDevicesFound?: OptionalIssue<DuplicatedDeviceInfo[]>;
  iccDeviceClusterIncomplete?: IccDeviceClusterIncomplete[];
}
