import { Injectable, ApplicationRef } from '@angular/core';
import { SplitPane } from '../models/split-pane';
import { Subject, Observable } from 'rxjs';
import { IOutputData } from 'angular-split/lib/interface';
import { LocalStorageService } from './browser-storage.service';
import { MenuService } from './menu.service';

@Injectable({ providedIn: 'root' })
export class SplitPanelService {
  public sizeLeft = 0;
  public sizeRight = 0;
  public leftPanelVisible = true;
  private STORAGE_KEY = 'sliderRatio2';
  private DEFAULT_SPLIT_PANE: SplitPane = {
    left: '25',
    right: '75',
  };

  private _beginGutterDrag: Subject<IOutputData> = new Subject();
  private _gutterClick: Subject<void> = new Subject();

  public get beginGutterDrag(): Observable<IOutputData> {
    return this._beginGutterDrag.asObservable();
  }

  public get gutterClick(): Observable<void> {
    return this._gutterClick.asObservable();
  }

  constructor(
    private localStorageService: LocalStorageService,
    private applicationChangeDetector: ApplicationRef,
    private menuService: MenuService,
  ) {}

  public updateGutterSize() {
    if (this.sizeLeft === 0 || this.sizeRight === 0) {
      return 30;
    } else {
      return 10;
    }
  }

  public onDragEnd($event) {
    this.sizeLeft = $event.sizes[0];
    this.sizeRight = $event.sizes[1];

    if (this.sizeLeft === 0 && this.sizeRight === 100) {
      this.leftPanelVisible = false;
    } else {
      this.leftPanelVisible = true;
    }

    this.updateLocalStorage();
  }

  public onDragStart(event: IOutputData) {
    this._beginGutterDrag.next(event);
    this.menuService.hideAll();
  }

  public onGutterClick() {
    this.leftPanelVisible = !this.leftPanelVisible;
    if (this.leftPanelVisible) {
      this.sizeLeft = Number(this.DEFAULT_SPLIT_PANE.left);
      this.sizeRight = Number(this.DEFAULT_SPLIT_PANE.right);
    } else {
      // If customer minimizes the left panel, then uses the grab-bar to make it visible again and tries
      // to minimize it again, both sizeLeft and sizeRight are already 0 and 100, so from Angular's point
      // of view there is no change and therefore no event to generate. The next three lines make sure
      // both variables are flagged dirty.
      this.sizeLeft = -1;
      this.sizeRight = -1;
      this.applicationChangeDetector.tick();
      this.sizeLeft = 0;
      this.sizeRight = 100;
    }
    this.updateLocalStorage();
    this._gutterClick.next();
    this.menuService.hideAll();
  }

  public setPanelSizesFromLocalStorage() {
    const sliderRatio = Object.assign(
      {},
      this.DEFAULT_SPLIT_PANE,
      this.localStorageService.get(this.STORAGE_KEY),
    );
    this.sizeLeft = Number(sliderRatio.left);
    this.sizeRight = Number(sliderRatio.right);
  }

  private updateLocalStorage() {
    this.localStorageService.set(this.STORAGE_KEY, {
      left: this.sizeLeft,
      right: this.sizeRight,
    });
  }
}
