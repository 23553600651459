import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  ContentChild,
  effect,
  input,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TableConfig } from '@assethub/shared/models';
import { MultiSelectItem } from '@assethub/shared/models/multiselect';
import { LocalizedDatePipe } from '@assethub/shared/pipes/localized-date.pipe';
import { TableConfigService } from '@assethub/shared/services';
import { LazyLoadTable, Logger } from '@assethub/shared/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Table, TableColumnReorderEvent, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { map, merge, switchMap } from 'rxjs';
import { LazyLoadTableMessagesComponent } from '../lazy-load-table-messages/lazy-load-table-messages.component';
import { FilterType, SortIconType } from '../sort-icon/sort-icon.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import {
  TableOptions,
  TableOptionsMenuComponent,
} from '../table-options-menu/table-options-menu.component';

export interface TableColumn<T> {
  label: string; // key in i18n file, piped though translate-pipe
  field: T; // forwarded to app-table-header
  essential?: boolean; // column can't be hidden
  type: SortIconType; // forwarded to app-table-header
  filter?: FilterType; // forwarded to app-table-header
  filterSignal?: Signal<FilterType>; // signal to provide column filters
  filterLabels?: string; // key i18n file for table with filter options
  width?: string; // column style, used only if table.autoWidth === false
}

type TableColumnMap<T> = Map<T, TableColumn<T>>;

@Component({
  selector: 'app-table-base',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    TranslateModule,
    TableHeaderComponent,
    TableOptionsMenuComponent,
    LazyLoadTableMessagesComponent,
    LocalizedDatePipe,
  ],
  templateUrl: './table-base.component.html',
  styleUrl: './table-base.component.scss',
  // To avoid NullInjectorError the following provider is needed.
  // See https://github.com/primefaces/primeng/issues/7985 and the
  // linked pages/stackblitz. (reported 2019-07-23, still needed 2024-03-01)
  // The issue arises in app-table-header / app-sort-icon
  // -> SortIcon and the parameter 'dt: Table' in the constructor.
  providers: [
    {
      provide: Table,
      useFactory: (component: TableBaseComponent<any>): Table => component.table,
      deps: [TableBaseComponent],
    },
  ],
})
export class TableBaseComponent<T, U extends string | number | symbol = keyof T> {
  private logger = new Logger('TableBaseComponent');

  // LazyLoadTable to display in this table
  readonly list = input.required<LazyLoadTable<T>>();

  // all columns
  readonly columns = input.required<TableColumn<U>[]>();

  // optional: columns that should be initially visible
  readonly initialColumns = input<U[]>();

  // Table will evaluate its width itself
  // Settings of column.width are ignored
  readonly autoWidth = input<boolean>(true);
  readonly actionColumnWidth = input<string>('48px');

  // messages (if not set, default messages are used)
  readonly noDataMsg = input<string>();
  readonly nofilteredDataMsg = input<string>();
  readonly errorMsg = input<string>();

  // inputs directly forwarded to p-table
  readonly scrollable = input<boolean>(true);
  readonly scrollHeight = input<string | undefined>('flex');
  readonly dataKey = input<string>();

  // tableName to load and save tableConfig
  readonly tableName = input<string>('');

  readonly tableStyle = computed(() => this.computeTableStyle());

  // collections of column-definitions/fields for different needs
  readonly optionalColumns = computed(() => this.computeOptionalColumns());
  readonly visibleColumns = computed(() => this.currentConfig().columns);

  private readonly columnMap = computed(() => this.computeColumnMap());
  private readonly essentialColumns = computed(() => this.computeEssentialColumns());

  // configurations: initial, read from backend, set by user and computed from the aforementioned
  private readonly initialConfig = computed(() => this.computeInitialConfig());
  private readonly persistentConfig = this.computePersistentConfig();
  private readonly currentConfig = this.computeCurrentConfig();

  // copy of last used config, used for check if update is necessary
  private cachedLastConfig?: TableConfig<U>;

  // ViewChild member to provide to factory function
  // make sure the id is in your markup <p-table #table>
  @ViewChild('table', { static: true }) table: Table;

  // Templates for header- and body-cells: make sure to
  // set the id in the templates for this table
  // Default templates are used if not set.
  @ContentChild('headerTemplate') headerTemplateRef: TemplateRef<any>;
  @ContentChild('cellTemplate') cellTemplateRef: TemplateRef<any>;

  constructor(
    private translateService: TranslateService,
    private tableConfigService: TableConfigService,
  ) {
    effect(() => {
      this.addFilterTranslations(this.columns());
    });
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    this.list().onLazyLoad(event);
  }

  onApplySettings(tableOptions: TableOptions<U>) {
    const currentConfig = this.currentConfig();
    this.storeConfig(
      {
        rows: tableOptions.rows,
        sortOrder: currentConfig.sortOrder,
        sortField: currentConfig.sortField,
        columns: this.applySelectedColumns(currentConfig.columns, tableOptions.columns),
      },
      false,
    );
  }

  onResetSettings() {
    const tableName = this.tableName();
    if (!tableName) {
      return;
    }
    this.resetFilters();
    this.tableConfigService.deleteTableConfig(tableName);
  }

  handleOnSort(event: any) {
    const currentConfig = this.currentConfig();
    if (event.field !== currentConfig.sortField || event.order !== currentConfig.sortOrder) {
      this.storeConfig({
        rows: currentConfig.rows,
        sortOrder: event.order || currentConfig.sortOrder,
        sortField: event.field || currentConfig.sortField,
        columns: [...currentConfig.columns],
      });
    }
  }

  handleOnColReorder(event: TableColumnReorderEvent) {
    if (event.columns) {
      const currentConfig = this.currentConfig();
      this.storeConfig({
        rows: currentConfig.rows,
        sortOrder: currentConfig.sortOrder,
        sortField: currentConfig.sortField,
        columns: [...event.columns],
      });
    }
  }

  getColumn(field: U): TableColumn<U> | undefined {
    return this.columnMap().get(field);
  }

  getColumnStyle(field: U): { [k: string]: string } {
    const style: { [k: string]: string } = {};
    const column = this.getColumn(field);
    if (!this.autoWidth() && column && column.width) {
      style.width = column.width;
    }
    return style;
  }

  public resetFilters() {
    this.list().resetFilters();
    if (this.table.filters) {
      Object.keys(this.table.filters).forEach(column => {
        this.table.filter(null, column, '');
      });
    }
  }

  private computeTableStyle(): { [k: string]: any } {
    if (this.autoWidth()) {
      return { width: 'auto' };
    }
    return {};
  }

  // accumulate translations in the column definitions for filter options
  // and return the same column-definition object.
  private addFilterTranslations(columns: TableColumn<U>[]) {
    for (const column of columns) {
      if (column.type === 'number' && column.filterLabels && column.filter === undefined) {
        const translations = this.translateService.instant(column.filterLabels);
        if (translations) {
          column.filter = this.convertTranslationIntoFilter(translations);
          this.translateFilterSelection(this.table, column.field, column.filter);
        }
      }
    }
  }

  private convertTranslationIntoFilter(translation?: Record<string, string>): MultiSelectItem[] {
    if (!translation) {
      return [];
    }
    return Object.entries(translation)
      .filter(x => !!x[1])
      .map(x => ({
        value: x[0],
        displayLabel: x[1],
      }))
      .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
  }

  private translateFilterSelection(table: Table | undefined, field: U, option: MultiSelectItem[]) {
    if (!table || !table.filters) {
      return;
    }
    const currentSelection = table.filters[String(field)];
    if (!currentSelection || Array.isArray(currentSelection)) {
      return;
    }
    const translated = currentSelection.value.map(x => option.find(z => z.value === x.value) || x);
    currentSelection.value = translated;
  }

  private computeColumnMap(): TableColumnMap<U> {
    const columns = this.columns();
    const columnMap: TableColumnMap<U> = new Map(columns.map(x => [x.field, x]));
    if (columnMap.size !== columns.length) {
      this.logger.warn(`Duplicate field(s) in attribute columns[]: fields must be unique!`);
    }
    return columnMap;
  }

  private computeOptionalColumns(): TableColumn<U>[] {
    return this.columns().filter(x => x.essential !== true);
  }

  private computeEssentialColumns(): TableColumn<U>[] {
    return this.columns().filter(x => x.essential === true);
  }

  private computeInitialConfig(): TableConfig<U> {
    const list = this.list();
    const allColumns = this.columns().map(x => x.field);
    const initialColumns = this.initialColumns();

    const validatedInitialColumns = initialColumns
      ? this.validateColumns(initialColumns)
      : allColumns;

    if (initialColumns && initialColumns.length !== validatedInitialColumns.length) {
      this.logger.warn(`Invalid column name in attribute 'initialColumns'`);
    }

    return {
      sortOrder: list.defaultSortOrder,
      sortField: list.defaultSortField.toString(),
      rows: list.defaultVisibleRows,
      columns: validatedInitialColumns,
    };
  }

  // returns a copy of the given array of columns where it is made
  // sure that every column is contained in the column definitions
  private validateColumns(columns: U[]): U[] {
    const columnMap = this.columnMap();
    return columns.filter(column => columnMap.has(column));
  }

  // returns a copy of the given array of columns where all missing
  // essential columns will be inserted at their original position
  private validateEssentialColumns(columns: U[]): U[] {
    const essential = this.essentialColumns().map(x => x.field);
    for (let i = 0; i < essential.length; i++) {
      const essentialCol = essential[i];
      if (!columns.includes(essentialCol)) {
        columns.splice(i, 0, essentialCol);
      }
    }
    return columns;
  }

  // make sure sortField is either a visible column or
  // use defaultSortField from LazyLoadTable as fallback
  private validateSortField(config: TableConfig<U>): TableConfig<U> {
    const list = this.list();
    const columnMap = this.columnMap();

    if (
      !config.columns.includes(config.sortField as U) ||
      columnMap.get(config.sortField as U)?.type === 'unsortable'
    ) {
      const sortField = config.columns.find(x => columnMap.get(x)?.type !== 'unsortable');
      if (sortField) {
        config.sortField = sortField.toString();
      } else {
        config.sortField = list.defaultSortField.toString();
        config.sortOrder = list.defaultSortOrder;
      }
    }

    return config;
  }

  private validateConfig(config: TableConfig<U>): TableConfig<U> {
    config = this.copyConfig(config);
    config.columns = this.validateColumns(config.columns);
    config.columns = this.validateEssentialColumns(config.columns);
    config = this.validateSortField(config);
    return config;
  }

  // Compute an array of columns that contains all columns from visible
  // in that order that are also contained in selected or in essential.
  private applySelectedColumns(visible: U[], selected: U[]): U[] {
    const essential = this.essentialColumns().map(x => x.field);

    // make sure we only have to deal with valid columns
    selected = this.validateColumns(selected);
    visible = this.validateColumns(visible);

    // from currently visible columns exclude all non-selected and non-essential columns
    let columns = visible.filter(x => selected.includes(x) || essential.includes(x));

    // append all selected columns that are not already included
    columns = columns.concat(selected.filter(x => !columns.includes(x)));

    // append all essential columns that are not already included
    columns = columns.concat(essential.filter(x => !columns.includes(x)));

    return columns;
  }

  private copyConfig(src: TableConfig<U>): TableConfig<U> {
    const dst = Object.assign({}, src);
    dst.columns = [...src.columns];
    return dst;
  }

  private equalConfig(a?: TableConfig<U>, b?: TableConfig<U>): boolean {
    return (
      a === b ||
      (a !== undefined &&
        b !== undefined &&
        a.rows === b.rows &&
        a.sortField === b.sortField &&
        a.sortOrder === b.sortOrder &&
        a.columns.length === b.columns.length &&
        JSON.stringify(a.columns) === JSON.stringify(b.columns))
    );
  }

  private computePersistentConfig(): Signal<TableConfig<U> | undefined> {
    return toSignal(
      toObservable(this.tableName).pipe(
        switchMap(name => this.tableConfigService.getTableConfig<U>(name)),
      ),
    );
  }

  private computeCurrentConfig(): Signal<TableConfig<U>> {
    interface ConfigEvent {
      source: 'initial' | 'persistent';
      config: TableConfig<U> | undefined;
    }

    return toSignal(
      merge(
        toObservable(this.initialConfig).pipe<ConfigEvent>(
          map(config => ({ config, source: 'initial' })),
        ),
        toObservable(this.persistentConfig).pipe<ConfigEvent>(
          map(config => ({ config, source: 'persistent' })),
        ),
      ).pipe(
        map((event: ConfigEvent) => {
          // event.config may be undefined, e.g. if the config was reset.
          // In this case we use the initial configuration.
          const config = this.copyConfig(event.config || this.initialConfig());

          // if we received initial config (e.g. because columns was changed)
          // or persistent config was reset, then we clear all column filters.
          const clearColumnFilters =
            event.source === 'initial' ||
            (event.source === 'persistent' && event.config === undefined);

          // if initialConfig is changed during lifetime of the component
          // (e.g. because a change of columns/initialColumns)
          // and we have a loaded/saved configuration, we try to preserve
          // the column settings from the loaded configuration.
          // This way the order of visible columns is preserved for those
          // columns that still exist in new initialConfig with eventually
          // new essential columns appended to the right.
          if (event.source === 'initial') {
            const persistentConfig = this.persistentConfig();
            if (persistentConfig) {
              config.columns = [...persistentConfig.columns];
            }
          }
          return this.setConfig(config, clearColumnFilters);
        }),
      ),
      {
        initialValue: {
          rows: 5,
          sortOrder: -1,
          sortField: '',
          columns: [],
        },
      },
    );
  }

  private setConfig(config: TableConfig<U>, clearColumnFilters: boolean) {
    const list = this.list();
    const currentConfig = this.cachedLastConfig;

    // make sure all columns are valid, all essential columns
    // are there and sortField is valid
    config = this.validateConfig(config);

    // find columns for which the filters should be cleared.
    // Either all columns or columns that are not visible and contain filters
    const removeFilterColumns = !this.table.filters
      ? []
      : clearColumnFilters
        ? Object.keys(this.table.filters)
        : Object.keys(this.table.filters).filter(column => !config.columns.includes(column as U));

    // if the configuration didn't change since last setConfig() and no columns
    // with filters were hidden, we don't need to update anything
    if (this.equalConfig(currentConfig, config) && removeFilterColumns.length > 0) {
      return config;
    }

    // briefly disable lazy loading to prevent the frontend from sending two requests
    let reloadData = list.loading || removeFilterColumns.length > 0;
    list.cancel();
    this.table.lazy = false;

    // assign config to LazyLoadTable
    list.first = 0;
    list.visibleRows = config.rows;
    list.sortField = config.sortField;
    list.sortOrder = config.sortOrder;

    // assign config to p-table
    this.table.rows = config.rows;
    this.table.sortField = config.sortField;
    this.table.sortOrder = config.sortOrder;

    // check whether to reload data
    reloadData ||=
      currentConfig === undefined ||
      config.sortField !== currentConfig.sortField ||
      config.sortOrder !== currentConfig.sortOrder ||
      config.rows !== currentConfig.rows;

    // clear filters for invisible columns in table
    // reload is required if filter is removed for invisible column
    for (const column of removeFilterColumns) {
      reloadData = true;
      this.table.filter(null, column, '');
    }

    // reload data if necessary
    if (reloadData) {
      list.reload({ first: 0, rows: list.visibleRows });
    }

    this.table.lazy = true;
    this.cachedLastConfig = this.copyConfig(config);

    return config;
  }

  private storeConfig(config: TableConfig<U>, delayed: boolean = true) {
    const tableName = this.tableName();
    if (!tableName) {
      return;
    }
    const saveConfig = this.validateConfig(config);
    if (delayed) {
      this.tableConfigService.setTableConfigDelayed(tableName, saveConfig);
    } else {
      this.tableConfigService.setTableConfig(tableName, saveConfig);
    }
  }
}
