export interface TrashListItem {
  uuid: string;
  name: string;
  type: number;

  rootUuid?: string;
  rootName?: string;
  rootType?: string;

  deletedAt: Date;
  purgeAt: Date;

  affectedAssets: number;
  affectedFilesize: number;
}
