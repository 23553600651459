import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-spinner-button',
  standalone: true,
  imports: [ProgressSpinnerModule, ButtonModule, TranslateModule],
  templateUrl: './spinner-button.component.html',
  styleUrl: './spinner-button.component.scss',
})
export class SpinnerButtonComponent {
  readonly disabled = input<boolean>(false);
  readonly busy = input<boolean>(false);
  readonly icon = input.required<string>();
  readonly hoverText = input<string>();
  readonly onClick = output<void>();
  readonly styleClass = input('ui-button-bare-neutral');
}
