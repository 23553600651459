{{ 'service-module-365.workorder.field.name' | translate }}: {{ workOrderName() }}<br />
{{ 'service-module-365.workorder.field.number' | translate }}: {{ workOrderNumber() || '' }}<br />
{{ 'service-module-365.workorder.field.customer-order-number' | translate }}:
{{ workOrderCustomerReference() }}<br />

<app-table-base
  #table
  [list]="mainList"
  [columns]="columns"
  [autoWidth]="false"
  dataKey="uuid"
  noDataMsg="service-module-365.list-of-work-order-documents.message.no-data"
  nofilteredDataMsg="service-module-365.list-of-work-order-documents.message.all-entries-filtered-out"
  errorMsg="service-module-365.list-of-work-order-documents.message.load-error"
>
  <ng-template #cellTemplate let-column let-row="row" let-index="rowIndex">
    @switch (column.field) {
      @case ('title') {
        <div [ngClass]="row.ellipsis ? 'ellipsis' : ''">
          <label class="pointer" (click)="toggleEllipsis(index)">
            {{ row.title }}.{{ row.type }}
          </label>
        </div>
      }
      @case ('lastModified') {
        <div [ngClass]="row.ellipsis ? 'ellipsis' : ''">
          <label class="pointer" (click)="toggleEllipsis(index)">
            {{ row.lastModified | localizedDate: 'mediumDate' }}
          </label>
        </div>
      }
      @case ('actions') {
        <app-download-service-file [file]="row"></app-download-service-file>
      }
    }
  </ng-template>
</app-table-base>

<div class="dialog-footer">
  <button
    pButton
    type="button"
    class="ui-button-primary"
    [disabled]="mainList.loading"
    (click)="dialogRef.close()"
    label="{{ 'buttons.close' | translate }}"
  ></button>
</div>
