import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import {
  ConfirmationService,
  LanguageService,
  LicensingService,
  PermissionsService,
  TreeService,
} from '../../services';
import { MessageService } from 'primeng/api';
import { catchError, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-menu-item-asset-end-sharing',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetEndSharingComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private auth: OAuthService,
    private permissionsService: PermissionsService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('eject', 'assetDetails.menu.leave');
  }

  static override isViableOption(asset?: GetAssetResponse | AssetItem): boolean {
    return true;
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    const rootUuid = this.getRoot().uuid;
    const claims = this.auth.getIdentityClaims();
    const currentLogin: string = claims['preferred_username'] || claims['email'];
    this.confirmationService.confirm({
      translationKey: 'confirm.tree.leave',
      accept: () =>
        this.permissionsService.revokePermissionsForUser(rootUuid, currentLogin).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'info',
              summary: 'toasts.tree.left-shared-tree',
              life: 10000,
            });
            this.forgetAsset(rootUuid);
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (
                err.status === 400 &&
                err.error?.message === 'Revoke would create orphaned tree'
              ) {
                // show error
                this.messageService.add({
                  severity: 'error',
                  summary: 'toasts.misc.leave-last-admin',
                  life: 10000,
                });
              } else if (
                err.status === 400 &&
                err.error?.message === 'user must leave group to revoke permission'
              ) {
                // show error
                this.messageService.add({
                  severity: 'error',
                  summary: 'toasts.misc.leave-from-group',
                  life: 10000,
                });
              }
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'toasts.tree.leave-shared-tree-error',
                life: 10000,
              });
            }
            throw err;
          }),
        ),
    });
  }
}
