import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Feedback } from '../../models/feedback';
import { FeedbackService } from '../../services/feedback.service';
import { Logger } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    RadioButtonModule,
    InputTextareaModule,
    CheckboxModule,
    ButtonModule,
    TranslateModule,
  ],
})
export class FeedbackComponent {
  public saving = false;
  public mainForm = new FormGroup({
    reason: new FormControl<string>('', Validators.required),
    rating: new FormControl<string>('', Validators.required),
    description: new FormControl<string>('', Validators.required),
    anonymous: new FormControl<boolean>(false),
  });

  private logger = new Logger(this.constructor.name);

  constructor(
    private ref: DynamicDialogRef,
    private feedbackService: FeedbackService,
    private config: DynamicDialogConfig,
  ) {
    this.config.dismissableMask = true;
  }

  public onCloseButton() {
    this.ref.close();
  }

  private fetch(name: string): AbstractControl {
    const field = this.mainForm.get(name);
    if (!field) {
      throw new Error(`Missing field ${field}`);
    }
    return field;
  }

  public onFormSubmit() {
    this.saving = true;
    const feedback: Feedback = {
      reason: this.fetch('reason').value,
      rating: parseInt(this.fetch('rating').value, 10),
      description: this.fetch('description').value,
      anonymous: this.fetch('anonymous').value,
      url: window.location.href,
    };

    this.logger.debug('About to send feedback', feedback);
    this.feedbackService.sendFeedback(feedback).subscribe({
      next: () => {
        this.logger.debug('Feedback successfully sent.');
        this.ref.close();
      },
      error: err => {
        this.logger.warn('Error sending feedback', err);
        this.ref.close();
      },
    });
  }
}
