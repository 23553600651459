import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, share, tap } from 'rxjs/operators';
import { MyAccount } from '../models/account';
import { ENVIRONMENT } from '../models';

export class MyAccountAccessDenied extends Error {}

@Injectable({ providedIn: 'root' })
export class AccountService {
  private myAccount: MyAccount;
  private myAccountRequest?: Observable<MyAccount>;

  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  public getMyAccount() {
    if (this.myAccount) {
      this.myAccountRequest = undefined;
      return of(this.myAccount);
    }

    if (this.myAccountRequest) {
      return this.myAccountRequest;
    }

    this.myAccountRequest = this.httpClient
      .get<MyAccount>(`${this.apiUrl}/my-account`, { params: { reset: 'true' } })
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 403) {
            return throwError(
              () => new MyAccountAccessDenied('Access denied when requesting /my-acocunt'),
            );
          }

          return throwError(() => err);
        }),
        tap(res => {
          this.myAccount = res;
        }),
        share(),
      );

    return this.myAccountRequest;
  }
}
