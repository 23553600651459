export function fromUnixTimestamp(ts: number): Date;
export function fromUnixTimestamp(ts: number | undefined): Date | undefined;
export function fromUnixTimestamp(ts: number | null): Date | null;
export function fromUnixTimestamp(ts: number | null | undefined): Date | null | undefined;
export function fromUnixTimestamp(ts: number | null | undefined): Date | null | undefined {
  if (ts === undefined) {
    return;
  }
  if (ts === null) {
    return null;
  }
  const result = new Date(ts * 1000);
  if (isNaN(result.getTime())) {
    throw new RangeError('timestamp too large');
  }
  return result;
}

export function toUnixTimestamp(ts: string | Date): number;
export function toUnixTimestamp(ts: string | Date | undefined): number | undefined;
export function toUnixTimestamp(ts: string | Date | null): number | null;
export function toUnixTimestamp(ts: string | Date | null | undefined): number | null | undefined;
export function toUnixTimestamp(ts: string | Date | null | undefined): number | null | undefined {
  if (ts === undefined) {
    return;
  }
  if (ts === null) {
    return null;
  }
  const date = ts instanceof Date ? ts : new Date(ts);
  return Math.floor(date.getTime() / 1000);
}
