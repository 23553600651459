import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServicePermissionModalComponent } from '@assethub/shared/components/modals';
import { ENVIRONMENT, GroupPermissions, GroupSearchEntry } from '@assethub/shared/models';
import { LanguageService, TreeService } from '@assethub/shared/services';
import { GroupManagementService } from '@assethub/shared/services/group-management.service';
import { Logger } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { SelectItem, SharedModule } from 'primeng/api';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-group-permissions-add',
  templateUrl: './group-permissions-add.component.html',
  styleUrls: ['./group-permissions-add.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    AutoCompleteModule,
    FormsModule,
    SharedModule,
    ButtonModule,
    DropdownModule,
    TranslateModule,
  ],
})
export class GroupPermissionsAddComponent {
  @Input() narrow = false;
  @Output() add = new EventEmitter<GroupPermissions>();

  private logger = new Logger(this.constructor.name);

  public extGroupId = '';
  public groupName = '';
  public suggestionInput = '';
  public groupSearchSuggestions: GroupSearchEntry[] = [];
  public activeSearch = false;
  public permissions = 'r';
  public permissionOptions: SelectItem[] = [
    {
      label: 'assetPermissions.read',
      value: 'r',
    },
    {
      label: 'assetPermissions.write',
      value: 'rw',
    },
    {
      label: 'assetPermissions.admin',
      value: 'rwa',
    },
  ];

  private groupManagement = inject(ENVIRONMENT).groupManagement;

  constructor(
    private groupMgmtService: GroupManagementService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private treeService: TreeService,
  ) {}

  public searchGroups(event: AutoCompleteCompleteEvent) {
    const searchText = event.query.trim();
    if (!searchText || searchText.length < 3) {
      // drop-down button has been pressed -> create copy of last results to make the auto-complete show it again
      this.groupSearchSuggestions = this.groupSearchSuggestions.slice();
      return;
    }

    this.activeSearch = true;
    this.groupMgmtService.findGroups(searchText, 0, 20).subscribe({
      next: pageOfGroups => {
        this.groupSearchSuggestions = pageOfGroups.page.map(x => ({
          extGroupId: x.id,
          displayName: x.display_name,
        }));
        this.activeSearch = false;
      },
      error: err => {
        this.logger.error(err);
        this.activeSearch = false;
      },
    });
  }

  public selectGroup(event: AutoCompleteSelectEvent) {
    this.extGroupId = event.value.extGroupId;
    this.groupName = event.value.displayName;
  }

  public onNewGroupPermission() {
    const selectedNode = this.treeService.selectedNode();
    if (selectedNode !== undefined && selectedNode.root.crm365) {
      this.dialogService
        .open(ServicePermissionModalComponent, {
          header: this.languageService.instant('assetPermissions.serviceWarning.title'),
          data: {
            group: true,
          },
        })
        .onClose.subscribe(value => {
          if (value !== undefined && value) {
            this.add.emit({
              groupName: this.groupName,
              extGroupId: this.extGroupId,
              permissions: this.permissions,
            });
          }
        });
    } else {
      this.add.emit({
        groupName: this.groupName,
        extGroupId: this.extGroupId,
        permissions: this.permissions,
      });
    }
  }

  public clear() {
    this.suggestionInput = '';
    this.groupSearchSuggestions = [];
    this.extGroupId = '';
    this.groupName = '';
    this.permissions = 'r';
  }

  public navigateToGroup(id: string) {
    if (!this.groupManagement) {
      return;
    }
    const url = this.groupManagement.baseUrl + `/visible-groups/${id}`;
    window.open(url, '_blank');
  }
}
