<span class="p-float-label" [ngClass]="{ 'always-expanded': hasTextInput() }">
  <p-autoComplete
    [(ngModel)]="labels"
    [maxlength]="40"
    appendTo="body"
    [unique]="true"
    [showClear]="true"
    [multiple]="true"
    [dropdown]="true"
    dropdownMode="current"
    [suggestions]="suggestions()"
    (completeMethod)="onCompleteMethod($event)"
    (onBlur)="onBlur($event)"
    (onClear)="onClear()"
    (onUnselect)="onUnselect($event)"
  >
    <ng-template pTemplate="empty">{{ 'label-picker.no-suggestions' | translate }}</ng-template>
    <ng-template pTemplate="clearicon"><app-icon shape="cancel"></app-icon></ng-template>
    <ng-template pTemplate="footer">
      @if (suggestNewLabel(); as suggestNewLabel) {
        <hr />
        <div class="footer p-autocomplete-item" (click)="onNewLabel(suggestNewLabel)">
          {{ suggestNewLabel }} ({{ 'label-picker.new-label' | translate }})
        </div>
      }
    </ng-template>
  </p-autoComplete>
  <label>{{ 'label-picker.title' | translate }}</label>
</span>
