import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface DraggedAssetNode {
  uuid: string;
  rootUuid: string;
  readonly: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TreeDragDropService {
  private dndEvent: Subject<DraggedAssetNode | undefined> = new Subject();
  get $dndEvent(): Observable<DraggedAssetNode | undefined> {
    return this.dndEvent.asObservable();
  }

  dragStart(draggedNode: DraggedAssetNode) {
    this.dndEvent.next(draggedNode);
  }

  dragEnd() {
    this.dndEvent.next(undefined);
  }
}
