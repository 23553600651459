@if (display() === 'preview') {
  <!-- 404 detector -->
  <img
    style="display: none"
    src="{{ previewUrl() }}"
    (error)="error.set(true)"
    (load)="error.set(false); loading.set(false)"
  />
  @if (error()) {
    <app-media-placeholder shape="broken_image"></app-media-placeholder>
  } @else if (loading()) {
    <app-media-placeholder shape="movie"></app-media-placeholder>
  } @else {
    <div
      class="preview-image cursor-pointer"
      [ngStyle]="{
        'background-image': 'url(' + previewUrl() + ')',
      }"
      (click)="onClick($event)"
    >
      <div class="icon">
        <app-icon shape="play_circle"></app-icon>
      </div>
    </div>
  }
} @else {
  <div class="frame">
    <div>
      <div id="{{ PLAYER_ID }}"></div>
    </div>
  </div>
}
