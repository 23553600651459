import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { AssetIconMode, AssetTitleMode, UserConfiguration } from '../models/user-configuration';
import { isDefined, Logger } from '../utils';
import { AccountService } from './account.service';
import { LocalStorageService } from './browser-storage.service';

const DEFAULT_SETTINGS: UserConfiguration = {
  showAsIcon: AssetIconMode.PHOTO,
  showAsTitle: AssetTitleMode.CUSTOM,
  navDrawerExpanded: false,
  lastTreeOpenedId: undefined,
  hideMapInfo: false,
  assetSearch: {},
  lastReadReleaseNote: '',
};

@Injectable({
  providedIn: 'root',
})
export class UserConfigurationService {
  private userConfigKey = 'user-config/';
  private config$: BehaviorSubject<UserConfiguration> = new BehaviorSubject(DEFAULT_SETTINGS);
  private logger = new Logger(this.constructor.name);

  constructor(
    private localStorageService: LocalStorageService,
    private accountService: AccountService,
  ) {}

  public config(): Observable<UserConfiguration> {
    return this.config$.asObservable();
  }

  public setShowAsIcon(mode: AssetIconMode) {
    this.config$.next({ ...this.config$.value, showAsIcon: mode });
    this.save();
  }

  public setShowAsTitle(mode: AssetTitleMode) {
    this.config$.next({ ...this.config$.value, showAsTitle: mode });
    this.save();
  }

  public setNavDrawerExpanded(expanded: boolean) {
    this.config$.next({ ...this.config$.value, navDrawerExpanded: expanded });
    this.save();
  }

  public setTreeId(treeId?: string) {
    this.config$.next({ ...this.config$.value, lastTreeOpenedId: treeId });
    this.save();
  }

  public setHideMapInfo(mapInfo: boolean) {
    this.config$.next({ ...this.config$.value, hideMapInfo: mapInfo });
    this.save();
  }

  public setAssetSearchSortCriteria(sortField: string, sortOrder: number) {
    const updatedConfig = this.config$.value;
    updatedConfig.assetSearch.sortField = sortField;
    updatedConfig.assetSearch.sortOrder = sortOrder;
    this.config$.next(updatedConfig);
    this.save();
  }

  public setAssetSearchHiddenColumns(hiddenColumns: string[]) {
    const updatedConfig = this.config$.value;
    updatedConfig.assetSearch.hiddenColumns = hiddenColumns;
    this.config$.next(updatedConfig);
    this.save();
  }

  public setLastReadReleaseNote(releaseNote: string) {
    this.config$.next({ ...this.config$.value, lastReadReleaseNote: releaseNote });
    this.save();
  }

  public setup(): Promise<void> {
    return firstValueFrom(
      this.accountService.getMyAccount().pipe(
        map(acc => {
          this.userConfigKey += acc.uuid;
          return this.localStorageService.get(this.userConfigKey) as UserConfiguration;
        }),
        tap(configStored => this.validateConfigAndSave(configStored)),
        switchMap(() => of(undefined)),
        retry(3),
        catchError(error => {
          this.logger.error(
            'Error initializing user configuration service. Retrieving user account data failed.',
          );
          throw error;
        }),
      ),
    );
  }

  private validateConfigAndSave(configStored?: UserConfiguration) {
    // In case new properties are added to our user configuration the first run of a user will
    // find an old version without that props. So we have to detect that changes by hand and
    // update the storage if needed
    if (!configStored) {
      configStored = DEFAULT_SETTINGS;
    } else {
      if (!isDefined(configStored.assetSearch)) {
        configStored.assetSearch = DEFAULT_SETTINGS.assetSearch;
      }

      if (!isDefined(configStored.hideMapInfo)) {
        configStored.hideMapInfo = DEFAULT_SETTINGS.hideMapInfo;
      }

      if (!isDefined(configStored.lastTreeOpenedId)) {
        configStored.lastTreeOpenedId = DEFAULT_SETTINGS.lastTreeOpenedId;
      }

      if (!isDefined(configStored.navDrawerExpanded)) {
        configStored.navDrawerExpanded = DEFAULT_SETTINGS.navDrawerExpanded;
      }

      if (!isDefined(configStored.showAsIcon)) {
        configStored.showAsIcon = DEFAULT_SETTINGS.showAsIcon;
      }

      if (!isDefined(configStored.showAsTitle)) {
        configStored.showAsTitle = DEFAULT_SETTINGS.showAsTitle;
      }
    }

    // Broadcast config loaded
    this.config$.next(configStored);

    // Save to persist config state in case some properties are updated
    this.save();
  }

  private save() {
    this.localStorageService.set(this.userConfigKey, this.config$.value);
  }
}
