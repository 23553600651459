import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightTextPipe implements PipeTransform {
  public transform(text?: string, searchKey?: string) {
    if (!searchKey || !text) {
      return text;
    }

    const pattern = searchKey
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');

    return text.replace(
      new RegExp(pattern, 'gi'),
      match => `<span class="highlight">${match}</span>`,
    );
  }
}
