<div>
  <app-callout>{{ 'assetPermissions.infoBox.user' | translate }}</app-callout>
  <app-permissions-add #permissionAdd [narrow]="narrow" (add)="onNewPermission($event)">
  </app-permissions-add>
  @if (error) {
    <app-callout
      [severity]="errorSeverity"
      [closable]="true"
      (calloutClosed)="setError(undefined)"
      >{{ error | translate }}</app-callout
    >
  }
</div>

<app-permissions-view
  [permissionEntries]="permissionEntries"
  [totalRecords]="totalRecords"
  [pageSize]="PAGE_SIZE"
  [paginatorStart]="paginatorStart"
  [narrow]="narrow"
  [loading]="loading"
  [saving]="saving"
  (deletePermission)="onDeletePermission($event)"
  (togglePermission)="onTogglePermissions($event.entry, $event.permission)"
  (lazyLoad)="onLazyLoad($event)"
></app-permissions-view>
