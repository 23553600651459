import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * LanguageService is a deprecated service. It was used to fetch translations, directly via `instant()` or as
 * Observables via `translate()` where the Observables would immediately resolve the translation and will re-emit
 * as soon as the frontend language changed.
 *
 * CLOUD-1395 introduces a frontend reinitialization on language change, so the Observables handed out are no
 * longer needed.
 *
 * LanguageService has been modified to be a thin wrapper around TranslateService.
 *
 * @deprecated Use TranslateService directly
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  /**
   *
   * @deprecated Use TranslateService.get()
   */
  translate(key: string | string[]): Observable<any> {
    return this.translateService.get(key);
  }

  /**
   *
   * @deprecated Use TranslateService.instant()
   */
  instant(key: string | string[], interpolateParams?: object): any {
    return this.translateService.instant(key, interpolateParams);
  }
}
