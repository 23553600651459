<div class="ellipsis">
  <label>{{ label }}</label>
  <app-sort-icon
    *ngIf="field"
    [field]="field"
    [filter]="filter"
    [filterName]="filterName"
    [type]="type"
  ></app-sort-icon>
</div>
