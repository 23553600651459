@if (permissions.write) {
  <div class="new-link">
    <app-text-field
      class="text-field-name"
      label="{{ 'assetLinks.name' | translate }}"
      name="name"
      [(ngModel)]="newName"
      [ngModelOptions]="{ standalone: true }"
      [errorMsg]="nameError"
      (keyup.enter)="addNewLink($event)"
      [required]="true"
    >
    </app-text-field>
    <app-text-field
      class="text-field-url"
      label="{{ 'assetLinks.url' | translate }}"
      name="url"
      [(ngModel)]="newUrl"
      [ngModelOptions]="{ standalone: true }"
      [errorMsg]="urlError"
      (keyup.enter)="addNewLink($event)"
      [required]="true"
    >
    </app-text-field>
    <button
      pButton
      type="button"
      class="ui-button-primary button"
      icon="pi pi-plus"
      label="{{ 'assetLinks.addLink' | translate }}"
      (click)="addNewLink()"
    ></button>
  </div>
}
<div class="table-wrapper">
  <p-table
    [value]="links"
    dataKey="uuid"
    editMode="row"
    breakpoint="0"
    [scrollable]="true"
    styleClass="limit-height"
  >
    <ng-template pTemplate="header">
      <tr>
        @if (rowRestructureEditModeEnabled) {
          <th style="width: 3.5rem"></th>
        }
        <th style="width: 45%">{{ 'assetLinks.table.name' | translate }}</th>
        <th style="width: 55%">{{ 'assetLinks.table.url' | translate }}</th>
        <th style="width: 6.5rem">
          <ng-container *ngTemplateOutlet="reorderLinksOptionMenu"></ng-container>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-link let-index="rowIndex" let-editing="editing">
      <tr [pEditableRow]="link" [pReorderableRow]="index">
        @if (rowRestructureEditModeEnabled) {
          <td>
            <div class="pi pi-drag-handle primary" pReorderableRowHandle></div>
          </td>
        }
        <td class="align-top ellipsis">
          <p-cellEditor>
            <ng-template pTemplate="output">
              <span class="cursor-insert">{{ link.name }}</span>
            </ng-template>
            <ng-template pTemplate="input">
              <app-text-field
                class="text-field-name"
                label="{{ 'assetLinks.name' | translate }}"
                name="name"
                [(ngModel)]="link.name"
                [ngModelOptions]="{ standalone: true }"
                [errorMsg]="updatedNameError"
                (blurred)="revalidateLinkName(index)"
                (keyup.enter)="onEnterKey($event, index)"
                (keyup.escape)="onEscapeKey(index)"
                [required]="true"
              ></app-text-field>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="align-top ellipsis">
          <p-cellEditor>
            <ng-template pTemplate="output">
              <a [href]="link.url" target="_blank" rel="noopener">{{ link.url }}</a>
            </ng-template>
            <ng-template pTemplate="input">
              <app-text-field
                class="text-field-url"
                label="{{ 'assetLinks.url' | translate }}"
                name="url"
                [(ngModel)]="link.url"
                [ngModelOptions]="{ standalone: true }"
                [errorMsg]="updatedUrlError"
                (blurred)="revalidateLinkUrl(index)"
                (keyup.enter)="onEnterKey($event, index)"
                (keyup.escape)="onEscapeKey(index)"
                [required]="true"
              ></app-text-field>
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          @if (permissions.write && !editing) {
            <div class="action-buttons">
              <button
                pButton
                pInitEditableRow
                type="button"
                class="ui-button-bare-neutral"
                icon="pi pi-pencil"
                [disabled]="rowRestructureEditModeEnabled || linkRowEditEnabled !== undefined"
                (click)="enableLinkEditMode(index)"
              ></button>
              <button
                pButton
                type="button"
                class="ui-button-bare-neutral"
                icon="pi pi-trash"
                [disabled]="rowRestructureEditModeEnabled || linkRowEditEnabled !== undefined"
                (click)="deleteLink(index)"
              ></button>
            </div>
          }
          @if (editing) {
            <div class="action-buttons editing">
              <button
                pButton
                pCancelEditableRow
                type="button"
                class="ui-button-bare-neutral"
                icon="pi pi-undo"
                (click)="revertLinkUpdate(index)"
              ></button>
              <button
                pButton
                pSaveEditableRow
                type="button"
                class="ui-button-bare"
                icon="pi pi-save"
                (click)="saveLinkUpdate(index)"
              ></button>
            </div>
          }
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4" class="empty-table-message">
          @if (permissions.write) {
            <div>
              <app-callout type="info">{{ 'assetLinks.noLinks' | translate }}</app-callout>
            </div>
          } @else {
            <div>
              <app-callout type="info">{{ 'assetLinks.noLinksReadOnly' | translate }}</app-callout>
            </div>
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div>
  <button
    pButton
    type="button"
    label="{{ 'buttons.close' | translate }}"
    (click)="close()"
  ></button>
</div>

<ng-template #reorderLinksOptionMenu>
  @if (permissions.write) {
    <div>
      @if (!rowRestructureEditModeEnabled) {
        <button
          pButton
          type="button"
          class="ui-button-bare-neutral p-button-icon-only"
          (click)="enableRowRestructureEditMode()"
          [disabled]="links.length === 1 || linkRowEditEnabled !== undefined"
        >
          <app-icon shape="custom_link_order_edit_icon"></app-icon>
        </button>
      } @else {
        <button
          pButton
          type="button"
          class="ui-button-bare-neutral"
          icon="pi pi-undo"
          (click)="undoRowRestructure()"
        ></button>
        <button
          pButton
          type="button"
          class="ui-button-bare-neutral"
          icon="pi pi-save"
          [disabled]="!rowsRestructured()"
          (click)="persistRowRestructure()"
        ></button>
      }
    </div>
  }
</ng-template>
