import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { ConsentService, LicensingService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { OAuthService } from 'angular-oauth2-oidc';

interface MyAccount {
  uuid: string;
  attributes: {
    admin?: 'operator' | 'admin';
    developer?: boolean;
    licenseAssetHub?: string;
    licenseLiveConnect?: null;
    licenseServiceModule?: null;
  };
}

@Component({
  selector: 'app-self-diagnosis',
  templateUrl: './self-diagnosis.component.html',
  styleUrls: ['./self-diagnosis.component.scss'],
  standalone: true,
  imports: [DatePipe],
})
export class SelfDiagnosisComponent implements OnInit {
  currentLogin: string;
  name: string;
  customernumber: string;
  subsidiaryCode: string;
  tokenIssuedAt: Date;
  tokenExpire: Date;
  customerId: string;
  now: Date;
  token: string;
  consents: string;
  overrideAssetHub: string;
  overrideLiveConnect: string;
  privileges: string;

  private logger = new Logger(this.constructor.name);
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(
    private auth: OAuthService,
    public licensingService: LicensingService,
    private httpClient: HttpClient,
    private consentService: ConsentService,
  ) {}

  ngOnInit(): void {
    this.token = this.auth.getIdToken();
    const claims = this.auth.getIdentityClaims();
    this.currentLogin = claims['preferred_username'] || claims['email'];
    this.name = claims['name'];
    this.customernumber = claims['customernumber'];
    this.subsidiaryCode = claims['ssucode'];
    this.tokenExpire = new Date(claims['exp'] * 1000);
    this.tokenIssuedAt = new Date(claims['iat'] * 1000);
    this.now = new Date();
    this.customerId = claims['sub'];

    this.consentService.fetchConsents().subscribe({
      next: countOpen => {
        if (countOpen) {
          this.consents = `ok, pending consents for ${countOpen} services`;
        } else {
          this.consents = `ok, no pending consents`;
        }
      },
      error: err => {
        this.logger.error(err);
        this.consents = this.formatError(err);
      },
    });

    this.httpClient.get<MyAccount>(this.apiUrl + '/my-account').subscribe({
      next: ok => {
        this.logger.debug('my account:', ok);
        this.overrideAssetHub = ok.attributes?.licenseAssetHub ? '*' : '';
        this.overrideLiveConnect = 'licenseLiveConnect' in ok.attributes ? '*' : '';
        this.privileges = ok.attributes?.admin || 'none';
      },
      error: err => {
        this.logger.error(err);
      },
    });
  }

  private formatError(err): string {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 0) {
        return err.message;
      } else {
        return `${err.message}: (${err.error?.message})`;
      }
    }
    return err.toString();
  }
}
