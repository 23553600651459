import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserConfigurationService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, map, Observable, of, take } from 'rxjs';
import { marked, RendererObject, Tokens } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// show notes for this many days
const RELEASE_NOTE_SHOW_DAYS = 28 * 24 * 60 * 60 * 1000;

export interface ReleaseNote {
  date: string;
  title: { [key: string]: string };
}

export interface TranslatedReleaseNote {
  date: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  public referenceDate = new Date();

  private releaseNotesIndexFile = 'assets/release-notes/index.json';
  private logger = new Logger(this.constructor.name);

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private userConfigService: UserConfigurationService,
    private domSanitizer: DomSanitizer,
  ) {}

  public getAllReleaseNotes(lang: string): Observable<TranslatedReleaseNote[]> {
    return this.httpClient
      .get<ReleaseNote[]>(this.releaseNotesIndexFile, { responseType: 'json' })
      .pipe(
        map(releaseNotes => releaseNotes.filter(note => undefined !== note.title[lang])),
        map(releaseNotes =>
          releaseNotes.map(note => ({ date: note.date, title: note.title[lang] })),
        ),
        catchError(err => {
          this.logger.warn('Error while loading release notes index', err);
          return of([]);
        }),
      );
  }

  public getLatestReleaseNote(lang: string): Observable<TranslatedReleaseNote | undefined> {
    return forkJoin({
      userConfig: this.userConfigService.config().pipe(take(1)),
      latestNote: this.getAllReleaseNotes(lang).pipe(map(notes => notes[0])),
    }).pipe(
      map(({ userConfig, latestNote }) => {
        if (latestNote === undefined) {
          return;
        }

        if (
          this.referenceDate.getTime() - new Date(latestNote.date).getTime() >
          RELEASE_NOTE_SHOW_DAYS
        ) {
          return;
        }

        if (userConfig.lastReadReleaseNote === latestNote.date) {
          return;
        }

        return latestNote;
      }),
    );
  }

  public downloadReleaseNote(releaseNoteDate: string): Observable<string> {
    const releaseNotesBasePath = 'assets/release-notes/';

    return this.httpClient
      .get(releaseNotesBasePath + releaseNoteDate + `/${this.translateService.currentLang}.md`, {
        responseType: 'text',
      })
      .pipe(
        // remove first line, since it is already shown in accordion or dialog header
        map(markDown => markDown.split('\n').splice(1).join('\n')),
      );
  }

  public downloadReleaseNoteHtml(releaseNoteDate: string): Observable<SafeHtml> {
    const renderer: RendererObject = {
      image: (token: Tokens.Image) => {
        let imageString = '<img';
        if (token.href) {
          imageString += ' src="assets/release-notes/' + releaseNoteDate + '/' + token.href + '"';
        }
        imageString += ' alt="' + token.text + '"';
        if (token.title) {
          imageString += ' title="' + token.title + '"';
        }
        imageString += '>';
        return imageString;
      },
    };

    marked.use({ useNewRenderer: true, renderer });

    return this.downloadReleaseNote(releaseNoteDate).pipe(
      map(markdown => marked.parse(markdown) as string),
      map(html => this.domSanitizer.bypassSecurityTrustHtml(html)),
    );
  }
}
