import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import {
  Endpoint,
  EndpointCreationRequest,
  EndpointStatus,
  EndpointVerificationRequest,
  EndpointWithChannels,
  LiveConnectChannel,
  Paginated,
  TrafficStats,
} from '@liveconnect/shared/models';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EndpointService {
  private endpointsSubject = new ReplaySubject<Endpoint[]>(1);
  private endpointsStore: Endpoint[] = [];
  private useStore = false;
  private logger = new Logger(this.constructor.name);
  private liveConnectUrl = inject(ENVIRONMENT).liveConnectUrl;

  constructor(private httpClient: HttpClient) {}

  public verifyEndpoint(endpoint: EndpointVerificationRequest) {
    if (!this.liveConnectUrl) {
      return of<EndpointStatus>({
        ok: false,
        error: { message: 'Live Connect not configured in this environment' },
      });
    }
    return this.httpClient.post<EndpointStatus>(`${this.liveConnectUrl}/endpoint/verify`, endpoint);
  }

  // TODO: Use this method
  public getEndpointStatus(endpointId: string) {
    if (!this.liveConnectUrl) {
      return of<EndpointStatus>({
        ok: false,
        error: { message: 'Live Connect not configured in this environment' },
      });
    }
    return this.httpClient.get<EndpointStatus>(
      `${this.liveConnectUrl}/endpoint/${endpointId}/status`,
    );
  }

  private emitEndpointChange() {
    this.endpointsSubject.next(this.endpointsStore);
  }

  public getEndpoints(): Observable<Endpoint[]> {
    if (!this.liveConnectUrl) {
      return of([]);
    }
    // The database may take a while to update modifications, so don't ask the backend immediately
    if (!this.useStore) {
      this.getEndpointsInternal().subscribe(endpoints => {
        this.logger.debug('Emitting received endpoints');
        this.endpointsStore = endpoints;
        this.emitEndpointChange();
      });
    } else {
      this.useStore = false;
    }
    return this.endpointsSubject.asObservable();
  }

  private getEndpointsInternal(lastKey?: string): Observable<Endpoint[]> {
    const params = undefined === lastKey ? undefined : { lastKey };
    return this.httpClient
      .get<Paginated<Endpoint>>(`${this.liveConnectUrl}/endpoints`, {
        params,
      })
      .pipe(
        concatMap(paginated => {
          if (paginated.pageSize <= paginated.items.length && paginated.lastKey !== undefined) {
            return this.getEndpointsInternal(paginated.lastKey).pipe(
              map(elements => [...elements, ...paginated.items]),
            );
          }

          return of(paginated.items);
        }),
      );
  }

  public createEndpoint(endpoint: EndpointCreationRequest) {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient.post<Endpoint>(`${this.liveConnectUrl}/endpoint`, endpoint).pipe(
      tap(created => {
        this.logger.debug('Emitting endpoint creation');
        this.useStore = true;
        this.endpointsStore.push(created);
        this.emitEndpointChange();
      }),
    );
  }

  public getEndpoint(endpointId: string): Observable<Endpoint> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient.get<EndpointWithChannels>(
      `${this.liveConnectUrl}/endpoint/${endpointId}`,
    );
  }

  public getEndpointChannels(endpointId: string): Observable<LiveConnectChannel[]> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.getEndpointChannelsInternal(endpointId);
  }

  public getTraffic(
    endpointId: string,
    duration: number,
    start: Date,
    end: Date,
  ): Observable<TrafficStats[]> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient
      .get<Paginated<TrafficStats>>(`${this.liveConnectUrl}/endpoint/${endpointId}/traffic`, {
        params: {
          duration,
          start: start.toISOString(),
          end: end.toISOString(),
        },
      })
      .pipe(map(pager => pager.items));
  }

  private getEndpointChannelsInternal(
    endpointId: string,
    lastKey?: string,
  ): Observable<LiveConnectChannel[]> {
    const params = undefined === lastKey ? undefined : { lastKey };
    return this.httpClient
      .get<Paginated<LiveConnectChannel>>(
        `${this.liveConnectUrl}/endpoint/${endpointId}/channels`,
        {
          params,
        },
      )
      .pipe(
        concatMap(paginated => {
          if (paginated.pageSize <= paginated.items.length && paginated.lastKey !== undefined) {
            return this.getEndpointChannelsInternal(endpointId, paginated.lastKey).pipe(
              map(elements => [...elements, ...paginated.items]),
            );
          }

          return of(paginated.items);
        }),
      );
  }

  public updateEndpoint(endpointId: string, endpoint: EndpointCreationRequest) {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient
      .put<Endpoint>(`${this.liveConnectUrl}/endpoint/${endpointId}`, endpoint)
      .pipe(
        tap(updated => {
          const index = this.endpointsStore.findIndex(element => element.id === updated.id);
          if (0 <= index) {
            this.logger.debug('Emitting endpoint update');
            this.useStore = true;
            this.endpointsStore.splice(index, 1, updated);
            this.emitEndpointChange();
          }
        }),
      );
  }

  public deleteEndpoint(endpointId: string): Observable<Endpoint> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient.delete<Endpoint>(`${this.liveConnectUrl}/endpoint/${endpointId}`).pipe(
      tap(deleted => {
        this.logger.debug('Emitting endpoint deletion');
        this.useStore = true;
        this.endpointsStore = this.endpointsStore.filter(element => element.id !== deleted.id);
        this.emitEndpointChange();
      }),
    );
  }
}
