import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ViewChild, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Link, NewLink } from '@assethub/asset-management/models/link';
import { LinkService } from '@assethub/asset-management/services/link.service';
import { AssetPermissions, GetAssetResponse, permissionsFromString } from '@assethub/shared/models';
import { Logger, isUrl } from '@assethub/shared/utils';
import { AppInjector } from '@assethub/shared/utils/app-injector';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table, TableModule } from 'primeng/table';
import { CalloutComponent } from '../callout/callout.component';
import { IconComponent } from '../icon/icon.component';
import { TextFieldComponent } from '../text-field/text-field.component';

const INITIAL_URL = 'https://';

interface DialogData {
  asset: GetAssetResponse;
  updatedLinks: WritableSignal<Link[]>;
}

@Component({
  selector: 'app-links-edit',
  templateUrl: './links-edit.component.html',
  styleUrls: ['./links-edit.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ButtonModule,
    TableModule,
    TranslateModule,
    TextFieldComponent,
    IconComponent,
    CalloutComponent,
  ],
})
export class LinksEditComponent {
  readonly asset: GetAssetResponse;
  readonly updatedLinks: WritableSignal<Link[]>;

  public newName = '';
  public newUrl: string = INITIAL_URL;
  public links: Link[];
  public rowRestructureBackup: string[] = [];
  public urlError?: string;
  public nameError?: string;
  public updatedNameError?: string;
  public updatedUrlError?: string;
  public columns;
  public permissions: AssetPermissions = { admin: false, write: false, read: true };
  public rowRestructureEditModeEnabled = false;
  public linkRowEditEnabled: number | undefined = undefined;

  @ViewChild('mainTable', { static: false })
  mainTable: Table;

  private linkNameBackup = '';
  private linkUrlBackup = '';
  private linksDirty = false;
  private logger = new Logger('LinksEditComponent');

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<DialogData>,
    private linkService: LinkService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.config.header = translateService.instant('assetLinks.title');
    this.config.focusOnShow = false;
    this.config.style = {
      'max-width': '60em',
      'min-height': '80%',
      'max-height': '80%',
    };

    const data = this.config.data;
    if (data) {
      this.asset = data.asset;
      this.permissions = permissionsFromString(this.asset.permissions);
      this.links = [...(this.asset.links || [])];
      this.updatedLinks = data.updatedLinks;

      // if dialog is closed and links were modified, then update
      // the asset and inform with the update links signal
      this.ref.onClose.subscribe(() => {
        if (this.linksDirty) {
          this.asset.links = [...this.links];
          this.updatedLinks.set(this.asset.links);
        }
      });
    }

    this.reset();
  }

  static open(asset: GetAssetResponse, updatedLinks: WritableSignal<Link[]>) {
    const data: DialogData = { asset, updatedLinks };
    return AppInjector.get(DialogService).open(LinksEditComponent, { data });
  }

  close() {
    this.ref.close();
  }

  private setLinks(links: Link[]) {
    this.links = links;
    this.linksDirty = true;
  }

  private reset() {
    this.newName = '';
    this.newUrl = INITIAL_URL;
    this.urlError = undefined;
    this.nameError = undefined;
    this.linkRowEditEnabled = undefined;
    this.linkNameBackup = '';
    this.linkUrlBackup = '';
    this.updatedUrlError = undefined;
    this.updatedNameError = undefined;
  }

  public addNewLink(event?: Event) {
    this.newUrl = this.newUrl.trim();
    this.newName = this.newName.trim();
    this.nameError = this.isLinkNameInvalid(this.newName);
    this.urlError = this.isLinkUrlInvalid(this.newUrl);

    if (this.nameError || this.urlError) {
      return;
    }

    if (event) {
      (event.target as HTMLElement).blur();
    }

    const newLink: NewLink = {
      name: this.newName,
      url: this.newUrl,
    };

    this.linkService.addLink(this.asset.uuid, newLink).subscribe({
      next: link => {
        this.setLinks([...this.links, link]);
        this.reset();
      },
      error: error => {
        this.logger.error('Error adding link: ' + JSON.stringify(newLink) + ': ', error);
        this.messageService.add({
          severity: 'error',
          summary: 'assetLinks.http-errors.add-link-failed',
          life: 5000,
        });
      },
    });
  }

  public enableLinkEditMode(index: number) {
    this.linkRowEditEnabled = index;
    this.linkNameBackup = this.links[index].name;
    this.linkUrlBackup = this.links[index].url;
  }

  public revertLinkUpdate(index: number) {
    this.linkRowEditEnabled = undefined;
    this.links[index].name = this.linkNameBackup;
    this.links[index].url = this.linkUrlBackup;
    this.updatedUrlError = undefined;
    this.updatedNameError = undefined;
  }

  public revalidateLinkName(index: number) {
    const name = (this.links[index].name = this.links[index].name.trim());
    this.updatedNameError = this.isLinkNameInvalid(name, index);
  }

  public revalidateLinkUrl(index: number) {
    const url = (this.links[index].url = this.links[index].url.trim());
    this.updatedUrlError = this.isLinkUrlInvalid(url, index);
  }

  public saveLinkUpdate(index: number) {
    return new Promise<void>((resolve, reject) => {
      this.updatedNameError = this.isLinkNameInvalid(this.links[index].name, index);
      this.updatedUrlError = this.isLinkUrlInvalid(this.links[index].url, index);

      if (this.updatedNameError || this.updatedUrlError) {
        reject();
        return;
      }

      const update: { name?: string; url?: string } = {
        name: this.links[index].name,
        url: this.links[index].url,
      };

      this.linkService.updateLink(this.asset.uuid, this.links[index].uuid, update).subscribe({
        next: () => {
          this.setLinks([...this.links]);
          this.linkRowEditEnabled = undefined;
          resolve();
        },
        error: error => {
          this.logger.error(
            'Error updating link ' + JSON.stringify(this.links[index]) + ': ' + error,
          );
          this.messageService.add({
            severity: 'error',
            summary: 'assetLinks.http-errors.update-link-failed',
            life: 5000,
          });
          reject();
        },
      });
    });
  }

  async onEnterKey(event: Event, index: number) {
    const target = event.target || event.currentTarget;
    let element: HTMLElement | null = <HTMLElement>target;
    while (element && element.tagName.toLowerCase() !== 'tr') {
      element = element.parentElement;
    }
    if (!element) {
      throw new Error('error: failed to find <tr> for event target');
    }
    try {
      await this.saveLinkUpdate(index);
      this.mainTable.saveRowEdit(this.links[index], <HTMLTableRowElement>element);
    } catch (ex) {
      // only interested in skipping the call to saveRowEdit
    }
  }

  onEscapeKey(index: number) {
    this.revertLinkUpdate(index);
    this.mainTable.cancelRowEdit(this.links[index]);
  }

  public deleteLink(index: number) {
    if (this.links.length !== 0) {
      const linkUuid = this.links[index].uuid;
      this.linkService.deleteLink(this.asset.uuid, linkUuid).subscribe({
        next: () => {
          this.setLinks(this.links.filter(link => link.uuid !== linkUuid));
        },
        error: error => {
          this.logger.error(
            'Error deleting link: ' + JSON.stringify(this.links[index]) + ': ' + error,
          );
          this.messageService.add({
            severity: 'error',
            summary: 'assetLinks.http-errors.remove-link-failed',
            life: 5000,
          });
        },
      });
    }
  }

  public enableRowRestructureEditMode() {
    this.rowRestructureBackup = this.links.map(link => link.uuid);
    this.rowRestructureEditModeEnabled = true;
  }

  public undoRowRestructure() {
    this.setLinks(
      this.rowRestructureBackup.map<Link>(item => this.links.find(link => link.uuid === item)!),
    );

    this.rowRestructureEditModeEnabled = false;
  }

  public persistRowRestructure() {
    // Create the update of restructured link rows
    this.linkService.reorderLinks(this.asset.uuid, this.links).subscribe({
      next: () => {
        this.setLinks([...this.links]);
        this.rowRestructureEditModeEnabled = false;
      },
      error: error => {
        this.logger.error('Error reordering links. Error = ', error);
        this.messageService.add({
          severity: 'error',
          summary: 'assetLinks.http-errors.reorder-links-failed',
          life: 5000,
        });
      },
    });
  }

  public rowsRestructured() {
    return this.rowRestructureBackup.some((linkId, idx) => this.links[idx].uuid !== linkId);
  }

  private valueAlreadyExists(fieldName: keyof Link, value: string, idx?: number): boolean {
    return this.links.some(
      (link, index) =>
        (idx === undefined || index !== idx) &&
        link[fieldName].toLowerCase() === value.toLocaleLowerCase(),
    );
  }

  private isLinkNameInvalid(name: string, index?: number): string | undefined {
    if (!name) {
      return this.translateService.instant('assetLinks').noName;
    }

    if (this.valueAlreadyExists('name', name, index)) {
      return this.translateService.instant('assetLinks.nameExistsAlready', {
        name,
      });
    }

    return undefined;
  }

  private isLinkUrlInvalid(url: string, index?: number): string | undefined {
    if (!isUrl(url)) {
      const trans = this.translateService.instant('assetLinks');
      if (!url) {
        return trans.noUrl;
      }

      return trans.noValidUrl;
    }

    if (this.valueAlreadyExists('url', url, index)) {
      return this.translateService.instant('assetLinks.urlExistsAlready', { url });
    }

    return undefined;
  }
}
