import { Component, Inject, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetItem, Environment, ENVIRONMENT, GetAssetResponse } from '@assethub/shared/models';
import { DialogService } from 'primeng/dynamicdialog';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { SwitchTreeComponent } from '../modals';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-menu-item-asset-move',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetMoveComponent extends BasicMenuItemAssetComponent {
  private static moveTreeEnabled = false;

  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private dialogService: DialogService,
    @Inject(ENVIRONMENT) private settings: Environment,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('shortcut', 'assetDetails.menu.switchTree');
    MenuItemAssetMoveComponent.moveTreeEnabled = this.settings.envSpecificFeatures.moveTree;
  }

  static override isViableOption(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (BasicMenuItemAssetComponent.isManagedExternally(asset)) {
      return false;
    }
    if (BasicMenuItemAssetComponent.isRoot(asset) && !this.moveTreeEnabled) {
      return false;
    }
    return BasicMenuItemAssetComponent.isWritable(asset);
  }

  protected override getReason(): string {
    if (BasicMenuItemAssetComponent.isManagedExternally(this.asset)) {
      return 'managedExternally';
    }
    if (
      BasicMenuItemAssetComponent.isRoot(this.asset) &&
      !MenuItemAssetMoveComponent.moveTreeEnabled
    ) {
      return 'moveTreeNotAllowed';
    }
    if (!BasicMenuItemAssetComponent.isWritable(this.asset)) {
      return 'moveRequiresWrite';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.dialogService.open(SwitchTreeComponent, {
      header: this.languageService.instant('switchAssetTree.dialog.title'),
      data: {
        assetId: this.asset.uuid,
      },
      dismissableMask: true,
      contentStyle: { flex: '1', display: 'flex' },
      style: {
        'min-height': '372px',
        'max-height': '600px',
        'min-width': '700px',
      },
    });
  }
}
