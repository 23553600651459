import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

import de from '@angular/common/locales/de';
import { Logger } from '@assethub/shared/utils';
import { LocalStorageService } from './browser-storage.service';

export type AvailableLangs = 'en' | 'de';

@Injectable({ providedIn: 'root' })
export class InternationalizationService {
  private static readonly DEFAULT_LANG: AvailableLangs = 'en';
  private static readonly LANG_STORAGE_PATH: string = 'app.internationalization.lang';

  private logger = new Logger(this.constructor.name);

  public constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  public setup() {
    // Define default language
    this.translateService.setDefaultLang(InternationalizationService.DEFAULT_LANG);
    this.translateService.addLangs([InternationalizationService.DEFAULT_LANG]);

    // Define additional languages
    for (const lang of environment.additionalLanguages) {
      switch (lang) {
        case 'de': {
          registerLocaleData(de);
          break;
        }
        default:
          this.logger.error(`Locale data for '${lang}' needs to be registered`);
      }
      this.translateService.addLangs([lang]);
    }

    // First try to detect if a language has been selected manually (using menu)
    let language: string | undefined = this.localStorageService.get(
      InternationalizationService.LANG_STORAGE_PATH,
    );

    if (!language) {
      // Try autodetect the users language by browser language or use the configured default language
      language = this.tryIdentifyPreferredLang() || InternationalizationService.DEFAULT_LANG;
    }

    // Trigger loading translations
    this.translateService.use(language);
  }

  public setLang(newLang: string): boolean {
    if (this.translateService.currentLang === newLang) {
      return false;
    }

    if (!this.translateService.getLangs().includes(newLang)) {
      return false;
    }

    this.localStorageService.set(InternationalizationService.LANG_STORAGE_PATH, newLang);
    return true;
  }

  private tryIdentifyPreferredLang(): AvailableLangs | undefined {
    const currentBrowserLang = this.translateService.getBrowserCultureLang();

    if (currentBrowserLang) {
      // Check if the language given by the browser is supported directly. Format shall be [lang]-[culture]
      if (this.translateService.getLangs().findIndex(lang => lang === currentBrowserLang) !== -1) {
        return currentBrowserLang as AvailableLangs;
      }

      // If it is not available we try to find out if only the language identifier, without culture information, is configured as available language
      const cultureSeparatorIndex = currentBrowserLang.indexOf('-');
      if (cultureSeparatorIndex !== -1) {
        return this.translateService
          .getLangs()
          .find(
            lang => lang === currentBrowserLang.slice(0, cultureSeparatorIndex),
          ) as AvailableLangs;
      }
    }
    return undefined;
  }
}
