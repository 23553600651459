<p-toast
  position="bottom-right"
  [key]="key"
  [showTransitionOptions]="'66ms ease-out'"
  [hideTransitionOptions]="'53ms ease-in'"
  [showTransformOptions]="'translateX(100%)'"
  [hideTransformOptions]="'translateX(100%)'"
  styleClass="sick-toast"
>
  <ng-template let-message pTemplate="message">
    <app-callout [closable]="true" (calloutClosed)="onClose()">
      <div class="toast-content">
        <h1>{{ 'toasts.liveconnect-asset-added.headline' | translate }}</h1>
        {{ 'toasts.liveconnect-asset-added.born-devices' | translate }}
        <ul class="list-none">
          <li *ngFor="let data of message.data.assets" class="thing mb-1">
            <app-asset-icon [asset]="data"></app-asset-icon>
            <a [routerLink]="['/asset', data.uuid, 'liveconnect', 'turntable']">
              {{ data | assetTitle }}
            </a>
          </li>
          <li *ngIf="message.data.total > 5">
            ... {{ 'toasts.liveconnect-asset-added.and' | translate }}
            {{ message.data.total - 5 }}
            {{ 'toasts.liveconnect-asset-added.more' | translate }}
          </li>
        </ul>
      </div>
    </app-callout>
  </ng-template>
</p-toast>
