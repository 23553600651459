import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetAssetResponse } from '@assethub/shared/models';
import { WebSocketService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import {
  Endpoint,
  LiveConnectChannel,
  LiveDataProperty,
  LiveDataWotProperty,
  ReceivedData,
} from '@liveconnect/shared/models';
import { ChannelService, EndpointService } from '@liveconnect/shared/services';
import { BehaviorSubject, Observable, Subject, finalize, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiveDataService {
  public channel?: LiveConnectChannel;

  private endpoint?: Endpoint;
  private activeProfiles: string[] = [];
  private activeWotEvents: string[] = [];
  private activeWotProperties: string[] = [];

  private websocketSubject: Subject<object>;
  private dataSubject: Subject<ReceivedData> = new Subject();
  private receivingSubject: Subject<boolean> = new BehaviorSubject(false);
  private pendingRequests = 0;
  private pendingActivation = false;

  private logger = new Logger(this.constructor.name);

  constructor(
    private httpClient: HttpClient,
    private endpointService: EndpointService,
    private channelService: ChannelService,
    private webSocketService: WebSocketService,
  ) {}

  public requestProperty(property: LiveDataProperty): Observable<ReceivedData> {
    this.startReceiving('property');
    const path = property.serviceLocation + property.path;
    return this.httpClient
      .request(property.method, path, {
        responseType: 'text',
        observe: 'response',
        headers: {
          'Cache-Control': 'max-age=10',
        },
      })
      .pipe(
        finalize(() => this.stopReceiving('property')),
        switchMap(response =>
          of({
            date: new Date(),
            payload: response.body ? JSON.parse(response.body) : undefined,
            property,
            http: {
              method: property.method,
              path,
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
            },
          }),
        ),
      );
  }

  public requestWotProperty(property: LiveDataWotProperty): Observable<ReceivedData> {
    this.startReceiving('property');

    const form = property.forms[0];
    return this.httpClient
      .request('GET', form.href, {
        responseType: 'text',
        observe: 'response',
        headers: {
          'Cache-Control': 'max-age=0',
        },
      })
      .pipe(
        finalize(() => this.stopReceiving('property')),
        switchMap(response =>
          of({
            date: new Date(),
            payload: response.body ? JSON.parse(response.body) : undefined,
            wotProperty: property,
            http: {
              method: 'GET',
              path: form.href,
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
            },
          } as ReceivedData),
        ),
      );
  }

  public activateProfiles(
    asset: GetAssetResponse,
    activeProfiles: string[],
    activeWotEvents: string[],
    activeWotProperties: string[],
  ) {
    this.activeProfiles = activeProfiles;
    this.activeWotEvents = activeWotEvents;
    this.activeWotProperties = activeWotProperties;

    if (this.pendingActivation) {
      this.logger.debug('Profiles are currently set up, updating later');
      return;
    }

    if (
      activeProfiles.length === 0 &&
      activeWotEvents.length === 0 &&
      activeWotProperties.length === 0 &&
      this.endpoint === undefined
    ) {
      return;
    }

    this.pendingActivation = true;
    this.startReceiving('event');

    this.getEndpoint(asset)
      .pipe(
        switchMap(endpoint => {
          if (
            activeProfiles.length === 0 &&
            activeWotEvents.length === 0 &&
            activeWotProperties.length === 0
          ) {
            return this.closeChannel();
          } else {
            return this.getChannel(
              asset,
              endpoint,
              activeProfiles,
              activeWotEvents,
              activeWotProperties,
            );
          }
        }),
        tap(channel => (this.channel = channel)),
        tap(() => {
          this.pendingActivation = false;
          if (
            this.activeProfiles !== activeProfiles ||
            this.activeWotEvents !== activeWotEvents ||
            this.activeWotProperties !== activeWotProperties
          ) {
            this.logger.debug('Profiles have been changed during setup, updating now');
            this.activateProfiles(
              asset,
              this.activeProfiles,
              this.activeWotEvents,
              this.activeWotProperties,
            );
          }
        }),
      )
      .subscribe({
        error: () => {
          this.pendingActivation = false;
          this.stopReceiving('event');
        },
      });
  }

  public deactivateAllProfiles(asset: GetAssetResponse) {
    return this.activateProfiles(asset, [], [], []);
  }

  public receiveData(): Observable<ReceivedData> {
    return this.dataSubject.asObservable();
  }

  public isReceiving(): Observable<boolean> {
    return this.receivingSubject.asObservable();
  }

  private startReceiving(type: 'event' | 'property') {
    if (type === 'property') {
      this.pendingRequests++;
    }
    this.receivingSubject.next(true);
  }

  private stopReceiving(type: 'event' | 'property') {
    if (type === 'property') {
      this.pendingRequests--;
    }
    if (this.pendingRequests === 0 && this.channel === undefined) {
      this.receivingSubject.next(false);
    }
  }

  private getEndpoint(asset: GetAssetResponse): Observable<Endpoint> {
    if (this.endpoint) {
      return of(this.endpoint);
    }

    return this.endpointService
      .createEndpoint({
        name: `AssetHub live data`,
        endpointType: 'WEBSOCKET',
        websocket: {
          ephemeral: true,
        },
      })
      .pipe(
        tap(endpoint => (this.endpoint = endpoint)),
        tap(endpoint => {
          if (endpoint.websocket && endpoint.websocket.url) {
            this.websocketSubject = this.webSocketService.connect<ReceivedData>(
              endpoint.websocket.url,
              true,
            );
            this.logger.debug('WebSocket connected');
            this.websocketSubject
              .pipe(tap(message => this.logger.debug('Received ws message', message)))
              .subscribe({
                next: message => {
                  if (message['LiveConnect-Source-MQTT-Topic'] !== undefined) {
                    this.dataSubject.next({
                      date: new Date(message['Date']),
                      payload: message['body'],
                      topic: message['LiveConnect-Source-MQTT-Topic'],
                    });
                  } else if (
                    message['WoT-Event'] !== undefined ||
                    message['WoT-Property'] !== undefined
                  ) {
                    let payload = message['body'];
                    if ('base64' === message['Transfer-Encoding']) {
                      payload = atob(payload);
                    }

                    if ('application/json' === message['Content-Type']) {
                      payload = JSON.parse(payload);
                    }

                    const emittable: ReceivedData = {
                      date: new Date(message['Date']),
                      payload,
                    };

                    if (message['WoT-Event'] !== undefined) {
                      emittable.wotEventName = message['WoT-Event'];
                    }

                    if (message['WoT-Property'] !== undefined) {
                      emittable.wotPropertyName = message['WoT-Property'];
                    }

                    this.dataSubject.next(emittable);
                  }
                },
                complete: () => {
                  this.logger.debug('WebSocket closed');
                },
              });
          }
        }),
      );
  }

  private getChannel(
    asset: GetAssetResponse,
    endpoint: Endpoint,
    activeProfiles: string[],
    activeWotEvents: string[],
    activeWotProperties: string[],
  ): Observable<LiveConnectChannel> {
    const profileUuids = activeProfiles.length > 0 ? activeProfiles : undefined;
    const eventNames = activeWotEvents.length > 0 ? activeWotEvents : undefined;
    const propertyNames = activeWotProperties.length > 0 ? activeWotProperties : undefined;
    if (this.channel) {
      return this.channelService.updateChannel(asset.uuid, this.channel.id, {
        description: this.channel.description,
        profileUuids,
        eventNames,
        propertyNames,
      });
    } else {
      return this.channelService.createChannel(asset.uuid, {
        endpointId: endpoint.id,
        description: this.constructor.name,
        profileUuids,
        eventNames,
        propertyNames,
      });
    }
  }

  private closeChannel() {
    if (this.endpoint !== undefined) {
      // Delete endpoint with channel by closing ephemeral websocket
      this.websocketSubject.complete();
      this.endpoint = undefined;
      this.channel = undefined;
      this.pendingActivation = false;
    }
    this.stopReceiving('event');
    return of();
  }
}
