import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError, timeout } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { Logger } from '../utils';

export interface GetConsentsResponse {
  statusMessage: string;
  consentUrls: { [serviceId: string]: string };
}

interface SimpleConsentState {
  state: 'RequestPending' | 'NoConsents' | 'Error';
}
interface OpenConsentState {
  state: 'ConsentsOpen';
  consentUrl: string;
}

export type ConsentState = SimpleConsentState | OpenConsentState;

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private serviceIds: string[];
  private serviceToConsentState: Map<string, BehaviorSubject<ConsentState>>;

  private apiUrl = inject(ENVIRONMENT).apiUrl;
  private digitalServiceCatalogUrl = inject(ENVIRONMENT).digitalServiceCatalogUrl;

  private logger = new Logger(this.constructor.name);

  constructor(private httpClient: HttpClient) {
    this.serviceIds = Object.values(this.digitalServiceCatalogUrl.serviceIds);
    this.serviceToConsentState = new Map(
      this.serviceIds.map(serviceId => [
        serviceId,
        new BehaviorSubject<ConsentState>({ state: 'RequestPending' }),
      ]),
    );
  }

  public fetchConsents(): Observable<number> {
    // request consent information for all known serviceIds
    const opts = {
      observe: 'response' as const,
      responseType: 'json' as const,
    };
    return this.httpClient.get<GetConsentsResponse>(`${this.apiUrl}/licensing/consents`, opts).pipe(
      timeout({
        first: 10000,
        with: () => {
          throw new Error('Timeout while fetching consents');
        },
      }),
      map(response => {
        switch (response.status) {
          case 204: {
            this.logger.debug('No consents open to confirm');
            this.updateConsentStateForEveryService({ state: 'NoConsents' });
            return 0;
          }
          case 200: {
            if (!response.body) {
              throw new Error(`Invalid answer for get consents received: no response body`);
            }

            const body: GetConsentsResponse = response.body;
            this.logger.debug(`Fetched open consents: ${body.statusMessage}`);
            let count = 0;
            this.serviceIds.forEach(serviceId => {
              const consentUrl = body.consentUrls[serviceId] || undefined;
              if (consentUrl !== undefined) {
                count++;
                this.updateConsentStateForServiceId(serviceId, {
                  state: 'ConsentsOpen',
                  consentUrl: consentUrl,
                });
              } else {
                this.updateConsentStateForServiceId(serviceId, { state: 'NoConsents' });
              }
            });
            return count;
          }
          default: {
            throw new Error(`Invalid status for open consents received: ${response.status}`);
          }
        }
      }),
      catchError(err => {
        this.logger.error(`Error while fetching consents: `, err);
        this.updateConsentStateForEveryService({ state: 'Error' });
        throw err;
      }),
    );
  }

  private updateConsentStateForEveryService(consentState: ConsentState) {
    this.serviceIds.forEach(serviceId =>
      this.updateConsentStateForServiceId(serviceId, consentState),
    );
  }

  private updateConsentStateForServiceId(serviceId, consentState: ConsentState) {
    const state = this.serviceToConsentState.get(serviceId);
    if (state !== null && state !== undefined) {
      state.next(consentState);
    }
  }

  public getConsentStateForServiceId(serviceId: string): Observable<ConsentState> {
    const consent = this.serviceToConsentState.get(serviceId);
    if (consent !== null && consent !== undefined) {
      return consent.asObservable();
    }
    return throwError(
      () => new Error(`Unable to provide consent information for unknown service: ${serviceId}`),
    );
  }
}
