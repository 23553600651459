import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, firstValueFrom, forkJoin, map, of } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { Logger } from '../utils';
import { TreeService } from './tree.service';

interface GetSubscriptionsResponse {
  subscriptions: string[];
}

@Injectable({ providedIn: 'root' })
export class LicensingService {
  private basic = false;
  private liveConnect = false;
  private serviceModuleInSync = false;

  private logger = new Logger(this.constructor.name);
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(
    private httpClient: HttpClient,
    private treeService: TreeService,
  ) {}

  get hasBasic() {
    return this.basic;
  }

  get hasLiveConnect() {
    return this.liveConnect;
  }

  get hasServiceModuleInSync() {
    return this.serviceModuleInSync;
  }

  public hasLicense(license: string): boolean {
    switch (license) {
      case 'basic': {
        return this.basic;
      }

      case 'liveConnect': {
        return this.liveConnect;
      }

      default: {
        this.logger.error('Unknown license type: "%s"', license);
        throw new Error('Invalid license configured');
      }
    }
  }

  public async setup(): Promise<void> {
    this.basic = false;
    this.liveConnect = false;

    const results = await firstValueFrom(
      forkJoin({
        subscriptions: this.fetchSubscriptions(),
        trees: this.treeService.fetchTrees(),
      }),
    );

    if (results.trees.find(x => x.crm365 === true)) {
      this.serviceModuleInSync = true;
    }
  }

  private fetchSubscriptions(): Observable<void> {
    return this.httpClient
      .get<GetSubscriptionsResponse>(this.apiUrl + '/licensing/subscriptions')
      .pipe(
        map(resp => {
          if (!Array.isArray(resp.subscriptions)) {
            this.logger.warn('response from licensing server lacks subscriptions field');
            return;
          }
          for (const item of resp.subscriptions) {
            const name = item.toLowerCase();
            switch (name) {
              case 'basic':
                this.basic = true;
                break;
              case 'standard':
                this.basic = true;
                break;
              case 'enterprise':
                this.basic = true;
                break;
              case 'live-connect':
                this.basic = true;
                this.liveConnect = true;
                break;
              case 'service-module':
                this.basic = true;
                break;
              default:
                this.logger.debug('ignoring unknown subscription type', name);
            }
          }
        }),
        catchError(ex => {
          this.logger.error('failed to query subscription service:', ex);
          return of();
        }),
      );
  }
}
