<div class="text-area-container">
  @if (disabled()) {
    <span class="readonly text-content" [ngStyle]="{ 'max-height': height() }">{{ value() }}</span>
  } @else {
    <span class="text-area-label" [ngClass]="{ 'p-float-label': label() }">
      <textarea
        id="textarea"
        class="text-content"
        pInputTextarea
        [(ngModel)]="value"
        [ngStyle]="{ height: height() }"
        [attr.placeholder]="placeholder() || null"
        [required]="required()"
      ></textarea>
      @if (label()) {
        <label for="textarea">{{ label() }}</label>
      }
    </span>
  }
</div>
