import {
  Component,
  ElementRef,
  Input,
  SkipSelf,
  Optional,
  forwardRef,
  ViewChild,
  OnInit,
} from '@angular/core';
import { BasicMenuComponent } from '../basic-menu.component';
import { MenuService } from '../../../services/menu.service';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { IconComponent } from '../../icon/icon.component';
import { NgIf, NgStyle } from '@angular/common';
import { TooltipService } from '../../../services/tooltip.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  providers: [{ provide: BasicMenuComponent, useExisting: forwardRef(() => SubmenuComponent) }],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent, NgStyle],
})
export class SubmenuComponent extends BasicMenuComponent implements OnInit {
  @Input() icon = '';
  @Input() label = '';
  @Input() reason = '';

  menuContentStyle: Record<string, string | number> = {};

  @ViewChild('menuFrame')
  protected menuFrame: ElementRef<HTMLDivElement>;

  constructor(
    element: ElementRef<HTMLElement>,
    menuService: MenuService,
    tooltipService: TooltipService,
    @SkipSelf() @Optional() parent: BasicMenuComponent | null,
  ) {
    super(parent, element, menuService, tooltipService);
    this.gap = 2;
  }

  override ngOnInit(): void {
    if (this.parentMenu) {
      this.openOnHover = this.parentMenu.openOnHover;
    }
    super.ngOnInit();
  }

  protected override isOutsideComponent(x: number, y: number): boolean {
    const rect = this.getComponentRect();
    return x < rect.left || x > rect.right || y < rect.top || y > rect.bottom;
  }

  protected override positionMenuContent(): void {
    const rect = this.getComponentRect();
    const menuFrame = this.menuFrame.nativeElement;
    const downStart = rect.top + 5;
    const downEnd = downStart + menuFrame.clientHeight;
    const height = rect.bottom - rect.top;
    const middleStart = rect.top + (height - menuFrame.clientHeight) / 2;
    const middleEnd = middleStart + menuFrame.clientHeight;
    this.menuContentStyle = {};
    if (downEnd < window.innerHeight) {
      this.menuContentStyle['top.px'] = downStart;
    } else if (middleEnd < window.innerHeight) {
      this.menuContentStyle['top.px'] = middleStart;
    } else {
      this.menuContentStyle['top'] = 'auto';
      this.menuContentStyle['bottom'] = '1px';
    }
    const gap = typeof this.gap === 'string' ? parseInt(this.gap, 10) : this.gap;
    const leftStart = rect.left - menuFrame.clientWidth - gap;
    const rightStart = rect.right;
    const rightEnd = rightStart + menuFrame.clientWidth + gap;
    if (rightEnd < window.innerWidth) {
      this.menuContentStyle['left.px'] = rightStart;
      this.menuContentStyle['padding-left.px'] = gap;
    } else {
      this.menuContentStyle['left.px'] = leftStart;
      this.menuContentStyle['padding-right.px'] = gap;
    }
  }

  private getComponentRect() {
    // the component is rendered with display:contents which is required to align all
    // children according to the grid provided by the parent. But that means we cannot use
    // getBoundingClientRect() to get its dimensions.
    // assumption: the elements are positioned in a single row from left to right
    const nativeElement = this.element.nativeElement;
    const leftRect = nativeElement.firstElementChild?.getBoundingClientRect();
    const rightRect = nativeElement.lastElementChild?.getBoundingClientRect();
    if (!leftRect || !rightRect) {
      return { top: 0, left: 0, bottom: 0, right: 0 };
    }
    return {
      top: leftRect.top,
      left: leftRect.left,
      bottom: rightRect.bottom,
      right: rightRect.right,
    };
  }
}
