<!-- 404 detector -->
<img
  style="display: none"
  src="{{ displayUrl() }}"
  (error)="error.set(true)"
  (load)="error.set(false); loading.set(false)"
/>
@if (error()) {
  <app-media-placeholder shape="broken_image"></app-media-placeholder>
} @else if (loading()) {
  <app-media-placeholder shape="image"></app-media-placeholder>
} @else {
  <div class="content">
    <div
      class="preview-image"
      [ngClass]="{ 'cursor-pointer': selectable() }"
      [ngStyle]="{
        'background-image': 'url(' + displayUrl() + ')',
      }"
      (click)="onClick($event)"
    ></div>
    <app-menu class="ui-button-bare-neutral" direction="dl">
      <app-menu-item
        icon="download"
        label="{{ 'buttons.download' | translate }}"
        (selected)="onDownload()"
      ></app-menu-item>
    </app-menu>
  </div>
}
