import { Component, input } from '@angular/core';

@Component({
  selector: 'app-media-placeholder',
  templateUrl: './media-placeholder.component.html',
  styleUrls: ['./media-placeholder.component.scss'],
  standalone: true,
  imports: [],
})
export class MediaPlaceholderComponent {
  readonly shape = input.required<string>();
}
