import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import {
  ChannelError,
  CreateLiveConnectChannel,
  LiveConnectChannel,
  Paginated,
  TrafficStats,
  UpdateLiveConnectChannel,
} from '@liveconnect/shared/models';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { LiveConnectEvent } from '../models/events';

interface Cache {
  deviceUuid: string;
  channels: LiveConnectChannel[];
  channelErrors: Map<string, ChannelError[]>;
  traffic: Map<string, TrafficStats[]>;
}

class ProfileError extends Error {
  constructor(public readonly profileId: string) {
    super();
  }
}
export class AsyncApiMalformedProfileError extends ProfileError {}
export class AsyncApiIncompatibleVersionError extends ProfileError {}
export class AsyncApiInvalidYmlError extends ProfileError {}

interface UpdateChannelRequestPayload {
  profileUuids?: string[];
  eventNames?: string[];
  propertyNames?: string[];
  description?: string;
  isActive?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private cache?: Cache;
  private logger = new Logger(this.constructor.name);
  private liveConnectUrl = inject(ENVIRONMENT).liveConnectUrl;

  constructor(private httpClient: HttpClient) {}

  public getChannels(deviceUuid: string, force = false): Observable<LiveConnectChannel[]> {
    if (!this.liveConnectUrl) {
      return of([]);
    }
    if (!force && this.cache?.deviceUuid === deviceUuid && this.cache.channels !== undefined) {
      this.logger.debug('Using cached channels');
      return of(this.cache.channels);
    }
    return this.httpClient
      .get<LiveConnectChannel[]>(`${this.liveConnectUrl}/device/${deviceUuid}/channels`)
      .pipe(
        tap(channels => {
          this.cacheFor(deviceUuid).channels = channels;
        }),
        catchError(err => {
          this.logger.error('Error fetching channels', err);
          return throwError(() => err);
        }),
      );
  }

  public createChannel(
    deviceUuid: string,
    channel: CreateLiveConnectChannel,
  ): Observable<LiveConnectChannel> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    if (
      channel.profileUuids === undefined &&
      channel.eventNames === undefined &&
      channel.propertyNames === undefined
    ) {
      return throwError(
        () => new Error('Channel must have at least one of profiles, events, properties'),
      );
    }
    return this.httpClient
      .post<LiveConnectChannel>(`${this.liveConnectUrl}/device/${deviceUuid}/channel`, channel)
      .pipe(
        tap(created => {
          this.cacheFor(deviceUuid).channels.push(created);
        }),
        catchError((err: any) => this.handleChannelError(err)),
      );
  }

  /** @deprecated Use updateChannelV2 instead */
  public updateChannel(
    deviceUuid: string,
    channelId: string,
    channel: UpdateLiveConnectChannel,
  ): Observable<LiveConnectChannel> {
    if (
      channel.profileUuids === undefined &&
      channel.eventNames === undefined &&
      channel.propertyNames === undefined
    ) {
      return throwError(
        () => new Error('Channel must have at least one of profiles, events, properties'),
      );
    }

    return this.updateChannelInternal(deviceUuid, channelId, channel);
  }

  public updateChannelV2(
    deviceUuid: string,
    channelId: string,
    description: string,
    events: LiveConnectEvent[],
  ): Observable<LiveConnectChannel> {
    return this.updateChannelInternal(deviceUuid, channelId, {
      description,
      ...this.buildPayload(events),
    });
  }

  private buildPayload(events: LiveConnectEvent[]) {
    const payload: {
      profileUuid?: string[];
      eventNames?: string[];
    } = {};

    for (const event of events) {
      for (const [key, value] of Object.entries(event.coordinates())) {
        if (payload[key] === undefined) {
          payload[key] = [];
        }

        payload[key].push(...value);
      }
    }
    return payload;
  }

  private handleChannelError(err: any): Observable<any> {
    // Handle specific tagged errors:
    if (
      err?.status !== undefined &&
      err.name === 'HttpErrorResponse' &&
      err.error.code !== undefined &&
      err.error.details !== undefined
    ) {
      switch (err.error.code) {
        case 'ERR_ASYNCAPI_MALFORMED_PROFILE': {
          return throwError(
            () => new AsyncApiMalformedProfileError(err.error.details['profileId']),
          );
        }
        case 'ERR_ASYNCAPI_INCOMPATIBLE_VERSION': {
          return throwError(
            () => new AsyncApiIncompatibleVersionError(err.error.details['profileId']),
          );
        }
        case 'ERR_ASYNCAPI_INVALID_YAML': {
          return throwError(() => new AsyncApiInvalidYmlError(err.error.details['profileId']));
        }
      }
    }
    return throwError(() => err);
  }

  public unsuspendChannel(deviceUuid: string, channelId: string): Observable<LiveConnectChannel> {
    return this.updateChannelInternal(deviceUuid, channelId, {});
  }

  public deleteChannel(deviceUuid: string, id: string): Observable<LiveConnectChannel> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient
      .delete<LiveConnectChannel>(`${this.liveConnectUrl}/device/${deviceUuid}/channel/${id}`)
      .pipe(
        tap(channel => {
          const cache = this.cacheFor(deviceUuid).channels;
          const index = cache.findIndex(cached => cached.id === channel.id);
          if (-1 !== index) {
            cache.splice(index, 1);
          }
        }),
      );
  }

  public pauseChannel(deviceUuid: string, id: string): Observable<LiveConnectChannel> {
    return this.updateChannelInternal(deviceUuid, id, { isActive: false });
  }

  public resumeChannel(deviceUuid: string, id: string): Observable<LiveConnectChannel> {
    return this.updateChannelInternal(deviceUuid, id, { isActive: true });
  }

  public getTraffic(
    deviceUuid: string,
    id: string,
    duration: number,
    start: Date,
    end: Date,
  ): Observable<TrafficStats[]> {
    if (!this.liveConnectUrl) {
      return of([]);
    }
    if (this.cache?.deviceUuid === deviceUuid) {
      const traffic = this.cache.traffic.get(id);
      if (traffic !== undefined) {
        this.logger.debug('Using cached channel traffic');
        return of(traffic);
      }
    }
    return this.httpClient
      .get<Paginated<TrafficStats>>(
        `${this.liveConnectUrl}/device/${deviceUuid}/channel/${id}/traffic`,
        {
          params: {
            duration,
            start: start.toISOString(),
            end: end.toISOString(),
          },
        },
      )
      .pipe(
        tap(traffic => {
          this.cacheFor(deviceUuid).traffic.set(id, traffic.items);
        }),
        map(pager => pager.items),
      );
  }

  public getChannelErrors(deviceUuid: string, id: string) {
    if (!this.liveConnectUrl) {
      return of([]);
    }
    if (this.cache?.deviceUuid === deviceUuid) {
      const channelErrors = this.cache.channelErrors?.get(id);
      if (channelErrors !== undefined) {
        this.logger.debug('Using cached channel errors');
        return of(channelErrors);
      }
    }
    return this.httpClient
      .get<ChannelError[]>(`${this.liveConnectUrl}/device/${deviceUuid}/channel/${id}/error`)
      .pipe(
        map(channelErrors => channelErrors.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))),
        tap(channelErrors => {
          this.cacheFor(deviceUuid).channelErrors.set(id, channelErrors);
        }),
      );
  }

  private updateChannelInternal(
    deviceUuid: string,
    channelId: string,
    data: UpdateChannelRequestPayload,
  ): Observable<LiveConnectChannel> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient
      .put<LiveConnectChannel>(
        `${this.liveConnectUrl}/device/${deviceUuid}/channel/${channelId}`,
        data,
      )
      .pipe(
        tap(channel => {
          const cache = this.cacheFor(deviceUuid).channels;
          const index = cache.findIndex(cached => cached.id === channelId);
          if (-1 !== index) {
            cache[index] = channel;
          }
        }),
        catchError((err: any) => this.handleChannelError(err)),
      );
  }

  private cacheFor(deviceUuid: string): Cache {
    if (this.cache?.deviceUuid === deviceUuid) {
      return this.cache;
    }

    this.cache = { deviceUuid, channels: [], channelErrors: new Map(), traffic: new Map() };
    return this.cache;
  }
}
