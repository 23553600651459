import { PinMetadata } from '@assethub/shared/components';

export interface GetGeoLocationResponse {
  inherited: boolean;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
}

export type GetGeoLocationsResponse = {
  inherited: boolean;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
  depth: number;
  assetUuid: string;
  typeId: number;
  customName?: string;
  profilePicture?: string;
  productPictureUrl?: string;
  productName?: string;
}[];

export interface MapMarkerElement extends PinMetadata {
  longitude: number;
  latitude: number;
}

export type MapMarkers = MapMarkerElement[];

export interface SetGeoLocationRequest {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
}
