<app-table-base
  #table
  [list]="list"
  [columns]="columns"
  [autoWidth]="autoWidth"
  [tableName]="tableName"
  noDataMsg="service-module-365.list-of-cases.message.no-data"
  nofilteredDataMsg="service-module-365.list-of-cases.message.all-entries-filtered-out"
  errorMsg="service-module-365.list-of-cases.message.load-error"
>
  <ng-template #cellTemplate let-column let-row="row">
    @switch (column.field) {
      @case ('title') {
        {{ row.title }}
      }
      @case ('ticketNumber') {
        {{ row.ticketNumber }}
      }
      @case ('created') {
        {{ row.created | localizedDate: 'mediumDate' }}
      }
      @case ('type') {
        {{ row.type | translate }}
      }
      @case ('status') {
        {{ row.status | translate }}
      }
      @case ('actions') {
        <button
          pButton
          type="button"
          class="ui-button-bare"
          icon="pi pi-chevron-right"
          (click)="navigateToDetails(row)"
        ></button>
      }
    }
  </ng-template>
</app-table-base>
