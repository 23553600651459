<p>{{ message }}</p>
<div>
  <button
    pButton
    type="button"
    class="ui-button-outline"
    (click)="onReject()"
    [label]="rejectButton"
    [disabled]="disableControls"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    (click)="onAccept()"
    [label]="acceptButton"
    [disabled]="disableControls"
  ></button>
</div>
