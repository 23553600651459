import { NgClass } from '@angular/common';
import { Component, Inject, computed, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Environment, ENVIRONMENT, PermissionEntry } from '@assethub/shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SelectItem, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { CalloutComponent } from '../callout/callout.component';
import { IconComponent } from '../icon/icon.component';
import { DropdownModule } from 'primeng/dropdown';

interface PermissionItem {
  id: string;
  label: string;
  permission: 'read' | 'write' | 'admin';
  options: SelectItem[];
  unerasable: boolean;
}

@Component({
  selector: 'app-permissions-view',
  templateUrl: './permissions-view.component.html',
  styleUrls: ['./permissions-view.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    TableModule,
    NgClass,
    SharedModule,
    IconComponent,
    DropdownModule,
    FormsModule,
    ButtonModule,
    CalloutComponent,
    TranslateModule,
  ],
})
export class PermissionsViewComponent {
  readonly permissionEntries = input.required<PermissionEntry[]>();
  readonly totalRecords = input(0);
  readonly pageSize = input(10);
  readonly paginatorStart = model(0);
  readonly narrow = input(false);
  readonly loading = input(false);
  readonly saving = input(false);
  readonly isGroupPermission = input(false);

  readonly deletePermission = output<PermissionEntry>();
  /** @deprecated Use changePermission */
  readonly togglePermission = output<{ entry: PermissionEntry; permission: 'admin' | 'write' }>();
  readonly changePermission = output<{
    id: string;
    permission: 'read' | 'write' | 'admin';
  }>();
  readonly lazyLoad = output<TableLazyLoadEvent>();

  readonly permissionList = computed(() => this.computePermissionList());

  readonly groupManagementUrl: string;

  private readonly permissionLabel: Record<'read' | 'write' | 'admin', string>;
  private readonly login: string;

  constructor(
    private oauthService: OAuthService,
    @Inject(ENVIRONMENT) env: Environment,
    translateService: TranslateService,
  ) {
    this.groupManagementUrl = env.groupManagement
      ? env.groupManagement.baseUrl + '/visible-groups/'
      : '';
    this.permissionLabel = {
      read: translateService.instant('assetPermissions.read'),
      write: translateService.instant('assetPermissions.write'),
      admin: translateService.instant('assetPermissions.admin'),
    };
    const claims = this.oauthService.getIdentityClaims();
    this.login = claims['preferred_username'] || claims['email'];
  }

  onDeletePermission(item: PermissionItem) {
    const entries = this.permissionEntries();
    const permission = entries.find(x => x.id === item.id);
    if (permission) {
      this.deletePermission.emit(permission);
    }
  }

  onPermissionChanged(item: PermissionItem) {
    const entries = this.permissionEntries();
    const permission = entries.find(x => x.id === item.id);
    if (permission) {
      let toggle: 'write' | 'admin' | undefined;
      switch (item.permission) {
        case 'read':
          if (permission.write) {
            toggle = 'write';
            permission.write = false;
          }
          break;
        case 'write':
          if (permission.admin) {
            toggle = 'admin';
            permission.admin = false;
          } else if (!permission.write) {
            toggle = 'write';
            permission.write = true;
          }
          break;
        case 'admin':
          if (!permission.admin) {
            toggle = 'admin';
            permission.admin = true;
          }
          break;
      }
      if (toggle) {
        this.togglePermission.emit({ entry: permission, permission: toggle });
      }
      this.changePermission.emit({ id: item.id, permission: item.permission });
    }
  }

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lazyLoad.emit($event);
  }

  private computePermissionList(): PermissionItem[] {
    const entries = this.permissionEntries();
    const retval: PermissionItem[] = [];
    for (const item of entries) {
      const permission = item.admin ? 'admin' : item.write ? 'write' : 'read';
      const isMyOwnPermission = item.id === this.login;
      const options: SelectItem[] = [
        {
          label: this.permissionLabel.read,
          value: 'read',
          disabled: item.pAdmin || item.pWrite || isMyOwnPermission,
        },
        {
          label: this.permissionLabel.write,
          value: 'write',
          disabled: item.pAdmin || isMyOwnPermission,
        },
        {
          label: this.permissionLabel.admin,
          value: 'admin',
        },
      ];
      retval.push({
        id: item.id,
        label: item.label,
        permission,
        options,
        unerasable: item.deleteDisabled || isMyOwnPermission,
      });
    }
    return retval;
  }
}
