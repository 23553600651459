import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ENVIRONMENT } from '@assethub/shared/models';
import { LazyLoadResponse, LazyLoadTable } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { NEVER, map } from 'rxjs';
import { LocalizedDatePipe } from '../../../pipes/localized-date.pipe';
import { TableBaseComponent, TableColumn } from '../../table-base/table-base.component';
import { TableHeaderComponent } from '../../table-header/table-header.component';

interface Case {
  uuid: string;
  title: string;
  ticketNumber: string;
  accountName: string;
  created: Date;
  type: string;
  status: string;
}

export interface CaseResponse {
  cases: {
    uuid: string;
    title: string;
    ticketNumber: string;
    accountName: string;
    created: number;
    modified: number;
    type: string;
    status: string;
  }[];
  total: number;
}

function convertCaseResponseToCaseItems(response: Pick<CaseResponse, 'cases'>): Case[] {
  return response.cases.map(x => ({
    uuid: x.uuid,
    title: x.title,
    ticketNumber: x.ticketNumber,
    accountName: x.accountName,
    status: `service-module-365.case.status.${x.status}`,
    type: `service-module-365.case.type.${x.type}`,
    created: new Date(x.created * 1000),
  }));
}

type CaseColumn = keyof Case;

@Component({
  selector: 'app-list-cases',
  templateUrl: './list-cases.component.html',
  styleUrls: ['./list-cases.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterLink,
    ButtonModule,
    TableHeaderComponent,
    TableBaseComponent,
    TranslateModule,
    LocalizedDatePipe,
  ],
})
export class ListCasesComponent implements OnInit, OnChanges {
  @Input() nodeUuid: string;
  @Input() limitToNode = false;
  @Input() initialVisibleRows = 20;
  @Input() sortField: CaseColumn = 'created';
  @Input() sortDirection: 1 | -1 = -1;
  @Input() autoWidth = true;
  @Input() tableInstance: string;

  list: LazyLoadTable<Case>;
  tableName: string;

  columns: TableColumn<CaseColumn>[] = [
    {
      field: 'title',
      label: 'service-module-365.case.field.title',
      type: 'string',
      filter: true,
      width: '200%',
    },
    {
      field: 'ticketNumber',
      label: 'service-module-365.case.field.ticket-number',
      type: 'string',
      filter: true,
      width: '100%',
    },
    {
      field: 'created',
      label: 'service-module-365.case.field.created',
      type: 'date',
      filter: true,
      width: '10em',
    },
    {
      field: 'type',
      label: 'service-module-365.case.field.type',
      type: 'number',
      width: '100%',
      filterLabels: 'service-module-365.case.type',
    },
    {
      field: 'status',
      label: 'service-module-365.case.field.status',
      type: 'number',
      width: '100%',
      filterLabels: 'service-module-365.case.status',
    },
  ];

  private sm365Url = inject(ENVIRONMENT).sm365Url;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
  ) {
    this.list = new LazyLoadTable<Case>(
      x => this.buildRequest(x),
      this.sortField,
      this.sortDirection,
      this.initialVisibleRows,
    );
  }

  ngOnInit() {
    this.tableName = 'cases';
    if (this.tableInstance) {
      this.tableName += '.' + this.tableInstance;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialVisibleRows) {
      this.list.visibleRows = this.initialVisibleRows;
    }
    if (changes.sortField) {
      this.list.sortField = this.sortField;
    }
    if (changes.nodeUuid) {
      this.list.reload({ first: 0 });
    }
  }

  navigateToDetails(row: Case) {
    this.router.navigate(['/maintenance365', this.nodeUuid, 'request', row.uuid, 'details']);
  }

  private buildRequest(params: { [k: string]: string }): LazyLoadResponse<Case> {
    if (!this.nodeUuid) {
      return NEVER;
    }
    if (this.limitToNode) {
      params.eqNode = '1';
    }
    return this.httpClient
      .get<CaseResponse>(`${this.sm365Url}/tree/${this.nodeUuid}/cases`, { params })
      .pipe(
        map(response => ({
          total: response.total,
          items: convertCaseResponseToCaseItems(response),
        })),
      );
  }
}
