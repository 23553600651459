import { CloneAssetModalComponent } from './clone-asset-modal/clone-asset-modal.component';
import { CreateDeviceBackupComponent } from './device-backup/create-backup/create-device-backup.component';
import { DeviceConfigurationUploadComponent } from './device-configuration/device-configuration-upload.component';
import { ExportCsvModalComponent } from './export-csv-modal/export-csv-modal.component';
import { ServicePermissionModalComponent } from './service-permission-modal/service-permission-modal.component';

export * from './clone-asset-modal/clone-asset-modal.component';
export * from './device-backup/create-backup/create-device-backup.component';
export * from './device-configuration/device-configuration-upload.component';
export * from './switch-tree/switch-tree.component';
export * from './export-csv-modal/export-csv-modal.component';
export * from './service-permission-modal/service-permission-modal.component';

export const modalComponents = [
  CloneAssetModalComponent,
  CreateDeviceBackupComponent,
  DeviceConfigurationUploadComponent,
  ExportCsvModalComponent,
  ServicePermissionModalComponent,
];
