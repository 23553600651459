<div>
  <div class="flex flex-column mb-2">
    <p class="font-semibold">{{ 'export.export-scope-message' | translate }}:</p>
    <div class="flex flex-column gap-2 ml-2">
      @if (!isTreeRoot()) {
        <p-radioButton
          value="ENTIRE_TREE"
          [label]="'export.entire-tree' | translate"
          [(ngModel)]="exportScope"
          [disabled]="loading"
        ></p-radioButton>
      }
      <p-radioButton
        value="SUBTREE"
        [label]="'export.complete-subtree' | translate"
        [(ngModel)]="exportScope"
        [disabled]="loading"
      ></p-radioButton>
      <p-radioButton
        value="SINGLE_NODE"
        [label]="'export.single-node' | translate"
        [(ngModel)]="exportScope"
        [disabled]="loading"
      ></p-radioButton>
    </div>
  </div>

  <div class="flex flex-column mb-2">
    <p class="font-semibold">{{ 'export.includes.additional-asset-message' | translate }}:</p>
    <div class="flex flex-column gap-2 ml-2">
      <p-checkbox
        class="m-0"
        label="{{ 'export.includes.latest-sopas-configurations' | translate }}"
        [binary]="true"
        [(ngModel)]="additionalExportItems.latestSopasConfigurations"
        [disabled]="loading"
      ></p-checkbox>
    </div>
  </div>

  <div class="flex flex-column mt-4">
    <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
    <div class="flex justify-content-between gap-8">
      <button
        pButton
        type="button"
        class="ui-button-outline"
        [disabled]="loading"
        (click)="onCancel()"
        [label]="'buttons.cancel' | translate"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-primary"
        [disabled]="loading"
        (click)="startExport()"
        [label]="'export.download' | translate"
      ></button>
    </div>
  </div>
</div>
