<p-toast
  position="bottom-right"
  [key]="key"
  [baseZIndex]="5000"
  [showTransitionOptions]="'66ms ease-out'"
  [hideTransitionOptions]="'53ms ease-in'"
  [showTransformOptions]="'translateX(100%)'"
  [hideTransformOptions]="'translateX(100%)'"
  styleClass="sick-toast"
>
  <ng-template let-message pTemplate="message">
    <app-callout [severity]="message.severity" [closable]="true" (remove)="onClose(message)">
      <div class="toast-content">
        <h1>{{ message.summary + '.summary' | translate }}</h1>
        <div>
          {{ message.summary + '.messagePartOne' | translate }}
          <a [routerLink]="['/asset', message.data.asset_uuid, 'asset-management']">{{
            message.data.asset_name
          }}</a>
          {{ message.summary + '.messagePartTwo' | translate }}
        </div>
      </div>
    </app-callout>
  </ng-template>
</p-toast>
