import { ActivatedRouteSnapshot } from '@angular/router';

export function pathFromRoutingTemplate(
  route: ActivatedRouteSnapshot,
  placeholders: Record<string, string | undefined | ((s: string) => string | undefined)>,
): string[] {
  // The template config is inherited to child routes. Picking the last one should be always ok.
  while (route.firstChild) {
    route = route.firstChild;
  }
  const routingTemplate: string[] | undefined = route.data.navigateToAssetTemplate;
  if (!routingTemplate || !Array.isArray(routingTemplate) || routingTemplate.length === 0) {
    throw new Error('Missing or empty routing template');
  }
  const retval: string[] = [];
  for (const part of routingTemplate) {
    if (part.startsWith(':')) {
      const name = part.substring(1);
      const placeholder = placeholders[name];
      if (!placeholder) {
        throw new Error(`Unresolved placeholder "${name}" in routing template`);
      }
      const value = typeof placeholder === 'function' ? placeholder(name) : placeholder;
      if (!value) {
        throw new Error(`Unresolved placeholder "${name}" in routing template`);
      }
      retval.push(value);
    } else {
      retval.push(part);
    }
  }
  return retval;
}
