import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { AssetFilterProposalResponse } from '../models/asset-search-proposals';

@Injectable({ providedIn: 'root' })
export class AssetSearchFilterProposalService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  loadAssetSearchFilterProposals(): Observable<AssetFilterProposalResponse> {
    return this.httpClient.get<AssetFilterProposalResponse>(
      this.apiUrl + '/search/filter-proposals',
    );
  }
}
