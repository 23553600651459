import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ENVIRONMENT,
  GrantedGroupPermission,
  GroupPermissions,
  PermissionEntry,
} from '@assethub/shared/models';
import { GroupPermissionsService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { TableLazyLoadEvent } from 'primeng/table';
import { PermissionsViewComponent } from '@assethub/shared/components/permissions-view/permissions-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { GroupPermissionsAddComponent } from '../../group-permission-add/group-permissions-add.component';
import { CalloutComponent } from '../../callout/callout.component';

@Component({
  selector: 'app-group-permissions',
  templateUrl: './group-permissions.component.html',
  styleUrls: ['./group-permissions.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    GroupPermissionsAddComponent,
    CalloutComponent,
    PermissionsViewComponent,
  ],
})
export class GroupPermissionsComponent implements OnChanges {
  @Input() assetDetails: { uuid: string; permissions: string };
  @Input() narrow: boolean;

  @ViewChild('groupPermissionAdd', { static: true })
  private groupPermissionAdd: GroupPermissionsAddComponent;

  public readonly PAGE_SIZE = 10;

  public permissionEntries: PermissionEntry[] = [];
  public tableColPermissions: any[];

  public loading = false;
  public saving = false;
  public paginatorStart = 0;
  public totalRecords = 0;
  public error?: string;
  public errorSeverity: 'error' | 'info' = 'error';
  public groupManagementUrl = inject(ENVIRONMENT).groupManagement!.baseUrl;

  private logger = new Logger('GroupPermissionsComponent');

  constructor(
    private groupPermissionService: GroupPermissionsService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.assetDetails) {
      this.paginatorStart = 0;
      this.groupPermissionAdd.clear();
      this.setError(undefined);
      this.getGroupPermissions();
    }
  }

  public onDeletePermission(entry: PermissionEntry): void {
    this.saving = true;
    entry.pendingAction = true;
    this.groupPermissionService
      .revokePermissionsForGroup(this.assetDetails.uuid, entry.id)
      .subscribe({
        next: () => {
          this.saving = false;
          this.resetError('assetPermissions.error.delete');
          this.deletePermissionEntry(entry.id);
        },
        error: error => {
          this.saving = false;
          entry.pendingAction = false;
          this.setError('assetPermissions.error.delete');
          this.logger.error(error);
        },
      });
  }

  public onLazyLoad(event: TableLazyLoadEvent) {
    this.paginatorStart = event.first || 0;
    this.getGroupPermissions();
  }

  public getGroupPermissions() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.groupPermissionService
      .getGroupPermissions(this.assetDetails.uuid, {
        start: this.paginatorStart,
        size: this.PAGE_SIZE,
      })
      .subscribe({
        next: p => {
          if (p.groupPermissions.length === 0 && this.paginatorStart > 0) {
            // Load previous page if current page got empty (after last entry was deleted)
            this.paginatorStart = this.paginatorStart - this.PAGE_SIZE;
            this.getGroupPermissions();
            return;
          }
          this.resetError('assetPermissions.error.get');
          this.permissionEntries = p.groupPermissions.map(group => this.convert(group));
          this.totalRecords = p.total;
          this.loading = false;
        },
        error: error => {
          this.setError('assetPermissions.error.get');
          this.logger.error(error);
          this.loading = false;
        },
      });
  }

  private convert(group: GrantedGroupPermission): PermissionEntry {
    return {
      label: group.groupName,
      id: group.extGroupId,
      admin: group.permissions.includes('a'),
      write: group.permissions.includes('w'),
      read: group.permissions.includes('r'),
      pAdmin: group.parentGroupPermissions ? group.parentGroupPermissions.includes('a') : false,
      pWrite: group.parentGroupPermissions ? group.parentGroupPermissions.includes('w') : false,
      pRead: group.parentGroupPermissions ? group.parentGroupPermissions.includes('r') : false,
      rightsInherited: group.inherited,
      deleteDisabled: group.parentGroupPermissions !== undefined,
      pendingAction: false,
    };
  }

  private addPermissionEntry(groupName: string, groupId: string, permissions: string): void {
    if (this.totalRecords < this.PAGE_SIZE) {
      const entries = [
        ...this.permissionEntries,
        this.convert({ groupName, extGroupId: groupId, permissions, inherited: false }),
      ];
      entries.sort((a, b) => a.label.localeCompare(b.label));
      this.permissionEntries = entries;

      this.totalRecords++;
      return;
    }
    // position unknown -> reload
    this.getGroupPermissions();
  }

  private changePermissionEntry(groupId: string, permissions: string) {
    const index = this.permissionEntries.findIndex(pe => pe.id === groupId);
    if (index >= 0) {
      this.permissionEntries[index].admin = permissions.includes('a');
      this.permissionEntries[index].write = permissions.includes('w');
      this.permissionEntries[index].read = permissions.includes('r');
      this.permissionEntries[index].pendingAction = false;
    }
  }

  private deletePermissionEntry(groupId: string) {
    const pageMaxCount = this.permissionEntries.length + this.paginatorStart;
    const pageModulo = pageMaxCount % this.PAGE_SIZE;

    if (
      pageModulo > 1 ||
      (pageModulo === 0 && this.totalRecords === pageMaxCount) ||
      (pageModulo === 1 && this.paginatorStart === 0)
    ) {
      // delete permission if no further entries (also from next page) need to be loaded
      // in case modulo === 0 and totalRecords > pageMaxIndex, the page need to be reloaded, which is not handled here
      // in case modulo === 1 and first page not displayed, the page need to be reloaded, which is not handled here
      this.permissionEntries = this.permissionEntries.filter(x => x.id !== groupId);
      this.totalRecords--;
      return;
    }
    if (pageModulo === 1 && this.paginatorStart > 0) {
      // is last entry of page -> load previous page
      // except first page -> no previous page to load
      this.paginatorStart = this.paginatorStart - this.PAGE_SIZE;
    }

    this.getGroupPermissions();
  }

  public onNewPermission($event: GroupPermissions) {
    const groupId = $event.extGroupId.trim();
    const groupName = $event.groupName.trim();
    if (this.isGroupValid(groupId)) {
      this.setPermissions(groupName, groupId, $event.permissions);
    }
  }

  private isGroupValid(groupId: string): boolean {
    if (groupId.length === 0) {
      this.setError('assetPermissions.noGroup');
      return false;
    }
    return true;
  }

  public onTogglePermissions(entry: PermissionEntry, toggled: 'admin' | 'write') {
    switch (toggled) {
      case 'admin':
        if (entry.admin) {
          entry.write = true;
        }
        break;
      case 'write':
        if (!entry.write) {
          entry.admin = false;
        }
        break;
    }
    entry.pendingAction = true;
    this.cdRef.detectChanges();
    this.setPermissions(
      entry.label,
      entry.id,
      entry.admin ? 'rwa' : entry.write ? 'rw' : 'r',
      'changed',
    );
  }

  private setPermissions(
    groupName: string,
    groupId: string,
    permissions: string,
    action: 'changed' | 'granted' = 'granted',
  ) {
    this.saving = true;
    this.groupPermissionService
      .grantPermissionsToGroup(this.assetDetails.uuid, {
        extGroupId: groupId,
        permissions,
        sendMail: true,
      })
      .subscribe({
        next: () => {
          this.saving = false;
          this.resetError('assetPermissions.error.conflict');
          this.resetError('assetPermissions.error.set');
          if (action === 'changed') {
            this.changePermissionEntry(groupId, permissions);
          } else {
            this.addPermissionEntry(groupName, groupId, permissions);
            this.groupPermissionAdd.clear();
          }
        },
        error: error => {
          this.saving = false;
          if (error.status === 409) {
            this.setError('assetPermissions.error.conflict', 'info');
          } else {
            this.setError('assetPermissions.error.set');
          }
          this.logger.error(error.error?.message || error);
          this.getGroupPermissions();
        },
      });
  }

  public setError(msg?: string, severity: 'error' | 'info' = 'error') {
    this.error = msg;
    this.errorSeverity = severity;
  }

  private resetError(msg: string) {
    if (this.error === msg) {
      this.error = undefined;
    }
  }
}
