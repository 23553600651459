@if (myPermissions.admin) {
  @if (showGroupPermissions) {
    <p-tabView
      [scrollable]="true"
      [style]="{
        height: '100%',
        display: 'grid',
        'grid-template-rows': 'min-content 1fr',
        gap: '16px',
      }"
    >
      <p-tabPanel header="{{ 'assetPermissions.user' | translate }}" [cache]="true">
        <ng-template pTemplate="content">
          <app-permissions [assetDetails]="asset" [narrow]="false"></app-permissions>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="{{ 'assetPermissions.group' | translate }}" [cache]="true">
        <ng-template pTemplate="content">
          <app-group-permissions [assetDetails]="asset" [narrow]="false"></app-group-permissions>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  } @else {
    <app-permissions [assetDetails]="asset" [narrow]="false"></app-permissions>
  }
} @else {
  <div class="insights">
    <div [innerHTML]="'permission-insights.own-permission.' + asset.permissions | translate"></div>
    <app-permission-insights [uuid]="asset.uuid"></app-permission-insights>
  </div>
}
<div>
  <button
    pButton
    type="button"
    label="{{ 'buttons.close' | translate }}"
    (click)="close()"
  ></button>
</div>
