import { Component } from '@angular/core';
import { MessageService, Message, SharedModule } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { CalloutComponent } from '../callout/callout.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-asset-cloned-toast',
  templateUrl: './asset-cloned-toast.component.html',
  styleUrls: ['./asset-cloned-toast.component.scss'],
  standalone: true,
  imports: [ToastModule, SharedModule, CalloutComponent, RouterLink, TranslateModule],
})
export class AssetClonedToastComponent {
  public readonly key = 'assetClonedToast';
  constructor(private messageService: MessageService) {}

  public onClose(message: Message) {
    this.messageService.clear(message.id);
  }
}
