import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ENVIRONMENT } from '../models';

@Injectable({ providedIn: 'root' })
export class OAuthInterceptor implements HttpInterceptor {
  private environment = inject(ENVIRONMENT);
  private oauthedUrls = [
    this.environment.apiUrl,
    this.environment.apiUrlEcs,
    this.environment.configBackendUrl,
    this.environment.iccUrl,
    this.environment.icc2Url,
    this.environment.liveConnectUrl,
    this.environment.sm365Url,
    this.environment.sm365DocumentProxyUrl,
    this.environment.monitoringBoxServiceUrl,
    this.environment.artifactRepositoryUrl,
    this.environment.apppoolBackend,
    this.environment.groupManagement?.apiUrl,
  ].filter((url): url is string => url !== undefined && url !== null && url !== '');

  constructor(
    private oauthStorage: OAuthStorage,
    private oauthErrorHandler: OAuthResourceServerErrorHandler,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.oauthedUrls.some(prefix => request.url.startsWith(prefix))) {
      const token = this.oauthStorage.getItem('access_token');

      if (token) {
        return next
          .handle(
            request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
              withCredentials: false,
            }),
          )
          .pipe(catchError(err => this.oauthErrorHandler.handleError(err)));
      }
    }

    return next.handle(request);
  }
}
