import { Component, OnDestroy, OnInit, ViewChild, effect, input, output } from '@angular/core';
import { AssetTypeMapping, GetAssetResponse } from '@assethub/shared/models/asset-details';
import {
  AssetAdministrationShellService,
  ProductService,
  TreeService,
} from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { validateIsVendorSick } from '@assethub/shared/utils/validators/vendor-validation';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MenuItemAssetCloneComponent } from '../menu-items/menu-item-asset-clone.component';
import { MenuItemAssetCreateComponent } from '../menu-items/menu-item-asset-create.component';
import { MenuItemAssetDeleteComponent } from '../menu-items/menu-item-asset-delete.component';
import { MenuItemAssetEditComponent } from '../menu-items/menu-item-asset-edit.component';
import { MenuItemAssetEndSharingComponent } from '../menu-items/menu-item-asset-end-sharing.component';
import { MenuItemAssetExportAasComponent } from '../menu-items/menu-item-asset-export-aas.component';
import { MenuItemAssetExportCsvComponent } from '../menu-items/menu-item-asset-export-csv.component';
import { MenuItemAssetMoveComponent } from '../menu-items/menu-item-asset-move.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { MenuItemAssetPermissionsComponent } from '../menu-items/menu-item-asset-permissions.component';

interface OptionVisibilityStates {
  showEditModeOption: boolean;
  showCreateAssetOption: boolean;
  showDeleteAssetOption: boolean;
  showLeaveSharedTreeOption: boolean;
  showCloneAssetOption: boolean;
  showMoveAssetOption: boolean;
  showDownloadAssetAdminShellOption: boolean;
}

@Component({
  selector: 'app-asset-option-menu',
  templateUrl: './asset-option-menu.component.html',
  styleUrls: ['./asset-option-menu.component.scss'],
  standalone: true,
  imports: [
    MenuComponent,
    MenuItemAssetEditComponent,
    MenuItemAssetCreateComponent,
    MenuItemAssetDeleteComponent,
    MenuItemAssetCloneComponent,
    MenuItemAssetMoveComponent,
    MenuItemAssetExportCsvComponent,
    MenuItemAssetExportAasComponent,
    MenuItemComponent,
    MenuItemAssetEndSharingComponent,
    MenuItemAssetPermissionsComponent,
    TranslateModule,
  ],
})
export class AssetOptionMenuComponent implements OnInit, OnDestroy {
  private logger = new Logger(this.constructor.name);

  readonly editAsset = output<void>();
  readonly asset = input.required<GetAssetResponse>();

  optionVisibilities: OptionVisibilityStates = {
    showEditModeOption: false,
    showCreateAssetOption: false,
    showDeleteAssetOption: false,
    showLeaveSharedTreeOption: false,
    showCloneAssetOption: false,
    showMoveAssetOption: false,
    showDownloadAssetAdminShellOption: false,
  };

  showExportCSVDialog = false;

  exportAasxFile = false;

  private productDetailsSubscription?: Subscription;

  @ViewChild('menu')
  private menu: MenuComponent;

  constructor(
    private treeService: TreeService,
    private assetAdminShellService: AssetAdministrationShellService,
    private productService: ProductService,
  ) {
    effect(() => {
      this.processAsset(this.asset());
      if (this.menu) {
        this.menu.hide();
      }
    });
    this.exportAasxFile = assetAdminShellService.canExportAasxFile;
  }

  ngOnInit(): void {
    if (!this.asset()) {
      throw Error('No asset assigned to asset-option-menu component');
    }
    this.processAsset(this.asset());
  }

  ngOnDestroy() {
    if (this.productDetailsSubscription) {
      this.productDetailsSubscription.unsubscribe();
    }
  }

  private processAsset(asset: GetAssetResponse) {
    const root = this.treeService.getTreeByUuid(asset.rootUuid || asset.uuid);
    this.optionVisibilities = {
      showEditModeOption: true,
      showCreateAssetOption: true,
      showDeleteAssetOption: true,
      showLeaveSharedTreeOption: true,
      showCloneAssetOption: true,
      showMoveAssetOption: root?.owner === undefined || root?.owner.uuid !== undefined,
      showDownloadAssetAdminShellOption: false,
    };

    if (this.productDetailsSubscription) {
      this.productDetailsSubscription.unsubscribe();
      this.productDetailsSubscription = undefined;
    }

    if (
      asset.details?.partNumber &&
      asset.details.partNumber.length > 4 &&
      validateIsVendorSick(asset.details.vendor) &&
      asset.typeId !== AssetTypeMapping.SOFTWARE
    ) {
      this.productDetailsSubscription = this.productService
        .getProductDetails(asset.details.partNumber)
        .subscribe({
          next: details => {
            this.productDetailsSubscription = undefined;
            this.optionVisibilities.showDownloadAssetAdminShellOption =
              details.Product.AAS === true;
          },
          error: err => {
            this.productDetailsSubscription = undefined;
            this.logger.error(err);
          },
        });
    }
  }

  startEditMode() {
    this.editAsset.emit();
  }

  downloadAssetAdminShell() {
    const partNumber = this.asset().details?.partNumber;
    if (!partNumber) {
      this.logger.debug(`failed to find part-number`);
      return;
    }

    this.assetAdminShellService.downloadAssetAdminShell(partNumber).subscribe();
  }
}
