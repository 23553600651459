import { Component, input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-color-bar',
  templateUrl: './color-bar.component.html',
  styleUrls: ['./color-bar.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class ColorBarComponent {
  readonly hexColor = input.required<string>();
}
