<p-table
  [value]="contacts()"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  [loading]="loading()"
  breakpoint="0px"
  [scrollable]="true"
  styleClass="limit-height"
>
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'permission-insights.column.type' | translate }}</th>
      <th>{{ 'permission-insights.column.name' | translate }}</th>
      <th>{{ 'permission-insights.column.address' | translate }}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="3" class="empty-table-message">
        <app-callout type="error">{{ 'permission-insights.no-contacts' | translate }}</app-callout>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-contact>
    <tr>
      <td>
        <span class="p-column-title">{{ 'permission-insights.column.type' | translate }}</span>
        <div>{{ 'permission-insights.type.' + contact.type | translate }}</div>
      </td>
      <td>
        <span class="p-column-title">{{ 'permission-insights.column.name' | translate }}</span>
        <div>{{ contact.name }}</div>
      </td>
      <td>
        <span class="p-column-title">{{ 'permission-insights.column.address' | translate }}</span>
        @if (contact.type === 'person') {
          <div>
            <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
          </div>
        }
        @if (contact.type === 'group' && groupHref) {
          <div>
            <a target="_blank" href="{{ groupHref }}/visible-groups/{{ contact.groupId }}">{{
              'permission-insights.open-group' | translate
            }}</a>
          </div>
        }
      </td>
    </tr>
  </ng-template>
</p-table>
