import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { Observable, Subject, forkJoin, of, switchMap } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import {
  AssetBreadcrumb,
  AssetItem,
  AssetRootItem,
  AssetTree,
  CrmId,
  ENVIRONMENT,
  GetAssetTreesResponseV2,
  UpdateInfos,
  assetTypeMappingToString,
} from '../models';

export interface CloneAssetOpt {
  recursive?: boolean;
  includeCustomUploads?: boolean;
  includePermissions?: boolean;
  assetTypeName?: string;
}

export interface CloneCheckResult {
  duplicateProducts?: {
    count: number;
  };
}

interface ClonedAsset extends AssetTree {
  insertAfterUuid?: string;
  parentUuid?: string;
}

interface NewAsset {
  uuid: string;
  parentUuid: string;
  name: string;
  type: number;
  productName?: string;
  productPictureUrl?: string;
}
@Injectable({ providedIn: 'root' })
export class TreeService {
  public treeChanged: Observable<string>;
  public selectedTreeChanged: Observable<string>;
  public nodeChanged: Observable<string>;

  private treeChangedSubject = new Subject<string>();
  private selectedTreeChangedSubject = new Subject<string>();
  private nodeChangedSubject = new Subject<string>();

  private treesRequest?: Observable<AssetRootItem[]>;
  private activeTreeBranchRequests = new Map<string, Observable<AssetTree>>();

  private trees = new Map<string, AssetRootItem>();
  private nodes = new Map<string, AssetItem>();
  private currentSelectedTree?: AssetRootItem;

  private env = inject(ENVIRONMENT);
  private baseUrl: string = this.env.apiUrl;
  private sm365Url = this.env.sm365Url;

  private logger = new Logger(this.constructor.name);

  constructor(private httpClient: HttpClient) {
    this.treeChanged = this.treeChangedSubject.asObservable();
    this.nodeChanged = this.nodeChangedSubject.asObservable();
    this.selectedTreeChanged = this.selectedTreeChangedSubject.asObservable();
  }

  get rootNodes(): AssetRootItem[] {
    return Array.from(this.trees.values());
  }

  public cloneAsset(assetUuid: string, optIn: CloneAssetOpt): Observable<AssetTree> {
    const opt: Required<CloneAssetOpt> = Object.assign(
      {
        recursive: false,
        includeCustomUploads: false,
        includePermissions: false,
        assetTypeName: '',
      },
      optIn,
    );
    return this.httpClient
      .post<ClonedAsset>(`${this.baseUrl}/asset/${assetUuid}/clone`, opt)
      .pipe<ClonedAsset>(
        tap(clone => {
          if (clone.parentUuid) {
            this.insertClonedNode(clone, clone.parentUuid, clone.insertAfterUuid);
          } else {
            this.trees.set(clone.uuid, this.buildAssetRootItem(clone));
            this.treeChangedSubject.next(clone.uuid);
          }
        }),
      );
  }

  public cloneCheck(assetUuid: string, optIn: CloneAssetOpt): Observable<CloneCheckResult> {
    return this.httpClient.post<CloneCheckResult>(
      `${this.baseUrl}/asset/${assetUuid}/cloneCheck`,
      optIn,
    );
  }

  // Retrieve all asset trees of the currently signed-in user. Returns both, owned as well as inherited trees.
  // The initial trees returned would be shallow by default unless an initial drill-down node id is given. If
  // this id is given, an attempt is made to retrieve the branch the given asset is located on. This functionality
  // is provided in case the initial URL contains a specific node.
  public fetchTrees(): Observable<AssetRootItem[]> {
    // If trees is already populated, serve that data as the result
    if (this.trees.size) {
      this.treesRequest = undefined;
      return of(Array.from(this.trees.values()));
    }

    // No data, but already a (shareable) observable? Then return this, so the caller can subscribe to
    // it.
    if (this.treesRequest) {
      return this.treesRequest;
    }

    const assetTreeRequest = this.httpClient.get<GetAssetTreesResponseV2>(
      this.baseUrl + '/v3/trees',
    );

    // None of the options above match, so create a new request and observable for it; it is important
    // to use the share() operator, so we can reuse this observable multiple times.

    this.treesRequest = forkJoin([assetTreeRequest, this.getActiveCrmIds()]).pipe(
      map(([treeResponse, customerIds]) => {
        const trees: AssetRootItem[] = [];

        for (const tree of treeResponse.owned) {
          trees.push(this.buildAssetRootItem(tree));
        }

        for (const tree of treeResponse.inherited) {
          trees.push(this.buildAssetRootItem(tree, 'r', customerIds));
        }
        // put service trees before regular trees and order same kind of trees by name
        trees.sort((a, b) =>
          a.crm365 === b.crm365 ? (a.name || '').localeCompare(b.name || '') : a.crm365 ? -1 : 1,
        );
        return trees;
      }),
      tap((trees: AssetRootItem[]) => {
        const treeMap = new Map<string, AssetRootItem>();
        for (const tree of trees) {
          treeMap.set(tree.root.uuid, tree);
          if (tree.permission === '-') {
            delete tree.profilePicture;
          }
        }
        this.trees = treeMap;
      }),
      share(),
    );

    return this.treesRequest;
  }

  public fetchBranch(leafUuid: string): Observable<AssetItem | AssetRootItem> {
    const node = this.findNode(leafUuid);

    if (node) {
      return of(node);
    }

    return this.fetchTrees().pipe(
      // Check if leaf of our branch is a root node
      map((treeRoots: AssetRootItem[]) => treeRoots.find(root => root.uuid === leafUuid)),
      switchMap((treeRoot: AssetRootItem | undefined) => {
        if (treeRoot) {
          // return treeRoot when not undefined
          return of(treeRoot);
        }

        // Load the complete branch if node is still not known
        return this.loadTreeBranch(leafUuid).pipe(
          map(tree => {
            const rootNode: AssetRootItem | undefined = this.getTreeByUuid(tree.uuid);

            if (!rootNode) {
              throw new Error('No root node for branch injection found');
            }

            const stack: AssetTree[] = [tree];
            let lastParent: AssetItem = rootNode;

            while (stack.length) {
              const currentNode = stack.shift();
              if (currentNode) {
                const treeNode = this.findNode(currentNode.uuid);
                if (undefined === treeNode) {
                  this.buildAssetItem(currentNode, lastParent);
                  continue;
                }

                if (currentNode.children.length) {
                  currentNode.children.forEach(child => stack.push(child));
                  lastParent = treeNode;
                }
              }
            }

            const leafNode: AssetItem = this.mustFindNode(leafUuid);
            this.treeChangedSubject.next(tree.uuid);
            return leafNode;
          }),
        );
      }),
    );
  }

  public getTreeByUuid(uuid: string): AssetRootItem | undefined {
    return this.trees.get(uuid);
  }

  public findTreeBySnapshot(snapshotId: string): AssetRootItem | undefined {
    return Array.from(this.trees.values()).find(t => t.snapshotId === snapshotId);
  }

  private selectNextTree() {
    const replace = Array.from(this.trees.values()).shift();

    if (undefined === replace) {
      this.currentSelectedTree = undefined;
      return;
    }

    this.setSelectedNode(replace.uuid);
  }

  public setSelectedNode(nodeUuid: string): void {
    const node = this.findNode(nodeUuid);
    if (undefined === node) {
      return;
    }

    const tree = node.root;

    tree.selectedNode = node.uuid;
    this.logger.debug('Selected new node = ', tree.selectedNode);

    if (this.currentSelectedTree !== tree) {
      // Tree was switched
      this.currentSelectedTree = tree;
      this.logger.debug('Current selected tree = ', this.currentSelectedTree.uuid);
      this.selectedTreeChangedSubject.next(tree.uuid);
    }
  }

  public selectedNode(): AssetItem | undefined {
    return this.currentSelectedTree !== undefined
      ? this.findNode(this.currentSelectedTree.selectedNode)
      : undefined;
  }

  // load child nodes asynchronously, store them in the global tree and return the Observable<...> to the tree for immediate processing
  public getChildren(uuid: string): Observable<readonly AssetItem[]> {
    const currentNode: AssetItem = this.mustFindNode(uuid);

    return this.httpClient.get<AssetTree[]>(`${this.baseUrl}/v3/asset/${uuid}/children`).pipe(
      tap(() => currentNode.clearChildren()),
      map(children => children.map(child => this.buildAssetItem(child, currentNode))),
      tap(() => {
        this.treeChangedSubject.next(currentNode.root.uuid);
      }),
    );
  }

  public loadTree(rootUuid: string): Observable<AssetRootItem> {
    return this.httpClient.get(`${this.baseUrl}/v3/tree/asset/${rootUuid}`).pipe(
      map((tree: AssetTree): AssetRootItem => this.buildAssetRootItem(tree)),
      tap((tree: AssetRootItem) => {
        this.trees.set(tree.uuid, tree);
        this.treeChangedSubject.next(rootUuid);
      }),
    );
  }

  public deleteNode(uuid: string) {
    const tree = this.trees.get(uuid);
    if (undefined !== tree) {
      this.trees.delete(uuid);
      tree.remove();

      if (this.currentSelectedTree?.uuid === uuid) {
        this.selectNextTree();
      }

      this.treeChangedSubject.next(uuid);
    } else {
      const nodeToDelete = this.findNode(uuid);
      if (nodeToDelete?.parent === undefined) {
        return;
      }

      nodeToDelete.remove();

      const selectedNodeRemoved = this.findNode(nodeToDelete.root.selectedNode) === undefined;
      if (selectedNodeRemoved) {
        this.setSelectedNode(nodeToDelete.parent.uuid);
      }
      this.treeChangedSubject.next(nodeToDelete.root.uuid);
    }
  }

  public addNode(newAsset: NewAsset) {
    const parentNode = this.mustFindNode(newAsset.parentUuid);
    const child = this.buildAssetItem(
      {
        uuid: newAsset.uuid,
        name: newAsset.name,
        productName: newAsset.productName,
        productPictureUrl: newAsset.productPictureUrl,
        profilePicture: undefined,
        type: assetTypeMappingToString[newAsset.type],
        hasChildren: false,
        children: [],
        rootUuid: parentNode.root.uuid,
        permission: parentNode.permission,
      },
      parentNode,
    );

    this.treeChangedSubject.next(child.root.uuid);
  }

  public insertClonedNode(node: AssetTree, parentUuid: string, afterUuid?: string) {
    const parentNode = this.mustFindNode(parentUuid);
    const newChildNode = AssetItem.build(this.nodes, node);

    if (afterUuid) {
      parentNode.insertChildAfter(this.mustFindNode(afterUuid), newChildNode);
    } else {
      parentNode.prependChild(newChildNode);
    }

    this.treeChangedSubject.next(parentNode.root.uuid);
  }

  // When updating the contents of a node, a copy will be stored because
  // in some situations the whole data object is passed to a pipe
  // and this means that Angular will only detect a change if the reference
  // of the object changes.
  public updateNode(updateInfos: UpdateInfos) {
    const node = this.findNode(updateInfos.uuid);

    if (node === undefined) {
      return;
    }

    this.logger.debug('Updating node', node.uuid, updateInfos);
    node.applyUpdate(updateInfos);

    if (node.isRoot()) {
      this.treeChangedSubject.next(node.uuid);
    }

    this.nodeChangedSubject.next(node.uuid);
  }

  private getActiveCrmIds(): Observable<CrmId[]> {
    const emptyCrmIdResponse: CrmId[] = [];
    if (!this.sm365Url) {
      this.logger.info(`request of my customer-ids from sm365 skipped!`);
      return of(emptyCrmIdResponse);
    }
    return this.httpClient
      .get<CrmId[]>(this.sm365Url + '/my-account/customer-ids')
      .pipe(catchError(() => of(emptyCrmIdResponse)));
  }

  private loadTreeBranch(nodeUuid: string): Observable<AssetTree> {
    const existingRequest = this.activeTreeBranchRequests.get(nodeUuid);
    if (existingRequest) {
      return existingRequest;
    }

    const branchRequest = this.httpClient
      .get<AssetTree>(`${this.baseUrl}/v3/asset/${nodeUuid}/branch`)
      .pipe(share());

    this.activeTreeBranchRequests.set(nodeUuid, branchRequest);
    branchRequest.subscribe({
      complete: () => {
        this.activeTreeBranchRequests.delete(nodeUuid);
      },
    });

    return branchRequest;
  }

  private buildAssetItem(tree: AssetTree, parent: AssetItem): AssetItem {
    const node = AssetItem.build(this.nodes, tree);
    parent.appendChild(node);
    return node;
  }

  private buildAssetRootItem(
    tree: AssetTree,
    defaultPermission = 'rwa',
    customerIds?: CrmId[],
  ): AssetRootItem {
    tree.permission ??= defaultPermission;
    return AssetRootItem.build(this.nodes, tree, customerIds);
  }

  public findNode(uuid: string): AssetItem | undefined {
    return this.nodes.get(uuid);
  }

  public findRootByCustomerId(
    customerNumber: string,
    subsidiaryCode: string,
  ): Observable<AssetRootItem | undefined> {
    return this.fetchTrees().pipe(
      map(trees =>
        trees.find(x => x.customerNumber === customerNumber && x.subsidiaryCode === subsidiaryCode),
      ),
    );
  }

  public mustFindNode(uuid: string): AssetItem {
    const node = this.findNode(uuid);
    if (node === undefined) {
      throw new Error(`No node found by id ${uuid}`);
    }
    return node;
  }

  public buildBreadcrumb(uuid: string): AssetBreadcrumb[] {
    for (const tree of this.trees.values()) {
      const retval = this.buildBreadcrumbRecursive(tree, uuid);
      if (retval.length > 0) {
        return retval;
      }
    }
    return [];
  }

  private buildBreadcrumbRecursive(node: AssetItem, uuid: string): AssetBreadcrumb[] {
    if (node.uuid === uuid) {
      return [this.getBreadcrumbFromTreeItem(node)];
    }

    for (const child of node.children) {
      const retval = this.buildBreadcrumbRecursive(child, uuid);
      if (retval.length > 0) {
        retval.unshift(this.getBreadcrumbFromTreeItem(node));
        return retval;
      }
    }

    return [];
  }

  public getBreadcrumbFromTreeItem(asset: AssetItem): AssetBreadcrumb {
    return {
      uuid: asset.uuid,
      type: asset.type,
      name: asset.name,
      productName: asset.productName,
    };
  }

  public isActiveAsset(uuid: string): boolean {
    return this.currentSelectedTree?.selectedNode === uuid;
  }

  public isActiveTree(uuid: string): boolean {
    return this.currentSelectedTree?.uuid === uuid;
  }

  /**
   * The `moveNodeWithinTree()` method shall be called only for cases where the user actively moves an
   * asset to a different place in the same tree, allegedly by drag&drop in AssetTreeComponent.
   *
   * This method assumes:
   * - the node to move is known
   * - the destination is known
   * - parents are known
   *
   */
  public moveNodeWithinTree(
    movedAsset: AssetItem,
    destinationNode: AssetItem,
    toStartOfChildren: boolean,
  ): void {
    const currentParent = movedAsset.parent;

    if (currentParent === undefined) {
      throw new Error('Cannot move root node within same tree');
    }

    if (toStartOfChildren) {
      if (!destinationNode.childrenLoaded()) {
        movedAsset.remove();
        return this.notify([movedAsset.root.uuid]);
      }

      destinationNode.prependChild(movedAsset);
      return this.notify([movedAsset.root.uuid]);
    }

    const destinationNodeParent = destinationNode.parent;
    if (destinationNodeParent === undefined) {
      throw new Error('Cannot move onto root level');
    }

    // Otherwise put it behind destination
    destinationNodeParent.insertChildAfter(destinationNode, movedAsset);
    return this.notify([movedAsset.root.uuid]);
  }

  /**
   * The `moveNodeToTree()` method shall be called for cases where the user actively moves a node to a different
   * tree.
   *
   * This method assumes:
   * - the node to move is known
   * - the destination is known
   */
  public moveNodeToTree(movedNode: AssetItem, destinationNode: AssetItem) {
    // First of all clean up the source part of that move process.
    if (movedNode.isRoot()) {
      this.trees.delete(movedNode.uuid);
      movedNode.remove();
    }

    // Copy root uuid into separate variable, as the value in the object will
    // change before emitting the values.
    const sourceTreeId = movedNode.root.uuid;

    // The destination has not loaded, just discard the node then:
    if (!destinationNode.childrenLoaded()) {
      movedNode.remove();
      return this.notify([sourceTreeId, destinationNode.root.uuid]);
    }

    destinationNode.prependChild(movedNode);
    return this.notify([sourceTreeId, destinationNode.root.uuid]);
  }

  /**
   * The `applyNodeMoved()` method shall be called for cases where an asset was moved (not by the current frontend)
   * and a notification via ws was received for it.
   *
   * This method assumes:
   * - the active node is never moved
   * - a node from active tree will never be moved out of tree
   * - consequently, the active tree will never be moved into another tree
   */
  public applyNodeMoved(
    movedNode: AssetItem,
    destination: AssetItem,
    toStartOfChildren: boolean,
  ): void {
    const affectedTreeIds = [movedNode.root.uuid, destination.root.uuid];

    // Try to receive the parent node of this move operation
    const destinationNode: AssetItem = toStartOfChildren
      ? destination
      : (destination.parent ?? destination);

    if (destination.isRoot() && !toStartOfChildren) {
      throw new Error('Move into new tree is not supported');
    }

    // First of all clean up the source part of that move process.
    if (movedNode.isRoot()) {
      this.trees.delete(movedNode.uuid);
      movedNode.remove();
    }

    // The node to move is known, hang it in
    if (toStartOfChildren) {
      if (!destinationNode.childrenLoaded()) {
        movedNode.remove();
        return this.notify(affectedTreeIds);
      }

      destinationNode.prependChild(movedNode);
      return this.notify(affectedTreeIds);
    }

    // Otherwise put it behind destination
    destinationNode.insertChildAfter(destination, movedNode);
    return this.notify(affectedTreeIds);
  }

  // Define helper to notify clients
  private notify(nodeIds: (string | undefined)[]): void {
    Array.from(new Set(nodeIds))
      .filter((id): id is string => id !== undefined)
      .forEach(id => {
        this.logger.info('Notifying about tree', id);
        this.treeChangedSubject.next(id);
      });
  }
}
