export interface DateRange {
  after?: Date;
  before?: Date;
}

export function isDateRange(o: unknown): o is DateRange {
  if (o === null || typeof o !== 'object') {
    return false;
  }
  if (o['before'] && !(o['before'] instanceof Date)) {
    return false;
  }
  if (o['after'] && !(o['after'] instanceof Date)) {
    return false;
  }
  return true;
}
