<app-menu #menu icon="settings" class="ui-button-bare-neutral" (showMenu)="onShowSettings()">
  <app-menu-item
    label="{{ 'table.resetTable' | translate }}"
    icon="recycling"
    (selected)="resetSettings.emit()"
  ></app-menu-item>
  <hr />
  <div class="visibleRows">
    <div>{{ 'table.rowsPerPage' | translate }}</div>
    <p-dropdown
      [scrollHeight]="'200px'"
      [overlayOptions]="{
        showTransitionOptions: '0ms',
        hideTransitionOptions: '0ms',
      }"
      [options]="visibleRowsOptions()"
      [optionLabel]="'displayLabel'"
      [optionValue]="'value'"
      [(ngModel)]="selectedVisibleRows"
    ></p-dropdown>
  </div>
  @if (availableColumnOptions().length > 0) {
    <p-listbox
      [options]="availableColumnOptions()"
      [(ngModel)]="selectedColumnOptions"
      [multiple]="true"
      [checkbox]="true"
      optionLabel="displayLabel"
      [showToggleAll]="true"
      scrollHeight="250px"
      [style]="{ 'max-width': '300px' }"
    >
      <ng-template pTemplate="filter">{{ 'sort-icon.select-all' | translate }}</ng-template>
    </p-listbox>
  }
  <div class="filter-buttons">
    <button
      pButton
      type="button"
      label="{{ 'buttons.apply' | translate }}"
      (click)="onApplySettings()"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-outline"
      label="{{ 'buttons.cancel' | translate }}"
      (click)="menu.hide()"
    ></button>
  </div>
</app-menu>
