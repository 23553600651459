import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { MapMarkers } from '@assethub/asset-management/models';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import { MessageService } from 'primeng/api';
import { ResizedDirective } from '../../directives/resized/resized.directive';
import { BouncingPinComponent } from '../bouncing-pin/bouncing-pin.component';

@Component({
  selector: 'app-asset-heremap',
  styleUrls: ['./asset-heremap.component.scss'],
  templateUrl: './asset-heremap.component.html',
  standalone: true,
  imports: [NgClass, ResizedDirective],
})
export class AssetHeremapComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() markers: MapMarkers = [];
  @Input() narrow: boolean = false;

  @ViewChild('map') mapElement: ElementRef;

  private platform: H.service.Platform;
  private map: H.Map;
  private hereMarkers: { [key: string]: H.map.DomMarker } = {};
  private markerPositions: H.geo.Point[];
  private logger = new Logger(this.constructor.name);
  private resizeTimerId?: number;

  private visiblePins: { [key: string]: ComponentRef<BouncingPinComponent> } = {};

  public mapVisible = false;

  private hereWeGoApiKey = inject(ENVIRONMENT).hereWeGoApiKey;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    try {
      this.platform = new H.service.Platform({
        apikey: this.hereWeGoApiKey,
      });
    } catch (ex) {
      this.messageService.add({
        severity: 'error',
        summary: 'toasts.geo-map.init-heremap-failed',
        life: 10000,
      });
      this.logger.error('Failed to initialize Here Maps', ex);
    }
  }

  ngAfterViewInit(): void {
    this.setupMap();
    this.renewMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.map) {
      return;
    }
    if (!this.platform) {
      return;
    }
    if (changes['markers']) {
      this.renewMap();
    }
  }

  private renewMap() {
    this.clearMap();
    if (this.markers.length > 0) {
      try {
        this.validateInput();
        this.setupMarkers();
        this.updateMap();
      } catch (error) {
        this.onError(error);
      }
    } else {
      this.mapVisible = false;
    }
  }

  onError(err: Error) {
    this.mapVisible = false;
    this.messageService.add({
      severity: 'error',
      summary: 'toasts.geo-map.default-heremap-error',
      life: 10000,
    });
    this.logger.error(err.message);
  }

  private clearMap() {
    this.map.removeObjects(this.map.getObjects());
    this.hereMarkers = {};
    this.markerPositions = [];
    this.visiblePins = {};
  }

  private validateInput() {
    this.markers.forEach((marker, index) => {
      if (marker.latitude < -90 || marker.latitude > 90) {
        this.logger.error('latitude is not a number or invalid value');
        this.markers.splice(index, 1);
      }
      if (marker.longitude < -180 || marker.longitude > 180) {
        this.logger.error('longitude is not a number or invalid value');
        this.markers.splice(index, 1);
      }
    });
  }

  private setupMap() {
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(this.mapElement.nativeElement, defaultLayers.vector.normal.map, {
      zoom: 17,
      center: { lat: 48.0889987, lng: 7.9472946 },
    });
    const ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const scalebar = ui.getControl('scalebar');
    if (scalebar) {
      scalebar.setAlignment(H.ui.LayoutAlignment.TOP_LEFT);
    }
    const mapsettings = ui.getControl('mapsettings');
    if (mapsettings) {
      mapsettings.setDisabled(true);
      mapsettings.setVisibility(false);
    }
    const panorama = ui.getControl('panorama');
    if (panorama) {
      panorama.setDisabled(true);
      panorama.setVisibility(false);
    }
    const mapEvents = new H.mapevents.MapEvents(this.map);
    new H.mapevents.Behavior(mapEvents);
  }

  private setupMarkers() {
    this.markers.forEach(marker => {
      // bouncing pin marker is generated only if assetUuid and typeId of PinMetadata are defined
      const domIcon = new H.map.DomIcon(`<div id="${marker.assetUuid}"></div>`, {
        onAttach: (clonedElement: HTMLElement) => {
          // create component and attach it to the marker div in the map map
          const componentRef = this.viewContainerRef.createComponent(BouncingPinComponent);
          componentRef.instance.asset = {
            typeId: marker.typeId,
            profilePicture: marker.profilePicture,
            productPictureUrl: marker.productPictureUrl,
            name: marker.name,
            productName: marker.productName,
            assetUuid: marker.assetUuid,
          };
          this.visiblePins[clonedElement.id] = componentRef;
          clonedElement.appendChild(componentRef.location.nativeElement);
        },
        onDetach: (clonedElement: HTMLElement) => {
          this.visiblePins[clonedElement.id]?.destroy();
          delete this.visiblePins[clonedElement.id];
        },
      });
      const hereMarker = new H.map.DomMarker(
        { lat: marker.latitude, lng: marker.longitude },
        { icon: domIcon },
      );
      this.hereMarkers[marker.assetUuid] = hereMarker;

      this.map.addObject(hereMarker);
    });
  }

  private updateMap() {
    this.markers.forEach(marker => {
      // update marker position
      const point: H.geo.Point = new H.geo.Point(marker.latitude, marker.longitude);
      this.hereMarkers[marker.assetUuid].setGeometry(point);
      this.markerPositions.push(point);
      // track marker positions to calculate the map bounds
    });
    // if there is only one marker, center the map on it
    if (this.markers.length === 1) {
      this.map.setCenter({ lat: this.markers[0].latitude, lng: this.markers[0].longitude });
    }
    const percentageMargin = 20; // If percentage Margin is 10% this adds 5% to the right and 5% will go to the left.
    let topMaxLat = Math.max(...this.markerPositions.map(p => p.lat));
    let rightMaxLng = Math.max(...this.markerPositions.map(p => p.lng));
    let bottomMinLat = Math.min(...this.markerPositions.map(p => p.lat));
    let leftMinLng = Math.min(...this.markerPositions.map(p => p.lng));
    const latDifference = topMaxLat - bottomMinLat;
    const lngDifference = rightMaxLng - leftMinLng;
    topMaxLat += (latDifference * percentageMargin) / 100;
    bottomMinLat -= (latDifference * percentageMargin) / 100;
    rightMaxLng += (lngDifference * percentageMargin) / 100;
    leftMinLng -= (lngDifference * percentageMargin) / 100;

    // Automatic Zoom if more than one marker is existing on the map, else 1km default zoom
    if (this.markers.length > 1) {
      const viewBounds = new H.geo.Rect(topMaxLat, leftMinLng, bottomMinLat, rightMaxLng);
      this.map.getViewModel().setLookAtData({ bounds: viewBounds });
    } else {
      this.map.setZoom(13, true);
    }

    setTimeout(() => {
      // This is necessary to avoid an ExpressionHasChangedAfterItHasBeenChecked error
      this.mapVisible = true;
    }, 0);
  }

  onResized() {
    if (!this.map) {
      return;
    }
    if (this.resizeTimerId !== undefined) {
      window.clearTimeout(this.resizeTimerId);
    }
    this.resizeTimerId = window.setTimeout(() => {
      this.map.getViewPort().resize();
      this.resizeTimerId = undefined;
    }, 125);
  }
}
