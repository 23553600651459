import { Component, HostBinding, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-no-elements-placeholder',
  templateUrl: './no-elements-placeholder.component.html',
  styleUrls: ['./no-elements-placeholder.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class NoElementsPlaceholderComponent {
  readonly icon = input('no_sim');
  readonly text = input('');
  readonly theme = input<'sds' | 'synergy'>('sds');

  @HostBinding('class.ui-theme-synergy')
  get hasThemeSynergy() {
    return this.theme() === 'synergy';
  }
}
