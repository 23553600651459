import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AssetItem, ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { TreeService } from './tree.service';

export abstract class SnapshotError {
  abstract readonly code: string;
}

export class InsufficientExpandedNodesError extends SnapshotError {
  readonly code = 'snapshot.errors.insufficient-expanded-nodes';
}

@Injectable({ providedIn: 'root' })
export class AssetTreeSnapshotService {
  private logger = new Logger(this.constructor.name);
  private apiUrl: string = inject(ENVIRONMENT).apiUrl;

  constructor(
    private httpClient: HttpClient,
    private treeService: TreeService,
  ) {}

  findSnapshotIdByTree(treeId: string): string | undefined {
    return this.treeService.getTreeByUuid(treeId)?.snapshotId;
  }

  createSnapshotFor(treeId: string): Observable<string> {
    const tree = this.treeService.getTreeByUuid(treeId);

    if (!tree) {
      throw new Error('Cannot create snapshot for nonexistant asset tree');
    }

    const expandedNodes = this.fetchExpandedNodes(tree);
    if (expandedNodes.length <= 1) {
      return throwError(() => new InsufficientExpandedNodesError());
    }

    return this.httpClient
      .post<string>(`${this.apiUrl}/snapshot/asset/${treeId}`, {
        expandedNodes,
      })
      .pipe(
        tap(snapshotId => {
          tree.snapshotId = snapshotId;
        }),
        catchError(err => {
          this.logger.debug('Error creating snapshot = ', err);
          throw err;
        }),
      );
  }

  updateSnapshot(snapshotId: string): Observable<void> {
    const tree = this.treeService.findTreeBySnapshot(snapshotId);

    if (!tree) {
      throw new Error('Cannot update snapshot for nonexistant asset tree');
    }

    const expandedNodes = this.fetchExpandedNodes(tree);
    if (expandedNodes.length <= 1) {
      return throwError(() => new InsufficientExpandedNodesError());
    }

    return this.httpClient
      .put<void>(`${this.apiUrl}/snapshot/${snapshotId}`, {
        expandedNodes,
      })
      .pipe(
        catchError(err => {
          this.logger.debug('Error updating snapshot = ', err);
          throw err;
        }),
      );
  }

  deleteSnapshot(snapshotId: string): Observable<void> {
    const tree = this.treeService.findTreeBySnapshot(snapshotId);

    if (!tree) {
      throw new Error('Cannot delete snapshot for nonexistant asset tree');
    }

    return this.httpClient.delete<void>(`${this.apiUrl}/snapshot/${snapshotId}`).pipe(
      tap(() => (tree.snapshotId = undefined)),
      catchError(err => {
        this.logger.debug('Error deleting snapshot = ', err);
        throw err;
      }),
    );
  }

  private fetchExpandedNodes(node: AssetItem) {
    const expandedIds: string[] = [];
    if (node.expanded === true) {
      expandedIds.push(node.uuid);
      for (const child of node.children) {
        expandedIds.push(...this.fetchExpandedNodes(child));
      }
    }

    return expandedIds;
  }
}
