@if (loading()) {
  <app-media-placeholder shape="image"></app-media-placeholder>
} @else if (error()) {
  <app-media-placeholder shape="broken_image"></app-media-placeholder>
} @else if (overlayShape()) {
  <div class="content">
    <div class="preview-image overlayed" [ngStyle]="previewStyle()"></div>
    <app-media-placeholder class="overlay" [shape]="overlayShape()"></app-media-placeholder>
  </div>
} @else if (displayUrl()) {
  <div class="content">
    <div
      class="preview-image"
      [ngClass]="{ 'cursor-pointer': this.selectable() }"
      [ngStyle]="previewStyle()"
      (click)="onClick()"
    ></div>
    <app-menu class="ui-button-bare-neutral" direction="dl">
      <app-menu-item
        icon="download"
        label="{{ 'buttons.download' | translate }}"
        (selected)="onDownload()"
      ></app-menu-item>
      @if (writable()) {
        <app-menu-item
          icon="delete"
          label="{{ 'buttons.delete' | translate }}"
          (selected)="onDelete()"
        ></app-menu-item>
        <app-menu-item
          icon="image"
          label="{{ 'mediaCustomImage.menu.useAsProfilePicture' | translate }}"
          [checked]="isCurrentProfilePicture()"
          (selected)="onUseAsProfilePicture()"
        ></app-menu-item>
        <app-menu-item
          icon="image_not_supported"
          label="{{ 'mediaCustomImage.menu.useDefaultProfilePicture' | translate }}"
          [checked]="defaultProfilePictureActive()"
          (selected)="onUseDefaultProfilePicture()"
        ></app-menu-item>
      }
    </app-menu>
    <!-- 404 detector -->
    <img style="display: none" src="{{ displayUrl() }}" (error)="error.set(true)" />
  </div>
} @else {
  <app-media-placeholder shape="image"></app-media-placeholder>
}

@if (showDeletePrompt()) {
  <p-dialog
    header="{{ 'confirm.image.delete.header' | translate }}"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [modal]="true"
    [blockScroll]="true"
    [visible]="true"
    appendTo="body"
  >
    <div class="confirm-body">
      <div
        class="confirm-picture"
        [ngStyle]="{
          'background-image': 'url(' + displayUrl() + ')',
        }"
      ></div>
      <span class="confirm-text">{{ 'confirm.image.delete.message' | translate }}</span>
    </div>
    <div class="dialog-footer">
      <button pButton type="button" class="ui-button-outline" (click)="showDeletePrompt.set(false)">
        {{ 'buttons.cancel' | translate }}
      </button>
      <button pButton type="button" class="ui-button-primary" (click)="confirmDeletion()">
        {{ 'buttons.delete' | translate }}
      </button>
    </div>
  </p-dialog>
}
