import { DOCUMENT, Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ConsentService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ENVIRONMENT } from '../models';

export const noServiceConsentAvailable: CanActivateFn = (route, state) => {
  const logger = new Logger('ConsentGuard');
  const serviceId: unknown = route.data.serviceId;
  if (!serviceId || typeof serviceId !== 'string') {
    logger.error(
      'Invalid route configuration - route data "serviceId" is missing or not a string.',
    );
    return true;
  }

  const consent = inject(ConsentService);
  const message = inject(MessageService);
  const document = inject(DOCUMENT);
  const location = inject(Location);

  logger.debug(`Checking consent for serviceId: ${serviceId}`);
  return consent.getConsentStateForServiceId(serviceId).pipe(
    filter(consentState => consentState.state !== 'RequestPending'),
    map(consentState => {
      switch (consentState.state) {
        case 'ConsentsOpen': {
          logger.debug(
            `Consents to be accepted for service ${serviceId}: ${consentState.consentUrl}`,
          );

          const href = `${consentState.consentUrl}?redirect_to=${
            document.location.origin
          }${location.prepareExternalUrl(state.url)}`;

          logger.debug('Sending user out to collect consent(s) on %s', href);
          document.location.href = href;
          break;
        }
        case 'NoConsents': {
          logger.debug(`No consents open for service ${serviceId}`);
          break;
        }
        case 'Error': {
          throw new Error(`Error fetching consents`);
        }
      }
      return consentState.state === 'NoConsents';
    }),
    catchError(err => {
      logger.error('Cannot fetch consents, see error:', err);
      const ignoreError = inject(ENVIRONMENT).ignoreConsentFailure;
      if (ignoreError !== undefined && ignoreError) {
        logger.info('Ignore consent error as defined in environment');
        message.add({
          severity: 'warn',
          summary: 'toasts.consent.error-retrieving',
          sticky: true,
        });
        return of(true);
      }
      logger.info('Access denied -> will not grant access');
      message.add({
        severity: 'error',
        summary: 'toasts.consent.error-retrieving',
        sticky: true,
      });
      return of(false);
    }),
  );
};
