import { LiveConnectChannel } from '@liveconnect/shared/models';

export interface WebsocketDetails {
  url?: string;
  ephemeral?: boolean;
}

export interface Paginated<T> {
  pageSize: number;
  items: T[];
  lastKey?: string;
}

export interface Endpoint {
  id: string;
  endpointType: string;
  name: string;
  http?: Http;
  monitoringBox?: MonitoringBox;
  websocket?: WebsocketDetails;
  permissions: EndpointPermission[];
  dtag?: DtagDetails;
}

export interface DtagDetails {
  apiKey: string;
  domainId: string;
  name: string;
}

export interface EndpointWithChannels extends Endpoint {
  channels: LiveConnectChannel[];
}

export interface Http {
  url: string;
  headers?: { [key: string]: string };
}

export interface MonitoringBox {
  customer: string;
  device: string;
  bearerToken: string;
}

export interface EndpointVerificationRequest {
  endpointType: 'HTTP';
  http: Http;
}

export interface EndpointPermission {
  userName: string;
  grant: 'r' | 'rw' | 'rwa';
}

interface CreateHttpEndpoint {
  endpointType: 'HTTP';
  name: string;
  http: Http;
  permissions?: EndpointPermission[];
}

interface CreateMonitoringBoxEndpoint {
  endpointType: 'MONITORING_BOX';
  name: string;
  monitoringBox: MonitoringBox;
  permissions?: EndpointPermission[];
}

interface CreateMonitoringBoxV2Endpoint {
  endpointType: 'MONITORING_BOX_V2';
  name: string;
  permissions?: EndpointPermission[];
}

interface CreateWebsocketEndpoint {
  endpointType: 'WEBSOCKET';
  name: string;
  websocket?: {
    ephemeral?: boolean;
  };
  permissions?: EndpointPermission[];
}

interface CreateDtagEndpoint {
  endpointType: 'DTAG';
  name: string;
  permissions?: EndpointPermission[];
  dtag: {
    apiKey: string;
    domainId: string;
    name: string;
  };
}

export type EndpointCreationRequest =
  | CreateHttpEndpoint
  | CreateMonitoringBoxEndpoint
  | CreateMonitoringBoxV2Endpoint
  | CreateWebsocketEndpoint
  | CreateDtagEndpoint;

export interface EndpointStatus {
  ok: boolean;
  code?: number;
  error?: {
    message?: string;
  };
}
