<div class="upload">
  <p-fileUpload
    #fileUpload
    name="files"
    [multiple]="multiple"
    [accept]="accept"
    [hidden]="true"
    (onSelect)="onSelect($event)"
  ></p-fileUpload>
  <button
    pButton
    type="button"
    (click)="onClick()"
    (dragenter)="onDragEnter($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    [disabled]="disabled"
    [label]="label"
    [icon]="currentIcon"
    [class]="buttonClass"
  ></button>
</div>
