export const CUSTOM_COLORS = Object.freeze({
  // Filter/Sorter page
  colorSearchValue: '#f4cccc',
  colorSelectedTrees: '#d9ead3',
  colorSelectedBuildingsOrLocations: '#d4ebff',
  colorSelectedTypes: '#aff8e6',
  colorSelectedVendors: '#c8caf8',
  colorSelectedProductNames: '#e2d1ea',
  colorSelectedFirmwareStates: '#f7dfa6',
});
