<div class="insights">
  @if (messageId) {
    <div [innerHTML]="messageId | translate"></div>
  }
  <app-permission-insights [uuid]="uuid"></app-permission-insights>
</div>
<div>
  <button
    pButton
    type="button"
    label="{{ 'buttons.close' | translate }}"
    (click)="close()"
  ></button>
</div>
