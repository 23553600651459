import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {}

  confirm(opt: {
    translationKey: string;
    accept?: () => unknown;
    reject?: () => unknown;
    placeholders?: Record<string, unknown>;
  }) {
    let translation = this.translateService.instant(opt.translationKey);
    if (!translation) {
      return;
    }
    if (opt.placeholders) {
      translation = { ...translation }; // to not overwrite message in cached translation
      translation.message = this.translateService.parser.interpolate(
        translation.message,
        opt.placeholders,
      );
    }
    this.dialogService.open(ConfirmationModalComponent, {
      header: translation.header,
      data: { translation, accept: opt.accept, reject: opt.reject },
      // Only cancel button provides means to keep the dialog open until reject callback has completed.
      // For the same reason do not enable dismissableMask.
      closable: false,
    });
  }
}
