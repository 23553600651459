import { Directive, EventEmitter, HostListener, Output, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOutsideAction]',
  standalone: true,
})
export class OutsideActionDirective {
  @Output()
  appOutsideAction: EventEmitter<Event> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:focusin', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onAction(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.appOutsideAction.emit(event);
    }
  }
}
