<p-checkbox
  [(ngModel)]="recursive"
  [binary]="true"
  [disabled]="loading"
  label="{{ 'clone.recursive' | translate }}"
  (onChange)="checkForCloneIssues()"
></p-checkbox>
<p-checkbox
  [(ngModel)]="includeCustomUploads"
  [binary]="true"
  [disabled]="loading"
  label="{{ 'clone.include-custom-uploads' | translate }}"
></p-checkbox>
<p-checkbox
  [(ngModel)]="includePermissions"
  [binary]="true"
  [disabled]="loading"
  label="{{ 'clone.include-permissions' | translate }}"
></p-checkbox>
<div *ngIf="loading">
  <div class="overlay"></div>
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>
<div *ngIf="cloneCheckResult.duplicateProducts !== undefined">
  <app-callout severity="warn">
    <span *ngIf="cloneCheckResult.duplicateProducts.count === 1">
      {{ 'clone.warnings.singleDuplicateProduct' | translate }}
    </span>
    <span *ngIf="cloneCheckResult.duplicateProducts.count > 1">
      {{
        'clone.warnings.multipleDuplicateProducts'
          | translate: { duplicateCount: cloneCheckResult.duplicateProducts.count }
      }}
    </span>
  </app-callout>
</div>
<div class="dialog-footer">
  <button
    pButton
    type="button"
    class="ui-button-outline"
    [disabled]="loading"
    (click)="onCancel()"
    label="{{ 'buttons.cancel' | translate }}"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    [disabled]="loading"
    (click)="onOk()"
    label="{{ 'buttons.okay' | translate }}"
  ></button>
</div>
