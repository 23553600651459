@if (checked()) {
  <app-icon class="checked" shape="check"></app-icon>
} @else {
  <div class="checked"></div>
}
<div class="icon">
  @if (shape() !== undefined) {
    <app-icon [shape]="shape()!"></app-icon>
  }
</div>
<div class="label" [innerHtml]="label()"></div>
@if (disabled() && reason().length > 0) {
  <app-tooltip class="info" icon="help_outline">{{ reason() }}</app-tooltip>
} @else {
  <div class="info"></div>
}
<div class="expand"></div>
