import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { HttpUtils } from '../utils/http-utils';

export interface AdditionalExportItems {
  latestSopasConfigurations?: boolean;
}

export type ExportScopes = 'ENTIRE_TREE' | 'SUBTREE' | 'SINGLE_NODE';
export type ExportScopesBackend = Exclude<ExportScopes, 'ENTIRE_TREE'>;

@Injectable({
  providedIn: 'root',
})
export class CSVExportService {
  private readonly environment = inject(ENVIRONMENT);
  private readonly apiUrl = this.environment.apiUrlEcs || this.environment.apiUrl;

  public constructor(private httpClient: HttpClient) {}

  public exportTree(
    nodeUuid: string,
    exportScope: ExportScopesBackend,
    additionalExportItems: AdditionalExportItems,
  ): Observable<{ exportData: Blob; fileName: string }> {
    const params = new HttpParams()
      .set('exportScope', exportScope)
      .set('latestSopasConfigurations', additionalExportItems.latestSopasConfigurations ?? false);
    const response = this.httpClient.get(`${this.apiUrl}/tree/${nodeUuid}/exportAsZip`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
    return response.pipe(
      map(res => ({
        exportData: res.body!,
        fileName: HttpUtils.readFileName(res.headers)!,
      })),
    );
  }

  hasAdditionalExportItems(additionalExportItems: AdditionalExportItems) {
    return (
      Object.keys(additionalExportItems).filter(key => !!additionalExportItems[key]).length > 0
    );
  }
}
