import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ENVIRONMENT } from '@assethub/shared/models';
import { BlockUiService } from '@assethub/shared/services/block-ui-service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { Observable, Subscription, finalize } from 'rxjs';
import { LabelPickerComponent } from '../../label-picker/label-picker.component';

export interface DeviceConfigurationAccept {
  customName: string;
  labels: string[];
}

export interface DeviceConfigurationData {
  customName: string;
  fileName: string;
  acceptLabel: string;
  acceptCallback: (opt: DeviceConfigurationAccept) => Observable<unknown>;
  labelCallback: (query: string) => Observable<string[]>;
  labels: string[];
}

@Component({
  selector: 'app-configuration-upload-dialog',
  templateUrl: './device-configuration-upload.component.html',
  styleUrls: ['./device-configuration-upload.component.scss'],
  standalone: true,
  imports: [
    LabelPickerComponent,
    InputTextModule,
    FormsModule,
    ButtonModule,
    TranslateModule,
    ProgressBarModule,
  ],
})
export class DeviceConfigurationUploadComponent {
  allowNewLabels = inject(ENVIRONMENT).envSpecificFeatures.allowNewConfigLabels;
  customName: string;
  fileName: string;
  acceptLabel: string;
  acceptCallback?: (opt: DeviceConfigurationAccept) => Observable<unknown>;
  labelCallback?: (query: string) => Observable<string[]>;
  disableControls = false;
  labels: string[];
  suggestions: string[] = [];

  private labelSubscription?: Subscription;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<DeviceConfigurationData>,
    private blockUiService: BlockUiService,
  ) {
    this.config.dismissableMask = true;
    const data = this.config.data;
    if (data) {
      this.fileName = data.fileName;
      this.acceptLabel = data.acceptLabel;
      this.labels = data.labels;
      this.acceptCallback = data.acceptCallback;
      this.labelCallback = data.labelCallback;
      this.customName = data.customName;
    }
  }

  cancel() {
    this.ref.close();
  }

  suggestLabels(query: string) {
    if (this.labelCallback) {
      if (this.labelSubscription) {
        this.labelSubscription.unsubscribe();
      }
      this.labelSubscription = this.labelCallback(query)
        .pipe(
          finalize(() => {
            this.labelSubscription = undefined;
          }),
        )
        .subscribe({
          next: suggestions => (this.suggestions = suggestions),
          error: () => (this.suggestions = []),
        });
    }
  }

  accept() {
    if (!this.acceptCallback) {
      return;
    }
    this.disableControls = true;
    this.blockUiService.block();
    this.acceptCallback({
      customName: this.customName,
      labels: this.labels,
    })
      .pipe(
        finalize(() => {
          this.disableControls = false;
          this.blockUiService.unblock();
          this.ref.close();
        }),
      )
      .subscribe();
  }
}
