import { UserConfigurationService } from './../services/user-configuration.service';
import { UserConfiguration } from '@assethub/shared/models';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  GuardResult,
  MaybeAsync,
} from '@angular/router';
import { RecentAssetService, NavigationSlot } from '../services/recent-asset.service';
import { TreeService } from '../services/tree.service';
import { AssetItem } from '../models';
import { Logger, pathFromRoutingTemplate } from '@assethub/shared/utils';

/**
 * This guard does:
 *
 * a) When a section is browsed to (eg. /asset) w/o a uuid, it checks the RoutingService whether a uuid
 *    had been browsed to before and navigates there.
 *    This helps with browsing from eg. /asset to /liveconnect via the top navigation bar, while keeping
 *    the respective current asset 'open'.
 *
 * b) When navigating to a section the first time in a session, when no asset had been selected, select
 *    just any, so the regular page can be displayed.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class NavigateToAssetGuard implements CanActivate {
  private logger = new Logger(this.constructor.name);
  private userConfig: UserConfiguration;

  constructor(
    private recentAssetService: RecentAssetService,
    private router: Router,
    private treeService: TreeService,
    private userConfigService: UserConfigurationService,
  ) {
    this.userConfigService.config().subscribe(config => (this.userConfig = config));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    try {
      return this.router.createUrlTree(
        pathFromRoutingTemplate(route, {
          uuid: () => this.recentAsset()?.uuid,
          rootUuid: () => this.recentAsset()?.root.uuid,
          serviceRootUuid: () => this.recentServiceAsset()?.root.uuid,
          view: () => this.recentAssetService.getActiveView() || 'asset-management',
          path: route.routeConfig?.path,
        }),
      );
    } catch (ex) {
      return this.router.createUrlTree(['/welcome']);
    }
  }

  private recentAsset(): AssetItem | undefined {
    let node: AssetItem | undefined;
    const uuid = this.recentAssetService.getActiveAssetUuid(NavigationSlot.ASSET);
    if (uuid) {
      node = this.treeService.findNode(uuid);
      if (!node) {
        this.logger.warn('Identified asset', uuid, 'as active, but it has disappeared');
      }
    }
    // If treeId from last selected tree is stored in userConfig, open this tree
    if (!node && this.userConfig.lastTreeOpenedId) {
      node = this.treeService.findNode(this.userConfig.lastTreeOpenedId);
    }
    // If no treeId or node is available, the user has never visited an asset in any section; then, send him
    // to any root node.
    if (!node) {
      node = this.treeService.rootNodes[0];
    }
    // Do not navigate to a virtual root node because it is not readable for the user and is just there
    // to allow the various shared parts to have a common root.
    return node?.virtual ? node.children[0] : node;
  }

  private recentServiceAsset(): AssetItem | undefined {
    let node: AssetItem | undefined;
    // Send user to most recently visited tree with service information or first available tree
    // with service information or welcome page.
    const recentUuid = this.recentAssetService.getActiveAssetUuid(NavigationSlot.SERVICEMODULE);
    if (recentUuid) {
      node = this.treeService.findNode(recentUuid);
    }
    if (!node?.root.crm365) {
      node = this.treeService.rootNodes.find(x => x.crm365);
    }
    return node?.virtual ? node.children[0] : node;
  }
}
