<div id="sick-footer">
  <a href="https://www.sick.com/imprint" target="_blank" rel="noopener">{{
    'footer.imprint' | translate
  }}</a>
  <a href="https://www.sick.com/tac" target="_blank" rel="noopener">{{
    'footer.terms-and-conditions' | translate
  }}</a>
  <a href="https://www.sick.com/terms-of-use" target="_blank" rel="noopener">{{
    'footer.terms-of-use' | translate
  }}</a>
  <a href="https://www.sick.com/dataprotection" target="_blank" rel="noopener">{{
    'footer.data-protection' | translate
  }}</a>
  <a class="copyright">© {{ copyrightYear }} SICK AG</a>
</div>
