import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Logger } from '@assethub/shared/utils';
import { LicensingService } from '../services/licensing.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseGuard implements CanActivate {
  private logger = new Logger(this.constructor.name);

  constructor(
    private router: Router,
    private licensingService: LicensingService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const hasLicense = this.licensingService.hasLicense(route.data.license);
    this.logger.debug('Has license %s?', route.data.license, hasLicense);

    if (!hasLicense) {
      return this.router.parseUrl(`/error/subscription/${route.data.license}`);
    }

    return true;
  }
}
