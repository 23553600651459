import debug from 'debug';
import { environment } from '@environments/environment';

export class Logger {
  public debug: (...args: unknown[]) => unknown;
  public info: (...args: unknown[]) => unknown;
  public warn: (...args: unknown[]) => unknown;
  public error: (...args: unknown[]) => unknown;

  constructor(namespace: string) {
    const scope = debug('AssetHub').extend(namespace);

    // Create log level methods
    const levels = Logger.generateLevels(scope);
    this.debug = levels.debug;
    this.info = levels.info;
    this.warn = levels.warn;
    this.error = levels.error;
  }

  private static generateLevels(scope: debug.Debugger) {
    return {
      debug: scope.extend('debug'),
      info: scope.extend('info'),
      warn: scope.extend('warn'),
      error: scope.extend('error'),
    };
  }

  static {
    if (localStorage.debug === undefined) {
      localStorage.debug = environment.debug;
    }
  }
}
