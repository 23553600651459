import { Component, Input, TemplateRef } from '@angular/core';
import { SplitPanelService } from '@assethub/shared/services/split-panel.service';
import { AngularSplitModule } from 'angular-split';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, AngularSplitModule],
})
export class SplitViewComponent {
  @Input()
  headline?: TemplateRef<any>;

  @Input()
  leftPane?: TemplateRef<any>;

  @Input()
  rightPane?: TemplateRef<any>;

  constructor(private splitPanelService: SplitPanelService) {
    this.splitPanelService.setPanelSizesFromLocalStorage();
  }

  public updateGutterSize(): number {
    return this.splitPanelService.updateGutterSize();
  }

  onDragStart($event: any): void {
    this.splitPanelService.onDragStart($event);
  }

  onDragEnd($event: any): void {
    this.splitPanelService.onDragEnd($event);
  }

  onGutterClick(): void {
    this.splitPanelService.onGutterClick();
  }

  public get sizeLeft(): number {
    return this.splitPanelService.sizeLeft;
  }

  public get sizeRight(): number {
    return this.splitPanelService.sizeRight;
  }
}
