export interface AssetImage {
  assetUuid?: string;
  uuid: string;
  name?: string;
}

export interface AssetImages {
  uuid?: string;
  name?: string;
  normal?: string;
  small?: string;
  zoomed?: string;
  isProfilePicture?: boolean;
  selected?: boolean;
  ready: boolean;
}

export interface AssetImageMetadata {
  uuid: string;
  assetUuid: string;
  name: string;
}
