import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';

/**
 *
 * @example
 * ```html
 * <app-file-chooser
 *   label="Upload Image"
 *   icon="pi pi-plus"
 *   dropIcon="pi pi-file-import"
 *   deniedIcon="pi pi-ban"
 *   accept=".png"
 *   buttonClass="ui-button-outline"
 *   [disabled]="false"
 *   [multiple]="true"
 * </app-file-chooser>
 * ```
 */
@Component({
  selector: 'app-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss'],
  standalone: true,
  imports: [FileUploadModule, ButtonModule],
})
export class FileChooserComponent {
  private logger = new Logger(this.constructor.name);

  @Input() label: string;
  @Input() icon = 'pi pi-cloud-upload';
  @Input() dropIcon = 'pi pi-file-import';
  @Input() deniedIcon = 'pi pi-ban';
  @Input() accept: string;
  @Input() buttonClass = 'ui-button-outline';
  @Input() disabled = false;
  @Input() multiple = true;

  @Output() filesChanged = new EventEmitter<File[] | null>();

  @HostBinding('class.dropAllowed')
  dropAllowed: boolean;

  @HostBinding('class.dropDenied')
  dropDenied: boolean;

  @ViewChild('fileUpload')
  private fileUpload: FileUpload;

  constructor(private messageService: MessageService) {}

  get currentIcon(): string {
    if (this.dropDenied) {
      return this.deniedIcon;
    }
    if (this.dropAllowed) {
      return this.dropIcon;
    }
    return this.icon;
  }

  onSelect(event: any) {
    if (event.files && event.files instanceof FileList) {
      this.handleFiles(event.files);
    } else {
      this.logger.warn('Event without files or wrong type');
    }
  }

  onClick() {
    this.fileUpload.clear();
    this.fileUpload.choose();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.fileUpload.clear();

    this.dropAllowed = this.isDropAllowed(event);
    this.dropDenied = !this.dropAllowed;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dropAllowed = this.isDropAllowed(event);
    this.dropDenied = !this.dropAllowed;
  }

  onDragLeave(event: DragEvent) {
    this.dropAllowed = false;
    this.dropDenied = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dropAllowed = false;
    this.dropDenied = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.handleFiles(event.dataTransfer.files);
    } else {
      this.logger.warn('Event without .dataTransfer.files');
    }
  }

  private isDropAllowed(event: DragEvent) {
    return (
      event.dataTransfer !== null &&
      ((event.dataTransfer.items.length === 1 && !this.multiple) ||
        (event.dataTransfer.items.length >= 1 && this.multiple))
    );
  }

  private handleFiles(srcFiles: FileList) {
    const dstFiles: Array<File> = [];
    for (let i = 0; i < srcFiles.length; ++i) {
      const file = srcFiles[i];
      if (this.fileUpload.validate(file)) {
        dstFiles.push(file);
      }
    }

    if (srcFiles.length > 1 && !this.multiple) {
      this.messageService.add({
        severity: 'error',
        summary: 'toasts.file-chooser.only-one-file-allowed',
        life: 5000,
      });
      return;
    }

    if (srcFiles.length !== dstFiles.length) {
      if (srcFiles.length === 1) {
        this.messageService.add({
          severity: 'error',
          summary: 'toasts.file-chooser.invalid-file-dropped',
          life: 5000,
          data: { accept: this.accept },
        });
        return;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'toasts.file-chooser.invalid-files-dropped',
          life: 5000,
          data: { accept: this.accept },
        });
        return;
      }
    }

    this.logger.debug(`emitting ${dstFiles.length} files...`);
    this.filesChanged.emit(dstFiles);
  }
}
