<div class="trash">
  <app-navigation-spinner></app-navigation-spinner>
  <h4 class="header">{{ 'trash.header.finalDelete' | translate }}</h4>
  <div class="scroll">
    <p-table
      [value]="trashList.items"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
      [loading]="trashList.loading"
      [lazyLoadOnInit]="true"
      [sortField]="trashList.sortField"
      [sortOrder]="trashList.sortOrder"
      [tableStyle]="{ width: 'auto' }"
      breakpoint="0"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <app-table-header label="{{ 'trash.list.rootName' | translate }}"></app-table-header>
          </th>
          <th>
            <app-table-header label="{{ 'trash.list.assetName' | translate }}"></app-table-header>
          </th>
          <th>
            <app-table-header label="{{ 'trash.list.assetType' | translate }}"></app-table-header>
          </th>
          <th>
            <app-table-header
              label="{{ 'trash.list.deletedAt' | translate }}"
              field="deletedAt"
              type="date"
              [filter]="false"
            ></app-table-header>
          </th>
          <th>
            <app-table-header
              label="{{ 'trash.list.remainingDays' | translate }}"
            ></app-table-header>
          </th>
          <th>
            <app-table-header label="{{ 'trash.list.assetCount' | translate }}"></app-table-header>
          </th>
          <th>
            <app-table-header label="{{ 'trash.list.action' | translate }}"></app-table-header>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td>
            {{
              row.rootName
                ? row.rootName
                : row.rootType
                  ? ('assetTypes.' + getTypeString(row.rootType) | translate)
                  : ' - '
            }}
          </td>
          <td>{{ row.name ? row.name : ('assetTypes.' + getTypeString(row.type) | translate) }}</td>
          <td>
            <div class="assetType">
              <app-icon [shape]="'assets/images/' + getTypeString(row.type) + '.svg'"></app-icon>
              {{ 'assetTypes.' + getTypeString(row.type) | translate }}
            </div>
          </td>
          <td>{{ row.deletedAt | localizedDate: 'mediumDate' }}</td>
          <td>{{ getRemainingDays(row.purgeAt) }}</td>
          <td>{{ row.affectedAssets }}</td>
          <td class="buttons">
            <button
              pButton
              type="button"
              class="ui-button-bare-neutral"
              icon="pi pi-restore-from-trash"
              [title]="'trash.list.restore' | translate"
              (click)="doRestoreCheck(row)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7" class="empty-table-message">
            <app-lazy-load-table-messages
              [listContext]="trashList"
              noDataMsg="{{ 'trash.list.noRecordsFound' | translate }}"
              nofilteredDataMsg="{{ 'trash.list.noFilterRecords' | translate }}"
              errorMsg="{{ 'trash.list.error' | translate }}"
            ></app-lazy-load-table-messages>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div></div>
  </div>
</div>
