import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { OutsideActionDirective } from '../../directives/action-outside.directive';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [OutsideActionDirective, SliderModule, FormsModule],
})
export class SliderComponent {
  @Input() public min = 0;
  @Input() public max = 100;
  @Input() public step = 1;
  @Input() public value = 0;

  displayTooltip: 'block' | 'none' = 'none';
  top: number;
  left: number;

  @Output() valueChange = new EventEmitter<number>();

  constructor(private element: ElementRef<HTMLElement>) {}

  showTooltip() {
    this.displayTooltip = 'block';
    const handle = this.element.nativeElement.getElementsByClassName(
      'p-slider-handle',
    )[0] as HTMLElement;
    setTimeout(() => {
      const rect = handle.getBoundingClientRect();
      this.top = rect.y + rect.height / 2 - 60;
      this.left = rect.x + rect.width / 2 - 45 / 2;
      this.valueChange.emit(this.value);
    }, 0);
  }

  suppressTooltip() {
    this.displayTooltip = 'none';
  }
}
