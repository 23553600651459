<div
  class="schematic-pin"
  (click)="onPinClicked()"
  (appOutsideAction)="unclickPin()"
  [ngClass]="{ active }"
>
  <div class="composition">
    <div class="inner shadow"></div>
    <div class="inner marker">
      <div class="header">
        <div [innerHtml]="getPinHeaderSvg()"></div>
      </div>
      <div class="slot left" (click)="navigateToDetails()">
        <app-icon shape="pi pi-list"></app-icon>
      </div>
      <div class="slot top" (click)="navigateToMap()">
        <app-icon shape="map"></app-icon>
      </div>
    </div>
    <div class="inner circle">
      <app-asset-icon [asset]="assetInfo" size="30"></app-asset-icon>
    </div>
  </div>
</div>
