import { Component, Input, OnInit } from '@angular/core';
import { NavigationTrackingService } from '@assethub/shared/services';
import { Observable, debounceTime } from 'rxjs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-navigation-spinner',
  templateUrl: './navigation-spinner.component.html',
  styleUrls: ['./navigation-spinner.component.scss'],
  standalone: true,
  imports: [NgIf, ProgressSpinnerModule, AsyncPipe],
})
export class NavigationSpinnerComponent implements OnInit {
  @Input() externalTrigger = false;
  @Input() disabled = false;

  public active$: Observable<boolean>;

  constructor(private navigationTrackingService: NavigationTrackingService) {}

  ngOnInit(): void {
    this.active$ = this.navigationTrackingService.navigating$.pipe(debounceTime(500));
  }
}
