import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, isObservable } from 'rxjs';
import { BlockUiService } from '@assethub/shared/services/block-ui-service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  standalone: true,
  imports: [ButtonModule],
})
export class ConfirmationModalComponent {
  message = '';
  acceptButton = '';
  rejectButton = '';
  disableControls = false;

  private accept?: () => unknown;
  private reject?: () => unknown;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private blockUiService: BlockUiService,
    translateService: TranslateService,
  ) {
    const data = this.config.data;
    if (data) {
      const translation = data.translation;
      if (translation) {
        this.message = translation.message || '';
        this.acceptButton = translation.acceptButton || translateService.instant('buttons.okay');
        this.rejectButton = translation.rejectButton || translateService.instant('buttons.cancel');
      }
      this.accept = data.accept;
      this.reject = data.reject;
    }
  }

  onAccept(): void {
    this.processResult(this.accept);
  }

  onReject(): void {
    this.processResult(this.reject);
  }

  private processResult(callback?: () => unknown): void {
    if (callback) {
      this.disableControls = true;
      this.blockUiService.block();
      const result = callback();
      if (isObservable(result)) {
        result.pipe(finalize(() => this.unblockAndClose())).subscribe();
        return;
      }
      Promise.resolve(result).finally(() => {
        this.unblockAndClose();
      });
      return;
    }
    this.ref.close();
  }

  private unblockAndClose() {
    this.blockUiService.unblock();
    this.ref.close();
  }
}
