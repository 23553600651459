<div *ngIf="statusList.length" class="status-container">
  <div *ngFor="let status of statusList">
    <div style="display: flex">
      @switch (status.state) {
        @case ('pending') {
          <p-progressSpinner strokeWidth="4"></p-progressSpinner>
        }
        @case ('error') {
          <app-icon size="22px" shape="pi-times-circle" class="error"></app-icon>
        }
        @case ('success') {
          <app-icon size="22px" shape="pi-check" class="success"></app-icon>
        }
      }
      <span style="margin-left: 5px">
        {{ status.messageTranslateKey | translate }}
      </span>
    </div>
    @if (status.progress !== undefined) {
      <p-progressBar [value]="status.progress"></p-progressBar>
    }
  </div>
</div>
