import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrimeNGConfig } from 'primeng/api';
import { LanguageService } from '@assethub/shared/services';
import { CalendarModule } from 'primeng/calendar';

type TouchFn = () => void;
type ChangeFn = (val: Date) => void;

@UntilDestroy()
@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateFieldComponent), multi: true },
  ],
  standalone: true,
  imports: [CalendarModule, FormsModule],
})
export class DateFieldComponent implements ControlValueAccessor, OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() disabled = false;
  @Output() blurred: EventEmitter<boolean> = new EventEmitter();

  placeholder = '';

  private internalValue: Date;
  private onTouch?: TouchFn;
  private onChange?: ChangeFn;

  get contents(): Date {
    return this.internalValue;
  }

  set contents(val: Date) {
    this.internalValue = val;
    if (this.onChange) {
      this.onChange(val);
    }
  }

  constructor(
    private config: PrimeNGConfig,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    // DateFormat of Calendar is
    // d (dd): day of month (with leading zeros)
    // D (DD): name of weekday short (long)
    // m (mm): number of month (with leading zeros)
    // M (MM): name of month short (long)
    // y: last two digits of year
    // yy: full year
    // o (oo): day of year (with leading zeros)
    this.languageService
      .translate('datePicker')
      .pipe(untilDestroyed(this))
      .subscribe(t => {
        this.config.setTranslation(t);
        this.placeholder = t['placeholder'];
        // force update of calendar
        if (this.internalValue) {
          this.writeValue(new Date(this.internalValue));
        }
      });
  }

  writeValue(val: Date): void {
    if (val !== this.internalValue) {
      this.internalValue = val;
    }
  }

  registerOnChange(fn: ChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: TouchFn): void {
    this.onTouch = fn;
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }
    this.blurred.emit(true);
  }
}
