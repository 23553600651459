import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, QueryFilter } from '../models';
import { PagedDeviceData } from '../models/device';

@Injectable()
export class DeviceService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  public loadChildDevices(
    parentAssetUuid: string,
    queryFilters: QueryFilter,
  ): Observable<PagedDeviceData> {
    return this.httpClient.get<PagedDeviceData>(
      this.apiUrl + `/asset/${parentAssetUuid}/child-devices`,
      {
        params: { ...queryFilters },
      },
    );
  }
}
