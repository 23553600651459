import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class DividerComponent {
  @Input()
  public label = '';
  @Input() public padding = '10px';
}
