import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, effect, input, output, Signal, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  AssetProfilePicture,
  AssetService,
  ImageService,
  ProfilePictureService,
} from '@assethub/shared/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { saveAs } from 'file-saver';
import { MediaPlaceholderComponent } from '../media-placeholder/media-placeholder.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { GetAssetResponse } from '../../models';

@Component({
  selector: 'app-media-custom-image',
  templateUrl: './media-custom-image.component.html',
  styleUrls: ['./media-custom-image.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgStyle,
    NgClass,
    DialogModule,
    ButtonModule,
    MenuComponent,
    MenuItemComponent,
    MediaPlaceholderComponent,
  ],
})
export class MediaCustomImageComponent {
  readonly display = input<'preview' | 'full'>('preview');
  readonly asset = input.required<GetAssetResponse>();
  readonly imageUuid = input.required<string>();
  readonly filename = input<string>();
  readonly writable = input(true);
  readonly selectable = input(true);
  readonly uploading = input(false);

  readonly selected = output();

  readonly loading = signal(false);
  readonly error = signal(false);
  readonly displayUrl = signal('');
  readonly showDeletePrompt = signal(false);
  readonly inDeletion = signal(false);
  readonly isCurrentProfilePicture = computed(() => this.computeIsCurrentProfilePicture());
  readonly defaultProfilePictureActive = computed(() => this.computeDefaultProfilePictureActive());
  readonly previewStyle = computed(() => ({
    'background-image': `url(${this.displayUrl()})`,
  }));
  readonly overlayShape = computed(() =>
    this.uploading() ? 'upload' : this.inDeletion() ? 'delete' : '',
  );

  private readonly profilePictureChanged: Signal<AssetProfilePicture | undefined>;

  constructor(
    private profilePictureService: ProfilePictureService,
    private imageService: ImageService,
    private assetService: AssetService,
  ) {
    this.profilePictureChanged = toSignal(profilePictureService.profilePictureChanged);
    effect(
      onCleanup => {
        const imageUuid = this.imageUuid();
        const displayUrl =
          this.display() === 'preview'
            ? imageService.proxyImageThumbnail(imageUuid)
            : imageService.proxyImageObservable(imageUuid);
        this.error.set(false);
        this.loading.set(true);
        const subscription = displayUrl.subscribe({
          next: url => {
            this.displayUrl.set(url);
            this.inDeletion.set(false);
            this.loading.set(false);
          },
          error: () => {
            this.error.set(true);
            this.loading.set(false);
          },
        });
        onCleanup(() => subscription.closed || subscription.unsubscribe());
      },
      { allowSignalWrites: true },
    );
  }

  private computeIsCurrentProfilePicture(): boolean {
    const asset = this.asset();
    const imageUuid = this.imageUuid();
    const profilePictureChanged = this.profilePictureChanged();
    if (!profilePictureChanged || profilePictureChanged.assetUuid !== asset.uuid) {
      return asset.profilePicture?.uuid === imageUuid;
    }
    return profilePictureChanged.picture.uuid === imageUuid;
  }

  private computeDefaultProfilePictureActive(): boolean {
    const asset = this.asset();
    const profilePictureChanged = this.profilePictureChanged();
    if (!profilePictureChanged || profilePictureChanged.assetUuid !== asset.uuid) {
      return !asset.profilePicture?.uuid;
    }
    return !profilePictureChanged.picture.uuid;
  }

  onClick(): void {
    if (!this.selectable()) {
      return;
    }
    this.selected.emit();
  }

  onDownload(): void {
    this.imageService.proxyImageObservable(this.imageUuid()).subscribe({
      next: url => saveAs(url, this.filename()),
    });
  }

  onDelete(): void {
    this.showDeletePrompt.set(true);
  }

  confirmDeletion(): void {
    this.showDeletePrompt.set(false);
    this.inDeletion.set(true);
    if (this.isCurrentProfilePicture()) {
      const asset = this.asset();
      asset.profilePicture = undefined;
      this.profilePictureService.deleteProfilePicture(asset).subscribe({
        next: () => this.assetService.deleteCustomImage(this.asset().uuid, this.imageUuid()),
      });
    } else {
      this.assetService.deleteCustomImage(this.asset().uuid, this.imageUuid());
    }
  }

  onUseAsProfilePicture(): void {
    if (this.isCurrentProfilePicture()) {
      return;
    }
    this.profilePictureService
      .setProfilePicture(this.asset(), { uuid: this.imageUuid() })
      .subscribe();
  }

  onUseDefaultProfilePicture(): void {
    if (this.defaultProfilePictureActive()) {
      return;
    }
    const asset = this.asset();
    asset.profilePicture = undefined;
    this.profilePictureService.deleteProfilePicture(asset).subscribe();
  }
}
