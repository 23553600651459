import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Tag } from '@assethub/shared/models';
import { Chip, ChipModule } from 'primeng/chip';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ResizedDirective } from '../../directives/resized/resized.directive';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ResizedDirective, NgFor, ChipModule, ButtonModule, TranslateModule],
})
export class TagComponent implements OnChanges {
  @Input()
  public tags: Tag[];

  @Input()
  public title: string;

  @Input()
  public maxRowsToDisplay = 2;

  @Input()
  public maxHeightPx = 400;

  @Input()
  public expandLabel: string | undefined;

  @Input()
  public collapseLabel: string | undefined;

  public currHeightPx = 0;

  public currentTagRows = 0;

  public expanded = false;

  public narrow = false;

  @Output()
  public tagRemoved: EventEmitter<Tag> = new EventEmitter();

  @ViewChild('tagContainer', { read: ElementRef, static: false })
  private tagContainerRef?: ElementRef;

  @ViewChild(Chip, { read: ElementRef })
  chip?: ElementRef<HTMLElement>;

  @ViewChild('scroller', { read: ElementRef })
  scroller: ElementRef<HTMLElement>;

  constructor(private componentContainer: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    const tags = changes.tags?.currentValue;
    if (tags && !tags.length) {
      // Tags removed from outside (e.g. asset filter components)
      this.resetTagView();
    }
  }

  deleteTag(tag: Tag) {
    this.tags = this.tags.filter(item => item !== tag); // remove deleted tag from array
    this.tagRemoved.emit(tag);
  }

  clearAll() {
    this.tags.forEach(tag => {
      this.deleteTag(tag);
    });

    if (!this.tags.length) {
      this.resetTagView();
    }
  }

  expandTags() {
    this.expanded = true;
    this.resizeTagView();
  }

  collapseTags() {
    this.expanded = false;
    this.resizeTagView();
    this.scroller.nativeElement.scroll({ top: 0 });
  }

  maxTagRowsExceeded() {
    return this.currentTagRows > this.maxRowsToDisplay;
  }

  private resetTagView() {
    this.currHeightPx = 0;
    this.currentTagRows = 0;
    this.expanded = false;
  }

  resizeTagView() {
    if (this.tagContainerRef && this.chip) {
      const rectHeight = this.tagContainerRef.nativeElement.getBoundingClientRect().height;
      const chipHeight =
        this.chip.nativeElement.getBoundingClientRect().height + 10; /* margin bottom */
      this.currentTagRows = Math.round(rectHeight / chipHeight);

      if (!this.maxTagRowsExceeded()) {
        this.currHeightPx = this.currentTagRows * chipHeight;
      } else if (!this.expanded) {
        this.currHeightPx = this.maxRowsToDisplay * chipHeight;
      } else {
        this.currHeightPx = rectHeight <= this.maxHeightPx ? rectHeight : this.maxHeightPx;
      }
    }
    // force the scroll axes to recalculate size (prevents showing scrollbar with too less height until hovering it)
    const componentRect = this.componentContainer?.nativeElement.getBoundingClientRect();
    this.narrow = componentRect?.width < 750;
  }
}
