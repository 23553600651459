export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface QueryFilter {
  start?: number; // Deprecated
  offset?: number;
  size?: number;
  orderBy?: string;
  sortDirection?: SortOrder;
}
