import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, computed, effect, input, output, signal } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { MediaPlaceholderComponent } from '../media-placeholder/media-placeholder.component';

@Component({
  selector: 'app-media-video',
  templateUrl: './media-video.component.html',
  styleUrls: ['./media-video.component.scss'],
  standalone: true,
  imports: [NgStyle, IconComponent, MediaPlaceholderComponent],
})
export class MediaVideoComponent implements AfterViewInit {
  readonly display = input<'preview' | 'full'>('preview');
  readonly videoId = input.required<string>();
  readonly error = signal(false);
  readonly previewUrl = computed(() => `https://img.youtube.com/vi/${this.videoId()}/default.jpg`);

  readonly selected = output();

  readonly loading = signal(true);
  readonly SCRIPT_ID = 'sick-ah-yt-player';
  readonly PLAYER_ID = 'app-media-video-player';

  private youTubePlayer: any;

  constructor() {
    effect(() => {
      const videoId = this.videoId();
      const display = this.display();
      if (display === 'full' && this.youTubePlayer) {
        this.youTubePlayer.cueVideoById(videoId);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.display() !== 'full') {
      return;
    }
    window.setTimeout(() => {
      const doc = window.document;
      if (doc.getElementById(this.SCRIPT_ID)) {
        this.setupYouTubePlayer();
      } else {
        // https://stackoverflow.com/questions/36467532/use-youtube-iframe-api-with-angular2-and-typescript
        const playerApiScript: HTMLScriptElement = doc.createElement('script');
        playerApiScript.id = this.SCRIPT_ID;
        playerApiScript.type = 'text/javascript';
        playerApiScript.src = 'https://www.youtube.com/iframe_api';

        doc.body.appendChild(playerApiScript);

        (window as any).onYouTubeIframeAPIReady = () => {
          this.setupYouTubePlayer();
        };
      }
    }, 0);
  }

  onClick(event: MouseEvent): void {
    this.selected.emit();
  }

  private setupYouTubePlayer() {
    const videoId = this.videoId();
    if (!videoId) {
      return;
    }

    if (!(window as any).YT) {
      return;
    }

    if (!document.getElementById(this.PLAYER_ID)) {
      return;
    }

    if (this.youTubePlayer) {
      return;
    }

    this.youTubePlayer = new (window as any).YT.Player(this.PLAYER_ID, {
      height: '100%',
      width: '100%',
      videoId,
      playerVars: { autoplay: 0, rel: 0, controls: 2 },
      events: {},
    });
  }
}
