import { NgClass, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, effect, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AssetSearchResultItem, AssetTypeMapping, ENVIRONMENT } from '@assethub/shared/models';
import {
  AVAILABLE_FIRMWARE_STATES,
  FIRMWARE_STATE_FILTER_ICON,
  FirmwareState,
} from '@assethub/shared/models/firmware-state';
import { MultiSelectItem } from '@assethub/shared/models/multiselect';
import { LazyLoadResponse, LazyLoadTable } from '@assethub/shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { AssetChipComponent } from '../asset-chip/asset-chip.component';
import { CalloutComponent } from '../callout/callout.component';
import { FirmwareStateIconComponent } from '../firmware-state-icon/firmware-state-icon.component';
import { TableBaseComponent, TableColumn } from '../table-base/table-base.component';

interface DeviceChild extends AssetSearchResultItem {
  firmwareVersion?: string;
  firmwareState?: FirmwareState;
}

type DeviceChildColumn = keyof DeviceChild;

@UntilDestroy()
@Component({
  selector: 'app-device-children',
  templateUrl: './device-children.component.html',
  styleUrls: ['./device-children.component.scss'],
  standalone: true,
  imports: [
    TableModule,
    NgClass,
    SharedModule,
    NgIf,
    MultiSelectModule,
    FormsModule,
    FirmwareStateIconComponent,
    AssetChipComponent,
    CalloutComponent,
    TranslateModule,
    TableBaseComponent,
  ],
})
export class DeviceChildrenComponent {
  readonly parentAssetUuid = input.required<string>();

  envFeatures = inject(ENVIRONMENT).envSpecificFeatures;
  private apiUrl = inject(ENVIRONMENT).apiUrl;
  list: LazyLoadTable<DeviceChild>;
  columns: TableColumn<DeviceChildColumn>[] = this.getColumns();

  public constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
  ) {
    effect(() => {
      this.parentAssetUuid();
      this.list.onLazyLoad({});
    });
    this.list = new LazyLoadTable<DeviceChild>(
      x => this.requestDeviceChildren(x),
      'productName',
      1,
      10,
    );
  }

  private getColumns() {
    const columns: TableColumn<DeviceChildColumn>[] = [
      {
        field: 'productName',
        label: 'asset.product-name',
        type: 'string',
        width: '30%',
      },
      {
        field: 'customName',
        label: 'asset.title',
        type: 'string',
        width: '30%',
        essential: true,
      },
      {
        field: 'firmwareVersion',
        label: 'asset.firmware.version',
        type: 'unsortable',
        width: '20%',
      },
    ];
    if (this.envFeatures.softwareState) {
      columns.splice(3, 0, {
        field: 'firmwareState',
        label: 'asset.firmware.state.title',
        type: 'unsortable',
        filter: AVAILABLE_FIRMWARE_STATES.map(state => this.transformFilterToSelectItem(state)),
        width: '20%',
      });
    }

    return columns;
  }

  private transformFilterToSelectItem(state: FirmwareState): MultiSelectItem {
    const icon = FIRMWARE_STATE_FILTER_ICON[state];
    return {
      value: state,
      icon: icon.shape,
      iconColor: icon.color,
      displayLabel: this.translateService.instant(icon.label),
    };
  }

  private requestDeviceChildren(params: { [k: string]: string }): LazyLoadResponse<DeviceChild> {
    return this.httpClient.get<{ total: number; items: DeviceChild[] }>(
      `${this.apiUrl}/asset/${this.parentAssetUuid()}/descendants`,
      {
        params: {
          type: AssetTypeMapping.DEVICE + ',' + AssetTypeMapping.MACHINE_SYSTEM,
          ...params,
        },
      },
    );
  }
}
