import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AssetPermissions,
  GrantedPermission,
  Permission,
  PermissionEntry,
  UserPermissions,
  isPermissionNotification,
} from '@assethub/shared/models';
import { NotificationService, PermissionsService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { OAuthService } from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Subscription, filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsAddComponent } from '../../permissions-add/permissions-add.component';
import { PermissionsViewComponent } from '../../permissions-view/permissions-view.component';
import { CalloutComponent } from '../../callout/callout.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  standalone: true,
  imports: [TranslateModule, PermissionsAddComponent, PermissionsViewComponent, CalloutComponent],
})
export class PermissionsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() assetDetails: { uuid: string; permissions: string };
  @Input() narrow: boolean;

  @ViewChild('permissionAdd', { static: true }) private permissionAdd: PermissionsAddComponent;

  public readonly PAGE_SIZE = 10;

  public permissionEntries: PermissionEntry[] = [];
  public tableColPermissions: any[];

  public assetPermissions: AssetPermissions;
  public loading = false;
  public saving = false;
  public paginatorStart = 0;
  public totalRecords = 0;
  public error?: string;
  public errorSeverity: 'error' | 'info' = 'error';

  private subscriptions: Subscription[] = [];
  private logger = new Logger('PermissionsComponent');

  constructor(
    private permissionService: PermissionsService,
    private oauth: OAuthService,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.assetDetails) {
      this.paginatorStart = 0;
      this.permissionAdd.clear();
      this.setError(undefined);
      this.getPermissions();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.notificationService.notification$
        .pipe(filter(notification => isPermissionNotification(notification)))
        .subscribe({
          next: (notification: { permission: Permission }) => {
            this.handleWsPermissionEvent(notification.permission);
          },
        }),
    );
  }

  private handleWsPermissionEvent(permissionEvent: Permission) {
    if (permissionEvent.account && permissionEvent.action === 'granted') {
      this.showToast('info', 'assetPermissions.info.grant-successful', {
        username: permissionEvent.account,
      });
    }
    if (permissionEvent.uuid !== this.assetDetails.uuid) {
      // reload permissions because of possible inherited permissions
      this.getPermissions();
      return;
    }
    if (
      !permissionEvent.account ||
      (!permissionEvent.permission && permissionEvent.action !== 'revoked')
    ) {
      // In case the account is missing, this user is targeted. Because of missing permissions info, reload is mandatory
      // If there is no permissions info defined, a reload is also mandatory, except permissions for user revoked
      this.getPermissions();
      return;
    }

    switch (permissionEvent.action) {
      case 'granted':
        if (permissionEvent.permission) {
          this.addPermissionEntry(permissionEvent.account, permissionEvent.permission);
        }
        break;
      case 'changed':
        if (permissionEvent.permission) {
          this.changePermissionEntry(permissionEvent.account, permissionEvent.permission);
        }
        break;
      case 'revoked':
        this.deletePermissionEntry(permissionEvent.account);
        break;
      default:
        throw Error('unknown permission action');
    }
  }

  public onDeletePermission(entry: PermissionEntry): void {
    this.saving = true;
    entry.pendingAction = true;
    this.permissionService.revokePermissionsForUser(this.assetDetails.uuid, entry.id).subscribe({
      next: () => {
        this.saving = false;
        this.resetError('assetPermissions.error.delete');
        this.deletePermissionEntry(entry.id);
      },
      error: error => {
        this.saving = false;
        entry.pendingAction = false;
        this.setError('assetPermissions.error.delete');
        this.logger.error(error);
      },
    });
  }

  public onLazyLoad(event: TableLazyLoadEvent) {
    this.paginatorStart = event.first || 0;
    this.getPermissions();
  }

  public getPermissions() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.permissionService
      .getPermissions(this.assetDetails.uuid, {
        start: this.paginatorStart,
        size: this.PAGE_SIZE,
      })
      .subscribe({
        next: p => {
          if (p.permissions.length === 0 && this.paginatorStart > 0) {
            // Load previous page if current page got empty (after last entry was deleted)
            this.paginatorStart = this.paginatorStart - this.PAGE_SIZE;
            this.getPermissions();
            return;
          }
          this.resetError('assetPermissions.error.get');
          this.permissionEntries = p.permissions.map(user => this.convert(user));
          this.totalRecords = p.total;
          this.loading = false;
        },
        error: error => {
          this.setError('assetPermissions.error.get');
          this.logger.error(error);
          this.loading = false;
        },
      });
  }

  private convert(user: GrantedPermission): PermissionEntry {
    return {
      label: user.username,
      id: user.username,
      admin: user.permissions.includes('a'),
      write: user.permissions.includes('w'),
      read: user.permissions.includes('r'),
      pAdmin: user.parentPermissions ? user.parentPermissions.includes('a') : false,
      pWrite: user.parentPermissions ? user.parentPermissions.includes('w') : false,
      pRead: user.parentPermissions ? user.parentPermissions.includes('r') : false,
      rightsInherited: user.inherited,
      deleteDisabled: user.parentPermissions !== undefined,
      pendingAction: false,
    };
  }

  private addPermissionEntry(username: string, permissions: string): void {
    if (this.totalRecords < this.PAGE_SIZE) {
      const entries = [
        ...this.permissionEntries,
        this.convert({ username, permissions, inherited: false }),
      ];
      entries.sort((a, b) => a.id.localeCompare(b.id));
      this.permissionEntries = entries;

      this.totalRecords++;
      return;
    }
    // position unknown -> reload
    this.getPermissions();
  }

  private changePermissionEntry(username: string, permissions: string) {
    const index = this.permissionEntries.findIndex(pe => pe.id === username);
    if (index >= 0) {
      this.permissionEntries[index].admin = permissions.includes('a');
      this.permissionEntries[index].write = permissions.includes('w');
      this.permissionEntries[index].read = permissions.includes('r');
      this.permissionEntries[index].pendingAction = false;
    }
  }

  private deletePermissionEntry(username: string) {
    const pageMaxCount = this.permissionEntries.length + this.paginatorStart;
    const pageModulo = pageMaxCount % this.PAGE_SIZE;

    if (
      pageModulo > 1 ||
      (pageModulo === 0 && this.totalRecords === pageMaxCount) ||
      (pageModulo === 1 && this.paginatorStart === 0)
    ) {
      // delete permission if no further entries (also from next page) need to be loaded
      // in case modulo === 0 and totalRecords > pageMaxIndex, the page need to be reloaded, which is not handled here
      // in case modulo === 1 and first page not displayed, the page need to be reloaded, which is not handled here
      this.permissionEntries = this.permissionEntries.filter(x => x.id !== username);
      this.totalRecords--;
      return;
    }
    if (pageModulo === 1 && this.paginatorStart > 0) {
      // is last entry of page -> load previous page
      // except first page -> no previous page to load
      this.paginatorStart = this.paginatorStart - this.PAGE_SIZE;
    }

    this.getPermissions();
  }

  public onNewPermission($event: UserPermissions) {
    const username = $event.username.trim().toLowerCase();
    if (this.isUserValid(username)) {
      this.setPermissions(username, $event.permissions);
    }
  }

  private isUserValid(username: string): boolean {
    const claims = this.oauth.getIdentityClaims();
    const login = claims['preferred_username'] || claims['email'];
    if (login === username) {
      this.permissionAdd.setError('assetPermissions.userIsOwner');
      return false;
    }
    if (username.length === 0) {
      this.permissionAdd.setError('assetPermissions.noUsername');
      return false;
    }
    this.permissionAdd.setError('');
    return true;
  }

  public onTogglePermissions(entry: PermissionEntry, toggled: 'admin' | 'write') {
    switch (toggled) {
      case 'admin':
        if (entry.admin) {
          entry.write = true;
        }
        break;
      case 'write':
        if (!entry.write) {
          entry.admin = false;
        }
        break;
    }
    entry.pendingAction = true;
    this.cdRef.detectChanges();
    this.setPermissions(entry.id, entry.admin ? 'rwa' : entry.write ? 'rw' : 'r', 'changed');
  }

  private setPermissions(
    username: string,
    permissions: string,
    action: 'changed' | 'granted' = 'granted',
  ) {
    this.saving = true;
    this.permissionService
      .grantPermissionsToUser(this.assetDetails.uuid, {
        username,
        permissions,
      })
      .subscribe({
        next: () => {
          this.saving = false;
          this.resetError('assetPermissions.error.conflict');
          this.resetError('assetPermissions.error.set');
          if (action === 'changed') {
            this.changePermissionEntry(username, permissions);
          } else {
            this.addPermissionEntry(username, permissions);
            this.permissionAdd.clear();
          }
        },
        error: error => {
          this.saving = false;
          if (error.status === 409) {
            this.setError('assetPermissions.error.conflict', 'info');
          } else {
            this.setError('assetPermissions.error.set');
          }
          this.logger.error(error.error?.message || error);
          this.getPermissions();
        },
      });
  }

  public setError(msg?: string, severity: 'error' | 'info' = 'error') {
    this.error = msg;
    this.errorSeverity = severity;
  }

  private resetError(msg: string) {
    if (this.error === msg) {
      this.error = undefined;
    }
  }

  private showToast(
    severity: 'error' | 'info' | 'success',
    summaryLabel: string,
    data?: { [key: string]: string },
  ) {
    this.messageService.add({
      severity,
      summary: summaryLabel,
      life: 5000,
      data: data,
    });
  }
}
