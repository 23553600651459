import { NgClass, NgStyle } from '@angular/common';
import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  standalone: true,
  imports: [FormsModule, InputTextareaModule, NgStyle, NgClass],
})
export class TexAreaComponent {
  value = model('');
  disabled = input(false);
  height = input('10em');
  placeholder = input('');
  label = input('');
  required = input(false);
}
