import { Injectable } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { TreeService } from './tree.service';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UserConfigurationService } from './user-configuration.service';
import { UserConfiguration } from '@assethub/shared/models';

export enum NavigationSlot {
  ASSET = 'asset',
  SERVICEMODULE = 'maintenance',
  DASHBOARD = 'dashboard',
}

@Injectable({ providedIn: 'root' })
export class RecentAssetService {
  private logger = new Logger(this.constructor.name);
  private assetStore: { [key: string]: string } = {};
  private activeView: string | undefined = undefined;
  private config?: UserConfiguration;

  constructor(
    private treeService: TreeService,
    private router: Router,
    private userConfigurationService: UserConfigurationService,
  ) {}

  initialize() {
    // Yes, this code could be in the constructor but let us give the
    // initializer a chance to call something
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          this.handleNavigationEnd();
        }
      },
    });
    this.userConfigurationService.config().subscribe({
      next: cfg => {
        this.config = cfg;
      },
    });
  }

  getActiveAssetUuid(slot: NavigationSlot): string | undefined {
    this.logger.debug('Last active asset on slot [%s] was %s', slot, this.assetStore[slot]);
    return this.assetStore[slot];
  }

  setActiveView(id: string | undefined) {
    this.activeView = id;
  }

  getActiveView(): string | undefined {
    return this.activeView;
  }

  private handleNavigationEnd() {
    let assetUuidKey = '';
    let assetUuid = '';
    for (
      let current: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;
      current;
      current = current.firstChild
    ) {
      if ('recentAssetFrom' in current.data) {
        assetUuidKey = current.data.recentAssetFrom;
      }
      if (assetUuidKey && assetUuidKey in current.params) {
        assetUuid = current.params[assetUuidKey];
      }
    }
    if (assetUuid) {
      this.logger.debug('Recent Asset set to %s', assetUuid);
      this.assetStore[NavigationSlot.ASSET] = assetUuid;
      // navigation starts after initialization has completed and resolvers make sure the targeted
      // node is loaded so it should be possible to find this node instantly
      const node = this.treeService.findNode(assetUuid);
      this.logger.debug('Found node %o', node);
      if (node && node.root.uuid !== this.config?.lastTreeOpenedId) {
        this.userConfigurationService.setTreeId(node.root.uuid);
      }
      if (node && node.root.crm365) {
        this.assetStore[NavigationSlot.SERVICEMODULE] = assetUuid;
        this.logger.debug('updated service-module slot');
      } else {
        this.logger.debug('not setting service-module slot');
      }
    }
  }
}
