import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum MapEditModes {
  EDIT_MAP = 1,
  EDIT_PIN = 2,
  EDIT_DISABLED = 3,
}

@Injectable({ providedIn: 'root' })
export class MapEditService {
  changeEditMode$: Observable<MapEditModes>;
  private changeEditModeSubject = new Subject<MapEditModes>();

  constructor() {
    this.changeEditMode$ = this.changeEditModeSubject.asObservable();
  }

  changeEditMode(editMode: MapEditModes) {
    this.changeEditModeSubject.next(editMode);
  }
}
