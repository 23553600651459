import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ProfilePictureService } from '@assethub/shared/services/profile-picture.service';
import { map, Observable, of } from 'rxjs';
import { ENVIRONMENT } from '../models';

interface BulkAssetColumns {
  name?: boolean;
  profilePicture?: boolean;
  details?: boolean;
  permissions?: boolean;
  location?: boolean;
}

interface BulkGetAssetRequest {
  action: 'get';
  uuid?: string[];
  extId?: string[];
  columns?: BulkAssetColumns;
}

export interface BulkAsset {
  uuid?: string;
  extId?: string;
  typeId?: number;
  customName?: string;
  profilePictureUuid?: string;
  details?: DeviceDetails | ShipDetails;
  permissions?: string;
  location?: {
    uuid: string;
    typeId: number;
    name: string;
  };
  tree?: {
    treeUuid?: string;
    rootUuid: string;
    parentUuid: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

export interface DeviceDetails {
  serialNumber: string;
  productName: string;
  partNumber: string;
  productPictureUrl?: string;
  vendor: string;
  firmwareVersion: string;
}

export interface ShipDetails {
  imo: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssethubBulkService {
  private cacheByExtId = new Map<string, BulkAsset>();
  private cacheByUuid = new Map<string, BulkAsset>();
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(
    private httpClient: HttpClient,
    profilePictureService: ProfilePictureService,
  ) {
    profilePictureService.profilePictureChanged.subscribe({
      next: event => {
        if (event) {
          const asset = this.cacheByUuid.get(event.assetUuid);
          if (asset) {
            asset.profilePictureUuid = event.picture.uuid;
          }
        }
      },
    });
  }
  getAssetDetailsByUuid(
    uuids: string[],
    columns: BulkAssetColumns = { name: true, profilePicture: true, details: true },
  ) {
    return this.getAssetDetails(uuids, undefined, columns);
  }

  getAssetDetailsByExtId(
    extIds: string[],
    columns: BulkAssetColumns = { name: true, profilePicture: true, details: true },
  ) {
    return this.getAssetDetails(undefined, extIds, columns);
  }

  private getAssetDetails(
    uuids?: string[],
    extIds?: string[],
    columns: BulkAssetColumns = { name: true, profilePicture: true, details: true },
  ): Observable<ReadonlyMap<string, Readonly<BulkAsset>>> {
    interface BulkGetAssetResponse {
      values: BulkAsset[];
    }
    const retval = new Map<string, BulkAsset>();
    const missingUuids: string[] = [];
    const missingExtIds: string[] = [];

    if (extIds !== undefined) {
      for (const extId of extIds) {
        const value = this.cacheByExtId.get(extId);
        if (value) {
          retval.set(extId, value);
        } else {
          missingExtIds.push(extId);
        }
      }
      if (missingExtIds.length === 0) {
        return of(retval);
      }
    }
    if (uuids !== undefined) {
      for (const uuid of uuids) {
        const value = this.cacheByUuid.get(uuid);
        if (value) {
          retval.set(uuid, value);
        } else {
          missingUuids.push(uuid);
        }
      }
      if (missingUuids.length === 0) {
        return of(retval);
      }
    }

    const req: BulkGetAssetRequest = {
      action: 'get',
      uuid: missingUuids.length > 0 ? missingUuids : undefined,
      extId: missingExtIds.length > 0 ? missingExtIds : undefined,
      columns,
    };

    return this.httpClient.post<BulkGetAssetResponse>(`${this.apiUrl}/bulk/asset`, req).pipe(
      map(result => {
        for (const asset of result.values) {
          if (asset.error) {
            continue;
          }
          if (asset.extId) {
            this.cacheByExtId.set(asset.extId, asset);
            retval.set(asset.extId, asset);
          }
          if (asset.uuid) {
            this.cacheByUuid.set(asset.uuid, asset);
            retval.set(asset.uuid, asset);
          }
        }
        return retval;
      }),
    );
  }
}
