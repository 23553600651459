import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { isAssetNotification, AssetCreated } from '../../models/notifications';
import { filter, tap, debounceTime } from 'rxjs/operators';
import { AssetTitlePipe } from '../../pipes/asset-title.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';
import { NgFor, NgIf } from '@angular/common';
import { CalloutComponent } from '../callout/callout.component';
import { ToastModule } from 'primeng/toast';
import { LicensingService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';

@Component({
  selector: 'app-liveconnect-asset-added',
  templateUrl: './liveconnect-asset-added.component.html',
  styleUrls: ['./liveconnect-asset-added.component.scss'],
  standalone: true,
  imports: [
    ToastModule,
    CalloutComponent,
    NgFor,
    AssetIconComponent,
    RouterLink,
    NgIf,
    TranslateModule,
    AssetTitlePipe,
  ],
})
export class LiveConnectAssetAddedComponent implements OnInit, OnDestroy {
  public readonly key = 'liveConnectToast';
  private readonly DEBOUNCE_TIME = 2500; // 2.5 sec
  private subscription?: Subscription;
  private toastShowing = false;
  private logger: Logger;

  private createdAssets: {
    uuid: string;
    type: number;
    name?: string;
    productName?: string;
    profilePicture?: string;
    productPictureUrl?: string;
    icon: string;
  }[] = [];

  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService,
    private licensingService: LicensingService,
  ) {
    this.logger = new Logger('LiveConnectAssetAddedComponent');
  }

  ngOnInit(): void {
    if (!this.licensingService.hasLiveConnect) {
      return;
    }

    this.logger.debug('Setting up LiveConnectAssetAddedComponent');

    this.subscription = this.notificationService.notification$
      .pipe(
        filter(
          notification =>
            isAssetNotification(notification) &&
            notification.asset.action === 'created' &&
            notification.asset.sourceType === 'icc',
        ),
        tap((notification: { asset: AssetCreated }) =>
          this.createdAssets.push({
            uuid: notification.asset.uuid,
            type: 2,
            name: notification.asset.customName,
            productName: notification.asset.productName,
            profilePicture: undefined,
            productPictureUrl: notification.asset.productPictureUrl,
            icon: 'device.svg',
          }),
        ),
        filter(() => !this.toastShowing),
        debounceTime(this.DEBOUNCE_TIME),
      )
      .subscribe(() => this.showToast());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  onClose() {
    this.messageService.clear(this.key);
    this.createdAssets = [];
    this.toastShowing = false;
  }

  private showToast() {
    this.messageService.clear(this.key);
    if (this.createdAssets.length > 0) {
      this.toastShowing = true;
      this.messageService.add({
        key: this.key,
        data: { assets: this.createdAssets.slice(0, 5), total: this.createdAssets.length },
        sticky: true,
        closable: false,
      });
    }
  }
}
