@if (envFeatures.softwareState) {
  <app-tooltip
    [icon]="icon"
    [iconColor]="color"
    width="300px"
    [iconSize]="size"
    [label]="showLabel ? (label | translate) : ''"
    [labelColor]="labelColor"
    [iconFirst]="true"
    direction="dm"
  >
    <div class="formatted-text" [innerHtml]="description | translate | safeHtml"></div>
  </app-tooltip>
}
