import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AssetIconPipe } from './asset-icon.pipe';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CompleteUrlPipe } from '.';
import { ImageService } from '../services';

@Pipe({
  name: 'assetImageUrl',
  standalone: true,
})
export class AssetImageUrlPipe implements PipeTransform {
  constructor(
    private assetIconPipe: AssetIconPipe,
    private urlCompletePipe: CompleteUrlPipe,
    private imageService: ImageService,
    private domSanitizer: DomSanitizer,
  ) {}

  transform(assetImageMetas: {
    type?: string | number;
    typeId?: number;
    profilePicture?: string;
    profilePictureUuid?: string;
    productPictureUrl?: string;
    icon?: string;
  }): Observable<string | SafeResourceUrl> {
    const profilePicUuid: string | undefined =
      assetImageMetas.profilePictureUuid || assetImageMetas.profilePicture;
    if (profilePicUuid) {
      // Load profile picture by resource id
      return of(profilePicUuid).pipe(
        switchMap(uuid =>
          // We need to encapsulate that observable into a new Observable (of()) because angular change detection
          // will not detect changes elseway (proxyImageThumbnail caches requests)
          this.imageService
            .proxyImageThumbnail(uuid)
            .pipe(map(url => this.domSanitizer.bypassSecurityTrustResourceUrl(url))),
        ),
      );
    } else if (assetImageMetas.productPictureUrl) {
      return of(this.urlCompletePipe.transform(assetImageMetas.productPictureUrl));
    } else if (assetImageMetas.icon) {
      return of(`assets/images/${assetImageMetas.icon}`);
    }

    return of(this.defaultIconForType(assetImageMetas));
  }

  public defaultIconForType(assetImage: {
    type?: string | number;
    typeId?: number;
  }): string | SafeResourceUrl {
    return this.assetIconPipe.transform(assetImage.type || assetImage.typeId || 'location');
  }
}
