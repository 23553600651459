export interface DeviceAction {
  action: string;
  payload?: {
    deviceUuid?: string;
    accessToken?: string;
  };
}

export interface DeviceNotify {
  type: 'NOTIFY';
  note: string;
  payload: {
    deviceUuid: string;
    timestamp: number;
  };
}

export type DeviceMessage = DeviceAction | DeviceNotify;

export function isDeviceAction(msg: DeviceMessage): msg is DeviceAction {
  return (msg as DeviceAction).action !== undefined;
}

export function isDeviceNotify(msg: DeviceMessage): msg is DeviceNotify {
  return (msg as DeviceNotify).type === 'NOTIFY';
}
