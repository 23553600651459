<p-toast
  position="bottom-right"
  [key]="key"
  [baseZIndex]="5000"
  [showTransitionOptions]="'66ms ease-out'"
  [hideTransitionOptions]="'53ms ease-in'"
  [showTransformOptions]="'translateX(100%)'"
  [hideTransformOptions]="'translateX(100%)'"
  styleClass="sick-toast"
>
  <ng-template let-message pTemplate="message">
    <app-callout severity="info" [closable]="true" (remove)="onClose(message)">
      @if (message.data.movedToTrash) {
        @if (message.data.canRestore) {
          <h1>{{ 'toasts.tree.asset-moved-to-trash-admin.summary' | translate }}</h1>
          <div>
            <span
              innerHtml="{{ 'toasts.tree.asset-moved-to-trash-admin.message.before' | translate }}"
            ></span
            ><a [routerLink]="['/trash-bin']">{{
              'toasts.tree.asset-moved-to-trash-admin.message.link' | translate
            }}</a
            ><span
              innerHtml="{{ 'toasts.tree.asset-moved-to-trash-admin.message.after' | translate }}"
            ></span>
          </div>
        } @else {
          <h1>{{ 'toasts.tree.asset-moved-to-trash-no-admin.summary' | translate }}</h1>
          <div>
            <span
              innerHtml="{{
                'toasts.tree.asset-moved-to-trash-no-admin.message.before' | translate
              }}"
            ></span
            ><a href="#" (click)="showAdmins($event, message.data.uuid)">{{
              'toasts.tree.asset-moved-to-trash-no-admin.message.link' | translate
            }}</a
            ><span
              innerHtml="{{
                'toasts.tree.asset-moved-to-trash-no-admin.message.after' | translate
              }}"
            ></span>
          </div>
        }
      } @else {
        <h1>{{ 'toasts.tree.some-asset-was-deleted.summary' | translate }}</h1>
        <div innerHtml="{{ 'toasts.tree.some-asset-was-deleted.message' | translate }}"></div>
      }
    </app-callout>
  </ng-template>
</p-toast>
