export interface AccountAttributes {
  admin?: 'operator' | 'admin';
  developer?: boolean;
  licenseAssetHub?: string;
  licenseLiveConnect?: null;
  licenseServiceModule?: null;
}

export interface MyAccount {
  uuid: string;
  attributes: AccountAttributes;
  treeRequested: boolean;
}
