import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { MediaPlaceholderComponent } from '../media-placeholder/media-placeholder.component';
import { IconComponent } from '../icon/icon.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgStyle,
    NgClass,
    IconComponent,
    MenuComponent,
    MenuItemComponent,
    MediaPlaceholderComponent,
  ],
})
export class MediaImageComponent {
  readonly display = input<'preview' | 'full'>('preview');
  readonly previewUrl = input<string>('');
  readonly url = input<string>('');
  readonly selectable = input(true);

  readonly selected = output();

  readonly error = signal(false);
  readonly loading = signal(true);
  readonly displayUrl = computed(() =>
    this.display() === 'preview' ? this.previewUrl() : this.url(),
  );

  onClick(event: MouseEvent): void {
    if (!this.selectable()) {
      return;
    }
    this.selected.emit();
  }

  onDownload(): void {
    const url = this.url();
    const filename = url.split('/').at(-1);
    saveAs(this.url(), filename);
  }
}
