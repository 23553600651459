import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OAuthErrorInterceptor implements OAuthResourceServerErrorHandler {
  constructor(
    private router: Router,
    private oauthService: OAuthService,
  ) {}

  public handleError(err: HttpResponse<any>): Observable<any> {
    if (err.status === 403 && !this.oauthService.hasValidAccessToken()) {
      return of(this.router.navigate(['/error', 'authentication'], { skipLocationChange: true }));
    }

    throw err;
  }
}
