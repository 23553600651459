import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { LazyLoadResponse, LazyLoadTable, fromUnixTimestamp } from '@assethub/shared/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { NEVER, map } from 'rxjs';
import { LocalizedDatePipe } from '../../../pipes/localized-date.pipe';
import { TableBaseComponent, TableColumn } from '../../table-base/table-base.component';
import { TableHeaderComponent } from '../../table-header/table-header.component';
import { ListWorkOrderDocumentsComponent } from '../list-work-order-documents/list-work-order-documents.component';

interface WorkOrder {
  uuid: string;
  name: string;
  number: number;
  customerReference?: string;
  customerPurchaseOrderNo?: string;
  status: string;
  accountName: string;
  accountId: string;
  timeFrom?: Date;
  timeTo?: Date;
  city?: string;
  documents: boolean;
}

export interface WorkOrderResponse {
  workOrders: {
    uuid: string;
    name: string;
    number: number;
    customerReference?: string;
    customerPurchaseOrderNo?: string;
    status: string;
    accountName: string;
    accountId: string;
    timeFrom: number;
    timeTo: number;
    created: number;
    modified: number;
    city?: string;
  }[];
  total: number;
}

function convertWorkOrderResponseToWorkOrderItems(
  response: Pick<WorkOrderResponse, 'workOrders'>,
): WorkOrder[] {
  return response.workOrders.map(x => ({
    uuid: x.uuid,
    name: x.name,
    number: x.number,
    customerReference: x.customerReference,
    customerPurchaseOrderNo: x.customerPurchaseOrderNo,
    status: `service-module-365.workorder.status.${x.status}`,
    accountName: x.accountName,
    accountId: x.accountId,
    timeFrom: fromUnixTimestamp(x.timeFrom),
    timeTo: fromUnixTimestamp(x.timeTo),
    city: x.city,
    documents: true,
  }));
}

type WorkOrderColumn = keyof WorkOrder;

@Component({
  selector: 'app-list-work-orders',
  templateUrl: './list-work-orders.component.html',
  styleUrls: ['./list-work-orders.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    DialogModule,
    RouterLink,
    ButtonModule,
    TableHeaderComponent,
    TableBaseComponent,
    TranslateModule,
    LocalizedDatePipe,
    ListWorkOrderDocumentsComponent,
  ],
})
export class ListWorkOrdersComponent implements OnInit, OnChanges {
  @Input() nodeUuid: string;
  @Input() requestUrl: string;
  @Input() limitToNode = false;
  @Input() initialVisibleRows = 20;
  @Input() sortField: WorkOrderColumn = 'name';
  @Input() sortDirection: 1 | -1 = -1;
  @Input() autoWidth = true;
  @Input() viewFlavour: 'complete' | 'reduced' = 'complete';
  @Input({ required: true }) tableInstance: string;

  list: LazyLoadTable<WorkOrder>;
  tableName: string;
  initialColumns: WorkOrderColumn[] = this.computeInitialColumns();

  columns: TableColumn<WorkOrderColumn>[] = [
    {
      field: 'name',
      label: 'service-module-365.workorder.field.name',
      type: 'string',
      filter: true,
      width: '200%',
    },
    {
      field: 'number',
      label: 'service-module-365.workorder.field.number',
      type: 'number',
      filter: true,
      width: '100%',
    },
    {
      field: 'customerReference',
      label: 'service-module-365.workorder.field.customer-order-number',
      type: 'unsortable',
      filter: true,
      width: '150%',
    },
    {
      field: 'customerPurchaseOrderNo',
      label: 'service-module-365.workorder.field.customer-purchase-number',
      type: 'unsortable',
      filter: true,
      width: '150%',
    },
    {
      field: 'timeFrom',
      label: 'service-module-365.workorder.field.timeFrom',
      type: 'date',
      filter: true,
      width: '9em',
    },
    {
      field: 'timeTo',
      label: 'service-module-365.workorder.field.timeTo',
      type: 'date',
      filter: true,
      width: '9em',
    },
    {
      field: 'status',
      label: 'service-module-365.workorder.field.status',
      type: 'number',
      filterLabels: 'service-module-365.workorder.status',
      width: '9em',
    },
    {
      field: 'documents',
      label: 'service-module-365.workorder.field.documents',
      type: 'unsortable',
      filter: false,
      width: '8em',
      essential: true,
    },
  ];

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    this.list = new LazyLoadTable<WorkOrder>(
      x => this.buildRequest(x),
      this.sortField,
      this.sortDirection,
      this.initialVisibleRows,
    );
  }

  ngOnInit() {
    this.tableName = `work-orders.${this.tableInstance}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewFlavour) {
      this.initialColumns = this.computeInitialColumns();
    }
    if (changes.initialVisibleRows) {
      this.list.visibleRows = this.initialVisibleRows;
    }
    if (changes.sortField) {
      this.list.sortField = this.sortField;
    }
    if (changes.requestUrl) {
      this.list.reload({ first: 0 });
    }
  }

  navigateToDetails(row: WorkOrder) {
    this.router.navigate(['/maintenance365', this.nodeUuid, 'workorder', row.uuid, 'details']);
  }

  showDocuments(row: WorkOrder) {
    this.dialogService.open(ListWorkOrderDocumentsComponent, {
      header: this.translateService.instant(
        'service-module-365.workorder-details.section.documents',
      ),
      data: {
        nodeUuid: this.nodeUuid,
        workOrder: row,
      },
      width: '95%',
      contentStyle: { flex: '1' },
      style: {
        'max-width': '860px',
      },
    });
  }

  private buildRequest(params: { [k: string]: string }): LazyLoadResponse<WorkOrder> {
    if (!this.requestUrl) {
      return NEVER;
    }
    if (this.limitToNode) {
      params.eqNode = '1';
    }
    return this.httpClient.get<WorkOrderResponse>(this.requestUrl, { params }).pipe(
      map(response => ({
        total: response.total,
        items: convertWorkOrderResponseToWorkOrderItems(response),
      })),
    );
  }

  private computeInitialColumns(): WorkOrderColumn[] {
    const viewFlavour = this.viewFlavour;
    if (viewFlavour === 'reduced') {
      return ['name', 'customerReference', 'status', 'timeFrom', 'documents'];
    }
    return [
      'name',
      'number',
      'customerReference',
      'customerPurchaseOrderNo',
      'timeFrom',
      'timeTo',
      'status',
      'documents',
    ];
  }
}
