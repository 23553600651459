<div class="{{ severity() }}">
  <div class="stripe"></div>
  <div class="callout">
    @if (showIcon()) {
      <app-icon [shape]="shape()"></app-icon>
    } @else {
      <div></div>
    }
    <div class="callout-content">
      <ng-content></ng-content>
    </div>
    <!-- Close button -->
    @if (closable()) {
      <button
        id="close-button"
        pButton
        type="button"
        class="ui-button-bare-neutral icon-button-small"
        icon="pi pi-times"
        (click)="close()"
      ></button>
    }
  </div>
</div>
