import { HttpClient } from '@angular/common/http';
import { Component, computed, effect, Inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  AssetMetadataV2,
  AssetTypeMapping,
  Environment,
  ENVIRONMENT,
} from '@assethub/shared/models';
import { AccountService, AssetService, TreeService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { finalize, map, repeat, switchMap } from 'rxjs';
import { AppSharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  standalone: true,
  imports: [ButtonModule, AppSharedModule, TranslateModule],
})
export class WelcomeComponent {
  private logger = new Logger('WelcomeComponent');
  readonly allowCreateTree = signal(this.env.enableCreateTreeByUser);
  readonly sendingRequest = signal(false);
  readonly treeRequestPending = computed(
    () => this.treeRequestSubmitted() || this.getMyAccount().treeRequested,
  );

  private readonly getMyAccount = toSignal(this.accountService.getMyAccount(), {
    requireSync: true,
  });
  private readonly treeRequestSubmitted = signal(false);
  readonly haveTrees = toSignal(
    this.treeService.fetchTrees().pipe(
      repeat({ delay: 10000 }),
      map(x => x.length > 0),
    ),
    { initialValue: this.treeService.rootNodes.length > 0 },
  );

  constructor(
    private httpClient: HttpClient,
    private accountService: AccountService,
    @Inject(ENVIRONMENT) private env: Environment,
    private treeService: TreeService,
    private assetService: AssetService,
    private router: Router,
    private messageService: MessageService,
  ) {
    effect(() => {
      if (this.haveTrees()) {
        this.router.navigate(['/']);
      }
    });
  }

  requestTree() {
    this.sendingRequest.set(true);
    this.httpClient
      .post<void>(`${this.env.apiUrl}/tree-request`, null)
      .pipe(
        finalize(() => {
          this.sendingRequest.set(false);
        }),
      )
      .subscribe({
        next: () => {
          this.treeRequestSubmitted.set(true);
        },
      });
  }

  createTree() {
    const newAsset: AssetMetadataV2 = {
      assetType: AssetTypeMapping.COMPANY,
      customName: 'SICK AssetHub',
    };
    this.sendingRequest.set(true);
    this.assetService
      .addAsset(newAsset)
      .pipe(
        switchMap(response => this.treeService.loadTree(response.uuid)),
        finalize(() => {
          this.sendingRequest.set(false);
        }),
      )
      .subscribe({
        next: response => {
          this.router.navigate(['dashboard', response.uuid]);
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            summary: 'welcome.create-failed',
            life: 10000,
          });
          this.logger.debug(`Create asset failed with `, err);
        },
      });
  }
}
