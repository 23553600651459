import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { TreeService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AccessibleTreeGuard implements CanActivate {
  constructor(
    private treeService: TreeService,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.treeService.fetchTrees().pipe(
      map(trees => {
        if (trees.length < 1) {
          return this.router.parseUrl('/welcome');
        } else {
          return true;
        }
      }),
    );
  }
}
