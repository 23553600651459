import { inject, Injectable } from '@angular/core';
import { IccState } from '@liveconnect/shared/models';
import { DeviceMonitorService, IccService } from '@liveconnect/shared/services';
import { map, Observable, of, share, switchMap, timer } from 'rxjs';
import { DEVICE_READY_SYSTEM_READY, ENVIRONMENT, GetAssetResponse } from '../models';
import { AssetService } from './asset.service';

export enum AssetConnectionState {
  VOID,
  OFFLINE,
  ONLINE,
}

export enum SystemState {
  VOID, // Value unset or connection timeout
  READY, // Only statusCode "110"
  ERROR, // All other status codes
}

export interface AssetStateDetails {
  connectionState: AssetConnectionState;
  stateDate?: Date;
  systemState?: SystemState;
}

@Injectable({ providedIn: 'root' })
export class AssetStateService {
  private assetService = inject(AssetService);
  private deviceMonitorService = inject(DeviceMonitorService);
  private iccService = inject(IccService);
  private timeoutDeviceOfflineSeconds = inject(ENVIRONMENT).timeoutDeviceOfflineSeconds;
  private cycleStateUpdateSeconds = 15;

  private assetStateLoader: Record<string, Observable<AssetStateDetails>> = {};

  hasInventorySync(asset?: GetAssetResponse): boolean {
    return asset?.inventoryDetails?.synced !== undefined;
  }

  fetchConnectionState(asset?: GetAssetResponse): Observable<AssetStateDetails> {
    if (asset?.inventoryDetails?.synced === undefined) {
      return of({ connectionState: AssetConnectionState.VOID });
    }

    if (this.deviceMonitorService.canMonitorDevices()) {
      return this.fetchFromIccService(asset.uuid);
    }

    return this.fetchFromInventoryData(asset.uuid);
  }

  private fetchFromIccService(uuid: string): Observable<AssetStateDetails> {
    return this.deviceMonitorService.getIccState(uuid).pipe(
      switchMap(iccState => {
        if (iccState === IccState.VOID) {
          return of({ connectionState: AssetConnectionState.VOID });
        }
        return this.iccService.getCurrentConnection(uuid).pipe(
          map(connection => {
            const connectionState = connection.connected
              ? AssetConnectionState.ONLINE
              : AssetConnectionState.OFFLINE;
            let stateDate: Date | undefined;

            if (connection.connected && connection.since) {
              stateDate = connection.since;
            } else if (!connection.connected && connection.lastSeen) {
              stateDate = connection.lastSeen;
            }

            return { connectionState, stateDate };
          }),
        );
      }),
    );
  }

  private fetchFromInventoryData(uuid: string): Observable<AssetStateDetails> {
    if (this.assetStateLoader[uuid] === undefined) {
      this.assetStateLoader[uuid] = (
        this.cycleStateUpdateSeconds > 0 ? timer(0, this.cycleStateUpdateSeconds * 1000) : of(0)
      ).pipe(
        switchMap(() =>
          this.assetService.fetchInventoryAssetDetails(uuid).pipe(
            map(details => {
              let connectionState = AssetConnectionState.VOID;
              let stateDate: Date | undefined;
              let systemState = SystemState.VOID;

              if (details.lastConnectedDate !== undefined) {
                stateDate = details.lastConnectedDate;
                const connectionTimeout = this.isDateExpired(
                  stateDate,
                  this.timeoutDeviceOfflineSeconds,
                );
                connectionState = connectionTimeout
                  ? AssetConnectionState.OFFLINE
                  : AssetConnectionState.ONLINE;

                if (details.systemState && !connectionTimeout) {
                  if (details.systemState.value === DEVICE_READY_SYSTEM_READY) {
                    systemState = SystemState.READY;
                  } else {
                    systemState = SystemState.ERROR;
                  }
                }
              }

              return { connectionState, stateDate, systemState };
            }),
          ),
        ),
        share(),
      );
    }

    return this.assetStateLoader[uuid];
  }

  private isDateExpired(comparedDate: Date, timeoutSeconds: number): boolean {
    const now = new Date().getTime();
    const dateTime = comparedDate.getTime();

    if (now >= dateTime) {
      return (now - dateTime) / 1000 >= timeoutSeconds;
    }

    return false;
  }
}
