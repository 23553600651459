import { MenuItem as MenuItemPrimeNG } from 'primeng/api';

export interface DrawerMenuItem {
  label: string;
  id?: number;
  icon?: string;
  // Use only route and pattern OR children. Menu items having children do not have navigation capabilities
  route?: string;
  pattern?: RegExp;
  children?: DrawerMenuItem[];
}

export interface TabMenuItem extends MenuItemPrimeNG {
  pattern: RegExp;
}
