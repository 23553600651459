import { Component, computed, effect, HostBinding, input, signal, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { MenuComponent } from '../menu/menu/menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { GetAssetResponse } from '../../models';
import { AssetDocumentService, AssetService } from '../../services';

type UploadDestination = 'images' | 'documents' | 'all';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FileUploadModule,
    ButtonModule,
    IconComponent,
    MenuComponent,
    MenuItemComponent,
  ],
})
export class MediaUploadComponent {
  readonly asset = input.required<GetAssetResponse>();
  readonly destination = input<UploadDestination>('all');
  readonly buttonClass = input('ui-button-outline');

  readonly accept = signal('');
  private readonly dropDenied = signal(false);
  private readonly dropAllowed = signal(false);
  readonly icon = computed(() =>
    this.dropDenied() ? 'block' : this.dropAllowed() ? 'upload_file' : 'upload',
  );
  readonly label = computed(() => `mediaUpload.destination.${this.destination()}`);
  readonly busy = computed(() => this.computeBusy());
  readonly uploadingImages = signal(false);
  readonly uploadingDocuments = signal(false);

  private currentDestination: UploadDestination = 'all';

  @HostBinding('class.dropAllowed')
  get hasClassDropAllowed() {
    return this.dropAllowed();
  }

  @HostBinding('class.dropDenied')
  get hasClassDropDenied() {
    return this.dropDenied();
  }

  @ViewChild('fileUpload')
  private fileUpload: FileUpload;

  constructor(
    private messageService: MessageService,
    private assetService: AssetService,
    private documentService: AssetDocumentService,
  ) {
    effect(
      onCleanup => {
        const asset = this.asset();
        const subscriptions = [
          this.assetService.monitorCustomImageUploads(asset.uuid).subscribe({
            next: status => this.uploadingImages.set(status !== undefined),
          }),
          this.documentService.monitorCustomDocumentUploads(asset.uuid).subscribe({
            next: status => this.uploadingDocuments.set(status !== undefined),
          }),
        ];
        onCleanup(() => subscriptions.forEach(x => x.unsubscribe()));
      },
      { allowSignalWrites: true },
    );
  }

  onFilesSelected(event: any) {
    if (event.files && event.files instanceof FileList) {
      this.handleFiles(event.files);
    }
  }

  chooseFiles(dst: UploadDestination): void {
    this.currentDestination = dst;
    this.accept.set(dst === 'images' ? '.jpeg,.jpg,.png' : '');
    window.setTimeout(() => {
      this.fileUpload.clear();
      this.fileUpload.choose();
    }, 0);
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    if (this.busy()) {
      return;
    }
    this.fileUpload.clear();

    const allowed = this.isDropAllowed(event);
    this.dropAllowed.set(allowed);
    this.dropDenied.set(!allowed);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    this.dropAllowed.set(false);
    this.dropDenied.set(false);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dropAllowed.set(false);
    this.dropDenied.set(false);

    if (event.dataTransfer && event.dataTransfer.files && !this.busy()) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  private isDropAllowed(event: DragEvent): boolean {
    return event.dataTransfer !== null && event.dataTransfer.items.length > 0;
  }

  private handleFiles(srcFiles: FileList) {
    const files: File[] = [];
    const errMsgId = srcFiles.length > 1 ? 'invalid-files-dropped' : 'invalid-file-dropped';
    for (let i = 0; i < srcFiles.length; i++) {
      const file = srcFiles[i];
      files.push(file);
      if (!this.fileUpload.validate(file)) {
        this.messageService.add({
          severity: 'error',
          summary: `toasts.file-chooser.${errMsgId}`,
          life: 10000,
          data: { accept: this.accept() },
        });
        return;
      }
      if (file.size > 10000000) {
        this.messageService.add({
          severity: 'error',
          summary: 'toasts.file.too-big-for-upload',
          life: 10000,
          data: { filename: file.name, limit: '10MB' },
        });
        return;
      }
    }
    if (this.currentDestination === 'images') {
      // upload to images only
      this.assetService.uploadCustomImages(this.asset().uuid, files);
    } else if (this.currentDestination === 'documents') {
      // upload to documents only
      this.documentService.uploadFiles(this.asset().uuid, files);
    } else {
      // dispatch by mime-type
      const images: File[] = [];
      const documents: File[] = [];
      for (const file of files) {
        if (file.type.startsWith('image/')) {
          images.push(file);
        } else {
          documents.push(file);
        }
      }
      this.assetService.uploadCustomImages(this.asset().uuid, images);
      this.documentService.uploadFiles(this.asset().uuid, documents);
    }
  }

  private computeBusy(): boolean {
    const destination = this.destination();
    const uploadingImages = this.uploadingImages();
    const uploadingDocuments = this.uploadingDocuments();
    switch (destination) {
      case 'all':
        return uploadingImages || uploadingDocuments;
      case 'images':
        return uploadingImages;
      case 'documents':
        return uploadingDocuments;
    }
  }
}
