import { Pipe, PipeTransform } from '@angular/core';
import { assetTypeMappingToString } from '../models/asset-details';

@Pipe({
  name: 'assetIcon',
  standalone: true,
})
export class AssetIconPipe implements PipeTransform {
  public transform(type: string | number): string {
    const numericType = typeof type === 'string' ? parseInt(type, 10) : type;
    const iconName = isNaN(numericType)
      ? (type as string).toLowerCase()
      : assetTypeMappingToString[numericType] || 'location';
    return `assets/images/${iconName}.svg`;
  }
}
