import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  MyAccountAccessDenied,
  AccountService,
  InternationalizationService,
  LicensingService,
  NavigationTrackingService,
  NotificationService,
  RecentAssetService,
  TreeEventsConsumerService,
} from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { firstValueFrom } from 'rxjs';
import { ENVIRONMENT, Environment } from './shared/models';
import { PermissionEventService } from './shared/services/permission-event.service';

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  public hasBearerToken = false;

  private canonicalBaseUrl?: string;
  private logger = new Logger('AppInitializerService');

  constructor(
    private oauthService: OAuthService,
    private internationalizationService: InternationalizationService,
    private router: Router,
    private navigationTracking: NavigationTrackingService,
    private licensingService: LicensingService,
    @Inject(DOCUMENT) private document: Document,
    private treeEventConsumerService: TreeEventsConsumerService,
    private permissionEventService: PermissionEventService,
    private notificationService: NotificationService,
    private accountService: AccountService,
    @Inject(ENVIRONMENT) private settings: Environment,
    private recentAssetService: RecentAssetService,
  ) {
    // must be instantiated here to pick up very first routing
    // eslint-disable-next-line no-self-assign
    this.navigationTracking = this.navigationTracking;
    this.recentAssetService.initialize();

    this.logger.info('AssetHub is starting up...');
  }

  public async initialize(): Promise<void> {
    this.canonicalBaseUrl = this.settings.canonicalBaseUrl;

    if (this.redirectForCanonicalUrlRequired()) {
      return;
    }

    this.internationalizationService.setup();
    await this.configureOidc();

    this.notificationService.connect();
    this.treeEventConsumerService.initialize();
    this.permissionEventService.initialize();
  }

  private redirectForCanonicalUrlRequired(): boolean {
    if (!this.canonicalBaseUrl) {
      return false;
    }

    const currentUrl = `${this.document.location.protocol}//${this.document.location.host}${this.document.location.pathname}`;
    if (currentUrl.startsWith(this.canonicalBaseUrl)) {
      return false;
    }

    const targetUrl = new URL(this.canonicalBaseUrl);
    const redirectUrl = new URL(currentUrl);
    redirectUrl.protocol = targetUrl.protocol;
    redirectUrl.hostname = targetUrl.hostname;
    redirectUrl.port = targetUrl.port;

    this.document.location.href = redirectUrl.href;
    return true;
  }

  private async configureOidc(): Promise<any> {
    // ... then setup oidc and try to login
    const redirectUri = `${this.document.location.protocol}//${this.document.location.host}${this.document.location.pathname}`;
    const oidcDefaults: AuthConfig = {
      redirectUri,
      responseType: 'code',
      scope: 'openid profile email',

      silentRefreshTimeout: 20000,
      timeoutFactor: 0.9,
      postLogoutRedirectUri: this.document.location.origin,
      showDebugInformation: false,
    };

    this.oauthService.configure(Object.assign(oidcDefaults, this.settings.oidc));
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        this.logger.warn('Auth event:', event);
      }
    });
    this.oauthService.setupAutomaticSilentRefresh();
    await this.oauthService.loadDiscoveryDocument();

    // Case 1: User already has a valid idToken: just reloaded the site, we can immediately
    // use it and continue working
    if (this.oauthService.hasValidIdToken()) {
      this.logger.debug('Found valid existing idToken - skipping auth roundtrip.');
      this.hasBearerToken = true;
      try {
        await firstValueFrom(this.accountService.getMyAccount());
        await this.licensingService.setup();
        return;
      } catch (e: unknown) {
        // Only let AccessDenied errors be fallen through (otherwise, this can lead to inifite page loops
        // when the server is incorrectly set up or not serving).
        if (!(e instanceof MyAccountAccessDenied)) {
          throw e;
        }
        this.logger.warn(e);
        this.logger.warn('Unable to setup w/ existing idToken - fallback to redirect');
      }
    }

    // No idToken, then try to consume a login redirect (might be there)
    const oidcState = new URLSearchParams(document.location.search).get('state') || '';
    const customQueryParams = new URLSearchParams(
      decodeURIComponent(oidcState.split(this.oauthService.nonceStateSeparator!).at(1) || ''),
    );
    try {
      await this.oauthService.tryLoginCodeFlow();
    } catch (ex) {
      if (ex instanceof OAuthErrorEvent && ex.type === 'invalid_nonce_in_state') {
        // Under some currently unknown circumstances the browser's session store can
        // get lost in transit between this page and the oidc server. In that case
        // nonce and PKCE verifier are gone and the oidc flow cannot continue.
        if (customQueryParams.has('__oidc_retry_login')) {
          throw new Error(`OIDC flow repeatedly failed: ${ex.type}`);
        }
        customQueryParams.append('__oidc_retry_login', '1');
        this.oauthService.initCodeFlow(customQueryParams.toString());
        return;
      }
      throw ex;
    }
    customQueryParams.delete('__oidc_retry_login');

    // Case 2: It did work \o/, we're authenticated, check license and navigate to
    // target URL, if necessary.
    if (this.oauthService.hasValidAccessToken()) {
      this.logger.debug('Valid token after trying to login');
      this.hasBearerToken = true;

      try {
        await firstValueFrom(this.accountService.getMyAccount());
        await this.licensingService.setup();

        // Check state: this can contain some query parameters - if so, navigate including them...
        if (customQueryParams.size > 0) {
          this.router.navigateByUrl(
            `${this.document.location.pathname}?${customQueryParams.toString()}`,
          );
        }

        return;
      } catch (e: unknown) {
        // Only let AccessDenied errors be fallen through (otherwise, this can lead to inifite page loops
        // when the server is incorrectly set up or not serving).
        if (!(e instanceof MyAccountAccessDenied)) {
          throw e;
        }
        this.logger.warn(e);
        this.logger.warn('Unable to consume seemingly valid access token, trying to re-login.');
        this.logger.info('Delaying for 5 seconds...');
        await new Promise<void>(resolve => {
          setTimeout(resolve, 5000);
        });
      }
    }

    // Case 3: Nope, didn't work - need to trigger the auth flow by calling initCodeFlow. Pass
    // the current location _including_ a potential query string as state for a subsequent
    // navigation *after* user has been logged in.
    this.logger.info('No usable credentials detected, sending user off to OIDC');
    this.hasBearerToken = false;

    this.oauthService.initCodeFlow(`${this.document.location.search}`);
  }
}
