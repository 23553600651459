export interface GroupPermissionsQuery {
  start?: number;
  size?: number;
}

export interface GroupPermissionsRequest {
  extGroupId: string;
  permissions: string;
  sendMail?: boolean;
}

export interface GroupPermissions extends GroupPermissionsRequest {
  groupName: string;
}

export interface GrantedGroupPermission extends GroupPermissions {
  inherited: boolean;
  parentGroupPermissions?: string;
}

export interface GroupPermissionsResponse {
  groupPermissions: GrantedGroupPermission[];
  total: number;
}
