import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {
  private env = inject(ENVIRONMENT);

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const apiKey = this.apiKey(request.url);
    if (null !== apiKey) {
      return next.handle(
        request.clone({
          headers: request.headers.set('x-api-key', apiKey),
          withCredentials: false,
        }),
      );
    }

    return next.handle(request);
  }

  private apiKey(url: string): string | null {
    if (this.env.sm365Url && url.startsWith(this.env.sm365Url)) {
      return this.env.sm365ApiKey || this.env.swsApiKey;
    }
    if (this.env.sm365DocumentProxyUrl && url.startsWith(this.env.sm365DocumentProxyUrl)) {
      return this.env.sm365DocumentProxyApiKey || this.env.swsApiKey;
    }

    if (this.env.configBackendUrl && url.startsWith(this.env.configBackendUrl)) {
      return this.env.configBackendApiKey || this.env.swsApiKey;
    }

    return null;
  }
}
