import { Pipe, PipeTransform } from '@angular/core';
import {
  AssetHistoryEvent,
  AssetHistoryEventTypeIds,
  AssetHistoryLinkUpdateDetails,
  AssetHistoryUpdateDetails,
  SetGeoLocationRequest,
} from '@assethub/asset-management/models';
import { assetTypeMappingToString } from '@assethub/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from './localized-date.pipe';

@Pipe({
  name: 'historyEventFormatter',
  standalone: true,
})
export class HistoryEventFormatterPipe implements PipeTransform {
  // mapping: attributes of the field values to the keys in the i18n-files
  private readonly mapAssetUpdatedFieldToI18NKeys: Record<string, string> = {
    'typeId': 'type',
    'customName': 'title',
    'description': 'description',
    'vendor': 'vendor',
    'partNumber': 'part-number',
    'serialNumber': 'serial-number',
    'firmwareVersion': 'firmware.version',
    'productName': 'product-name',
    'imo': 'imo-number',
  };
  private readonly mapLinkUpdatedFieldToI18NKeys: Record<string, string> = {
    'name': 'name',
    'url': 'url',
  };

  constructor(
    private translateService: TranslateService,
    private localizedDatePipe: LocalizedDatePipe,
  ) {}

  transform(event: AssetHistoryEvent) {
    const assetTypes = this.translateService.instant('assetTypes');
    const unknownHistory = this.translateService.instant('assetHistory.unknown');
    const assetType = assetTypes[assetTypeMappingToString[event.asset.typeId]] || unknownHistory;
    switch (event.type) {
      case AssetHistoryEventTypeIds.ASSET_CREATED: {
        const source = event.details.source
          ? this.translateService.instant('assetHistory.' + [event.details.source])
          : 'unknown';
        return this.translateService.instant('assetHistory.assetCreated', { assetType, source });
      }
      case AssetHistoryEventTypeIds.ASSET_UPDATED: {
        return this.translateService.instant(
          'assetHistory.assetUpdated',
          this.translateAssetUpdatedDetails(event.asset.typeId, event.details),
        );
      }
      case AssetHistoryEventTypeIds.ASSET_MOVED: {
        const eventDetails = event.details;
        const parentTo =
          eventDetails.to.parent.name ||
          assetTypes[assetTypeMappingToString[eventDetails.to.parent.typeId]] ||
          unknownHistory;

        if (!eventDetails.from) {
          return this.translateService.instant('assetHistory.fullTreeMoved', {
            assetType,
            parentTo,
          });
        }

        const parentFrom =
          eventDetails.from.parent.name ||
          assetTypes[assetTypeMappingToString[eventDetails.from.parent.typeId]] ||
          unknownHistory;
        return this.translateService.instant('assetHistory.assetMoved', {
          assetType,
          parentFrom,
          parentTo,
        });
      }
      case AssetHistoryEventTypeIds.ASSET_DELETED: {
        const assetTypeDeleted =
          assetTypes[assetTypeMappingToString[event.details.asset.typeId]] || unknownHistory;
        const assetNameDeleted = event.details.asset.name || unknownHistory;
        return this.translateService.instant('assetHistory.assetDeleted', {
          assetType: assetTypeDeleted,
          assetName: assetNameDeleted,
        });
      }
      case AssetHistoryEventTypeIds.ASSET_MOVED_TO_TRASH: {
        const assetTypeDeleted =
          assetTypes[assetTypeMappingToString[event.details.asset.typeId]] || unknownHistory;
        const assetNameDeleted = event.details.asset.name || unknownHistory;
        return this.translateService.instant('assetHistory.assetMovedToTrash', {
          assetType: assetTypeDeleted,
          assetName: assetNameDeleted,
        });
      }
      case AssetHistoryEventTypeIds.ASSET_RESTORED_FROM_TRASH: {
        const assetTypeRestored =
          assetTypes[assetTypeMappingToString[event.details.asset.typeId]] || unknownHistory;
        const assetNameRestored = event.details.asset.name || unknownHistory;
        return this.translateService.instant('assetHistory.assetRestoredFromTrash', {
          assetType: assetTypeRestored,
          assetName: assetNameRestored,
        });
      }
      case AssetHistoryEventTypeIds.PERMISSION_GRANTED: {
        const permission = this.translatePermission(event.details.permission);
        const to = event.details.to || '';
        return this.translateService.instant('assetHistory.permissionGranted', { permission, to });
      }
      case AssetHistoryEventTypeIds.PERMISSION_CHANGED: {
        const from = this.translatePermission(event.details.from);
        const to = this.translatePermission(event.details.to);
        const subject = event.details.subject || '';
        return this.translateService.instant('assetHistory.permissionChanged', {
          from,
          to,
          subject,
        });
      }
      case AssetHistoryEventTypeIds.PERMISSION_REVOKED: {
        const permission = this.translatePermission(event.details.permission);
        const from = event.details.from || '';
        return this.translateService.instant('assetHistory.permissionRevoked', {
          permission,
          from,
        });
      }
      case AssetHistoryEventTypeIds.GEO_LOCATION_CREATED: {
        const geoLocation = this.parseLocation(event.details);
        return this.translateService.instant('assetHistory.geoLocationCreated', { geoLocation });
      }
      case AssetHistoryEventTypeIds.GEO_LOCATION_UPDATED: {
        const geoLocationFrom = this.parseLocation(event.details.from);
        const geoLocationTo = this.parseLocation(event.details.to);
        return this.translateService.instant('assetHistory.geoLocationUpdated', {
          geoLocationFrom,
          geoLocationTo,
        });
      }
      case AssetHistoryEventTypeIds.GEO_LOCATION_DELETED: {
        const geoLocation = this.parseLocation(event.details);
        return this.translateService.instant('assetHistory.geoLocationDeleted', { geoLocation });
      }
      case AssetHistoryEventTypeIds.ASSET_IMAGE_CREATED: {
        const imageName = event.details.imageName;
        return this.translateService.instant('assetHistory.assetImageCreated', { imageName });
      }
      case AssetHistoryEventTypeIds.ASSET_IMAGE_DELETED: {
        const imageName = event.details.imageName;
        return this.translateService.instant('assetHistory.assetImageDeleted', { imageName });
      }
      case AssetHistoryEventTypeIds.ASSET_IMAGE_REJECTED: {
        const imageName = event.details.imageName ? `'${event.details.imageName}'` : '';
        return this.translateService.instant('assetHistory.assetImageRejected', { imageName });
      }
      case AssetHistoryEventTypeIds.LINK_ADDED: {
        const linkName = event.details.name;
        const linkUrl = event.details.url;
        return this.translateService.instant('assetHistory.linkAdded', { linkName, linkUrl });
      }
      case AssetHistoryEventTypeIds.LINK_UPDATED: {
        return this.translateService.instant(
          'assetHistory.linkUpdated',
          this.translateLinkUpdatedDetails(event.details),
        );
      }
      case AssetHistoryEventTypeIds.LINK_DELETED: {
        const linkName = event.details.name;
        const linkUrl = event.details.url;
        return this.translateService.instant('assetHistory.linkDeleted', { linkName, linkUrl });
      }
      case AssetHistoryEventTypeIds.LINK_MOVED: {
        const linkName = event.details.field;
        const from = event.details.from;
        const to = event.details.to;
        return this.translateService.instant('assetHistory.linkMoved', { linkName, from, to });
      }
      case AssetHistoryEventTypeIds.DOCUMENT_UPLOADED: {
        const documentName = event.details.name;
        const documentSize = event.details.size;
        return this.translateService.instant('assetHistory.documents.upload', {
          documentName,
          documentSize,
        });
      }
      case AssetHistoryEventTypeIds.DOCUMENT_REMOVED: {
        const documentName = event.details.name;
        return this.translateService.instant('assetHistory.documents.removed', { documentName });
      }
      case AssetHistoryEventTypeIds.SHARE_UNSUBSCRIBED: {
        return this.translateService.instant('assetHistory.shareUnsubscribed');
      }
      case AssetHistoryEventTypeIds.SHARE_ACCEPTED: {
        return this.translateService.instant('assetHistory.shareAccepted');
      }
      case AssetHistoryEventTypeIds.SHARE_REJECTED: {
        return this.translateService.instant('assetHistory.shareRejected');
      }
      case AssetHistoryEventTypeIds.DOCUMENT_REJECTED: {
        const documentName = event.details.name;
        const documentSize = event.details.size;
        return this.translateService.instant('assetHistory.documents.rejected', {
          documentName,
          documentSize,
        });
      }
      case AssetHistoryEventTypeIds.SENSOR_CONFIG_UPLOADED: {
        const configName: string = event.details.configName || '';
        const configType: string = this.translateService.instant(
          'assetConfigurations.table.typeValues.' + event.details.configType,
        );
        return this.translateService.instant('assetHistory.configurations.sensor-config-uploaded', {
          configName,
          configType,
        });
      }
      case AssetHistoryEventTypeIds.SENSOR_CONFIG_DELETED: {
        const configName: string = event.details.configName || '';
        const configType: string = this.translateService.instant(
          'assetConfigurations.table.typeValues.' + event.details.configType,
        );
        return this.translateService.instant('assetHistory.configurations.sensor-config-deleted', {
          configName,
          configType,
        });
      }
      case AssetHistoryEventTypeIds.SENSOR_BACKUP_CREATED: {
        const configName: string = event.details.configName || '';
        return this.translateService.instant('assetHistory.configurations.sensor-backup-created', {
          configName,
        });
      }
      case AssetHistoryEventTypeIds.SENSOR_BACKUP_RESTORED: {
        const configName: string = event.details.configName || '';
        return this.translateService.instant('assetHistory.configurations.sensor-backup-restored', {
          configName,
        });
      }
      case AssetHistoryEventTypeIds.CHILD_SWITCHED_TREE: {
        const eventDetails = event.details;
        const movedAsset = eventDetails.affectedChild.asset.name || unknownHistory;
        const targetTree = eventDetails.targetTree.name || unknownHistory;
        const targetParent = eventDetails.to.parent.name || unknownHistory;
        if (eventDetails.targetTree.uuid === eventDetails.to.parent.uuid) {
          // Asset was moved to root of target tree
          return this.translateService.instant('assetHistory.childSwitchedToForeignTree', {
            movedAsset,
            targetTree,
          });
        }
        // Asset was moved to subtree of a different tree
        return this.translateService.instant('assetHistory.childSwitchedToForeignSubtree', {
          movedAsset,
          targetTree,
          targetParent,
        });
      }
      default: {
        return this.translateService.instant('assetHistory.unsupportedEventType');
      }
    }
  }

  private translatePermission(permission: string): string {
    if (permission.includes('a')) {
      return this.translateService.instant('assetPermissions.admin');
    } else if (permission.includes('w')) {
      return this.translateService.instant('assetPermissions.write');
    } else if (permission.includes('r')) {
      return this.translateService.instant('assetPermissions.read');
    }
    return this.translateService.instant('assetHistory.unknown');
  }

  private translateAssetUpdatedDetails(
    assetTypeId: AssetHistoryEventTypeIds,
    details: AssetHistoryUpdateDetails,
  ) {
    let field = details.field || '';
    let detailFrom = details.from || '';
    let detailTo = details.to || '';

    if (details.field === 'deploymentDate') {
      const deploymentDates = this.translateService.instant('asset.deployment-date');
      field = deploymentDates[assetTypeMappingToString[assetTypeId]] || deploymentDates['default'];

      detailFrom = detailFrom ? this.localizedDatePipe.transform(new Date(detailFrom)) || '' : '';
      detailTo = detailTo ? this.localizedDatePipe.transform(new Date(detailTo)) || '' : '';
    } else {
      const translationKey = this.mapAssetUpdatedFieldToI18NKeys[field];
      if (translationKey) {
        field = this.translateService.instant('asset.' + translationKey);
      }
    }

    return {
      field,
      detailFrom,
      detailTo,
    };
  }

  private translateLinkUpdatedDetails(details: AssetHistoryLinkUpdateDetails) {
    let field = details.field || '';
    const from = details.from || '';
    const to = details.to || '';

    const translationKey = this.mapLinkUpdatedFieldToI18NKeys[field];
    if (translationKey) {
      field = this.translateService.instant('assetLinks.' + translationKey);
    }

    return {
      field,
      from,
      to,
    };
  }

  private parseLocation(details: SetGeoLocationRequest) {
    const address = [
      details.address1,
      details.address2,
      [details.zipCode, details.city].filter(x => x).join(' '),
      details.country,
    ]
      .filter(x => x)
      .join(', ');
    if (details.longitude) {
      return [address, `Long/Lat: ${details.longitude}/${details.latitude}`].join(' - ');
    }
    return address || '-/-';
  }
}
