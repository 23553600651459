import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { GetAssetResponse } from '../models/asset-details';
import { AssetService } from '../services/asset.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';

export const assetResolver: ResolveFn<GetAssetResponse> = (route: ActivatedRouteSnapshot) => {
  const uuid: string | undefined = route.params.uuid;

  if (!uuid) {
    throw new Error('Route does not contain uuid parameter');
  }

  const router = inject(Router);
  return inject(AssetService)
    .getAsset(uuid)
    .pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 404)) {
          router.navigate(['error', 'assetnotfound'], { skipLocationChange: true });
          return EMPTY;
        }
        throw error;
      }),
    );
};
