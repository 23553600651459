<form [formGroup]="mainForm" (ngSubmit)="onFormSubmit()">
  <div class="fb-row">
    <h4>{{ 'feedback.title2' | translate }}</h4>
  </div>
  <div class="fb-row">
    {{ 'feedback.rating.label' | translate }}:
    <p-rating [stars]="5" [cancel]="false" formControlName="rating"></p-rating>
  </div>
  <div>
    <p-radioButton
      formControlName="reason"
      value="question"
      [label]="'feedback.reason.question' | translate"
    ></p-radioButton>
    <p-radioButton
      formControlName="reason"
      value="bug"
      [label]="'feedback.reason.bug' | translate"
    ></p-radioButton>
    <p-radioButton
      formControlName="reason"
      value="idea"
      [label]="'feedback.reason.idea' | translate"
    ></p-radioButton>
    <p-radioButton
      formControlName="reason"
      value="other"
      [label]="'feedback.reason.other' | translate"
    ></p-radioButton>
  </div>
  <div class="fb-row">
    <span class="p-float-label description">
      <textarea pInputTextarea id="description" formControlName="description"></textarea>
      <label for="description">{{ 'feedback.description.label' | translate }}</label>
    </span>
  </div>
  <p-checkbox
    formControlName="anonymous"
    [binary]="true"
    label="{{ 'feedback.anonymous.label' | translate }}"
  >
  </p-checkbox>
  <div class="dialog-footer">
    <button
      pButton
      type="button"
      class="ui-button-outline"
      label="{{ 'buttons.cancel' | translate }}"
      (click)="onCloseButton()"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      label="{{ 'feedback.button' | translate }}"
      (click)="onFormSubmit()"
      [disabled]="!mainForm.valid || saving"
    ></button>
  </div>
</form>
