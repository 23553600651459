import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssetItem, GetAssetResponse } from '../../../models';
import {
  AdditionalExportItems,
  AssetSelectionService,
  BlockUiService,
  CSVExportService,
  ExportScopes,
  ExportScopesBackend,
} from '../../../services';
import { finalize } from 'rxjs';
import { saveAs } from 'file-saver';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-export-csv-modal',
  templateUrl: './export-csv-modal.component.html',
  styleUrls: ['./export-csv-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgressBarModule,
    ButtonModule,
    TranslateModule,
    CheckboxModule,
    RadioButtonModule,
    FormsModule,
  ],
})
export class ExportCsvModalComponent implements OnInit {
  loading = false;
  readonly additionalExportItems: AdditionalExportItems = {
    latestSopasConfigurations: true,
  };
  exportScope: ExportScopes = 'SUBTREE';

  private asset: GetAssetResponse | AssetItem;
  nodeIsTreeRoot = false;

  constructor(
    private ref: DynamicDialogRef,
    config: DynamicDialogConfig,
    private messageService: MessageService,
    private assetSelectionService: AssetSelectionService,
    private csvExportService: CSVExportService,
    private blockUiService: BlockUiService,
  ) {
    this.asset = config.data.asset;
    this.nodeIsTreeRoot = this.isTreeRoot();
    config.dismissableMask = true;
    this.ref.onClose.subscribe({
      next: () => {
        this.assetSelectionService.markNode();
      },
    });
  }

  ngOnInit() {
    this.assetSelectionService.markNode(this.asset.uuid);
  }

  onCancel() {
    this.ref.close();
  }

  startExport() {
    this.loading = true;
    this.blockUiService.block();
    const exportOpts = this.getExportOpts();
    this.csvExportService
      .exportTree(exportOpts.assetUuid, exportOpts.exportScope, this.additionalExportItems)
      .pipe(
        finalize(() => {
          this.blockUiService.unblock();
          this.ref.close();
        }),
      )
      .subscribe({
        next: res => saveAs(res.exportData, res.fileName),
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.file.download-failed',
            life: 10000,
          });
        },
      });
  }

  private getExportOpts(): {
    assetUuid: string;
    exportScope: ExportScopesBackend;
  } {
    if (this.exportScope === 'ENTIRE_TREE') {
      return {
        assetUuid: this.getRootId(),
        exportScope: 'SUBTREE',
      };
    }
    return {
      assetUuid: this.asset.uuid,
      exportScope: this.exportScope,
    };
  }

  isTreeRoot(): boolean {
    if (this.asset instanceof AssetItem) {
      return this.asset.isRoot();
    }
    return this.asset.rootUuid === null;
  }

  private getRootId(): string {
    if (this.asset instanceof AssetItem) {
      return this.asset.root.uuid;
    }
    return this.asset.rootUuid || this.asset.uuid;
  }
}
