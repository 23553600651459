import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import {
  GrantedGroupPermission,
  GroupPermissionsQuery,
  GroupPermissionsRequest,
  GroupPermissionsResponse,
} from '../models/group-permissions';

@Injectable({ providedIn: 'root' })
export class GroupPermissionsService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  public getGroupPermissions(
    assetUuid: string,
    qp?: GroupPermissionsQuery,
  ): Observable<GroupPermissionsResponse> {
    const query: { [param: string]: string } = {};
    if (qp) {
      if (qp.start && qp.start >= 0) {
        query.start = qp.start.toString();
      }
      if (qp.size) {
        query.size = qp.size.toString();
      }
    }

    return this.httpClient.get<GroupPermissionsResponse>(
      this.apiUrl + '/asset/' + assetUuid + '/group-permissions',
      { params: query },
    );
  }

  public grantPermissionsToGroup(
    assetUuid: string,
    permissions: GroupPermissionsRequest,
  ): Observable<void> {
    return this.httpClient.put<void>(
      this.apiUrl + '/asset/' + assetUuid + '/group-permission',
      permissions,
    );
  }

  public revokePermissionsForGroup(
    assetUuid: string,
    extGroupId: string,
  ): Observable<GrantedGroupPermission | void> {
    return this.httpClient.delete<GrantedGroupPermission>(
      this.apiUrl + '/asset/' + assetUuid + '/group-permission/' + extGroupId,
    );
  }
}
