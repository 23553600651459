import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface AssetHintingEvent {
  uuid: string;
  on: boolean;
}

@Injectable({ providedIn: 'root' })
export class AssetSelectionService {
  private highlightedNodeSubject = new Subject<AssetHintingEvent>();
  private selectedNodeSubject = new Subject<AssetHintingEvent>();
  private markedNodeSubject = new Subject<AssetHintingEvent>();

  private currentHighlightedNode?: string;
  private currentSelectedNode?: string;
  private currentMarkedNode?: string;

  public get $onNodeHighlighted() {
    return this.highlightedNodeSubject.asObservable();
  }

  public get $onNodeSelected() {
    return this.selectedNodeSubject.asObservable();
  }

  get $onNodeMarked() {
    return this.markedNodeSubject.asObservable();
  }

  public get highlightedNode() {
    return this.currentHighlightedNode;
  }

  public get selectedNode() {
    return this.currentSelectedNode;
  }

  public highlightNode(uuid?: string) {
    if (uuid === this.currentHighlightedNode) {
      return;
    }

    const oldHighlighted = this.currentHighlightedNode;
    this.currentHighlightedNode = uuid;

    this.highlightedNodeSubject.next({
      uuid: uuid ?? oldHighlighted!,
      on: uuid ? true : false,
    });
  }

  public selectNode(uuid?: string) {
    if (uuid === this.currentSelectedNode) {
      return;
    }
    if (this.currentSelectedNode) {
      this.selectedNodeSubject.next({ uuid: this.currentSelectedNode, on: false });
    }
    this.currentSelectedNode = uuid;
    if (this.currentSelectedNode) {
      this.selectedNodeSubject.next({ uuid: this.currentSelectedNode, on: true });
    }
  }

  markNode(uuid?: string): void {
    if (uuid === this.currentMarkedNode) {
      return;
    }
    if (this.currentMarkedNode) {
      this.markedNodeSubject.next({ uuid: this.currentMarkedNode, on: false });
    }
    this.currentMarkedNode = uuid;
    if (this.currentMarkedNode) {
      this.markedNodeSubject.next({ uuid: this.currentMarkedNode, on: true });
    }
  }
}
