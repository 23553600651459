<div class="menu-component-container" [ngClass]="{ 'icon-first': iconFirst }">
  <div *ngIf="label" class="label" [ngStyle]="{ color: labelColor }">{{ label }}</div>
  <div *ngIf="icon" class="menu-icon" #menuIcon>
    <app-icon [shape]="icon" [color]="iconColor" [size]="iconSize" [title]="title"></app-icon>
  </div>
</div>
<div #menuContent *ngIf="displayMenu" class="menu-content" [ngStyle]="menuContentStyle">
  <div #menuFrame class="menu-frame" [ngClass]="menuFrameClass">
    <div class="menu-canvas">
      <ng-content></ng-content>
    </div>
  </div>
</div>
