import { HttpHeaders } from '@angular/common/http';

export class HttpUtils {
  static readFileName(headers: HttpHeaders) {
    return headers
      .get('content-disposition')
      ?.match(/(?<=filename(?:=|\*=(?:[\w\-]+'')))["']?(?<filename>[^"';\n]+)["']?/g)
      ?.pop()
      ?.replace(/\"/g, '');
  }
}
