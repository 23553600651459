import { NgClass } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { AssetItem, AssetTypeMapping, assetTypeMappingToString } from '@assethub/shared/models';
import { TreeService } from '@assethub/shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { catchError, map, of, switchMap } from 'rxjs';
import { AssetTitlePipe } from '../../pipes/asset-title.pipe';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';

@UntilDestroy()
@Component({
  selector: 'app-asset-icon-label',
  templateUrl: './asset-icon-label.component.html',
  styleUrls: ['./asset-icon-label.component.scss'],
  standalone: true,
  imports: [
    ProgressSpinnerModule,
    AssetIconComponent,
    RouterLink,
    NgClass,
    TranslateModule,
    AssetTitlePipe,
  ],
})
export class AssetIconLabelComponent {
  readonly assetUuid = input.required<string>();
  readonly targetPage = input<string[]>([]);
  readonly ellipsis = input<boolean>(true);

  readonly navigated = output();

  readonly targetNavigationUrl = computed(() => this.computeTargetNavigationUrl());

  readonly assetNode = toSignal<AssetItem>(
    toObservable(this.assetUuid).pipe(
      switchMap(assetUuid =>
        this.treeService.fetchBranch(assetUuid).pipe(
          catchError(() => of(undefined)),
          map(node => node || this.FALLBACK_NODE),
        ),
      ),
    ),
  );

  private readonly FALLBACK_NODE: Pick<AssetItem, 'type'> = {
    type: assetTypeMappingToString[AssetTypeMapping.DEVICE],
  };

  constructor(private treeService: TreeService) {}

  computeTargetNavigationUrl(): undefined | string[] {
    if (!this.assetNode()?.uuid) {
      return undefined;
    }
    return ['/asset', this.assetUuid(), ...this.targetPage()];
  }

  preventBubbling(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.navigated.emit();
  }
}
