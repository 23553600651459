<div class="option-container">
  <button
    pButton
    type="button"
    class="ui-button-outline"
    label="{{ 'buttons.cancel' | translate }}"
    (click)="cancel()"
    [disabled]="cancelDisabled"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    label="{{ 'buttons.save' | translate }}"
    (click)="save()"
    [disabled]="saveDisabled"
  ></button>
</div>
