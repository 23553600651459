import { Component, ElementRef, computed, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { IconComponent } from '../icon/icon.component';

type Severity = 'success' | 'info' | 'warn' | 'error' | 'neutral';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
  standalone: true,
  imports: [IconComponent, ButtonModule],
})
export class CalloutComponent {
  readonly severity = input<Severity>('info');
  readonly closable = input(false);
  readonly shape = computed(() => this.computeShape());
  readonly showIcon = input(true);

  readonly calloutClosed = output();

  private static readonly shapes: Readonly<Record<Severity, string>> = {
    success: 'check_circle',
    info: 'info',
    warn: 'warning',
    error: 'error',
    neutral: 'settings',
  };

  constructor(private host: ElementRef<HTMLElement>) {}

  close() {
    this.calloutClosed.emit();
    this.host.nativeElement.remove();
  }

  private computeShape() {
    return CalloutComponent.shapes[this.severity()] || 'circle';
  }
}
