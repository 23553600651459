import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeAll, switchMap } from 'rxjs/operators';
import {
  AssetImageMetadata,
  AssetImages,
  assetTypeMappingToString,
  DeviceDetails,
  ENVIRONMENT,
  GetAssetResponse,
  ProductDetailsResultV2,
} from '../models';
import { RemoteResource } from '../models/remote-resource';
import { CompleteUrlPipe } from '../pipes';
import { ProductService } from './product-service';
import { MessageService } from 'primeng/api';

export const ZOOMED_IMAGE_SIZE = 9999;

export interface ProfilePicture {
  assetUuid: string;
  picture: AssetImages;
}

export interface UploadStatus {
  uuid: string;
  file: File;
  status: 'pending' | 'done' | 'error';
}

@Injectable({ providedIn: 'root' })
export class ImageService {
  private objectURLs: { [key: string]: string } = {};
  private pendingRequests: Map<string, Subject<string>>;
  private logger = new Logger(this.constructor.name);
  private apiUrl = inject(ENVIRONMENT).apiUrl;
  private imageProxyUrl = inject(ENVIRONMENT).imageProxyUrl;

  constructor(
    private httpClient: HttpClient,
    private productService: ProductService,
    private urlCompletePipe: CompleteUrlPipe,
    private messageService: MessageService,
  ) {
    this.pendingRequests = new Map<string, Subject<string>>();
  }

  public getDefaultPictureUrl(asset: GetAssetResponse): string {
    return `assets/images/${assetTypeMappingToString[asset.typeId] || 'location'}.svg`;
  }

  public fetchCustomPicture(asset: GetAssetResponse): Observable<ProfilePicture> {
    const assetUuid = asset.uuid;
    const picture = asset.profilePicture;
    const defaultPictureUrl = this.getDefaultPictureUrl(asset);
    if (!picture) {
      throw new Error('No custom profile picture set');
    }
    if (!picture.uuid) {
      return of(this.assignLoadedCustomPicture(asset, defaultPictureUrl));
    }
    if (picture.ready) {
      return of({ assetUuid, picture });
    }
    return this.proxyImageThumbnail(picture.uuid).pipe(
      map(url => this.assignLoadedCustomPicture(asset, url)),
      catchError(() => of(this.assignLoadedCustomPicture(asset, defaultPictureUrl))),
    );
  }

  private assignLoadedCustomPicture(asset: GetAssetResponse, url: string): ProfilePicture {
    if (!asset.profilePicture) {
      throw new Error('No custom profile picture set');
    }
    asset.profilePicture.normal = url;
    asset.profilePicture.ready = true;
    return { assetUuid: asset.uuid, picture: asset.profilePicture };
  }

  public fetchProductPicture(
    asset: GetAssetResponse,
    deviceDetails: DeviceDetails,
  ): Observable<ProfilePicture> {
    return this.productService.getProductDetails(deviceDetails.partNumber).pipe(
      map<ProductDetailsResultV2, ProfilePicture>(productDetails => {
        const retval: ProfilePicture = { assetUuid: asset.uuid, picture: { ready: true } };
        const images = this.getMediaFiles(
          productDetails.Product.MultiMedia.ProductImages.MediaFile,
        );
        if (images.size > 0) {
          const iterator = images.values();
          const firstImage = iterator.next();
          const secondImage = iterator.next();
          const normal = secondImage.done ? firstImage.value : secondImage.value;
          retval.picture.zoomed = this.getImageUrl(firstImage.value, ZOOMED_IMAGE_SIZE, true);
          retval.picture.normal = this.getImageUrl(normal, 320, true);
          retval.picture.small = this.getImageUrl(firstImage.value, 51, true);
        }
        return retval;
      }),
      catchError(err => {
        this.logger.error(err);
        return of<ProfilePicture>({
          assetUuid: asset.uuid,
          picture: { normal: this.getDefaultPictureUrl(asset), ready: true },
        });
      }),
    );
  }

  public getMediaFiles(source): Map<string, Map<number, object>> {
    const result = new Map<string, Map<number, object>>();
    if (source) {
      for (const item of source) {
        const path = item.URL.split('/');
        const fileName = path[path.length - 1];
        let bucket = result.get(fileName);
        if (!bucket) {
          bucket = new Map<number, object>();
          result.set(fileName, bucket);
        }
        bucket.set(path[2] === 'ZOOM' ? ZOOMED_IMAGE_SIZE : parseInt(path[2], 10), item);
      }
    }
    return result;
  }

  public getImageUrl(images: Map<number, object>, optSize: number, omitHost = false) {
    let currentDiff;
    let currentResult;
    const it = images.entries();
    for (let item = it.next(); !item.done; item = it.next()) {
      const diff = Math.abs(optSize - item.value[0]);
      if (currentDiff === undefined || currentDiff > diff) {
        currentDiff = diff;
        currentResult = item.value[1];
      }
    }
    if (omitHost) {
      return currentResult.URL;
    } else {
      return this.urlCompletePipe.transform(currentResult.URL);
    }
  }

  public deleteImage(assetUuid: string, imageUuid: string): Observable<void> {
    return this.httpClient
      .delete<void>(this.apiUrl + '/asset/' + assetUuid + '/image/' + imageUuid)
      .pipe(
        catchError(err => {
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.file.delete-failed',
            life: 5000,
          });
          throw err;
        }),
      );
  }

  public getUploadUrls(assetUuid: string, count: number): Observable<RemoteResource[]> {
    return this.httpClient.get<RemoteResource[]>(
      this.apiUrl + '/asset/' + assetUuid + '/image-upload',
      {
        params: {
          count: JSON.stringify(count),
        },
      },
    );
  }

  // uploaded images can be added explicitly
  public addUploadedImage(uuid: string, objectUrl: string) {
    if (this.objectURLs[uuid]) {
      this.logger.warn(`new image ${uuid} ignored because already cached`);
      return;
    }
    this.objectURLs[uuid] = objectUrl;
    this.objectURLs[uuid + '_small'] = objectUrl;
  }

  public addImageMetadata(imgMd: AssetImageMetadata): Observable<void> {
    return this.httpClient.put<void>(
      this.apiUrl + '/asset/' + imgMd.assetUuid + '/image/' + imgMd.uuid,
      { name: imgMd.name },
    );
  }

  public proxyImageObservable(uuid: string): Observable<string> {
    if (this.objectURLs[uuid]) {
      this.logger.debug('Returning already cached image %s', uuid);
      return of(this.objectURLs[uuid]);
    }

    const req = this.pendingRequests.get(uuid);
    if (req) {
      this.logger.debug('Returning pending request for %s', uuid);
      return req.asObservable();
    }

    // serverless-offline does not provide a media proxy to access images on S3
    const gfxUrl = this.imageProxyUrl
      ? `${this.imageProxyUrl}/image/${uuid}`
      : `${this.apiUrl}/graphics/${uuid}`;

    this.logger.debug('Fetching proxy image %s', uuid);
    const subject = new Subject<string>();
    this.pendingRequests.set(uuid, subject);

    this.httpClient
      .get(gfxUrl, { headers: { accept: ['image/png', 'image/jpeg'] }, responseType: 'blob' })
      .subscribe({
        next: data => {
          const result = this.cacheProxyImage(uuid, data);
          this.pendingRequests.delete(uuid);
          subject.next(result);
          subject.complete();
        },
        error: err => {
          this.pendingRequests.delete(uuid);
          subject.error(err);
        },
      });
    return subject.asObservable();
  }

  public proxyImageThumbnail(uuid: string): Observable<string> {
    return this.proxyImageObservable(uuid + '_small');
  }

  public cacheProxyImage(uuid: string, data: Blob): string {
    this.objectURLs[uuid] = window.URL.createObjectURL(data);
    return this.objectURLs[uuid];
  }

  // This method releases the objectURLs, effectively freeing memory. However, clients of this service
  // must actually call this. As this was introduced, no client does keep track of images that have been
  // fetched from here; ie. when ngOnDestroy` is called in a client component, this would need to be done.
  public freeProxyImage(uuid: string) {
    if (!this.objectURLs[uuid]) {
      return;
    }

    this.logger.debug('Freeing cached image %s', uuid);
    window.URL.revokeObjectURL(this.objectURLs[uuid]);
    delete this.objectURLs[uuid];
  }

  uploadImages(assetUuid: string, images: File[]): Observable<UploadStatus[]> {
    return this.getUploadUrls(assetUuid, images.length).pipe(
      switchMap(res => {
        const status: UploadStatus[] = [];
        const uploads: Observable<UploadStatus[]>[] = [];
        for (let i = 0; i < images.length; i++) {
          const upload = res[i];
          const file = images[i];
          status.push({ uuid: upload.uuid, file, status: 'pending' });
          uploads.push(this.uploadImageInternal(assetUuid, upload.presignedUrl, i, status));
          this.addUploadedImage(upload.uuid, window.URL.createObjectURL(file));
        }
        uploads.unshift(of(status));
        return uploads;
      }),
      mergeAll(3),
    );
  }

  private uploadImageInternal(
    assetUuid: string,
    url: string,
    index: number,
    status: UploadStatus[],
  ): Observable<UploadStatus[]> {
    const uuid = status[index].uuid;
    const file = status[index].file;
    const name = file.name || '';
    return this.httpClient
      .put(url, status[index].file, { headers: { 'x-amz-server-side-encryption': 'AES256' } })
      .pipe(
        switchMap(() => this.addImageMetadata({ assetUuid, uuid, name })),
        map(() => ((status[index].status = 'done'), status)),
        catchError(() => {
          status[index].status = 'error';
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.file.upload-failed',
            data: { filename: status[index].file.name },
            life: 10000,
          });
          return of(status);
        }),
      );
  }
}
