import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ENVIRONMENT } from '../models';
import {
  ArtifactSortCriteria,
  SearchArtifactResponse,
  SoftwareArtifact,
} from '../models/software-artifact';

@Injectable({
  providedIn: 'root',
})
export class ArtifactRepoService {
  private artifactRepositoryUrl = inject(ENVIRONMENT).artifactRepositoryUrl;

  constructor(private httpClient: HttpClient) {}

  getByPartNumber(
    itemNumber: string,
    artifactSort?: ArtifactSortCriteria,
  ): Observable<SearchArtifactResponse> {
    return this.httpClient
      .get<SearchArtifactResponse>(
        this.artifactRepositoryUrl + '/artifact/item-number/' + itemNumber,
        {
          params: { ...artifactSort },
        },
      )
      .pipe(
        map(res => ({
          // Creation date is received as string value
          result: res.result.map(artifact => ({
            ...artifact,
            createdAt: new Date(artifact.createdAt),
          })),
        })),
      );
  }

  getLatestArtifactByPartNumber(itemNumber: string): Observable<SoftwareArtifact | undefined> {
    return this.httpClient.get<SoftwareArtifact | undefined>(
      this.artifactRepositoryUrl + '/artifact/item-number/' + itemNumber + '/latest',
    );
  }

  downloadArtifact(endpoint: string): Observable<ArrayBuffer> {
    return this.httpClient
      .get(this.artifactRepositoryUrl + endpoint, {
        responseType: 'text',
      })
      .pipe(
        switchMap(presignedDownloadUrl => {
          const options = { responseType: 'arraybuffer' as const, withCredentials: false };
          return this.httpClient.get(presignedDownloadUrl, options);
        }),
      );
  }
}
