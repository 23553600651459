import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetItem, AssetRootItem, ENVIRONMENT } from '@assethub/shared/models';
import {
  MoveAssetPreflightInfo,
  isOptionalIssue,
} from '@assethub/shared/models/move-asset-preflight-checks';
import { AssetSelectionService, ConfirmationService } from '@assethub/shared/services';
import { BlockUiService } from '@assethub/shared/services/block-ui-service';
import { Logger, pathFromRoutingTemplate } from '@assethub/shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, finalize } from 'rxjs';
import { CalloutComponent } from '../../callout/callout.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { TreeSelectorComponent } from '../../tree-selector/tree-selector.component';
import { AssetService } from './../../../services/asset.service';
import { TreeService } from './../../../services/tree.service';

@UntilDestroy()
@Component({
  selector: 'app-switch-tree',
  templateUrl: './switch-tree.component.html',
  styleUrls: ['./switch-tree.component.scss'],
  standalone: true,
  imports: [
    TreeSelectorComponent,
    NgIf,
    CalloutComponent,
    ButtonModule,
    NgFor,
    NgClass,
    CheckboxModule,
    FormsModule,
    TooltipComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SwitchTreeComponent implements OnInit {
  loading = false;
  trees: AssetRootItem[] = [];
  selectedTree?: AssetRootItem;

  keepPermissionsOpt = true;
  keepHistoryOpt = true;
  keepPermissions = true;
  keepHistory = true;
  readonly digitalServiceCatalogUrl = inject(ENVIRONMENT).digitalServiceCatalogUrl.basePath;

  private $preflightCheck: BehaviorSubject<
    MoveAssetPreflightInfo & {
      treeLoadError?: boolean;
      preflightCheckError?: boolean;
      moveProcessFailed?: boolean;
    }
  > = new BehaviorSubject({});
  private currentAsset: AssetItem;
  private logger = new Logger(this.constructor.name);

  get onPreflightCheckUpdate() {
    return this.$preflightCheck.asObservable();
  }

  constructor(
    private treeService: TreeService,
    private assetService: AssetService,
    private dialogRef: DynamicDialogRef,
    private diagConfig: DynamicDialogConfig,
    private router: Router,
    private confirmationService: ConfirmationService,
    public assetSelectionService: AssetSelectionService,
    private blockUiService: BlockUiService,
  ) {}

  ngOnInit(): void {
    const assetId = this.diagConfig.data.assetId;
    const currentAsset = this.treeService.findNode(assetId);

    if (!currentAsset) {
      throw new Error('Source asset to be moved is not available!');
    }

    this.dialogRef.onClose
      .pipe(
        untilDestroyed(this),
        finalize(() => this.assetSelectionService.markNode()),
      )
      .subscribe();
    this.assetSelectionService.markNode(assetId);

    this.onPreflightCheckUpdate.pipe(untilDestroyed(this)).subscribe({
      next: () => this.updateMoveOptionCheckStates(),
    });

    this.currentAsset = currentAsset;
    this.loadAvailableTrees();
  }

  uiEnabled(): boolean {
    return this.loading === false;
  }

  moveOperationEnabled(): boolean {
    return (
      this.uiEnabled() === true && !!this.selectedTree && this.hasPreflightCheckIssues() === false
    );
  }

  hasPermissionMoveRights(): boolean {
    // If canMovePermissions is not available or true, permissions can be moved.
    return !(this.$preflightCheck.value.canMovePermissions?.value === false);
  }

  updatePermissionSelection(keepPermissions: boolean) {
    this.keepPermissionsOpt = keepPermissions;
  }

  updateHistorySelection(keepHistory: boolean) {
    this.keepHistoryOpt = keepHistory;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openAssetInNewTab(uuid: string) {
    const newRelativeUrl = this.router.createUrlTree(['asset', uuid]);
    const baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  selectTree(targetTreeId: string) {
    this.selectedTree = this.trees.find(t => t.uuid === targetTreeId);
    this.checkMoveCanBeDone();
  }

  checkMoveCanBeDone() {
    if (!this.selectedTree) {
      return;
    }

    this.$preflightCheck.next({});
    if (this.selectedTree.managed === true) {
      // No service tree allowed
      this.$preflightCheck.next({ isManagedNode: true });
      // Will not perform further preflight checks when basic checks before has been failed
      return;
    }

    this.loading = true;
    this.assetService
      .moveAssetPreflightChecks(this.currentAsset.uuid, this.selectedTree.uuid, true)
      .subscribe({
        next: preflightRes => {
          this.$preflightCheck.next(preflightRes);
          this.loading = false;
        },
        error: error => {
          this.logger.error(
            'Performing preflight checks for moving assets to new tree failed = ',
            error,
          );
          this.$preflightCheck.next({ preflightCheckError: true });
          this.loading = false;
        },
      });
  }

  moveToSelectedTree() {
    if (this.selectedTree === undefined) {
      return;
    }

    const selectedTree = this.selectedTree;

    if (this.currentAsset.isRoot()) {
      // Asset to be moved must be the root itself. Show warn and accept dialog
      this.confirmationService.confirm({
        translationKey: 'switchAssetTree.dialog.moveFullTree',
        accept: () => {
          this.moveToTree(this.currentAsset, selectedTree);
        },
      });
      return;
    }

    this.moveToTree(this.currentAsset, selectedTree);
  }

  loadAvailableTrees() {
    this.treeService.fetchTrees().subscribe({
      next: trees => {
        // Avoid to be able to select the tree we are inside right now.
        this.trees = trees.filter(t => t.uuid !== this.currentAsset.root.uuid);

        if (this.trees.length > 0) {
          this.selectTree(this.trees[0].uuid);
          return;
        }
      },
      error: error => {
        this.logger.error('Error while loading available trees = ', error);
        this.$preflightCheck.next({ treeLoadError: true });
      },
    });
  }

  private moveToTree(sourceAsset: AssetItem, selectedTree: AssetRootItem) {
    this.loading = true;
    this.blockUiService.block();

    // Only admins and owners of destination tree can set this flag!
    const keepExistingPermissions = this.hasPermissionMoveRights()
      ? this.keepPermissions
      : undefined;

    this.assetService
      .moveAssetToDifferentTree(
        sourceAsset.uuid,
        selectedTree.uuid,
        this.keepHistory,
        keepExistingPermissions,
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this.blockUiService.unblock();
        }),
      )
      .subscribe({
        next: () => {
          // If current asset is a sub-tree, move to current parent.
          // In case it is a tree-root itself, navigate to to the tree which is the new parent.
          const destinationNode = sourceAsset.parent ?? selectedTree;
          this.treeService.moveNodeToTree(sourceAsset, selectedTree);
          this.router.navigate(
            pathFromRoutingTemplate(this.router.routerState.snapshot.root, {
              uuid: destinationNode.uuid,
            }),
          );
          this.closeDialog();
        },
        error: error => {
          this.logger.error('Error during moving asset to a new tree = ', error);
          this.$preflightCheck.next({ moveProcessFailed: true });
        },
      });
  }

  private updateMoveOptionCheckStates() {
    this.keepHistory = this.keepHistoryOpt === true && this.hasPreflightCheckIssues() === false;
    this.keepPermissions =
      this.keepPermissionsOpt === true &&
      this.hasPermissionMoveRights() === true &&
      this.hasPreflightCheckIssues() === false;
  }

  private hasPreflightCheckIssues(): boolean {
    return (
      Object.values(this.$preflightCheck.value).filter(i => {
        if (isOptionalIssue(i)) {
          return false;
        }

        if (Array.isArray(i)) {
          return i.length;
        }

        return i;
      }).length > 0
    );
  }
}
