import { Directive, input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTemplate]',
  standalone: true,
})
export class TemplateDirective {
  readonly appTemplate = input.required<string>();

  get key(): string {
    return this.appTemplate();
  }

  constructor(public template: TemplateRef<unknown>) {}
}
