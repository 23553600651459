import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of, tap } from 'rxjs';
import { AssetImages, ENVIRONMENT, GetAssetResponse } from '../models';
import { validateIsVendorSick } from '../utils';
import { ImageService } from './image.service';
import { TreeService } from './tree.service';

export interface AssetProfilePicture {
  assetUuid: string;
  picture: AssetImages;
}

@Injectable({ providedIn: 'root' })
export class ProfilePictureService {
  private profilePicture: BehaviorSubject<AssetProfilePicture> = new BehaviorSubject({
    assetUuid: '',
    picture: { normal: 'assets/images/device.svg', ready: true },
  });

  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(
    private httpClient: HttpClient,
    private treeService: TreeService,
    private imageService: ImageService,
  ) {}

  get profilePictureChanged() {
    return this.profilePicture.asObservable();
  }

  public setProfilePicture(asset: { uuid: string }, image: { uuid?: string }): Observable<void> {
    const picture: AssetImages = {
      uuid: image.uuid,
      ready: false,
    };
    this.profilePicture.next({ assetUuid: asset.uuid, picture });
    this.treeService.updateNode({
      uuid: asset.uuid,
      profilePicture: image.uuid,
    });
    return forkJoin({
      update: this.httpClient.put<void>(`${this.apiUrl}/asset/${asset.uuid}/profilepicture`, {
        imageUuid: image.uuid,
      }),
      load: this.imageService.proxyImageThumbnail(image.uuid!).pipe(
        tap(url => {
          picture.normal = url;
          picture.ready = true;
        }),
      ),
    }).pipe(map(() => undefined));
  }

  public deleteProfilePicture(asset: GetAssetResponse): Observable<void> {
    this.treeService.updateNode({
      uuid: asset.uuid,
      profilePicture: '',
    });
    return this.httpClient.delete<void>(`${this.apiUrl}/asset/${asset.uuid}/profilepicture`).pipe(
      tap(() => {
        this.getProfilePicture(asset).subscribe(picture => this.profilePicture.next(picture));
      }),
    );
  }

  public getProfilePicture(asset: GetAssetResponse): Observable<AssetProfilePicture> {
    if (asset.profilePicture) {
      return this.imageService.fetchCustomPicture(asset);
    }

    if (asset.details && validateIsVendorSick(asset.details.vendor)) {
      return this.imageService.fetchProductPicture(asset, asset.details);
    }

    return of<AssetProfilePicture>({
      assetUuid: asset.uuid,
      picture: { ready: true },
    });
  }
}
