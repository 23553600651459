import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-menu-item-asset-create',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetCreateComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('add', 'assetDetails.menu.add');
  }

  static override isViableOption(asset?: GetAssetResponse | AssetItem): boolean {
    if (BasicMenuItemAssetComponent.isManagedExternally(asset)) {
      return false;
    }
    return BasicMenuItemAssetComponent.isWritable(asset);
  }

  protected override getReason(): string {
    if (BasicMenuItemAssetComponent.isManagedExternally(this.asset)) {
      return 'managedExternally';
    }
    if (!BasicMenuItemAssetComponent.isWritable(this.asset)) {
      return 'createRequiresWrite';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.router.navigate(['/asset', this.asset.uuid, 'asset-management', 'add']);
  }
}
