import {
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  HostBinding,
  QueryList,
  ContentChildren,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Directive,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../../services/menu.service';
import { SDSColors } from '@assethub/shared/utils';
import { TooltipService } from '../../services/tooltip.service';

enum State {
  Hidden = 0,
  PrepareToFadeIn = 1,
  Visible = 2,
  FadingOut = 3,
}

const MENU_DELAY = 170;

// DO NOT define content in a template and reference it with <ng-container *ngTemplateOutlet...
// It will not work properly because dependency injection will not inject the parent component
// and there seems to be no way to even access the list of children via ContentChildren.
// See https://github.com/angular/angular/issues/14842
@Directive()
export class BasicMenuComponent implements OnDestroy, OnChanges, OnInit {
  @Output() showMenu = new EventEmitter<any>();
  @Output() hideMenu = new EventEmitter<any>();

  @Input() hideIfClickedOutside = true;
  @Input() hideOnAnyUserInteraction = false;
  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Input() gap: string | number = 0;
  @Input() backgroundColor: string = SDSColors.background;
  openOnHover = false;

  @HostBinding('class.expanded')
  expanded = false;

  // Use this flag in an ngIf to show or hide the menuContent element
  displayMenu = false;

  // Helper to calculate the z-index so all members of a nested menu structure overlap correctly
  @Input() set depth(value: number | undefined) {
    this._depth = value;
  }
  get depth(): number {
    if (this._depth !== undefined) {
      return this._depth;
    }
    let depth = 0;
    for (let i = this.parentMenu; i !== null; i = i.parentMenu) {
      depth++;
    }
    return depth;
  }
  private _depth: number | undefined;

  @ViewChild('menuContent')
  protected menuContent: ElementRef<HTMLDivElement>;

  @ContentChildren(BasicMenuComponent)
  private submenus: QueryList<BasicMenuComponent>;

  private hasTouchEvents = false;

  // helper variable to prevent multiple quick events like wheel-events from repeatedly starting the fade-out animation
  protected state: State = State.Hidden;
  private stateChangeTimer = -1;
  private userInteractionSubscription?: Subscription;
  private hideAllSubscription?: Subscription;

  constructor(
    protected parentMenu: BasicMenuComponent | null,
    protected element: ElementRef<HTMLElement>,
    protected menuService: MenuService,
    protected tooltipService: TooltipService,
  ) {}

  ngOnInit(): void {
    if (this.parentMenu) {
      this.openOnHover = this.parentMenu.openOnHover;
    }
    this.element.nativeElement.addEventListener('mousedown', ev => this.onMouseDown(ev));
    if (this.openOnHover) {
      this.element.nativeElement.addEventListener('mouseenter', ev =>
        this.onMouseEnterComponent(ev),
      );
      this.element.nativeElement.addEventListener('mouseleave', ev =>
        this.onMouseLeaveComponent(ev),
      );
      this.element.nativeElement.addEventListener('touchstart', ev => this.onTouchStart(ev));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.disabled) {
      this.hideAllSubmenus();
      this.hide();
    }
  }

  ngOnDestroy() {
    // If selecting a menu item has the consequence that the app-menu node is removed
    // for example by an ngIf, the expanded menu canvas stays behind and has to be
    // cleaned up here.
    if (this.displayMenu) {
      this.menuContent.nativeElement.remove();
    }
    this.monitorCloseEvents(false);
  }

  onTouchStart(event: TouchEvent) {
    this.hasTouchEvents = true;
    event.stopImmediatePropagation();
    event.preventDefault();
    this.toggleVisibility();
  }

  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.hasTouchEvents) {
      return; // IPad Safari keeps sending mouse events
    }
    this.toggleVisibility();
  }

  onMouseEnterComponent(event: MouseEvent) {
    if (this.hasTouchEvents) {
      return; // IPad Safari keeps sending mouse events
    }
    this.show();
  }

  onMouseLeaveComponent(event: MouseEvent) {
    if (this.hasTouchEvents) {
      return; // IPad Safari keeps sending mouse events
    }
    const x = event.clientX;
    const y = event.clientY;
    if (this.isOutsideMenuContent(x, y)) {
      this.hideAllParentsWhereOutsideMenuContent(x, y);
      this.hide();
    }
  }

  onMouseLeaveContent(event: MouseEvent) {
    if (this.hasTouchEvents) {
      return; // IPad Safari keeps sending mouse events
    }
    const x = event.clientX;
    const y = event.clientY;
    if (!this.isSubmenuExpanded()) {
      if (this.isOutsideComponent(x, y)) {
        this.hideAllParentsWhereOutsideMenuContent(x, y);
        this.hide();
      }
    }
  }

  isSubmenuExpanded() {
    if (!this.submenus) {
      return false;
    }
    for (const submenu of this.submenus) {
      if (submenu.expanded) {
        return true;
      }
    }
    return false;
  }

  hideAllSubmenus() {
    if (!this.submenus) {
      return;
    }
    this.submenus.forEach(x => {
      x.hideAllSubmenus();
      x.hide();
    });
  }

  hideAll() {
    if (this.parentMenu) {
      let rootMenu = this.parentMenu;
      while (rootMenu.parentMenu) {
        rootMenu = rootMenu.parentMenu;
      }
      rootMenu.hide();
    } else {
      this.hide();
    }
  }

  hide() {
    switch (this.state) {
      case State.Visible:
        // current state: menu contents are visible or currently fading in
        this.tooltipService.hideAll();
        this.hideAllSubmenus();
        this.state = State.FadingOut;
        const menuContent = this.menuContent.nativeElement;
        menuContent.style.opacity = '0';
        menuContent.style.pointerEvents = 'none';
        this.expanded = false;
        this.stateChangeTimer = window.setTimeout(() => {
          this.displayMenu = false;
          this.state = State.Hidden;
        }, MENU_DELAY);
        break;
      case State.PrepareToFadeIn:
        // this situation can occur when pointer is moved over the component's area quickly
        window.clearTimeout(this.stateChangeTimer);
        this.expanded = false;
        this.displayMenu = false;
        this.state = State.Hidden;
        break;
      default:
        return;
    }
    this.monitorCloseEvents(false);
    if (this.parentMenu) {
      this.parentMenu.hideIfClickedOutside = true;
    }
    this.hideMenu.emit(null);
  }

  private onClickedOutside(event: MouseEvent | WheelEvent | TouchEvent) {
    if (this.hideIfClickedOutside) {
      if (event.type === 'blur') {
        return;
      }
      let x = -9999;
      let y = -9999;
      if (event instanceof MouseEvent) {
        x = event.clientX;
        y = event.clientY;
      }
      // Firefox has no support for TouchEvent unless touch is enabled
      if (window.TouchEvent && event instanceof TouchEvent && event.type === 'touchstart') {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      }
      this.hideAllParentsWhereOutsideMenuContent(x, y);
      this.hide();
    }
  }

  private hideAllParentsWhereOutsideMenuContent(x: number, y: number) {
    let current = this.parentMenu;
    while (current) {
      const rect = current.menuContent.nativeElement.getBoundingClientRect();
      if (x <= rect.right && x >= rect.left && y >= rect.top && y <= rect.bottom) {
        return;
      }
      current.hide();
      current = current.parentMenu;
    }
  }

  protected toggleVisibility(): void {
    if (this.state === State.Hidden) {
      this.show();
    } else if (this.state === State.Visible) {
      this.hide();
    }
  }

  show() {
    if (this.disabled) {
      return;
    }
    switch (this.state) {
      case State.Hidden:
        this.tooltipService.hideAll();
        if (this.parentMenu) {
          this.parentMenu.hideAllSubmenus();
          this.parentMenu.hideIfClickedOutside = false;
        }
        this.state = State.PrepareToFadeIn;
        this.stateChangeTimer = window.setTimeout(() => {
          // all this happens after a zero delay timeout because displayMenu has been set to true
          // and the corresponding HTML must be added to the DOM-tree before we can continue
          //
          // next: move the drop-down box to top of DOM tree so it can be rendered on top of everything
          const appRoot = window.document.body.firstElementChild;
          if (!appRoot) {
            return;
          }
          appRoot.insertBefore(this.menuContent.nativeElement, appRoot.firstChild);
          if (this.openOnHover) {
            this.menuContent.nativeElement.addEventListener('mouseleave', ev =>
              this.onMouseLeaveContent(ev),
            );
          }
          this.positionMenuContent();
          const menubox = this.menuContent.nativeElement;
          menubox.style.transition = `opacity ${MENU_DELAY}ms linear`;
          menubox.style.opacity = '1';
          menubox.style.pointerEvents = 'auto';
          menubox.style.zIndex = (this.depth * 10 + 2000).toString();
          menubox.style.setProperty('--menu-background-color', this.backgroundColor);
          this.state = State.Visible;
        });
        break;
      case State.FadingOut:
        window.clearTimeout(this.stateChangeTimer);
        this.menuContent.nativeElement.style.opacity = '1';
        this.menuContent.nativeElement.style.pointerEvents = 'auto';
        this.state = State.Visible;
        break;
      default:
        return;
    }
    this.monitorCloseEvents(true);
    this.expanded = true;
    this.displayMenu = true;
    // emit before actually showing the menu to give the component time to prepare the contents
    // so positionMenuContent() has the right dimensions to work with
    this.showMenu.emit();
  }

  private monitorCloseEvents(active: boolean): void {
    if (this.userInteractionSubscription) {
      this.userInteractionSubscription.unsubscribe();
      this.userInteractionSubscription = undefined;
    }
    if (this.hideAllSubscription) {
      this.hideAllSubscription.unsubscribe();
      this.hideAllSubscription = undefined;
    }
    if (active) {
      this.hideAllSubscription = this.menuService.hideAll$.subscribe({
        next: () => this.hideAll(),
      });
      this.userInteractionSubscription = this.menuService.userInteraction$.subscribe({
        next: event => {
          if (this.hideOnAnyUserInteraction) {
            this.hideAll();
          } else {
            if (this.state !== State.Visible) {
              return;
            }
            const nativeElem = this.menuContent?.nativeElement;
            if (nativeElem && !nativeElem.contains(event.target as Node)) {
              this.onClickedOutside(event);
            }
          }
        },
      });
    }
  }

  protected isOutsideMenuContent(x: number, y: number): boolean {
    if (!this.menuContent) {
      return true;
    }
    const rect = this.menuContent.nativeElement.getBoundingClientRect();
    // When mouseleave fires in Chrome because the mouse slowly crosses the border from
    // the component into the content area, the reported mouse position is still slightly
    // less than the left end of the content's rectangle. This only happens during the
    // transition from left to right but just to be on the safe side, add one to each direction.
    return x < rect.left - 1 || x > rect.right + 1 || y < rect.top - 1 || y > rect.bottom + 1;
  }

  protected isOutsideComponent(x: number, y: number): boolean {
    const rect = this.element.nativeElement.getBoundingClientRect();
    return x < rect.left || x > rect.right || y < rect.top || y > rect.bottom;
  }

  protected positionMenuContent(): void {
    // override me
  }
}
