<div class="card flex justify-content-center">
  <app-menu #overlay direction="dr" icon="" [showTriangle]="false">
    <div class="search-results">
      @if (overallState === 'empty') {
        <div class="empty">
          {{ 'global-search.no-data' | translate }}
        </div>
      }

      @if (overallState === 'error') {
        <div class="error">
          {{ 'global-search.error' | translate }}
        </div>
      }

      @for (resultGroup of results; track resultGroup) {
        <span class="group-title">
          {{ 'global-search.groups.' + resultGroup.label + '.label' | translate }}
        </span>
        @for (item of resultGroup.items; track item) {
          <div (click)="onSelection(item)" class="search-result">
            <div class="picture">
              @if (item.assetImage) {
                <app-asset-icon [asset]="item.assetImage" size="38"></app-asset-icon>
              }

              @if (item.icon) {
                <app-icon [shape]="item.icon" size="38"></app-icon>
              }
            </div>
            <div class="label">
              <div class="primary" [innerHTML]="item.primaryLabel | highlight: item.keyword"></div>
              @if (item.secondaryLabel) {
                <div
                  class="secondary"
                  [innerHTML]="item.secondaryLabel | highlight: item.keyword"
                ></div>
              }
            </div>
          </div>
        }
      }
      @if (overallState === 'searching') {
        <div class="searching">
          <p-skeleton></p-skeleton>
        </div>
      }
      @if (overallState !== 'error') {
        @if (searchState().assetState === 'error') {
          <div class="error">
            {{ 'global-search.groups.assets.error' | translate }}
          </div>
        }
        @if (searchState().workOrderState === 'error') {
          <div class="error">
            {{ 'global-search.groups.workOrders.error' | translate }}
          </div>
        }
        @if (searchState().caseState === 'error') {
          <div class="error">
            {{ 'global-search.groups.cases.error' | translate }}
          </div>
        }
        @if (searchState().documentState === 'error') {
          <div class="error">
            {{ 'global-search.groups.documents.error' | translate }}
          </div>
        }
      }
    </div>
  </app-menu>
  <div #searchField>
    <app-expandable-search-field
      (valueChange)="searchChanged($event)"
      (expandStateChanged)="expandChanged($event)"
      (searchIconClicked)="searchChanged($event)"
    >
    </app-expandable-search-field>
  </div>
</div>
