import { ActionElement, EventElement, PropertyElement } from 'wot-thing-description-types';

export interface ReceivedData {
  date: Date;
  payload?: object;
  error?: string;
  topic?: string; // set for received SICK profile (MQTT) events
  wotEventName?: string; // set for received WoT events
  wotPropertyName?: string; // set for received WoT events

  // SICK profile elements
  property?: LiveDataProperty;
  event?: LiveDataEvent;

  // WoT elements
  wotProperty?: LiveDataWotProperty;
  wotEvent?: LiveDataWotEvent;
}

export interface LiveDataProperty {
  name: string;
  method: string;
  serviceLocation: string;
  path: string;
  apiName: string;
  description?: string;
}

export type LiveDataWotProperty = Pick<
  PropertyElement,
  'title' | 'forms' | 'description' | 'readOnly' | 'writeOnly' | 'observable'
> & {
  name: string;
};

export interface LiveDataEvent {
  name: string;
  profileUuid: string;
  topic: string;
  apiName: string;
}

export type LiveDataWotEvent = Pick<
  EventElement,
  'title' | 'forms' | 'description' | 'readOnly' | 'writeOnly'
> & {
  name: string;
};

export type LiveDataWotAction = Pick<
  ActionElement,
  'title' | 'forms' | 'description' | 'readOnly' | 'writeOnly'
> & {
  name: string;
};

export interface ApiDescription {
  profileUuid: string;
  title: string;
  apiType: 'OpenAPI' | 'AsyncAPI';
  document: any;
  serviceLocation?: string;
}

export interface DeviceDescription {
  properties: LiveDataProperty[];
  events: LiveDataEvent[];
}

export interface OpenApiDescription extends ApiDescription {
  apiType: 'OpenAPI';
  properties?: LiveDataProperty[];
  document: string;
}

export interface AsyncApiDescription extends ApiDescription {
  apiType: 'AsyncAPI';
  events: LiveDataEvent[];
  document: AsyncApiDocument;
}

export interface AsyncApiDocument {
  asyncapi: string;
  info?: { [key: string]: any };
  baseTopic?: string;
  topics?: { [key: string]: any };
  channels?: { [key: string]: any };
}
