import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { FrontendInstanceService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AssetHubOriginInterceptor {
  private apiUrl = inject(ENVIRONMENT).apiUrl;
  constructor(private frontendInstanceService: FrontendInstanceService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.startsWith(this.apiUrl)) {
      return next.handle(
        request.clone({
          headers: request.headers.set('AssetHub-Origin', this.frontendInstanceService.getId()),
        }),
      );
    }

    return next.handle(request);
  }
}
