import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ProgressElement, ProgressState } from '../../models/progress-status-model';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { IconComponent } from '../icon/icon.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-status-line',
  templateUrl: './progress-status.component.html',
  styleUrls: ['./progress-status.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, ProgressSpinnerModule, IconComponent, ProgressBarModule, TranslateModule],
})
export class ProgressStatusComponent {
  @Input()
  hideAfterCompleteTimeout = 5000;

  statusList: ProgressElement[] = [];
  iconShape = '';

  constructor(private changeDetector: ChangeDetectorRef) {}

  next(progressItem: ProgressElement) {
    this.statusList.push(progressItem);
    this.changeDetector.detectChanges();
  }

  complete() {
    this.updateState('success');
    this.reset(this.hideAfterCompleteTimeout);
  }

  updateState(state: ProgressState, messageTranslateKey?: string, progress?: number) {
    const currentProgressElement = this.getCurrent();

    if (currentProgressElement) {
      currentProgressElement.state = state;

      if (messageTranslateKey) {
        currentProgressElement.messageTranslateKey = messageTranslateKey;
      }

      if (progress !== undefined) {
        currentProgressElement.progress = progress;
      }

      if (state === 'error') {
        this.reset(this.hideAfterCompleteTimeout);
        currentProgressElement.progress = undefined;
      } else if (state === 'success') {
        if (currentProgressElement.progress !== undefined) {
          currentProgressElement.progress = 100;
        }
      }
    }
  }

  reset(timeout?: number) {
    if (!timeout) {
      this.statusList = [];
      return;
    }
    setTimeout(() => {
      this.statusList = [];
    }, timeout);
  }

  currentlyActive() {
    return this.statusList.length > 0;
  }

  private getCurrent(): ProgressElement | undefined {
    return this.statusList[this.statusList.length - 1];
  }
}
