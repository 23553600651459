@if (assetId()) {
  <div class="container">
    @if (trees().length > 1) {
      <div class="tree-selector">
        @if (draggedNode() !== undefined) {
          <app-asset-drop-zone
            label="asset-drag-drop.switch-trees.label"
            (assetDropped)="onAssetDropped($event)"
          ></app-asset-drop-zone>
        } @else {
          <app-tree-selector
            #treeSelector
            [disableNonServiceTrees]="onlyServiceTrees()"
            [trees]="trees()"
            [activeTreeId]="treeId()"
            (treeSelected)="navigateToTree($event)"
          ></app-tree-selector>
        }
      </div>
    }
    @if (breadcrumbs().length > 0) {
      <div class="breadcrumb">
        <app-breadcrumb
          [items]="breadcrumbs()"
          (itemSelected)="navigateToAsset($event)"
        ></app-breadcrumb>
      </div>
    }
    <div class="toolbar">
      <ng-content select="[slot=toolbar]"></ng-content>
    </div>
  </div>
}
