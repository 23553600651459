import { formatDate, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { LanguageService } from '@assethub/shared/services';
import { MenuComponent } from '../menu/menu/menu.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [MenuComponent, CalendarModule, FormsModule, NgIf, ButtonModule, TranslateModule],
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  @Input() minDate?: number;
  @Input() maxDate?: number;
  @Input() dateRange: number[] = [];
  @Output() dateRangeChange = new EventEmitter<number[]>();
  @Output() dateChange = new EventEmitter();
  label = '';
  dateRangeIntern: any = null;
  minDateIntern = new Date(0);
  maxDateIntern = new Date();
  @ViewChild('datePickerMenu') datePickerMenu: MenuComponent;
  @ViewChild('calendar') calendar: Calendar;

  private noRangeText = 'all';
  private dateFormat = 'dd.MM.yyyy';

  constructor(
    private config: PrimeNGConfig,
    private languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.languageService
      .translate(['datePicker', 'common'])
      .pipe(untilDestroyed(this))
      .subscribe(t => {
        this.config.setTranslation(t.datePicker);
        this.updateInternalValues();
        this.noRangeText = t.datePicker.noRangeText;
        // p-calendar uses different date format
        this.dateFormat = t.common.shortDateZF;
        this.updateLabel();
      });
  }

  ngOnChanges() {
    this.updateInternalValues();
  }

  onShowMenu() {
    if (this.calendar) {
      // undocumented function of primeng calendar that sets the visible month/year to defaultDate
      // if nothing else is selected
      this.calendar.updateUI();
    }
  }

  // make return type of this component compatible with the slider we used to have
  accept() {
    if (this.dateRangeIntern === null) {
      this.dateRange = [];
    } else {
      const start = this.dateRangeIntern[0];
      let end = this.dateRangeIntern[1];
      if (end === null) {
        end = new Date(start);
      }
      end.setHours(23, 59, 59, 0);
      this.dateRange = [start.getTime(), end.getTime()];
    }
    this.dateRangeChange.emit(this.dateRange);
    this.dateChange.emit();
    this.datePickerMenu.hide();
  }

  clear() {
    this.dateRangeIntern = null;
    this.accept();
  }

  selectFirstDay() {
    if (this.minDateIntern) {
      this.selectDate(this.minDateIntern);
    }
  }

  selectLastDay() {
    if (this.maxDateIntern) {
      this.selectDate(this.maxDateIntern);
    }
  }

  selectToday() {
    this.selectDate(new Date());
  }

  selectDate(now: Date) {
    now.setHours(0, 0, 0, 0);
    if (
      (!this.minDateIntern || now.getTime() >= this.minDateIntern.getTime()) &&
      (!this.maxDateIntern || now.getTime() <= this.maxDateIntern.getTime())
    ) {
      if (this.dateRangeIntern === null) {
        this.dateRangeIntern = [now, null];
      } else {
        const [start, end] = this.dateRangeIntern;
        if (end === null) {
          if (start.getTime() < now.getTime()) {
            this.dateRangeIntern = [start, now];
          } else {
            this.dateRangeIntern = [now, null];
          }
        } else {
          this.dateRangeIntern = [now, null];
        }
      }
    } else {
      this.dateRangeIntern = null;
    }
  }

  private updateInternalValues() {
    if (this.dateRange.length > 0) {
      const start = new Date(this.dateRange[0]);
      start.setHours(0, 0, 0, 0);
      const end = new Date(this.dateRange[1]);
      end.setHours(0, 0, 0, 0);
      if (start.getTime() === end.getTime()) {
        this.dateRangeIntern = [start, null];
      } else {
        this.dateRangeIntern = [start, end];
      }
    } else {
      this.dateRangeIntern = null;
    }
    if (this.minDate) {
      const lowerLimit = new Date(this.minDate);
      lowerLimit.setHours(0, 0, 0, 0);
      this.minDateIntern = lowerLimit;
      if (!this.maxDate) {
        this.maxDate = Date.now();
      }
      const upperLimit = new Date(this.maxDate);
      upperLimit.setHours(0, 0, 0, 0);
      this.maxDateIntern = upperLimit;
    }
    this.updateLabel();
  }

  private updateLabel() {
    if (this.dateRangeIntern === null) {
      this.label = this.noRangeText;
    } else {
      const [start, end] = this.dateRangeIntern;
      this.label = formatDate(start, this.dateFormat, 'en');
      if (end !== null) {
        this.label = `${this.label} - ${formatDate(end, this.dateFormat, 'en')}`;
      }
    }
  }
}
