<ng-template #empty></ng-template>

<ng-container *ngTemplateOutlet="headline ? headline : empty"></ng-container>
<div class="content">
  <as-split
    unit="percent"
    direction="horizontal"
    [gutterSize]="updateGutterSize()"
    (dragStart)="onDragStart($event)"
    (dragEnd)="onDragEnd($event)"
    (gutterClick)="onGutterClick()"
  >
    <as-split-area [size]="sizeLeft">
      <ng-container *ngTemplateOutlet="leftPane ? leftPane : empty"></ng-container>
    </as-split-area>

    <as-split-area [size]="sizeRight">
      <ng-container *ngTemplateOutlet="rightPane ? rightPane : empty"></ng-container>
    </as-split-area>
  </as-split>
</div>
