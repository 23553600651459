export enum ImportJobStatusMapping {
  CREATED = 1,
  RUNNING = 2,
  DONE = 3,
  FAILED = 4,
  ABORTED = 5,
}

export const importJobStatusToString: string[] = [
  '',
  'created',
  'running',
  'done',
  'failed',
  'aborted',
];

export interface ImportJobDetails {
  uuid: string;
  status: number;
  created: number;
}

export interface GetImportJobsResponse {
  importJobs: ImportJobDetails[];
}
