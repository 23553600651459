<h5>{{ 'mediaGallery.title' | translate }}</h5>
@if (overview().length > 0) {
  <div class="overview">
    @for (item of overview(); track item.id) {
      @switch (item.type) {
        @case ('image') {
          <app-media-image
            [previewUrl]="item.previewUrl"
            [url]="item.url"
            (selected)="onItemSelected(item)"
          ></app-media-image>
        }
        @case ('custom-image') {
          <app-media-custom-image
            [imageUuid]="item.id"
            [filename]="item.filename"
            [asset]="asset()"
            [writable]="writable()"
            [uploading]="item.uploading ?? false"
            (selected)="onItemSelected(item)"
          ></app-media-custom-image>
        }
        @case ('video') {
          <app-media-video [videoId]="item.id" (selected)="onItemSelected(item)"></app-media-video>
        }
      }
    }
  </div>
  <button
    pButton
    type="button"
    label="{{ 'mediaGallery.button.showAll' | translate }}"
    class="ui-button-bare"
    (click)="onShowAll()"
  ></button>
} @else {
  <div class="empty">
    <app-no-elements-placeholder
      icon="image"
      text="{{ 'mediaGallery.message.noImages' | translate }}"
      theme="synergy"
    ></app-no-elements-placeholder>
    @if (writable()) {
      <app-media-upload destination="images" [asset]="asset()"></app-media-upload>
    }
  </div>
}

<app-media-viewer
  [items]="items()"
  [(show)]="showViewer"
  [(index)]="showIndex"
  [asset]="asset()"
  [writable]="writable()"
  (closed)="onViewerClosed()"
>
  <ng-template appTemplate="footer">
    @if (showAll()) {
      <button pButton type="button" class="ui-button-outline" (click)="onBackToShowingAll()">
        <app-icon shape="keyboard_backspace" size="16"></app-icon
        >{{ 'mediaGallery.button.showAll' | translate }}
      </button>
    }
  </ng-template>
</app-media-viewer>

<p-dialog
  header="{{ 'mediaGallery.title' | translate }}"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [blockScroll]="true"
  [focusOnShow]="false"
  [visible]="showAll()"
  [style]="{
    width: 'auto',
    height: 'auto',
    'max-width': '1000px',
    'max-height': '90%',
    'min-width': '50%',
    'min-height': '50%',
  }"
  appendTo="body"
>
  <div class="main">
    <div class="dialog-content">
      @if (customImages().length > 0) {
        @if (writable()) {
          <app-media-upload
            buttonClass="ui-button-bare slim"
            destination="images"
            [asset]="asset()"
          ></app-media-upload>
        }
        <h6>{{ 'mediaGallery.section.customImages' | translate }}</h6>
        <div class="images">
          @for (item of customImages(); track item.id) {
            <app-media-custom-image
              [ngClass]="{ uploading: item.uploading }"
              [imageUuid]="item.id"
              [filename]="item.filename"
              [asset]="asset()"
              [uploading]="item.uploading ?? false"
              [writable]="writable()"
              (selected)="onItemSelected(item)"
            ></app-media-custom-image>
          }
        </div>
      }
      @if (productImages().length > 0) {
        <h6>{{ 'mediaGallery.section.productImages' | translate }}</h6>
        <div class="images">
          @for (item of productImages(); track item.id) {
            <app-media-image
              [previewUrl]="item.previewUrl"
              [url]="item.url"
              (selected)="onItemSelected(item)"
            ></app-media-image>
          }
        </div>
      }
      @if (technicalDrawings().length > 0) {
        <h6>{{ 'mediaGallery.section.technicalDrawings' | translate }}</h6>
        <div class="images">
          @for (item of technicalDrawings(); track item.id) {
            <app-media-image
              [previewUrl]="item.previewUrl"
              [url]="item.url"
              (selected)="onItemSelected(item)"
            ></app-media-image>
          }
        </div>
      }
      @if (productVideos().length > 0) {
        <h6>{{ 'mediaGallery.section.productVideos' | translate }}</h6>
        <div class="images">
          @for (item of productVideos(); track item.id) {
            <app-media-video
              [videoId]="item.id"
              (selected)="onItemSelected(item)"
            ></app-media-video>
          }
        </div>
      }
    </div>
    <div class="dialog-footer">
      <button pButton type="button" class="ui-button-outline" (click)="onCloseAll()">
        {{ 'buttons.close' | translate }}
      </button>
    </div>
  </div>
</p-dialog>
