<app-menu direction="dl" #menu>
  <app-menu-item-asset-edit [asset]="asset()"></app-menu-item-asset-edit>
  @if (optionVisibilities.showCreateAssetOption) {
    <app-menu-item-asset-create [asset]="asset()"></app-menu-item-asset-create>
  }
  @if (optionVisibilities.showDeleteAssetOption) {
    <app-menu-item-asset-delete [asset]="asset()"></app-menu-item-asset-delete>
  }
  @if (optionVisibilities.showCloneAssetOption) {
    <app-menu-item-asset-clone [asset]="asset()"></app-menu-item-asset-clone>
  }
  @if (optionVisibilities.showMoveAssetOption) {
    <app-menu-item-asset-move [asset]="asset()"></app-menu-item-asset-move>
  }
  <app-menu-item-asset-export-csv [asset]="asset()"></app-menu-item-asset-export-csv>
  @if (exportAasxFile) {
    <app-menu-item-asset-export-aas [asset]="asset()"></app-menu-item-asset-export-aas>
  }
  @if (optionVisibilities.showDownloadAssetAdminShellOption) {
    <app-menu-item
      [label]="'assetDetails.menu.download-aasx-file' | translate"
      icon="pi pi-cloud-download"
      (selected)="downloadAssetAdminShell()"
    ></app-menu-item>
  }
  <app-menu-item-asset-permissions [asset]="asset()"></app-menu-item-asset-permissions>
  <ng-content></ng-content>
  <div class="menu-group">
    <hr />
    @if (optionVisibilities.showLeaveSharedTreeOption) {
      <app-menu-item-asset-end-sharing [asset]="asset()"></app-menu-item-asset-end-sharing>
    }
  </div>
</app-menu>
