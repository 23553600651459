export interface TableStatus {
  tableStatus: string;
  tableName: string;
}

export interface TableConfig<T> {
  rows: number;
  sortOrder: number;
  sortField: string;
  columns: T[];
}
