<div>
  <div (appOutsideAction)="suppressTooltip()" class="slider-container">
    <p-slider
      pSlider
      [(ngModel)]="value"
      [min]="min"
      [max]="max"
      [step]="step"
      (onChange)="showTooltip()"
    >
    </p-slider>
    <span
      class="slider-tooltip"
      [style.top.px]="top"
      [style.left.px]="left"
      [style.display]="displayTooltip"
      >{{ value }}</span
    >
  </div>
</div>
