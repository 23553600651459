import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LicensingService } from '../services/licensing.service';

export const errorResolver: ResolveFn<string | undefined> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const licensingService = inject(LicensingService);

  const reason: string | undefined = route.params.reason;
  const subscription: string | undefined = route.params.subscription;

  if (!reason && !subscription) {
    router.navigate(['/']);
    return;
  }
  if (reason === 'subscription') {
    router.navigate(['/error/subscription/basic']);
    return;
  }
  if (subscription) {
    if (subscription in environment.digitalServiceCatalogUrl.serviceIds) {
      if (licensingService.hasLicense(subscription)) {
        router.navigate(['/dashboard']);
      }
    } else {
      router.navigate(['/error/subscription/basic']);
    }
  }
  return reason || subscription;
};
