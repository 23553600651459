import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TooltipService {
  private _hideAll = new Subject<void>();

  get hideAll$(): Observable<void> {
    return this._hideAll.asObservable();
  }

  hideAll(): void {
    this._hideAll.next();
  }
}
