import { Logger } from '@assethub/shared/utils';
import { Observable, catchError, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface ConfigRestoreOptions {
  url: string;
  backupId?: string;
  extUserId?: string;
}

/* Model for Device Backup creation */
interface CreateBackupOptions {
  url: string;
  backupApps?: boolean;
  backupAppParams?: boolean;
  backupPublic?: boolean;
}

export enum AMRServiceStateCode {
  IDLE = 'Idle',
  ERROR = 'Error',
  CREATE_BACKUP = 'CreateBackup',
  CREATE_BACKUP_DONE = 'CreateBackupDone',
  RESTORE_BACKUP = 'RestoreBackup',
  RESTORE_BACKUP_DONE = 'RestoreBackupDone',
  INSTALL_SAPK = 'InstallSapk',
  INSTALL_SAPK_DONE = 'InstallSapkDone',
}

interface AMRServiceState {
  state: AMRServiceStateCode;
}

type AMRErrorId = 'InternalDeviceConnectionError' | 'InternalAuthError' | 'UnspecifiedError';

interface AMRInternalError extends AMRServiceState {
  state: AMRServiceStateCode.ERROR;
  errorId: AMRErrorId;
  errorMessage: string;
}

interface BackupCreateInProgress extends AMRServiceState {
  state: AMRServiceStateCode.CREATE_BACKUP;
  progress: number;
}

interface BackupCreateCompleted extends AMRServiceState {
  state: AMRServiceStateCode.CREATE_BACKUP_DONE;
  fileName: string;
}

interface BackupRestoreInProgress extends AMRServiceState {
  state: AMRServiceStateCode.RESTORE_BACKUP;
  progress: number;
}

interface SapkInstallInProgress extends AMRServiceState {
  state: AMRServiceStateCode.INSTALL_SAPK;
  progress: number;
}

type AMRServiceStateResponse =
  | AMRServiceState
  | AMRInternalError
  | BackupCreateInProgress
  | BackupCreateCompleted
  | BackupRestoreInProgress
  | SapkInstallInProgress;

function isAMRErrorEvent(serviceEvent: AMRServiceState): serviceEvent is AMRInternalError {
  return serviceEvent.state === AMRServiceStateCode.ERROR;
}

export function isBackupCreateProgressEvent(
  serviceEvent: AMRServiceState,
): serviceEvent is BackupCreateInProgress {
  return serviceEvent.state === AMRServiceStateCode.CREATE_BACKUP;
}

export function isBackupCreateCompleteEvent(
  serviceEvent: AMRServiceState,
): serviceEvent is BackupCreateCompleted {
  return serviceEvent.state === AMRServiceStateCode.CREATE_BACKUP_DONE;
}

export function isBackupRestoreProgressEvent(
  serviceEvent: AMRServiceState,
): serviceEvent is BackupRestoreInProgress {
  return serviceEvent.state === AMRServiceStateCode.RESTORE_BACKUP;
}

export function isInstallSapkInProgessEvent(
  serviceEvent: AMRServiceState,
): serviceEvent is SapkInstallInProgress {
  return serviceEvent.state === AMRServiceStateCode.INSTALL_SAPK;
}

export class AppManagerRemoteError extends Error {
  firmwareIncompatible = false;
  deviceBusy = false;

  constructor(errorCode: AMRErrorId | 'DeviceBusyError') {
    super('AppManagerRemote service delivered error = ' + errorCode);
    switch (errorCode) {
      case 'InternalDeviceConnectionError':
      case 'InternalAuthError':
        this.firmwareIncompatible = true;
        break;
      case 'DeviceBusyError':
        this.deviceBusy = true;
        break;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class AppManagerRemoteService {
  private readonly logger = new Logger(this.constructor.name);
  constructor(private httpClient: HttpClient) {}

  getServiceState(serviceLocation: string): Observable<AMRServiceStateResponse> {
    return this.httpClient
      .get<AMRServiceStateResponse>(`${serviceLocation}/status`, {
        headers: {
          'cache-control': 'max-age=0',
        },
      })
      .pipe(
        catchError(ex => {
          // Request did not even come through or app crashed unexpected
          this.logger.error('Fetching AppManagerRemote service status failed. Details = ', ex);
          throw ex;
        }),
        tap(stateResponse => {
          if (isAMRErrorEvent(stateResponse)) {
            // Request made successfully, but AppManagerRemote detected an internal error occoured during async operation
            this.logger.error(
              'AppManagerRemote detected an internal error occoured during async operation. Details =  ',
              stateResponse,
            );
            throw new AppManagerRemoteError(stateResponse.errorId);
          }
        }),
      );
  }

  installAppAsync(serviceLocation: string, downloadUrl: string): Observable<void> {
    return this.getServiceState(serviceLocation).pipe(
      tap(stateResponse => this.serviceIsIdleOrFail(stateResponse)),
      switchMap(() =>
        this.httpClient
          .post<void>(
            `${serviceLocation}/install-sapk-async`,
            { url: downloadUrl },
            {
              headers: {
                'cache-control': 'max-age=0',
              },
            },
          )
          .pipe(
            catchError(ex => {
              this.logger.error('App installation failed. Details = ', ex);
              throw ex;
            }),
          ),
      ),
    );
  }

  removeApps(serviceLocation: string, appNames: string[]): Observable<void> {
    return this.getServiceState(serviceLocation).pipe(
      tap(stateResponse => this.serviceIsIdleOrFail(stateResponse)),
      switchMap(() =>
        this.httpClient
          .post<void>(
            `${serviceLocation}/delete-apps`,
            {
              apps: appNames.map(name => ({
                name,
              })),
            },
            {
              headers: {
                'cache-control': 'max-age=0',
              },
            },
          )
          .pipe(
            catchError(ex => {
              this.logger.error('App removal failed. Details = ', ex);
              throw ex;
            }),
          ),
      ),
    );
  }

  public createBackupAsync(
    serviceLocation: string,
    createBackupOptions: CreateBackupOptions,
  ): Observable<void> {
    return this.getServiceState(serviceLocation).pipe(
      tap(stateResponse => this.serviceIsIdleOrFail(stateResponse)),
      switchMap(() =>
        this.httpClient
          .post<void>(`${serviceLocation}/create-backup-async`, createBackupOptions, {
            headers: {
              'cache-control': 'max-age=0',
            },
          })
          .pipe(
            catchError(ex => {
              this.logger.error('Create backup failed. Details = ', ex);
              throw ex;
            }),
          ),
      ),
    );
  }

  public restoreBackupAsync(serviceLocation: string, restoreOpts: ConfigRestoreOptions) {
    return this.getServiceState(serviceLocation).pipe(
      tap(stateResponse => this.serviceIsIdleOrFail(stateResponse)),
      switchMap(() =>
        this.httpClient
          .post<void>(`${serviceLocation}/restore-backup-async`, restoreOpts, {
            headers: {
              'cache-control': 'max-age=0',
            },
          })
          .pipe(
            catchError(ex => {
              this.logger.error('Backup restore failed. Details = ', ex);
              throw ex;
            }),
          ),
      ),
    );
  }

  private serviceIsIdleOrFail(serviceState: AMRServiceState) {
    if (serviceState.state !== AMRServiceStateCode.IDLE) {
      this.logger.error('Cannot execute action because device is busy.');
      throw new AppManagerRemoteError('DeviceBusyError');
    }
  }
}
