import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgStyle } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { IconComponent } from '../icon/icon.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-expandable-search-field',
  templateUrl: './expandable-search-field.component.html',
  styleUrls: ['./expandable-search-field.component.scss'],
  standalone: true,
  imports: [FormsModule, IconComponent, ButtonModule, NgClass, NgStyle, TranslateModule],
})
export class ExpandableSearchFieldComponent implements OnChanges {
  @Input() value = '';
  @Input() holdTimeout = 500;

  @Output() valueChange = new EventEmitter<string>();
  @Output() expandStateChanged = new EventEmitter<boolean>(); // true = expanded
  @Output() searchIconClicked = new EventEmitter<string>();

  @HostBinding('class.expanded')
  expanded = false;
  clearButtonClass = '';
  closeButtonClass = '';

  private collapseTimerId?: number;
  private hideClearButtonTimerId?: number;
  private pendingChangeTimerId?: number;
  private enterKeyDown = false;
  readonly buttonFadeTime = 130;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.clearPendingChangeTimer();
      this.updateVisibilityOfClearButton();
    }
  }

  onKeyboard(event: KeyboardEvent): void {
    if (this.expanded) {
      return;
    }
    if (event.key !== ' ' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.expand();
  }

  onInput(): void {
    this.updateVisibilityOfClearButton();
    if (this.holdTimeout === 0) {
      this.valueChange.emit(this.value);
    } else {
      this.clearPendingChangeTimer();
      this.pendingChangeTimerId = window.setTimeout(() => {
        this.valueChange.emit(this.value);
        this.pendingChangeTimerId = undefined;
      }, this.holdTimeout);
    }
  }

  onBlur(): void {
    this.enterKeyDown = false;
  }

  onEnterKeyDown(): void {
    this.enterKeyDown = true;
  }

  // When clear button is triggered, focus is moved from the fading button
  // back to the input box. However, this makes the input box receive the
  // key-up event from the enter key if the clear button is triggered by
  // enter key. So, to prevent this component from immediately emitting the
  // empty string despite of holdTime being non-zero, it has to track
  // whether the key-up event has been preceded by a key-down event.
  onEnterKeyUp(): void {
    if (!this.enterKeyDown) {
      return;
    }
    this.enterKeyDown = false;
    this.flushPendingChangeNotification();
  }

  iconClicked() {
    this.searchIconClicked.emit(this.value);
  }

  expand(): void {
    if (this.expanded) {
      return;
    }
    this.expanded = true;
    this.expandStateChanged.emit(true);
    this.closeButtonClass = 'unhide';
    this.updateVisibilityOfClearButton();
    if (this.collapseTimerId !== undefined) {
      window.clearTimeout(this.collapseTimerId);
      this.collapseTimerId = undefined;
    }
    window.setTimeout(() => {
      this.focusInputElement();
    }, 0);
  }

  collapse(): void {
    if (!this.expanded) {
      return;
    }
    this.expanded = false;
    this.expandStateChanged.emit(false);
    this.updateVisibilityOfClearButton();
    this.flushPendingChangeNotification();
    this.collapseTimerId = window.setTimeout(() => {
      this.closeButtonClass = '';
      this.focusSearchBox();
      this.collapseTimerId = undefined;
    }, this.buttonFadeTime);
  }

  onClearButton(): void {
    if (this.value.length === 0) {
      return;
    }
    this.value = '';
    this.onInput();
    this.focusInputElement();
  }

  private updateVisibilityOfClearButton(): void {
    if (this.expanded) {
      if (this.value.length > 0) {
        this.showClearButton();
      } else {
        this.hideClearButton();
      }
    } else {
      if (this.value.length > 0) {
        this.hideClearButton();
      }
    }
  }

  private showClearButton(): void {
    if (this.clearButtonClass === 'unhide show') {
      return;
    }
    this.clearButtonClass = 'unhide show';
    if (this.hideClearButtonTimerId !== undefined) {
      window.clearTimeout(this.hideClearButtonTimerId);
      this.hideClearButtonTimerId = undefined;
    }
  }

  private hideClearButton(): void {
    if (this.clearButtonClass === '' || this.hideClearButtonTimerId !== undefined) {
      return;
    }
    this.clearButtonClass = 'unhide';
    this.hideClearButtonTimerId = window.setTimeout(() => {
      this.clearButtonClass = '';
      this.hideClearButtonTimerId = undefined;
    }, this.buttonFadeTime);
  }

  private focusInputElement(): void {
    const input = this.element.nativeElement.getElementsByTagName('input')[0];
    if (!input) {
      return;
    }
    input.focus();
  }

  private focusSearchBox(): void {
    const searchbox = this.element.nativeElement.getElementsByClassName('searchbox')[0];
    if (searchbox instanceof HTMLElement) {
      searchbox.focus();
    }
  }

  private flushPendingChangeNotification(): void {
    if (this.holdTimeout === 0) {
      return;
    }
    this.clearPendingChangeTimer();
    this.valueChange.emit(this.value);
  }

  private clearPendingChangeTimer(): void {
    if (this.pendingChangeTimerId !== undefined) {
      window.clearTimeout(this.pendingChangeTimerId);
      this.pendingChangeTimerId = undefined;
    }
  }
}
