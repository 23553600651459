import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Logger } from '../utils/logger';

export interface KVStorage {
  get<T>(key: string): T | undefined;
  set(key: string, value: string | number | boolean | object): void;
}

export type BrowserStorage = Pick<Storage, 'getItem' | 'setItem'>;

const BROWSER_LOCAL_STORAGE = new InjectionToken('BROWSER_LOCAL_STORAGE', {
  providedIn: 'root',
  factory: () => localStorage,
});

const BROWSER_SESSION_STORAGE = new InjectionToken('BROWSER_SESSION_STORAGE', {
  providedIn: 'root',
  factory: () => sessionStorage,
});

export class BrowserStorageService implements KVStorage {
  private readonly PREFIX = 'adl';
  protected logger: Logger;

  constructor(private store: BrowserStorage) {
    this.logger = new Logger(this.constructor.name);
  }

  get<T>(key: string): T | undefined {
    const value = this.store.getItem(this.key(key));
    if ('string' !== typeof value) {
      return undefined;
    }

    try {
      return JSON.parse(value);
    } catch (e: unknown) {
      this.logger.warn('Cannot deserialize key', key);
      return undefined;
    }
  }

  set(key: string, value: string | number | boolean | object) {
    try {
      this.store.setItem(this.key(key), JSON.stringify(value));
    } catch (e: unknown) {
      this.logger.warn('Cannot store value', key, e);
      // Ignore intentionally - this storage is optional functionality!
    }
  }

  private key(key: string): string {
    return `${this.PREFIX}.${key}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends BrowserStorageService {
  constructor(@Inject(BROWSER_LOCAL_STORAGE) store: BrowserStorage = localStorage) {
    super(store);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends BrowserStorageService {
  constructor(@Inject(BROWSER_SESSION_STORAGE) store: BrowserStorage = sessionStorage) {
    super(store);
  }
}
