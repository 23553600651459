import { InjectionToken } from '@angular/core';
import { Environment } from '../models';

export type FeatureToggleEvalFn = (name: string) => boolean;

export const featureToggleEvalFactory =
  (env: Environment): FeatureToggleEvalFn =>
  (toggle: string) =>
    featureToggleActive(toggle, env);

export const FEATURE_TOGGLE = new InjectionToken<(name: string) => boolean>(
  'FeatureToggleEvaluator',
);

function featureToggleActive(name: string, env: Environment): boolean {
  return Boolean(env.featureToggles && env.featureToggles[name] === true);
}
