import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateWithFallback',
  standalone: true,
})
export class TranslateWithFallbackPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: string,
    fallbackOpts: { fallback: string; isTranslationKey?: boolean; interpolateParams?: object },
  ): string {
    const translated = this.translateService.instant(value);

    if (`'${value}'` === translated) {
      // If not translation is found, result will have format 'value'
      return fallbackOpts.isTranslationKey
        ? this.translateService.instant(fallbackOpts.fallback, fallbackOpts.interpolateParams)
        : fallbackOpts.fallback;
    }

    return translated;
  }
}
