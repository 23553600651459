export interface DeviceConfiguration {
  uuid: string;
  name: string;
  creatorId: string;
  type: string;
  validationState: ConfigCheckState;
  creationDate: Date;
  fileMetas: FileMetas;
  devicePartNumber?: string;
  labels?: string[];
}

export interface UpdateDeviceConfiguration {
  name: string;
  labels: string[];
  labelContext: string;
}

export enum ConfigCheckState {
  // Check is triggered, but not completed right now
  PENDING = 'PENDING',
  // Configuration is valid
  VERIFIED = 'VERIFIED',
  // Configuration has valid file format
  INVALID_FILE_FORMAT = 'INVALID_FILE_FORMAT',
  // Configuration matches to device type
  INCOMPATIBLE = 'INCOMPATIBLE',
  // It's not possible to automatically verify that a configuration is compatible to match to a device
  INDETERMINABLE_FOR_DEVICE_TYPE = 'INDETERMINABLE_FOR_DEVICE_TYPE',
  // Configuration cannot be verified. This does not mean it is invalid.
  NOT_VERIFYABLE = 'NOT_VERIFYABLE',
}

export class FileMetas {
  configName: string;
  size: number;
}
