import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationRef,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
  AssetHubOriginInterceptor,
  OAuthErrorInterceptor,
  OAuthInterceptor,
  RequestInterceptor,
} from '@assethub/shared/interceptors';
import { ENVIRONMENT, Environment } from '@assethub/shared/models';
import { AssetIconPipe, CompleteUrlPipe } from '@assethub/shared/pipes';
import { AppSharedModule } from '@assethub/shared/shared.module';
import { FEATURE_TOGGLE, Logger, featureToggleEvalFactory } from '@assethub/shared/utils';
import { environment } from '@environments/environment';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppInitializerService } from './app/app-initializer.service';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';

if (environment.production) {
  enableProdMode();
}

class MissingTranslationsLoggerHandler implements MissingTranslationHandler {
  private logger = new Logger(this.constructor.name);

  public handle(params: MissingTranslationHandlerParams) {
    this.logger.warn(
      'Missing translation in [%s]: "%s"',
      params.translateService.currentLang,
      params.key,
    );
    return `'${params.key}'`;
  }
}

function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

function appInitializerServiceFactory(appInitializerService: AppInitializerService) {
  return () => appInitializerService.initialize();
}

function bootstrapAssetHub(env: Environment): Promise<ApplicationRef> {
  return bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
          missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: MissingTranslationsLoggerHandler,
          },
          loader: {
            provide: TranslateLoader,
            useFactory: translateHttpLoaderFactory,
            deps: [HttpClient],
          },
        }),
        OAuthModule.forRoot(),
        FormsModule,
        AppSharedModule,
      ),
      DatePipe,
      CompleteUrlPipe,
      AssetIconPipe,
      { provide: ENVIRONMENT, useValue: env },
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializerServiceFactory,
        multi: true,
        deps: [AppInitializerService],
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: OAuthInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AssetHubOriginInterceptor,
        multi: true,
      },
      {
        provide: FEATURE_TOGGLE,
        useFactory: featureToggleEvalFactory,
        deps: [ENVIRONMENT],
      },
      // Workaround for known issue on old Edge browsers to log in on 'localhost'
      // See https://textslashplain.com/2020/01/30/security-zones-in-edge/
      {
        provide: OAuthStorage,
        useFactory: () =>
          window.location.hostname === 'localhost' && window.navigator.userAgent.includes('Edge')
            ? localStorage
            : sessionStorage,
      },
      {
        provide: OAuthResourceServerErrorHandler,
        useExisting: OAuthErrorInterceptor,
      },
      ConfirmationService,
      DialogService,
      DynamicDialogConfig,
      MessageService,
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      provideRouter(
        routes,
        withRouterConfig({
          onSameUrlNavigation: 'reload',
          paramsInheritanceStrategy: 'always',
        }),
      ),
    ],
  }).catch(err => {
    // eslint-disable-next-line no-console
    console.error('Bootstrap error', err);

    document.querySelectorAll('[data-fadeout-class]').forEach(element => {
      element.className = element.getAttribute('data-fadeout-class') ?? '';
    });

    const message = document.getElementById('bootstrap-message');
    if (message) {
      message.className = 'error';
      message.innerText = 'An error occurred while bootstrapping the application';
    }

    return err;
  });
}

if (environment.dynamicSettings === true) {
  fetch('./assets/app-settings/settings.json')
    .then(res => res.json())
    .then(settings => bootstrapAssetHub(Object.assign(environment, settings)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('Unable to load dynamic application configuration', err);
      return err;
    });
} else {
  bootstrapAssetHub(environment);
}
