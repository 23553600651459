import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssetTitleMode } from '../models/user-configuration';
import { assetTypeMappingToString } from './../models/asset-details';

interface AssetTitleData {
  name?: string;
  productName?: string;
  type: string | number;
}

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'assetTitle',
  standalone: true,
})
export class AssetTitlePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(node: AssetTitleData, mode: AssetTitleMode = AssetTitleMode.CUSTOM): string {
    if (mode === AssetTitleMode.OFFICIAL && node.productName) {
      return node.productName;
    }

    if (node.name) {
      return node.name;
    }

    if (node.productName) {
      return node.productName;
    }

    const type = typeof node.type === 'number' ? assetTypeMappingToString[node.type] : node.type;
    return this.translateService.instant('assetTypes.' + type);
  }
}
