import { Endpoint } from '@liveconnect/shared/models';

export interface UpdateLiveConnectChannel {
  profileUuids?: string[];
  eventNames?: string[];
  propertyNames?: string[];
  description?: string;
}

export interface CreateLiveConnectChannel extends UpdateLiveConnectChannel {
  endpointId: string;
}

export interface LiveConnectChannel extends CreateLiveConnectChannel {
  id: string;
  deviceUuid: string;
  channelType: string;
  endpoint?: Endpoint;
  suspendedUntil?: string;
  isActive?: boolean;
}

export interface ChannelError {
  statusCode: number;
  message: string;
  timestamp: string;
  body: string;
}
