<div
  class="drop-zone"
  [ngClass]="{ 'is-dragging-over': assetEntered }"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
>
  <span id="label">{{ label() | translate }}</span>
</div>
