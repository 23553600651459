export interface MaintenanceTreeEntry {
  uuid: string;
  type: string;
  color: number;
  hasChildren?: boolean;
  children?: MaintenanceTreeEntry[];
  technicalPlaceName?: string;
  equipmentCode?: string;
  productName?: string;
  productPictureUrl?: string;
  snapshotId?: string;
}

export interface MaintenanceTree {
  rootUuid: string;
  uuid: string;
  icon: string;
  expanded: boolean;
  isHidden: boolean;
  children?: MaintenanceTree[];
  hasChildren: boolean;
  productPictureUrl: string;
  type: string;
  name: string;
  color: string;
  parent?: MaintenanceTree;
  snapshotId?: string;
}
