import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: '<app-sick-footer>',
  templateUrl: './sick-footer.component.html',
  styleUrls: ['./sick-footer.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class SickFooterComponent {
  public copyrightYear = new Date().getFullYear();
}
