import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AssetSearchResultItem, ProductId } from '@assethub/shared/models';
import { ProductService } from '@assethub/shared/services';
import { AssetSearchService } from '@assethub/shared/services/asset-search.service';
import { Logger } from '@assethub/shared/utils';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { AppSharedModule } from '@assethub/shared/shared.module';

const PID_PARAMETER = 'pid';

@Component({
  selector: 'app-product-id',
  templateUrl: './product-id.component.html',
  styleUrls: ['./product-id.component.scss'],
  standalone: true,
  imports: [
    AppSharedModule,
    NgIf,
    ProgressSpinnerModule,
    RouterLink,
    NgFor,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
  ],
})
export class ProductIdComponent implements OnInit, OnDestroy {
  public showLoadingIndicator = true;
  public showCreateHint = false;
  public matchingAssets: AssetSearchResultItem[] = [];

  private logger = new Logger(this.constructor.name);
  private subscription?: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,

    private assetSearchService: AssetSearchService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const pid = params.get(PID_PARAMETER);
      if (pid) {
        let product: ProductId;
        try {
          product = ProductId.fromString(pid);
        } catch (e) {
          this.logger.warn(e);
          this.router.navigate(['/']);
          return;
        }

        // First, find node(s) with matching properties
        this.productService.storeProductId(product);

        this.subscription = this.assetSearchService
          .searchAssetByPartNumberSerial(product.getTypeId(), product.getInstanceId())
          .subscribe({
            next: result => {
              this.showLoadingIndicator = false;
              this.showCreateHint = result.total === 0;
              this.matchingAssets = result.items;
            },
            error: () => {
              this.showLoadingIndicator = false;
              this.showCreateHint = true;
            },
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  public openAsset(uuid: string): void {
    this.router.navigate(['/asset', uuid]);
  }
}
