import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Selectable element used in Multi-Select boxes
export interface MultiSelectItem {
  value: string;
  displayLabel: string;
  icon?: string | SafeResourceUrl | Observable<string | SafeResourceUrl>;
  iconColor?: string;
  metas?: { [keyof: string]: string | number | boolean | object };
  translate?: boolean;
}

export function isMultiSelectItem(obj): obj is MultiSelectItem {
  return obj !== undefined && typeof obj.value === 'string' && typeof obj.displayLabel === 'string';
}

export function isMultiSelectItemArray(o: unknown): o is MultiSelectItem[] {
  return Array.isArray(o) && (o.length === 0 || isMultiSelectItem(o[0]));
}
