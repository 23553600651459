import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import {
  ApiDescription,
  AsyncApiDocument,
  DeviceDescription,
  IccRegistrationState,
  LiveConnectProfile,
  LiveDataEvent,
  LiveDataProperty,
  OpenApiDescription,
} from '@liveconnect/shared/models';
import { DeviceMonitorService } from '@liveconnect/shared/services/device-monitor.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  mergeWith,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private cache?: {
    uuid: string;
    capabilities$: Subject<LiveConnectProfile[]>;
    openApis$?: Subject<OpenApiDescription[]>;
  };
  private connected?: Subscription;
  private deviceState?: Subscription;
  private logger = new Logger(this.constructor.name);
  private liveConnectUrl = inject(ENVIRONMENT).liveConnectUrl;

  constructor(
    private httpClient: HttpClient,
    private deviceMonitor: DeviceMonitorService,
  ) {}

  // New values are emitted when the device reconnects and the capabilities have changed
  public getCapabilities(uuid: string): Observable<LiveConnectProfile[]> {
    if (!this.liveConnectUrl) {
      return of([]);
    }
    if (this.cache?.uuid === uuid) {
      return this.cache.capabilities$.asObservable();
    }

    const capabilities$ = new ReplaySubject<LiveConnectProfile[]>(1);

    this.connected?.unsubscribe();
    this.connected = this.deviceMonitor
      .isConnected(uuid)
      .pipe(
        filter(connected => connected || this.cache?.uuid !== uuid),
        tap(() => this.logger.debug(`Requesting capabilities of device ${uuid}`)),
        switchMap(() =>
          this.httpClient.get<LiveConnectProfile[]>(
            `${this.liveConnectUrl}/device/${uuid}/capabilities`,
          ),
        ),
        mergeWith(
          // Workaround for CLOUD-330: Refresh capababilities after 10 seconds
          this.deviceMonitor.isConnected(uuid).pipe(
            filter(connected => connected),
            delay(10000),
            switchMap(() =>
              this.httpClient.get<LiveConnectProfile[]>(
                `${this.liveConnectUrl}/device/${uuid}/capabilities`,
              ),
            ),
          ),
        ),
        catchError(error => {
          // Forward incoming errors to replay subject subscribers
          capabilities$.error(error);
          return throwError(() => error);
        }),
        distinctUntilChanged((previous, current) => {
          if (previous.length !== current.length) {
            return false;
          }
          const previousIds = previous.map(profile => profile.id);
          const currentIds = current.map(profile => profile.id);
          return previousIds.every(id => currentIds.includes(id));
        }),
        tap(capabilities => {
          if (this.cache?.uuid === uuid) {
            this.logger.info('Device has reported updated capabilities');
          } else {
            this.cache?.capabilities$.complete();
            this.cache = { uuid, capabilities$ };
          }
          this.logger.debug(`Device ${uuid} has ${capabilities.length} capabilities`);
          this.cache.capabilities$.next(capabilities);
        }),
      )
      .subscribe();

    this.deviceState?.unsubscribe();
    this.deviceState = this.deviceMonitor
      .getDeviceState(uuid)
      .pipe(filter(state => state === IccRegistrationState.UNREGISTERED))
      .subscribe(() => {
        this.logger.debug(`Device ${uuid} was unpaired, closing capabilities`);
        this.cache?.capabilities$.complete();
        this.cache = undefined;
      });

    return capabilities$.asObservable();
  }

  public getDeviceDescription(uuid: string): Observable<DeviceDescription> {
    if (!this.liveConnectUrl) {
      return of({ properties: [], events: [] });
    }
    return this.httpClient
      .get<ApiDescription[]>(`${this.liveConnectUrl}/device/${uuid}/capabilities/api-descriptions`)
      .pipe(
        switchMap(apis => {
          const properties: LiveDataProperty[] = [];
          const events: LiveDataEvent[] = [];

          for (const description of apis) {
            if (description.apiType === 'AsyncAPI') {
              events.push(...this.getEvents(description.document, description.profileUuid));
            } else if (description.apiType === 'OpenAPI') {
              properties.push(
                ...this.getProperties(description.serviceLocation ?? '', description.document),
              );
            } else {
              throw new Error('Unknown API description type');
            }
          }
          return of({ properties, events });
        }),
      );
  }

  public getApiDescription(assetId: string, capabilityId: string): Observable<ApiDescription> {
    if (!this.liveConnectUrl) {
      return throwError(() => new Error('Live Connect not configured in this environment'));
    }
    return this.httpClient.get<ApiDescription>(
      `${this.liveConnectUrl}/device/${assetId}/capabilities/${capabilityId}/api-description`,
      { params: { raw: true } },
    );
  }

  public getEvents(apiDocument: AsyncApiDocument, profileUuid: string): LiveDataEvent[] {
    const events: LiveDataEvent[] = [];
    const topics = apiDocument.topics || apiDocument.channels;
    if (topics !== undefined) {
      const baseTopic = apiDocument.baseTopic;
      for (const topic of Object.keys(topics)) {
        const resource = this.getEventResource(topic, baseTopic);
        const title = this.getEventTitle(resource);
        this.logger.debug(`Topic ${resource} ('${title}') detected`);
        events.push({
          name: title,
          profileUuid,
          topic: this.getTopic(resource),
          apiName: apiDocument.info?.title || '',
        });
      }
    }
    return events;
  }

  private getEventResource(topic: string, baseTopic?: string): string {
    const prefix = baseTopic ? baseTopic + '/' : '';
    let resource = prefix + topic;
    if (resource.indexOf('{') > -1) {
      // Workaround for broken parameters in SICK MQTT profile descriptions
      // Example: {$device.name} -> {devicename}
      resource = resource.replace(/{\$/g, '{');
      resource = resource.replace(/{([^}]*)\.([^}]*)}/g, '{$1$2}');
    }
    return resource.replace(/\./g, '/');
  }

  private getEventTitle(resource: string) {
    // Use last level of resource as title, ignore variables like {$device.name}
    const topic = this.getTopic(resource);
    const lastLevel = topic.lastIndexOf('/');
    if (lastLevel > -1) {
      return topic.slice(lastLevel + 1);
    } else {
      return topic;
    }
  }

  private getTopic(resource: string) {
    // Use first levels of resource as topic, ignore variables like {$device.name}
    const firstVar = resource.indexOf('/{');
    if (firstVar > -1) {
      resource = resource.slice(0, firstVar);
    }
    return resource;
  }

  private getProperties(serviceLocation: string, api: any): LiveDataProperty[] {
    const result: LiveDataProperty[] = [];
    for (const pathName in api.paths) {
      const path = api.paths[pathName];

      const properties = [
        { type: 'HEAD', op: path.head },
        { type: 'GET', op: path.get },
        { type: 'PUT', op: path.put },
        { type: 'POST', op: path.post },
        { type: 'DELETE', op: path.delete },
        { type: 'TRACE', op: path.trace },
      ]
        // Filter out all operations that are not defined
        .filter(entry => !!entry.op)

        // Filter out all operations that require input parameters or a request body
        .filter(({ type, op }: { type: string; op: any }) => {
          if (op.requestBody) {
            return false;
          }

          if (!op.parameters) {
            return true;
          }

          // Accept all parameters that are not required (= optional)
          return !Array.from(op.parameters)
            .map((param: any): boolean => param.required || false)
            .reduce((required: boolean, current: boolean) => required || current);
        })

        // Map into property structures
        .map(({ type, op }: { type: string; op: any }) => ({
          name: pathName.startsWith('/') ? pathName.substring(1) : pathName,
          method: type,
          serviceLocation,
          path: pathName,
          apiName: api.info.title,
          description: op.description,
        }));
      result.push(...properties);
    }
    return result;
  }
}
