<app-table-base
  #table
  [list]="list"
  [columns]="columns"
  [initialColumns]="initialColumns"
  [autoWidth]="autoWidth"
  [tableName]="tableName"
  dataKey="number"
  noDataMsg="service-module-365.list-of-workorders.message.no-data"
  nofilteredDataMsg="service-module-365.list-of-workorders.message.all-entries-filtered-out"
  errorMsg="service-module-365.list-of-workorders.message.load-error"
>
  <ng-template #cellTemplate let-column let-row="row">
    @switch (column.field) {
      @case ('name') {
        {{ row.name }}
      }
      @case ('number') {
        {{ row.number }}
      }
      @case ('customerReference') {
        {{ row.customerReference }}
      }
      @case ('customerPurchaseOrderNo') {
        {{ row.customerPurchaseOrderNo }}
      }
      @case ('timeFrom') {
        {{
          row.timeFrom
            | localizedDate
              : 'mediumDate' ||
                  ('service-module-365.list-of-workorders.message.not-available' | translate)
        }}
      }
      @case ('timeTo') {
        {{
          row.timeTo
            | localizedDate
              : 'mediumDate' ||
                  ('service-module-365.list-of-workorders.message.not-available' | translate)
        }}
      }
      @case ('status') {
        {{ row.status | translate }}
      }
      @case ('documents') {
        <div class="flex flex-column align-items-center">
          <button
            pButton
            type="button"
            class="ui-button-bare"
            icon="pi pi-file"
            (click)="showDocuments(row)"
          ></button>
        </div>
      }
      @case ('actions') {
        <button
          pButton
          type="button"
          class="ui-button-bare"
          icon="pi pi-chevron-right"
          (click)="navigateToDetails(row)"
        ></button>
      }
    }
  </ng-template>
</app-table-base>
