interface DeviceCapability {
  id: string;
  version: string;
  name: string;
  description: string;
  hasApiDescription: boolean;
  hasInlineApiDescription: boolean;
  serviceLocation?: string;
  baseTopic?: string;
}

interface LiveConnectHttpProfile extends DeviceCapability {
  type: 'HTTP';
  serviceLocation: string;
}

export interface LiveConnectMqttProfile extends DeviceCapability {
  type: 'MQTT';
  baseTopic: string;
}

export type LiveConnectProfile = LiveConnectHttpProfile | LiveConnectMqttProfile;

export function isMqttProfile(profile: LiveConnectProfile): profile is LiveConnectMqttProfile {
  return profile.type === 'MQTT';
}

// See following URL for a reference on the UUIDs:
// https://mosaicplus.sickcn.net/display/wsSickApplicationModel/SICK+HTTP+Interface+-+Profile+Catalog
export const PROFILE: Readonly<Record<string, string>> = {
  HTTP_CAPABILITIES: '2b292921-823e-4fde-a9e7-a556fe493ec1',
  ASYNC_API: 'e105d8e0-6c70-4f0a-abed-6f746e8328f9',
  APP_MANAGER_REMOTE: 'd469e9b6-cd7b-400f-a74f-05c465eb3d4d',
};
