<p-dialog
  [modal]="true"
  header="{{ 'authentication.silent_refresh_error.summary' | translate }}"
  [(visible)]="showMessage"
  [closable]="false"
  [dismissableMask]="true"
>
  <div class="message">
    <app-icon shape="info"></app-icon>
    <div class="pre-wrap">
      {{ 'authentication.silent_refresh_error.detail' | translate }}
    </div>
  </div>
  <div class="dialog-footer">
    <button
      pButton
      type="button"
      class="ui-button-outline"
      label="{{ 'authentication.button.save_changes' | translate }}"
      (click)="dismiss()"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      label="{{ 'authentication.button.reload' | translate }}"
      (click)="reinitiateLogin()"
    ></button>
  </div>
</p-dialog>
