<app-menu
  #datePickerMenu
  [label]="label"
  icon=""
  direction="um"
  (showMenu)="onShowMenu()"
  class="ui-button-bare-neutral"
>
  <div class="main">
    <p-calendar
      #calendar
      selectionMode="range"
      [inline]="true"
      [(ngModel)]="dateRangeIntern"
      [selectOtherMonths]="true"
      [defaultDate]="maxDateIntern"
      [showWeek]="true"
    ></p-calendar>
    <div class="buttons">
      <button
        pButton
        type="button"
        class="ui-button-outline"
        label="{{ 'datePicker.firstDay' | translate }}"
        *ngIf="minDateIntern && undefined !== minDate"
        (click)="selectFirstDay()"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-outline"
        label="{{ 'datePicker.lastDay' | translate }}"
        *ngIf="maxDateIntern && undefined !== maxDate"
        (click)="selectLastDay()"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-outline"
        label="{{ 'datePicker.today' | translate }}"
        *ngIf="!minDateIntern && !maxDateIntern"
        (click)="selectToday()"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-outline"
        label="{{ 'datePicker.clear' | translate }}"
        (click)="clear()"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-primary"
        label="{{ 'buttons.okay' | translate }}"
        (click)="accept()"
      ></button>
    </div>
  </div>
</app-menu>
