import { Component, Input } from '@angular/core';
import { FilterType, SortIconType, SortIconComponent } from '../sort-icon/sort-icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: true,
  imports: [NgIf, SortIconComponent],
})
export class TableHeaderComponent {
  @Input() label = '';
  @Input() field = '';
  @Input() type: SortIconType = 'string';
  @Input() filter?: FilterType;
  @Input() filterName?: string;
}
