<span class="p-float-label">
  <p-inputNumber
    [(ngModel)]="contents"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    [readonly]="readonly"
    [name]="name"
    mode="decimal"
    [maxFractionDigits]="fractionDigits ? maxFractionDigits : 0"
    [showButtons]="showButtons"
    [step]="step"
    [format]="format"
    [useGrouping]="useGrouping"
    [locale]="locale"
    (onBlur)="onBlur()"
    [tooltipDisabled]="!showTooltip"
    [pTooltip]="toolTipText"
    [escape]="!renderTooltipAsHtml"
    [hideDelay]="100"
    [tooltipStyleClass]="tooltipStyleClass"
    [tooltipPosition]="tooltipPosition"
    tooltipEvent="focus"
    appendTo="body"
    [ngClass]="{
      'required': required,
      'disabled': disabled,
      'placeholder': placeholder.length > 0,
      'bad-input': error || !!errorMsg,
    }"
    incrementButtonIcon="pi pi-small pi-angle-up"
    decrementButtonIcon="pi pi-small pi-angle-down"
    incrementButtonClass="ui-button-bare-neutral"
    decrementButtonClass="ui-button-bare-neutral"
  >
  </p-inputNumber>
  <label>{{ label }}</label>
  <span
    class="pi pi-exclamation-circle error-icon"
    [ngClass]="{ 'show-pointer': handleErrorIconClick, 'buttons-shown': showButtons }"
    (click)="errorIconClick()"
  ></span>
</span>
<span *ngIf="!!errorMsg" class="errormsg">{{ errorMsg }}</span>
