import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Pipe({
  name: 'localizedCurrency',
  pure: false,
  standalone: true,
})
export class LocalizedCurrencyPipe implements PipeTransform {
  private currency: string;
  private usedLocale: string;
  private onLangChange?: Subscription;

  constructor(private translateService: TranslateService) {}

  transform(
    value: number,
    currencyCode: string,
    display: 'code' | 'symbol' | 'symbol-narrow' = 'symbol',
    digitsInfo = '1.2-2',
    customLocale?: string,
  ): string {
    if (!customLocale) {
      this.resetLocaleOnLangChange();
      this.setLocale();
    }
    const locale = customLocale || this.usedLocale;
    switch (display) {
      case 'code':
        this.currency = currencyCode;
        break;
      case 'symbol':
        this.currency = getCurrencySymbol(currencyCode, 'wide', locale);
        break;
      case 'symbol-narrow':
        this.currency = getCurrencySymbol(currencyCode, 'narrow', locale);
        break;
      default:
        break;
    }
    return formatCurrency(value, locale, this.currency, currencyCode, digitsInfo);
  }

  setLocale() {
    this.usedLocale = this.translateService.currentLang || this.translateService.defaultLang;
  }

  resetLocaleOnLangChange() {
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.usedLocale = event.lang;
      });
    }
  }
}
