import { FirmwareState } from './firmware-state';

export interface AssetSearchResultItem {
  created: number;
  updated: number;
  uuid: string;
  type: number;
  permission?: string;
  customName?: string;
  description?: string;
  rootUuid?: string;
  rootName?: string;
  parentUuid?: string;
  parentCustomName?: string;
  parentProductName?: string;
  partNumber?: string;
  serialNumber?: string;
  productName?: string;
  vendorName?: string;
  productPictureUrl?: string;
  profilePictureUuid?: string;
  nearestBuildingOrLocation?: {
    uuid: string;
    type: number;
    customName?: string;
  };
  firmwareData?: {
    version?: string;
    state: FirmwareState;
  };
}

export interface AssetSearchResultItemV2 {
  created: number;
  updated: number;
  uuid: string;
  type: number;
  customName?: string;
  description?: string;
  partNumber?: string;
  serialNumber?: string;
  productName?: string;
  vendorName?: string;
  productPictureUrl?: string;
  profilePictureUuid?: string;
  parentBuildingOrLocation?: {
    uuid: string;
    type: number;
    customName?: string;
  };
  parentMachineSystem?: {
    uuid: string;
    type: number;
    customName?: string;
  };
  firmwareData?: {
    version?: string;
    state: FirmwareState;
  };
}

export interface AssetSearchResultItemBase {
  created: number;
  updated: number;
  uuid: string;
  type: number;
  customName?: string;
  description?: string;
  partNumber?: string;
  serialNumber?: string;
  productName?: string;
  vendorName?: string;
  productPictureUrl?: string;
  profilePictureUuid?: string;
  firmwareData?: {
    version?: string;
    state: FirmwareState;
  };
  root?: {
    uuid: string;
    customName: string;
  };
}

export interface ParentAsset {
  uuid: string;
  type: number;
  customName?: string;
}

export interface MainSearchItem extends AssetSearchResultItemBase {
  parentBuildingOrLocation?: ParentAsset;
  parentMachineSystem?: ParentAsset;
}

export interface TreeSearchItem extends AssetSearchResultItemBase {
  rootUuid?: string;
  rootName?: string;
  parentUuid?: string;
  parentCustomName?: string;
  parentProductName?: string;
}

export interface AssetSearchResult<T = AssetSearchResultItemBase> {
  total: number;
  pageSize: number;
  offset: number;
  items: T[];
}

export interface TechnicalPlaceSearchResult {
  total: number;
  results: TechPlaceResultEntry[];
}

export interface TechPlaceResultEntry {
  uuid: string;
  technicalPlaceNumber: string;
  color: number;
  parentTechnicalPlaceNumber?: string;
  technicalPlaceName?: string;
  parentTechnicalPlaceName?: string;
  machineType?: string;
  serialNumber?: string;
  partNumber?: string;
  productName?: string;
  equipmentCode?: string;
  productPictureUrl?: string;
  description?: string;
}

export interface VisualTreeSearchEntry {
  uuid: string;
  displayField: string;
  keyword: string;
  secondaryInfos?: string;
  assetImage: {
    type?: string | number;
    productPictureUrl?: string;
    profilePictureUuid?: string;
  };
  parentName?: string;
}
