export interface NewLink {
  name: string;
  url: string;
  uuid?: string;
}

export interface Link {
  uuid: string;
  name: string;
  url: string;
}
