import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  Component,
  computed,
  ContentChildren,
  input,
  model,
  output,
  QueryList,
  signal,
  TemplateRef,
} from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { MediaGalleryItem } from '../media-gallery/media-gallery.component';
import { GetAssetResponse } from '../../models';
import { DialogModule } from 'primeng/dialog';
import { MediaImageComponent } from '../media-image/media-image.component';
import { MediaCustomImageComponent } from '../media-custom-image/media-custom-image.component';
import { MediaVideoComponent } from '../media-video/media-video.component';
import { ButtonModule } from 'primeng/button';
import { TemplateDirective } from '../../directives/template.directive';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    DialogModule,
    NgStyle,
    NgClass,
    IconComponent,
    ButtonModule,
    MediaImageComponent,
    MediaCustomImageComponent,
    MediaVideoComponent,
    NgTemplateOutlet,
  ],
})
export class MediaViewerComponent implements AfterContentInit {
  readonly show = model(true);
  readonly items = input.required<MediaGalleryItem[]>();
  readonly index = model(0);
  readonly asset = input.required<GetAssetResponse>();
  readonly writable = input(true);

  readonly closed = output();

  @ContentChildren(TemplateDirective)
  templates?: QueryList<TemplateDirective>;

  readonly currentItem = computed(() => this.computeCurrentItem());
  readonly maxIndex = computed(() => this.items().length - 1);
  readonly footer = signal<TemplateRef<unknown> | null>(null);

  ngAfterContentInit(): void {
    this.footer.set(this.templates?.find(x => x.key === 'footer')?.template ?? null);
  }

  onClose(): void {
    this.show.set(false);
    this.closed.emit();
  }

  onPrevious(): void {
    const index = this.index();
    if (index > 0) {
      this.index.set(index - 1);
    }
  }

  onNext(): void {
    const index = this.index();
    if (index < this.maxIndex()) {
      this.index.set(index + 1);
    }
  }

  computeCurrentItem(): MediaGalleryItem | undefined {
    const items = this.items();
    const maxIndex = this.maxIndex();
    const index = this.index();
    return items.at(index > maxIndex ? maxIndex : index);
  }
}
