import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ENVIRONMENT, TrashListItem } from '../models';

type RawTrashListItem = Omit<TrashListItem, 'deletedAt' | 'purgeAt'> & {
  deletedAt: string;
  purgeAt: string;
};

export class NoDestinationFoundError {
  name = 'NoDestinationFoundError';

  constructor(public missingRoot: string) {}
}

interface RestoreCheckResult {
  duplicateProducts?: {
    count: number;
  };
}

@Injectable({ providedIn: 'root' })
export class TrashService {
  private apiUrl: string = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  moveToTrash(assetUuid: string): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/asset/${assetUuid}/trash`, null);
  }

  restoreFromTrash(assetUuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/asset/${assetUuid}/trash`).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          if ('missingRoot' in error.error) {
            throw new NoDestinationFoundError(error.error.missingRoot);
          }
        }
        throw error;
      }),
    );
  }

  trashRestoreCheck(assetUuid: string): Observable<RestoreCheckResult> {
    return this.httpClient
      .get<RestoreCheckResult>(`${this.apiUrl}/asset/${assetUuid}/trash-restore-check`)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            if ('missingRoot' in error.error) {
              throw new NoDestinationFoundError(error.error.missingRoot);
            }
          }
          throw error;
        }),
      );
  }

  listTrashItems(): Observable<TrashListItem[]> {
    return this.httpClient.get<RawTrashListItem[]>(`${this.apiUrl}/trash`).pipe(
      map(response =>
        response.map(item => ({
          ...item,
          deletedAt: new Date(item.deletedAt),
          purgeAt: new Date(item.purgeAt),
        })),
      ),
    );
  }
}
