import { AssetImageUrlPipe } from '@assethub/shared/pipes/asset-image-url.pipe';
import { Component, computed, input, signal } from '@angular/core';
import { AssetIconMode } from '../../models/user-configuration';
import { UserConfigurationService } from '../../services/user-configuration.service';
import { combineLatest, of } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { catchError, switchMap } from 'rxjs/operators';
import { IconComponent } from '../icon/icon.component';
import { NgStyle, NgClass } from '@angular/common';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

export interface AssetIcon {
  type: string | number;
  profilePicture?: string;
  profilePictureUuid?: string;
  productPictureUrl?: string;
  icon?: string;
}
@Component({
  selector: 'app-asset-icon',
  templateUrl: './asset-icon.component.html',
  styleUrls: ['./asset-icon.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, IconComponent],
})
export class AssetIconComponent {
  readonly asset = input.required<AssetIcon>();
  readonly size = input<string | number>(24);
  readonly disabled = input(false);
  readonly src = computed(() => this.computeSrc());
  readonly shape = computed(() => this.getShapeForImage(this.src()));
  readonly style = computed(() => this.computeStyle());

  private readonly imageUrl = this.getImageUrl();
  private readonly fallback = signal<boolean>(false);

  constructor(
    private assetImageUrlPipe: AssetImageUrlPipe,
    private userConfigService: UserConfigurationService,
  ) {}

  private computeStyle() {
    const size = this.size().toString();
    return { 'width.px': size, 'height.px': size };
  }

  private computeSrc() {
    if (this.fallback()) {
      return this.assetImageUrlPipe.defaultIconForType(this.asset());
    }
    return this.imageUrl();
  }

  private getImageUrl() {
    return toSignal(
      combineLatest({
        asset: toObservable(this.asset),
        config: this.userConfigService.config(),
      }).pipe(
        switchMap(event => {
          this.fallback.set(false);
          if (event.config.showAsIcon === AssetIconMode.PHOTO) {
            return this.assetImageUrlPipe
              .transform(event.asset)
              .pipe(catchError(() => of(this.assetImageUrlPipe.defaultIconForType(event.asset))));
          } else if (event.asset.icon) {
            return of(`assets/images/${event.asset.icon}`);
          } else {
            return of(this.assetImageUrlPipe.defaultIconForType(event.asset));
          }
        }),
      ),
      { initialValue: this.assetImageUrlPipe.defaultIconForType({}) },
    );
  }

  public fallbackToIcon() {
    this.fallback.set(true);
  }

  private getShapeForImage(url: string | SafeResourceUrl | undefined): string {
    if (typeof url !== 'string') {
      return '';
    }
    const path = 'assets/images/';
    if (!url.startsWith(path)) {
      return '';
    }
    const pos = url.indexOf('.svg');
    if (pos < 0) {
      return '';
    }
    const iconName = url.substring(path.length, pos);
    switch (iconName) {
      case 'building':
        return 'custom_building';
      case 'company':
        return 'location_city';
      case 'device':
        return 'memory';
      case 'line':
        return 'device_hub';
      case 'location':
        return 'control_camera';
      case 'machine_system':
        return 'developer_board';
      case 'ship':
        return 'custom_ship';
      case 'software':
        return 'custom_software';
      case 'factory':
        return 'custom_factory';
    }
    return '';
  }
}
