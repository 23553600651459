import {
  Component,
  computed,
  effect,
  forwardRef,
  input,
  model,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
  AutoCompleteUnselectEvent,
} from 'primeng/autocomplete';
import { IconComponent } from '../icon/icon.component';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-label-picker',
  templateUrl: './label-picker.component.html',
  styleUrls: ['./label-picker.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelPickerComponent),
      multi: true,
    },
  ],
  imports: [AutoCompleteModule, IconComponent, FormsModule, TranslateModule, NgClass],
})
export class LabelPickerComponent implements ControlValueAccessor {
  readonly labels = model<string[]>([]);
  readonly completeMethod = output<string>();
  readonly suggestions = input<string[]>([]);
  readonly allowNewLabels = input(true);
  readonly blur = output<Event>();
  readonly newLabel = output<string>();

  @ViewChild(AutoComplete, { static: true })
  autoComplete: AutoComplete;

  readonly suggestNewLabel = computed(() => this.computeNewLabel());
  readonly hasTextInput = signal(false);
  private readonly currentQuery = signal('');
  private onChange: (value: string[]) => void;
  private onTouched: () => void;
  private newLabels = new Set<string>();

  constructor() {
    effect(() => {
      const labels = this.labels();
      if (this.onChange) {
        this.onChange(labels);
      }
    });
  }

  writeValue(value: string[]): void {
    this.labels.set(value);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onCompleteMethod(event: AutoCompleteCompleteEvent): void {
    const query = event.query.trim();
    this.currentQuery.set(query);
    this.completeMethod.emit(query);
  }

  onBlur(event: Event): void {
    if (this.onTouched) {
      this.onTouched();
    }
    // When autocomplete has text input but no labels, PrimeNG does not mark the component as having content.
    // On blur, float-label will collapse and hide the text which looks bad. This is an ugly workaround
    // but there is currently no other way to check if input box of autocomplete is empty.
    this.hasTextInput.set(!!this.autoComplete.inputEL?.nativeElement.value);
    this.blur.emit(event);
  }

  onClear(): void {
    this.newLabels.clear();
    this.currentQuery.set('');
    this.labels.set([]); // p-autocomplete sets its ngModel to null
  }

  onUnselect(event: AutoCompleteUnselectEvent): void {
    this.newLabels.delete(event.value);
  }

  onNewLabel(label: string): void {
    this.autoComplete.hide();
    this.autoComplete.updateInputValue();
    this.newLabel.emit(label);
    this.newLabels.add(label);
    const labels = [...(this.labels() || []), label];
    this.labels.set(labels);
  }

  private computeNewLabel(): string {
    if (!this.allowNewLabels()) {
      return '';
    }
    const currentQuery = this.currentQuery();
    if (this.newLabels.has(currentQuery)) {
      return '';
    }
    for (const suggestion of this.suggestions()) {
      if (suggestion === currentQuery) {
        return '';
      }
    }
    return currentQuery;
  }
}
