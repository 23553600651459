export interface OperatingData {
  name: string;
  value: string | number;
  updatedAt: Date;
}

export interface OperatingDataResponse {
  name: string;
  value: string | number;
  updatedAt: string;
}

export interface AssetInventoryDetails {
  lastConnectedDate?: Date;
  systemState?: {
    value: string;
    lastUpdated: Date;
  };
}

export interface AssetInventoryDetailsResponse {
  lastConnectedDate?: number;
  systemState?: {
    value: string;
    timestamp: number;
  };
}

export const DEVICE_READY_SYSTEM_READY = '110';
