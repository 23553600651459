import { Component, EventEmitter, Output, input } from '@angular/core';
import { DraggedAssetNode } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-asset-drop-zone',
  templateUrl: './asset-drop-zone.component.html',
  styleUrls: ['./asset-drop-zone.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class AssetDropZoneComponent {
  readonly label = input('asset-drag-drop.label');
  @Output() assetDropped: EventEmitter<DraggedAssetNode> = new EventEmitter();
  public assetEntered = false;

  public onDragEnter(event: Event) {
    event.preventDefault();
    this.assetEntered = true;
  }

  public onDragOver(event: Event) {
    event.preventDefault();
  }

  public onDragLeave(event: Event) {
    this.assetEntered = false;
  }

  public onDrop(event: DragEvent) {
    const payload = event.dataTransfer?.getData('application/json');

    if (payload === undefined || payload === '') {
      return;
    }

    const data: DraggedAssetNode = JSON.parse(payload);
    this.assetDropped.emit(data);
  }
}
