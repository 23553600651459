import { Component, Input, OnChanges } from '@angular/core';
import { finalize } from 'rxjs';
import { OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { NgIf, NgClass } from '@angular/common';
import { AssetTreeSnapshotService, SnapshotError } from '@assethub/shared/services';
import { MessageService } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonModule, NgClass, TranslateModule],
})
export class SnapshotComponent implements OnInit, OnChanges {
  @Input() treeId: string;
  snapshotId: string | undefined;
  loading = false;

  constructor(
    private snapshotService: AssetTreeSnapshotService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (!this.treeId) {
      throw new Error('No assetId attribute provided');
    }
  }

  ngOnChanges(): void {
    if (this.treeId) {
      this.snapshotId = this.snapshotService.findSnapshotIdByTree(this.treeId);
    }
  }

  createSnapshot() {
    this.loading = true;
    this.snapshotService
      .createSnapshotFor(this.treeId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: snapshotId => (this.snapshotId = snapshotId),
        error: error => {
          if (error instanceof SnapshotError) {
            this.messageService.add({
              severity: 'error',
              summary: error.code,
              life: 10000,
            });
            return;
          }
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.snapshot.create-failed',
            life: 10000,
          });
        },
      });
  }

  updateSnapshot() {
    if (!this.snapshotId) {
      return;
    }

    this.loading = true;
    this.snapshotService
      .updateSnapshot(this.snapshotId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        error: error => {
          if (error instanceof SnapshotError) {
            this.messageService.add({
              severity: 'error',
              summary: error.code,
              life: 10000,
            });
            return;
          }
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.snapshot.update-failed',
            life: 10000,
          });
        },
      });
  }

  deleteSnapshot() {
    if (!this.snapshotId) {
      return;
    }

    this.loading = true;
    this.snapshotService
      .deleteSnapshot(this.snapshotId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => (this.snapshotId = undefined),
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.snapshot.delete-failed',
            life: 10000,
          });
        },
      });
  }
}
