import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlockUiService {
  private blockedUiEvent = new Subject<boolean>();

  get blockedUiEvent$() {
    return this.blockedUiEvent.asObservable();
  }

  block() {
    this.blockedUiEvent.next(true);
  }

  unblock() {
    this.blockedUiEvent.next(false);
  }
}
