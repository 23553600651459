import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MultiSelectItem, isMultiSelectItemArray } from '@assethub/shared/models/multiselect';
import { DateRange, isDateRange } from '@assethub/shared/models/date-range';
import { FilterMatchMode, SharedModule } from 'primeng/api';
import { SortIcon, Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { MenuComponent } from '../menu/menu/menu.component';
import { NumberRange, isNumberRange } from '@assethub/shared/models/number-range';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { NumberFieldComponent } from '../number-field/number-field.component';
import { DateFieldComponent } from '../date-field/date-field.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { IconComponent } from '../icon/icon.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

export type SortIconType = 'string' | 'date' | 'number' | 'numberrange' | 'unsortable';

export type FilterType = MultiSelectItem[] | DateRange | NumberRange | boolean | string;

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    IconComponent,
    MenuComponent,
    MenuItemComponent,
    NgSwitch,
    NgSwitchCase,
    ListboxModule,
    FormsModule,
    SharedModule,
    TextFieldComponent,
    DateFieldComponent,
    NumberFieldComponent,
    ButtonModule,
    TranslateModule,
  ],
})
export class SortIconComponent extends SortIcon implements OnDestroy, OnChanges {
  @Input() filter?: FilterType;
  @Input() type: SortIconType = 'string';
  @Input() filterName?: string;
  filterActive = false;
  filterMode: 'listbox' | 'daterange' | 'numberrange' | 'text' | 'none' = 'none';
  availableFilter: MultiSelectItem[] = [];
  currentFilterSelection: MultiSelectItem[] = [];
  availableDateRange: DateRange = {};
  currentDateRange: DateRange = {};
  availableNumberRange: NumberRange = {};
  currentNumberRange: NumberRange = {};
  currentSearchText = '';
  noFilterSelected = true;
  labelSortDescending = '';
  labelSortAscending = '';

  @ViewChild('menu') menu: MenuComponent;

  private filterSubscription?: Subscription;

  get filterKey(): string {
    return this.filterName || this.field || '';
  }

  constructor(dt: Table, cd: ChangeDetectorRef) {
    super(dt, cd);
    this.filterSubscription = this.dt.onFilter.subscribe({
      next: filter => {
        this.filterActive = this.filterKey in filter.filters;
      },
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.filterSubscription) {
      this.filterSubscription?.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterActive = this.filterKey in this.dt.filters;
    switch (this.type) {
      case 'string':
        this.labelSortAscending = 'sort-icon.sort-a-to-z';
        this.labelSortDescending = 'sort-icon.sort-z-to-a';
        break;
      case 'date':
        this.labelSortAscending = 'sort-icon.sort-old-to-new';
        this.labelSortDescending = 'sort-icon.sort-new-to-old';
        break;
      default:
        this.labelSortAscending = 'sort-icon.sort-ascending';
        this.labelSortDescending = 'sort-icon.sort-descending';
    }
    if (this.filter) {
      if (isMultiSelectItemArray(this.filter)) {
        this.filterMode = 'listbox';
        this.availableFilter = this.filter;
      } else if (isDateRange(this.filter)) {
        this.filterMode = 'daterange';
        this.availableDateRange = { before: this.filter.before, after: this.filter.after };
      } else if (isNumberRange(this.filter)) {
        this.filterMode = 'numberrange';
        this.availableNumberRange = {
          greaterThan: this.filter.greaterThan,
          lessThan: this.filter.lessThan,
        };
      } else {
        // this.filter === true -> decide by type
        this.filterMode =
          this.type === 'date' ? 'daterange' : this.type === 'numberrange' ? 'numberrange' : 'text';
      }
    }
  }

  onSortColumn(order: number): void {
    this.dt.sortField = this.field;
    this.dt.sortOrder = order;
    this.dt.sortSingle();
  }

  onShowMenu(): void {
    this.updateMenuContent();
  }

  onFilterSelectionChanged(event: { originalEvent: Event; value: MultiSelectItem[] }) {
    this.noFilterSelected = event.value.length === 0;
  }

  onEnterKey(event: Event) {
    // prevent "changed after checked" error when closing menu with enter key
    if (event.target instanceof HTMLElement) {
      event.target.blur();
    }
    this.onApply();
  }

  // this.dt.filter(value, field, matchMode) treats values of undefined, null, [] and /\s*/ as "blank" which means the filter entry for field is removed
  onApply(): void {
    if (!this.filter) {
      return;
    }
    switch (this.filterMode) {
      case 'text':
        this.dt.filter(this.currentSearchText, this.filterKey, FilterMatchMode.CONTAINS);
        break;
      case 'listbox':
        if (this.currentFilterSelection.length === this.availableFilter.length) {
          // every item selected -> clear filter on table
          this.dt.filter(undefined, this.filterKey, '');
        } else {
          this.dt.filter(this.currentFilterSelection, this.filterKey, FilterMatchMode.IN);
        }
        break;
      case 'daterange':
        this.applyDateRange();
        break;
      case 'numberrange':
        this.applyNumberRange();
        break;
    }
    this.menu.hide();
  }

  private applyDateRange() {
    const range: DateRange = {};
    const after = this.currentDateRange.after;
    const before = this.currentDateRange.before;
    const min = this.availableDateRange.after;
    const max = this.availableDateRange.before;
    if (after) {
      if (!min || after.getTime() > min.getTime()) {
        range.after = after;
      }
    }
    if (before) {
      if (!max || before.getTime() < max.getTime()) {
        range.before = before;
      }
    }
    if (range.after || range.before) {
      this.dt.filter(range, this.filterKey, FilterMatchMode.BETWEEN);
    } else {
      this.dt.filter(undefined, this.filterKey, '');
    }
  }

  private applyNumberRange() {
    const range: NumberRange = {};
    const greater = this.currentNumberRange.greaterThan;
    const less = this.currentNumberRange.lessThan;
    const min = this.availableNumberRange.greaterThan;
    const max = this.availableNumberRange.lessThan;
    if (greater) {
      if (!min || greater > min) {
        range.greaterThan = greater;
      }
    }
    if (less) {
      if (!max || less < max) {
        range.lessThan = less;
      }
    }
    if (range.greaterThan || range.lessThan) {
      this.dt.filter(range, this.filterKey, FilterMatchMode.BETWEEN);
    } else {
      this.dt.filter(undefined, this.filterKey, '');
    }
  }

  onCancel(): void {
    this.menu.hide();
  }

  onClearFilter(): void {
    this.dt.filter(undefined, this.filterKey, '');
  }

  private updateMenuContent(): void {
    const filterSetting = this.dt.filters[this.filterKey];
    const value: FilterType | undefined = Array.isArray(filterSetting)
      ? undefined
      : filterSetting?.value;
    switch (this.filterMode) {
      case 'text':
        this.currentSearchText = typeof value === 'string' ? value : '';
        this.noFilterSelected = false;
        break;
      case 'listbox':
        this.currentFilterSelection = isMultiSelectItemArray(value)
          ? [...value]
          : [...this.availableFilter];
        this.noFilterSelected = this.currentFilterSelection.length === 0;
        break;
      case 'daterange':
        this.currentDateRange = isDateRange(value)
          ? { after: value.after, before: value.before }
          : { after: this.availableDateRange.after, before: this.availableDateRange.before };
        this.noFilterSelected = false;
        break;
      case 'numberrange':
        this.currentNumberRange = isNumberRange(value)
          ? { greaterThan: value.greaterThan, lessThan: value.lessThan }
          : {
              greaterThan: this.availableNumberRange.greaterThan,
              lessThan: this.availableNumberRange.lessThan,
            };
        this.noFilterSelected = false;
        break;
    }
  }
}
