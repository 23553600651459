import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ENVIRONMENT, PageOfGroups } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GroupManagementService {
  private groupManagement = inject(ENVIRONMENT).groupManagement;

  constructor(private httpClient: HttpClient) {}

  public findGroups(search: string, offset: number, max: number): Observable<PageOfGroups> {
    if (!this.groupManagement) {
      return of({
        page: [],
        totalCount: 0,
        previous: null,
        next: null,
        users: [],
      });
    }

    let params: HttpParams = new HttpParams().set('max', max).set('offset', offset);

    const trimmedSearch = search.trim();
    if (trimmedSearch !== '') {
      params = params.set('search', trimmedSearch);
    }

    return this.httpClient.get<PageOfGroups>(this.groupManagement.apiUrl + 'groups', {
      params,
    });
  }
}
