import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '../models';
import { Feedback } from '../models/feedback';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  constructor(private httpClient: HttpClient) {}

  public sendFeedback(feedback: Feedback): Observable<void> {
    return this.httpClient.post<void>(this.apiUrl + '/feedback', feedback);
  }
}
