@if (!assetNode()) {
  <div class="loading">
    <p-progressSpinner strokeWidth="4"></p-progressSpinner>
  </div>
  <span class="loading">{{ 'assetTree.loadingNodes' | translate }}</span>
}

@if (assetNode(); as assetNode) {
  <app-asset-icon [asset]="assetNode"></app-asset-icon>
  @if (targetNavigationUrl()) {
    <a
      [routerLink]="targetNavigationUrl()"
      [ngClass]="{ ellipsis: ellipsis() }"
      (click)="preventBubbling($event)"
    >
      <span [title]="assetUuid()">{{ assetNode | assetTitle }}</span>
    </a>
  } @else {
    <span
      title="{{ 'liveconnect.components.endpoint-channels.noPermissions' | translate }}"
      [ngClass]="{ ellipsis: ellipsis() }"
      >{{ assetNode | assetTitle }}</span
    >
  }
}
