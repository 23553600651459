// Official SICK design colors
// Please see https://extranet.sick.com/display/SDS/Color for reference and description

// Official color palette
const primary600 = '#005b8e';
const primary500 = '#007cc1';
const primary400 = '#009df4';
const primary10 = '#bce7ff';
const primary3 = '#e5f6ff';

const accent600 = '#e36a00';
const accent500 = '#f39200';
const accent400 = '#ffa927';
const error500 = '#ea0823';
const error20 = '#fee9eb';
const warning500 = '#f5c413';
const warning100 = '#fdf5d7';
const success500 = '#63b017';
const success10 = '#e0f8c9';
const success5 = '#f0fbe4';

const neutral900 = '#141718';
const neutral800 = '#2c3133';
const neutral650 = '#50585c';
const neutral500 = '#737f85';
const neutral400 = '#8e989d';
const neutral300 = '#b1b8bc';
const neutral200 = '#c5cacc';
const neutral100 = '#e0e3e4';
const neutral50 = '#eeeff0';
const neutral20 = '#f6f7f7';
const neutral1 = '#fbfcfc';
const neutral0 = '#ffffff';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SDSColors = Object.freeze({
  // Official SICK design colors
  // Please see https://extranet.sick.com/display/SDS/Color for reference and description

  // Official color palette
  primary600,
  primary500,
  primary400,
  primary10,
  primary3,

  accent600,
  accent500,
  accent400,
  error500,
  error20,
  warning500,
  warning100,
  success500,
  success10,
  success5,

  neutral900,
  neutral800,
  neutral650,
  neutral500,
  neutral400,
  neutral300,
  neutral200,
  neutral100,
  neutral50,
  neutral20,
  neutral1,
  neutral0,

  // UI and neutral colors

  primary: primary500,
  primaryLight: primary400,
  primaryDark: primary600,

  secondary: neutral500,
  secondaryLight: neutral400,
  secondaryDark: neutral650,

  accent: accent500,
  accentLight: accent400,
  accentDark: accent600,
  textDark: neutral900,
  text: neutral800,
  textLight: neutral650,
  textLighter: neutral500,
  textDisabled: neutral300,
  background: neutral0,
  background2: neutral1,
  background3: neutral50,
  outline: neutral400,
  outlineLight: neutral200,

  // Status colors
  error: error500,
  errorLight: error20,
  success: success500,
  successLight: success10,
  warning: warning500,
  warningLight: warning100,
  informationPrimary: primary500,
  informationPrimaryLight: primary10,
});
