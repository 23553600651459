<div class="new-user" [ngClass]="{ narrow: narrow }">
  <app-text-field
    class="text-field-with-error"
    label="{{ 'assetPermissions.username' | translate }}"
    name="username"
    [(ngModel)]="username"
    [ngModelOptions]="{ standalone: true }"
    [errorMsg]="error | translate"
    (keyup.enter)="onNewPermission()"
    [required]="true"
  >
  </app-text-field>

  <p-dropdown
    class="permission-selector"
    [options]="permissionOptions"
    [checkmark]="true"
    [(ngModel)]="permissions"
    appendTo="body"
  >
    <ng-template let-permission pTemplate="selectedItem">
      {{ permission.label | translate }}
    </ng-template>
    <ng-template let-permission pTemplate="item">
      {{ permission.label | translate }}
    </ng-template>
  </p-dropdown>

  <button
    pButton
    type="button"
    label="{{ 'assetPermissions.grant' | translate }}"
    (click)="onNewPermission()"
  ></button>
</div>
