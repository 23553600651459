export class ProductId {
  public static readonly AUTHORITY_SICK = 'pid.sick.com';

  constructor(
    private authority: string,
    private typeId: string,
    private instanceId?: string,
  ) {}

  public static fromString(input: string): ProductId {
    const parts = input.split('/');

    if (parts.length < 2 || parts.length > 3) {
      throw new Error('Malformed Product ID');
    }

    const [authority, typeId, instanceId] = parts;
    if (!/^[a-zA-Z0-9\.]+$/.test(authority)) {
      throw new Error('Malformed authority');
    }

    if (!/^[a-zA-Z0-9]{7}$/.test(typeId)) {
      throw new Error('Malformed Type ID');
    }

    if (instanceId) {
      if (!/^[a-zA-Z0-9\.]{7,}$/.test(authority)) {
        throw new Error('Malformed Instance ID');
      }
    }

    return new ProductId(authority, typeId, instanceId);
  }

  public getAuthority(): string {
    return this.authority;
  }

  public isSICKAuthority(): boolean {
    return this.authority === ProductId.AUTHORITY_SICK;
  }

  public getTypeId(): string {
    return this.typeId;
  }

  public getInstanceId(): string | undefined {
    return this.instanceId;
  }

  public toString(): string {
    return `${this.authority}/${this.typeId}${this.instanceId ? '/' + this.instanceId : ''}`;
  }
}
