import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { EMPTY, catchError } from 'rxjs';
import { AssetItem, AssetRootItem } from '../models';
import { TreeService } from '../services/tree.service';

export const treeResolver: ResolveFn<AssetItem | AssetRootItem> = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);
  const treeService = inject(TreeService);
  const uuid: string | undefined = route.params.uuid;

  if (!uuid) {
    throw new Error('Route does not contain uuid parameter');
  }

  return treeService.fetchBranch(uuid).pipe(
    catchError(error => {
      if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 404)) {
        router.navigate(['error', 'assetnotfound'], { skipLocationChange: true });
        return EMPTY;
      }
      throw error;
    }),
  );
};
