<p-fileUpload
  #fileUpload
  name="files"
  [multiple]="true"
  [accept]="accept()"
  [hidden]="true"
  (onSelect)="onFilesSelected($event)"
></p-fileUpload>
@if (destination() === 'all') {
  <app-menu
    [icon]="icon()"
    label="{{ label() | translate }}"
    [iconFirst]="true"
    [class]="buttonClass()"
    [disabled]="busy()"
    (dragenter)="onDragEnter($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <app-menu-item
      icon="image"
      label="{{ 'mediaUpload.menu.images' | translate }}"
      (selected)="chooseFiles('images')"
    ></app-menu-item>
    <app-menu-item
      icon="file_present"
      label="{{ 'mediaUpload.menu.documents' | translate }}"
      (selected)="chooseFiles('documents')"
    ></app-menu-item>
  </app-menu>
} @else {
  <button
    pButton
    type="button"
    (click)="chooseFiles(destination())"
    (dragenter)="onDragEnter($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    [disabled]="busy()"
    [class]="buttonClass()"
  >
    <app-icon [shape]="icon()"></app-icon>{{ label() | translate }}
  </button>
}
