<div class="card" [ngClass]="{ 'clickable': !!onClick }" (click)="onClick!()">
  <div class="icon" *ngIf="icon">
    <ng-container>
      <app-icon [shape]="icon"></app-icon>
    </ng-container>
  </div>
  <div class="headers">
    <h4 class="header truncated multiline-2">{{ header }}</h4>
    <p class="subheader truncated multiline-3">{{ subheader }}</p>
  </div>
  <div class="image-container">
    <div class="image" *ngIf="image" [ngStyle]="{ 'background-image': 'url(' + image + ')' }"></div>
  </div>
  <div class="description">
    <ng-content></ng-content>
  </div>
  <app-divider *ngIf="actionbar.hasChildNodes()"></app-divider>
  <div #actionbar class="actionbar">
    <ng-content select="[actionbar]"></ng-content>
  </div>
</div>
