import { Component, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';

/**
 *
 * @example
 * ```html
 * <app-number-field
 *   label="Number"
 *   placeholder="Enter a number"
 *   error="{{ validateNumber }}"
 * </app-text-field>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [InputNumberModule, FormsModule, TooltipModule, NgClass, NgIf],
})
// Implements the ControlValueAccessor interface to support "ngModel"
export class NumberFieldComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  // buttons related properties
  @Input() showButtons = true;
  @Input() step = 1;
  // number related properties
  @Input() fractionDigits = false;
  @Input() maxFractionDigits = 20;
  @Input() min: number;
  @Input() max: number;
  @Input() maxlength: number;
  @Input() format = true;
  @Input() useGrouping = true;
  locale: string;
  // error related properties
  @Input() error = false;
  @Input() errorMsg?: string;
  @Input() handleErrorIconClick?: () => void;
  // tooltip related properties
  @Input() showTooltip = false;
  @Input() toolTipText = '';
  @Input() renderTooltipAsHtml = false;
  @Input() tooltipStyleClass?;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';

  @Output() blurred: EventEmitter<boolean> = new EventEmitter();

  private internalValue: number;

  private onTouch?: () => void;
  private onChange?: (val: number) => void;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.format) {
      this.locale = this.translateService.currentLang;
      this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(x => {
        this.locale = x.lang;
      });
    }
  }

  get contents(): number {
    return this.internalValue;
  }

  set contents(val: number) {
    this.internalValue = val;
    if (this.onChange) {
      this.onChange(val);
    }
  }

  writeValue(val: number): void {
    if (val !== this.internalValue) {
      this.internalValue = val;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }

    this.blurred.emit(true);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  errorIconClick() {
    if (this.handleErrorIconClick) {
      this.handleErrorIconClick();
    }
  }
}
