import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    AccordionModule,
    TableModule,
    SelectButtonModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    ProgressBarModule,
    AutoCompleteModule,
    DropdownModule,
    CheckboxModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    MultiSelectModule,
    RatingModule,
    DynamicDialogModule,
    TabMenuModule,
    ButtonModule,
    ToastModule,
    OverlayPanelModule,
    CalendarModule,
    MessageModule,
    DialogModule,
    TooltipModule,
    FieldsetModule,
    ConfirmDialogModule,
    BreadcrumbModule,
    ChipModule,
    SplitterModule,
    PaginatorModule,
    SkeletonModule,
    ListboxModule,
    ChartModule,
    SliderModule,
    TreeModule,
    OverlayModule,
    BlockUIModule,
    InputNumberModule,
  ],
})
export class PrimengModule {}
