import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Observable } from 'rxjs';
import { Link, NewLink } from '../models/link';

@Injectable({ providedIn: 'root' })
export class LinkService {
  private apiUrl = inject(ENVIRONMENT).apiUrl;
  private httpClient = inject(HttpClient);

  public deleteLink(assetUuid: string, linkUuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/asset/${assetUuid}/link/${linkUuid}`);
  }

  public addLink(assetUuid: string, link: NewLink): Observable<Link> {
    return this.httpClient.post<Link>(`${this.apiUrl}/asset/${assetUuid}/link`, link);
  }

  public updateLink(
    assetUuid: string,
    linkUuid: string,
    update: { name?: string; url?: string },
  ): Observable<Link> {
    return this.httpClient.put<Link>(`${this.apiUrl}/asset/${assetUuid}/link/${linkUuid}`, update);
  }

  public reorderLinks(assetUuid: string, reorderedLinks: Link[]) {
    return this.httpClient.put<void>(`${this.apiUrl}/asset/${assetUuid}/links/reorder`, {
      reorderedLinks: reorderedLinks.map(l => l.uuid),
    });
  }
}
