import { Component, Inject, Signal, input, signal } from '@angular/core';
import { ContactsResponse, Environment, ENVIRONMENT } from '@assethub/shared/models';
import { PermissionsService } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { catchError, map, switchMap, tap } from 'rxjs';
import { CalloutComponent } from '../../callout/callout.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-permission-insights',
  templateUrl: './permission-insights.component.html',
  styleUrls: ['./permission-insights.component.scss'],
  standalone: true,
  imports: [TableModule, TranslateModule, CalloutComponent],
})
export class PermissionInsightsComponent {
  readonly uuid = input.required<string>();
  readonly loading = signal(false);
  readonly contacts: Signal<ContactsResponse['contacts']>;
  readonly groupHref: string;

  constructor(@Inject(ENVIRONMENT) env: Environment, permissionsService: PermissionsService) {
    this.contacts = toSignal(
      toObservable(this.uuid).pipe(
        tap(() => this.loading.set(true)),
        switchMap(uuid => permissionsService.getContacts(uuid)),
        map(x => x.contacts),
        tap(() => this.loading.set(false)),
        catchError(() => (this.loading.set(false), [])),
      ),
      { initialValue: [] },
    );
    this.groupHref = env.groupManagement?.baseUrl || '';
  }
}
