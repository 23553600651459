<div class="self-diagnosis-panel">
  <h1>Self-Diagnosis Page</h1>
  <h3>Customer Token</h3>
  <table>
    <tr>
      <th>Name</th>
      <td>{{ name }}</td>
    </tr>
    <tr>
      <th>Login</th>
      <td>{{ currentLogin }}</td>
    </tr>
    <tr>
      <th>Privileges</th>
      <td>{{ privileges }}</td>
    </tr>
    <tr>
      <th>Token issued at</th>
      <td>{{ tokenIssuedAt | date: 'medium' }}</td>
    </tr>
    <tr>
      <th>Local time</th>
      <td>{{ now | date: 'medium' }}</td>
    </tr>
    <tr>
      <th>Token expires</th>
      <td>{{ tokenExpire | date: 'medium' }}</td>
    </tr>
    <tr>
      <th>Customer number</th>
      <td>{{ customernumber }}</td>
    </tr>
    <tr>
      <th>Subsidiary code</th>
      <td>{{ subsidiaryCode }}</td>
    </tr>
    <tr>
      <th>CustomerId</th>
      <td>{{ customerId }}</td>
    </tr>
    <tr>
      <th>Bearer Token</th>
      <td class="token">{{ token }}</td>
    </tr>
  </table>

  <h3>Consents</h3>
  <p><b>Status: </b>{{ consents }}</p>

  <h3>Licensing</h3>
  <p>Overrides are marked with an asterisk.</p>
  <table>
    <tr>
      <th>AssetHub</th>
      <td>{{ licensingService.hasBasic ? 'Basic' : 'None' }}{{ overrideAssetHub }}</td>
    </tr>
    <tr>
      <th>Live Connect</th>
      <td>{{ licensingService.hasLiveConnect }}{{ overrideLiveConnect }}</td>
    </tr>
  </table>
</div>
