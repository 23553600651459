import { QueryFilter } from '@assethub/shared/models';
import { SetGeoLocationRequest } from './geo-location';

export enum AssetHistoryEventTypeIds {
  ASSET_CREATED = 1,
  ASSET_UPDATED = 2,
  ASSET_MOVED = 3,
  ASSET_DELETED = 4,
  PERMISSION_GRANTED = 5,
  PERMISSION_CHANGED = 6,
  PERMISSION_REVOKED = 7,
  GEO_LOCATION_CREATED = 8,
  GEO_LOCATION_UPDATED = 9,
  GEO_LOCATION_DELETED = 10,
  ASSET_IMAGE_CREATED = 11,
  ASSET_IMAGE_DELETED = 12,
  LINK_ADDED = 13,
  LINK_UPDATED = 14,
  LINK_DELETED = 15,
  LINK_MOVED = 16,
  ASSET_CONVERTED_TO_COMPANY_TREE = 17,
  DOCUMENT_UPLOADED = 18,
  DOCUMENT_REMOVED = 19,
  ASSET_IMAGE_REJECTED = 20,
  SHARE_UNSUBSCRIBED = 21,
  SHARE_ACCEPTED = 22,
  SHARE_REJECTED = 23,
  DOCUMENT_REJECTED = 24,
  SENSOR_CONFIG_UPLOADED = 25,
  SENSOR_CONFIG_DELETED = 26,
  SENSOR_BACKUP_CREATED = 27,
  SENSOR_BACKUP_RESTORED = 28,
  CHILD_SWITCHED_TREE = 29,
  ASSET_MOVED_TO_TRASH = 30,
  ASSET_RESTORED_FROM_TRASH = 31,
}

// Auto-generate list of asset history event ids (enum additionally contains event keys, so filter
// these out ...)
export const ASSET_HISTORY_EVENT_TYPES = Object.keys(AssetHistoryEventTypeIds)
  .filter(key => !isNaN(Number(key)))
  .map(typeId => typeId as unknown as number);

export interface AssetHistoryCreateDetails {
  source: string;
}

export interface AssetHistoryUpdateDetails {
  field: string;
  from?: string;
  to?: string;
}

export interface AssetHistoryAsset {
  uuid: string;
  typeId: number;
  name?: string;
}

export interface AssetHistoryMoveDetails {
  from?: {
    parent: AssetHistoryAsset;
    position: number;
  };
  to: {
    parent: AssetHistoryAsset;
    position: number;
  };
}

interface AssetHistoryDeleteDetails {
  asset: AssetHistoryAsset;
}

interface AssetHistoryPermissionGrantedDetails {
  permission: string;
  to: string;
}

interface AssetHistoryPermissionChangeDetails {
  from: string;
  to: string;
  subject: string;
}

interface AssetHistoryPermissionRevokeDetails {
  permission: string;
  from: string;
}

interface AssetHistoryGeoLocationChangedDetails {
  from: SetGeoLocationRequest;
  to: SetGeoLocationRequest;
}

interface AssetHistoryImageDetails {
  imageName: string;
}

interface AssetHistoryLinkDetails {
  name?: string;
  url?: string;
}

export interface AssetHistoryLinkUpdateDetails {
  to?: string;
  from?: string;
  field?: string;
}

interface AssetHistoryDocumentEventDetails {
  name: string;
  size: number;
}

interface AssetHistoryConfigurationEventDetails {
  configName?: string;
  configType: string;
}

interface AssetHistoryChildChangeTreeDetails {
  affectedChild: {
    asset: AssetHistoryAsset;
    position: number;
  };
  targetTree: AssetHistoryAsset;
  to: {
    parent: AssetHistoryAsset;
    position: number;
  };
}

interface AssetHistoryConvertedToCompanyTreeDetails {
  owner: {
    name: string;
    extUserId: string;
  };
  customerNumber: string;
  subsidiaryCode: string;
  permission: string;
}

interface BaseAssetHistoryEvent {
  uuid: string;
  ts: number;
  user: string;
  asset: AssetHistoryAsset;
}

interface AssetHistoryCreateEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.ASSET_CREATED;
  details: AssetHistoryCreateDetails;
}

interface AssetHistoryUpdateEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.ASSET_UPDATED;
  details: AssetHistoryUpdateDetails;
}

interface AssetHistoryMoveEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.ASSET_MOVED;
  details: AssetHistoryMoveDetails;
}

interface AssetHistoryDeleteEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.ASSET_DELETED
    | AssetHistoryEventTypeIds.ASSET_MOVED_TO_TRASH
    | AssetHistoryEventTypeIds.ASSET_RESTORED_FROM_TRASH;
  details: AssetHistoryDeleteDetails;
}

interface AssetHistoryPermissionGrantEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.PERMISSION_GRANTED;
  details: AssetHistoryPermissionGrantedDetails;
}

interface AssetHistoryPermissionChangeEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.PERMISSION_CHANGED;
  details: AssetHistoryPermissionChangeDetails;
}

interface AssetHistoryPermissionRevokeEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.PERMISSION_REVOKED;
  details: AssetHistoryPermissionRevokeDetails;
}

interface AssetHistoryGeoLocationEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.GEO_LOCATION_CREATED
    | AssetHistoryEventTypeIds.GEO_LOCATION_DELETED;
  details: SetGeoLocationRequest;
}

interface AssetHistoryGeoLocationChangeEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.GEO_LOCATION_UPDATED;
  details: AssetHistoryGeoLocationChangedDetails;
}

interface AssetHistoryImageEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.ASSET_IMAGE_CREATED
    | AssetHistoryEventTypeIds.ASSET_IMAGE_DELETED
    | AssetHistoryEventTypeIds.ASSET_IMAGE_REJECTED;
  details: AssetHistoryImageDetails;
}

interface AssetHistoryLinkEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.LINK_ADDED | AssetHistoryEventTypeIds.LINK_DELETED;
  details: AssetHistoryLinkDetails;
}

interface AssetHistoryLinkUpdateEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.LINK_UPDATED | AssetHistoryEventTypeIds.LINK_MOVED;
  details: AssetHistoryLinkUpdateDetails;
}

interface AssetHistoryDocumentEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.DOCUMENT_UPLOADED
    | AssetHistoryEventTypeIds.DOCUMENT_REMOVED
    | AssetHistoryEventTypeIds.DOCUMENT_REJECTED;
  details: AssetHistoryDocumentEventDetails;
}

interface AssetHistoryConfigEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.SENSOR_CONFIG_UPLOADED
    | AssetHistoryEventTypeIds.SENSOR_CONFIG_DELETED
    | AssetHistoryEventTypeIds.SENSOR_BACKUP_CREATED
    | AssetHistoryEventTypeIds.SENSOR_BACKUP_RESTORED;
  details: AssetHistoryConfigurationEventDetails;
}

interface AssetHistoryChildChangeTreeEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.CHILD_SWITCHED_TREE;
  details: AssetHistoryChildChangeTreeDetails;
}

interface AssetHistoryConvertedToCompanyTreeEvent extends BaseAssetHistoryEvent {
  type: AssetHistoryEventTypeIds.ASSET_CONVERTED_TO_COMPANY_TREE;
  details: AssetHistoryConvertedToCompanyTreeDetails;
}

interface AssetHistoryEmptyDetailsEvent extends BaseAssetHistoryEvent {
  type:
    | AssetHistoryEventTypeIds.SHARE_UNSUBSCRIBED
    | AssetHistoryEventTypeIds.SHARE_ACCEPTED
    | AssetHistoryEventTypeIds.SHARE_REJECTED;

  details: Record<string, never>;
}

export type AssetHistoryEvent =
  | AssetHistoryCreateEvent
  | AssetHistoryUpdateEvent
  | AssetHistoryMoveEvent
  | AssetHistoryDeleteEvent
  | AssetHistoryPermissionGrantEvent
  | AssetHistoryPermissionChangeEvent
  | AssetHistoryPermissionRevokeEvent
  | AssetHistoryGeoLocationEvent
  | AssetHistoryGeoLocationChangeEvent
  | AssetHistoryImageEvent
  | AssetHistoryLinkEvent
  | AssetHistoryLinkUpdateEvent
  | AssetHistoryDocumentEvent
  | AssetHistoryConfigEvent
  | AssetHistoryChildChangeTreeEvent
  | AssetHistoryConvertedToCompanyTreeEvent
  | AssetHistoryEmptyDetailsEvent;

export interface AssetHistoryResponse {
  events: AssetHistoryEvent[];
  metadata: HistoryMetadata;
}

export interface HistoryMetadata {
  total: number;
  // Properties should be undefined only in case no history events found
  users?: {
    username: string;
    // Disabled due to backend response
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_user_id: string;
  }[];
  dateRange?: {
    first: number;
    last: number;
  };
}

export interface AssetHistoryEntries {
  date: Date | null;
  type: number;
  asset: AssetHistoryAsset;
  details: AssetHistoryEvent['details'];
  user: string;
}

export interface HistoryQuery extends QueryFilter {
  after?: number;
  before?: number;
  order?: number;
  users?: string[];
  events?: number[];
  includeChildrensHistory?: boolean;
}

export interface EventHistoryFilter {
  dateRange: number[];
  users: {
    label: string;
    value: string;
  }[];
  events: {
    label: string;
    value: string;
  }[];
}
