import { Component, Input } from '@angular/core';
import { LazyLoadTable } from '@assethub/shared/utils/lazy-load-table';
import { CalloutComponent } from '../callout/callout.component';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

@Component({
  selector: 'app-lazy-load-table-messages',
  templateUrl: './lazy-load-table-messages.component.html',
  styleUrls: ['./lazy-load-table-messages.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, CalloutComponent, NgIf],
})
export class LazyLoadTableMessagesComponent<T> {
  @Input() listContext: LazyLoadTable<T>;
  @Input() noDataMsg: string;
  @Input() nofilteredDataMsg: string;
  @Input() errorMsg?: string;
}
