import { Injectable } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { interval, Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private logger = new Logger(this.constructor.name);

  // istanbul ignore next
  public connect<T>(url: string, keepAlive = false): Subject<T> {
    const wsUrl = url.replace(/^https\:\/\//, 'wss://');
    this.logger.debug('Connecting to WebSocket ', wsUrl);
    const ws = webSocket<T>({ url: wsUrl });

    if (keepAlive) {
      // Websocket must receive a keepalive every <10 minutes
      const timer = interval(9.5 * 60 * 1000).subscribe(() => {
        this.logger.debug(`Keeping alive WebSocket ${wsUrl}`);
        ws.next({ action: 'PING' } as unknown as T);
      });

      ws.asObservable().subscribe({
        complete: () => {
          this.logger.info('WebSocket was closed, stopped keeping alive');
          timer.unsubscribe();
        },
      });
    }

    return ws;
  }
}
