import { Component, OnDestroy, OnInit } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { IconComponent } from '../icon/icon.component';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-auth-notification',
  templateUrl: './auth-notification.component.html',
  styleUrls: ['./auth-notification.component.scss'],
  standalone: true,
  imports: [DialogModule, IconComponent, ButtonModule, TranslateModule],
})
export class AuthNotificationComponent implements OnInit, OnDestroy {
  public showMessage = false;

  private subscription?: Subscription;

  constructor(private oauth: OAuthService) {}

  public ngOnInit() {
    this.subscription = this.oauth.events.subscribe((event: OAuthEvent) => {
      switch (event.type) {
        case 'token_refresh_error': {
          this.showMessage = true;
          break;
        }
      }
    });
  }

  public reinitiateLogin() {
    this.oauth.logOut(true);
    window.location.reload();
  }

  public dismiss() {
    this.showMessage = false;
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
