import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, debounceTime, map, of, switchMap } from 'rxjs';
import { ENVIRONMENT, TableConfig, TableStatus } from '../models';

type TableConfigSubject<T> = BehaviorSubject<TableConfig<T> | undefined>;

interface SetTableConfigDelayed {
  tableName: string;
  tableConfig: TableConfig<unknown>;
}

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class TableConfigService {
  private apiUrl: string = inject(ENVIRONMENT).apiUrl;
  private setTableConfigSubject = new Subject<SetTableConfigDelayed>();
  private tableConfigSubjects = new Map<string, TableConfigSubject<unknown>>();
  private tableStatusRequest?: Observable<void>;

  constructor(private httpClient: HttpClient) {
    this.setTableConfigSubject
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(5000))
      .pipe(switchMap(x => this.storeTableConfig(x.tableName, x.tableConfig)))
      .subscribe();
  }

  getTableConfig<T>(tableName: string): Observable<TableConfig<T> | undefined> {
    if (!tableName) {
      return of(undefined);
    }
    let subject = this.tableConfigSubjects.get(tableName) as TableConfigSubject<T>;
    if (!subject) {
      subject = new BehaviorSubject<TableConfig<T> | undefined>(undefined);
      this.tableConfigSubjects.set(tableName, subject);
    }
    return subject.asObservable();
  }

  setTableConfig(tableName: string, tableConfig: TableConfig<unknown>) {
    this.updateTableConfig(tableName, tableConfig);
    this.storeTableConfig(tableName, tableConfig).subscribe();
  }

  setTableConfigDelayed(tableName: string, tableConfig: TableConfig<unknown>) {
    this.updateTableConfig(tableName, tableConfig);
    this.setTableConfigSubject.next({ tableName, tableConfig });
  }

  fetchTableConfigs(): Observable<void> {
    if (this.tableConfigSubjects.size > 0) {
      this.tableStatusRequest = undefined;
      return of(void 0);
    }

    if (this.tableStatusRequest) {
      return this.tableStatusRequest;
    }

    this.tableStatusRequest = this.httpClient
      .get<TableStatus[]>(`${this.apiUrl}/table-statuses`)
      .pipe(
        map(x => {
          this.tableConfigSubjects.clear();
          for (const y of x) {
            const status: TableConfig<unknown> = JSON.parse(y.tableStatus);
            this.tableConfigSubjects.set(
              y.tableName,
              new BehaviorSubject<TableConfig<unknown> | undefined>(status),
            );
          }
        }),
      );

    return this.tableStatusRequest;
  }

  deleteTableConfig(tableName: string) {
    if (!tableName) {
      return;
    }
    const subject = this.tableConfigSubjects.get(tableName);
    if (subject) {
      subject.next(undefined);
    }
    this.httpClient.delete<void>(`${this.apiUrl}/table-status/${tableName}`).subscribe();
  }

  private updateTableConfig(tableName: string, tableConfig: TableConfig<unknown>) {
    if (!tableName) {
      return;
    }
    let subject = this.tableConfigSubjects.get(tableName);
    if (!subject) {
      subject = new BehaviorSubject<TableConfig<unknown> | undefined>(tableConfig);
      this.tableConfigSubjects.set(tableName, subject);
    } else {
      subject.next(tableConfig);
    }
  }

  private storeTableConfig(tableName: string, tableConfig: TableConfig<unknown>): Observable<void> {
    if (!tableName) {
      return of();
    }
    const tableStatus = JSON.stringify(tableConfig);
    const body: TableStatus = { tableStatus, tableName };
    return this.httpClient.put<void>(`${this.apiUrl}/table-status/${tableName}`, body);
  }
}
