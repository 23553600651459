import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { Logger } from '@assethub/shared/utils';
import { ResizedDirective } from '../../directives/resized/resized.directive';

@Component({
  selector: 'app-basic-heremap',
  styleUrls: ['./basic-heremap.component.scss'],
  templateUrl: './basic-heremap.component.html',
  standalone: true,
  imports: [NgClass, ResizedDirective],
})
export class BasicHeremapComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() latitude?: number;
  @Input() longitude?: number;
  @Input() mapVisible?: boolean = false;
  @Input() narrow?: boolean = false;

  @ViewChild('map') mapElement: ElementRef;

  private platform: H.service.Platform;
  private map: H.Map;
  private marker: H.map.Marker;
  private logger = new Logger(this.constructor.name);
  private resizeTimerId?: number;

  private hereWeGoApiKey = inject(ENVIRONMENT).hereWeGoApiKey;

  constructor() {}

  ngOnInit(): void {
    try {
      this.platform = new H.service.Platform({
        apikey: this.hereWeGoApiKey,
      });
    } catch (ex) {
      this.logger.error('Failed to initialize Here Maps', ex);
    }
  }

  ngAfterViewInit() {
    try {
      this.setupMap();
      this.onDataInputChanged();
    } catch (error) {
      this.onError(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.platform) {
      return;
    }
    if (changes['latitude'].isFirstChange() || changes['longitude'].isFirstChange()) {
      return;
    }
    if (changes['latitude'] || changes['longitude']) {
      this.onDataInputChanged();
    }
  }

  private onDataInputChanged() {
    try {
      this.validateInput();
      this.updateMap();
    } catch (error) {
      this.onError(error);
    }
  }

  onError(err: Error) {
    this.mapVisible = false;
    this.logger.error(err.message);
  }

  private validateInput() {
    if (!this.longitude || !this.latitude) {
      throw Error('latitude nor longitude is not defined');
    }
    if (isNaN(this.latitude) || this.latitude < -90 || this.latitude > 90) {
      throw Error('latitude is not a number or invalid value');
    }
    if (isNaN(this.longitude) || this.longitude < -180 || this.longitude > 180) {
      throw Error('longitude is not a number or invalid value');
    }
  }

  private setupMap() {
    if (!this.platform) {
      throw Error('platform is not defined');
    }
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(this.mapElement.nativeElement, defaultLayers.vector.normal.map, {
      zoom: 17,
      center: { lat: 48.0889987, lng: 7.9472946 },
    });
    const ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const scalebar = ui.getControl('scalebar');
    if (scalebar) {
      scalebar.setAlignment(H.ui.LayoutAlignment.TOP_LEFT);
    }
    const mapsettings = ui.getControl('mapsettings');
    if (mapsettings) {
      mapsettings.setDisabled(true);
      mapsettings.setVisibility(false);
    }
    const panorama = ui.getControl('panorama');
    if (panorama) {
      panorama.setDisabled(true);
      panorama.setVisibility(false);
    }
    const mapEvents = new H.mapevents.MapEvents(this.map);
    new H.mapevents.Behavior(mapEvents);
    this.marker = new H.map.Marker({ lat: 48.0889987, lng: 7.9472946 });
    this.map.addObject(this.marker);
  }

  private updateMap() {
    if (!this.map) {
      throw Error('map is not defined');
    }
    if (!this.longitude || !this.latitude) {
      throw Error('latitude nor longitude is not defined');
    }
    this.marker.setGeometry({ lat: this.latitude, lng: this.longitude });
    this.map.setCenter({ lat: this.latitude, lng: this.longitude });
    this.map.getViewPort().resize();
    setTimeout(() => {
      // This is necessary to avoid an ExpressionHasChangedAfterItHasBeenChecked error
      this.mapVisible = true;
    }, 0);
  }

  onResized() {
    if (!this.latitude || !this.longitude) {
      return;
    }
    if (!this.map) {
      return;
    }
    if (this.resizeTimerId !== undefined) {
      window.clearTimeout(this.resizeTimerId);
    }
    this.resizeTimerId = window.setTimeout(() => {
      this.map.getViewPort().resize();
      this.resizeTimerId = undefined;
    }, 125);
  }
}
