<app-navigation-spinner></app-navigation-spinner>

<div class="main">
  <div *ngIf="reason === 'subscription'" class="error">
    <div class="messagebox">
      <div class="heading">
        <app-icon shape="info"></app-icon>
        {{ 'error.subscription.title_' + subscription | translate }}
      </div>
      <div class="message">
        {{ 'error.subscription.beginMessage' | translate }}
        <a href="{{ serviceCatalogUrl }}">{{ 'error.subscription.link' | translate }}</a>
        {{ 'error.subscription.endMessage' | translate }}
      </div>
    </div>
  </div>

  <app-asset-error
    *ngIf="reason === 'assetnotfound'"
    errorTitle="{{ 'error.assetNotFound.title' | translate }}"
    errorDetails="{{ 'error.assetNotFound.message' | translate }}"
  >
  </app-asset-error>

  <app-asset-error
    *ngIf="reason === 'generic'"
    errorTitle="{{ 'error.generic.title' | translate }}"
    errorDetails="{{ 'error.generic.message' | translate }}"
  >
  </app-asset-error>

  <app-asset-error
    *ngIf="reason === 'authentication'"
    errorTitle="{{ 'error.authentication.title' | translate }}"
    errorDetails="{{ 'error.authentication.message' | translate }}"
  >
  </app-asset-error>
</div>
