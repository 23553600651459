<div class="container">
  <span id="title" *ngIf="title">{{ title }}</span>
  <div id="tag-container" [ngClass]="{ 'narrow': narrow }">
    <div
      #scroller
      id="scroller"
      [style]="{ height: currHeightPx + 'px', maxHeigt: maxHeightPx + 'px' }"
      [ngClass]="{ 'prevent-scroll': !expanded || currHeightPx < maxHeightPx }"
    >
      <div #tagContainer id="tags" (resized)="resizeTagView()">
        <p-chip
          *ngFor="let tag of tags"
          [ngClass]="[tag.styleClass || '']"
          [removable]="true"
          removeIcon="pi pi-times"
          (onRemove)="deleteTag(tag)"
        >
          <span class="tag-content">{{
            !tag.translate ? tag.displayValue : (tag.displayValue | translate)
          }}</span>
        </p-chip>
      </div>
    </div>
    <div id="tag-options">
      <button
        pButton
        type="button"
        class="ui-button-bare"
        label="{{ 'tags.clear_all' | translate }}"
        (click)="clearAll()"
        *ngIf="tags.length > 1"
      ></button>
      <ng-container *ngIf="maxTagRowsExceeded()">
        <ng-container
          *ngIf="expanded; then showCollapseOption; else showExpandOption"
        ></ng-container>
        <ng-template #showExpandOption>
          <button
            pButton
            type="button"
            class="ui-button-bare"
            icon="pi pi-angle-down icon-label-button"
            label="{{ expandLabel || ('tags.expand_view' | translate) }}"
            (click)="expandTags()"
          ></button>
        </ng-template>
        <ng-template #showCollapseOption>
          <button
            pButton
            type="button"
            class="ui-button-bare"
            icon="pi pi-angle-up icon-label-button"
            label="{{ collapseLabel || ('tags.collapse_view' | translate) }}"
            (click)="collapseTags()"
          ></button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
