import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { AssetService, LanguageService, LicensingService, TreeService } from '../../services';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-menu-item-asset-edit',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetEditComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private assetService: AssetService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('edit', 'assetDetails.menu.edit');
  }

  static override isViableOption(asset?: GetAssetResponse | AssetItem): boolean {
    if (BasicMenuItemAssetComponent.isManagedExternally(asset)) {
      return false;
    }
    return BasicMenuItemAssetComponent.isWritable(asset);
  }

  protected override getReason(): string {
    if (BasicMenuItemAssetComponent.isManagedExternally(this.asset)) {
      return 'managedExternally';
    }
    if (!BasicMenuItemAssetComponent.isWritable(this.asset)) {
      return 'editRequiresWrite';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.assetService.editMode.set(true);
  }
}
