import { Component, Input } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';

/**
 * A card component.
 *
 * Shows a card with an optional icon, header, subheader and image.
 * An action bar with buttons can be added, then the `<div>` must contain the
 * *actionbar* attribute.
 *
 * @example
 * ```html
 * <app-card
 *   icon="maps/traffic"
 *   header="Service"
 *   subheader="Get help!"
 *   image="service.png"
 * >
 *   Order SICK service.
 *   <div actionbar>
 *     [...]
 *   </div>
 * </app-card>
 * ```
 */

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, IconComponent, NgStyle, DividerComponent],
})
export class CardComponent {
  /**
   * Specifies the header of the card.
   */
  @Input()
  header = '';

  /**
   * Specifies the subheader of the card.
   *
   * It will be displayed below the card header.
   */
  @Input()
  subheader = '';

  /**
   * Specifies the icon of the card.
   *
   * It will be shown next to the headers. Header and subheader will be indented
   * to the right side.
   */
  @Input()
  icon?: string = '';

  /**
   * Specifies the image of the card.
   *
   * It will be displayed below the card header and subheader.
   */
  @Input()
  image = '';

  @Input()
  onClick?: () => void;
}
